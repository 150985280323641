<template>
    <div class="flex flex-col mt-2">
        <div class="flex items-center">
            <div
                @click="goBack"
                class="bg-white px-3 py-2 rounded cursor-pointer"
            >
                <i class="la la-arrow-left font-bold"></i>
            </div>

            <p class="px-2 font-semibold">{{ selectedPortolio?.name }}</p>
        </div>
        <div class="mt-4 bg-white px-3 py-2 rounded" v-if="selectedPortolio">
            <div class="flex py-4">
                <div class="w-2/5">
                    <label class="font-semibold" for="name">Nom</label>
                    <Field v-slot="{ field }" name="name" v-model="name">
                        <input
                            type="text"
                            id="name"
                            v-bind="field"
                            placeholder="Nom du portfolio"
                            class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                    /></Field>
                </div>
                <div class="flex items-end w-3/5 justify-end">
                    <button
                        class="ml-4 rounded bg-primary text-white px-3 py-2"
                        type="submit"
                        variant="primary"
                        @click="editPortfolioName"
                    >
                        Valider
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-4 bg-white px-3 py-4 rounded" v-if="selectedPortolio">
            <div class="flex flex-col w-full">
                <h3 class="font-bold">
                    Configuration de téléphone
                    <el-tooltip content="" placement="top"
                        ><template #content>
                            Ces numeros sont utilisés pour <br />
                            valider une intervention quand vous <br />
                            n'étes pas en ligne.</template
                        >
                        <i class="la la-info-circle ml-2"></i>
                    </el-tooltip>
                </h3>
                <div class="flex w-1/2 mt-4">
                    <div class="flex flex-col w-4/5">
                        <label class="font-semibold" for="name"
                            >Téléphone 1</label
                        >

                        <div
                            class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                        >
                            <Field
                                v-slot="{ field }"
                                name="phone_1"
                                v-model="phones.phone_1"
                            >
                                <phone-number-input
                                    type="text"
                                    v-bind="field"
                                    :defaultCountry="countryCode"
                                    placeholder="Numero de télephone *"
                                    class="doctor-info-phone h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                            /></Field>
                        </div>
                    </div>
                    <div
                        v-if="validatingPhoneField === 'phone_1'"
                        class="w-1/5 pl-2"
                    >
                        <label class="font-semibold" for="name">Code</label>
                        <Field
                            v-slot="{ field }"
                            name="code_1"
                            v-model="code_1"
                        >
                            <input
                                type="text"
                                id="code_1"
                                v-bind="field"
                                placeholder="Code "
                                class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                        /></Field>
                    </div>
                    <div class="flex items-end w-3/5 justify-end">
                        <button
                            :class="`rounded bg-opacity-15 ${
                                selectedPortolio?.details
                                    ?.phone_1_verified_at &&
                                validatingPhoneField !== 'phone_1'
                                    ? 'bg-success text-success'
                                    : 'bg-warning text-warning'
                            }  px-3 py-2 `"
                            type="submit"
                            variant="primary"
                            @click="handlePhone('phone_1')"
                        >
                            {{
                                selectedPortolio?.details
                                    ?.phone_1_verified_at &&
                                validatingPhoneField !== "phone_1"
                                    ? "Changer"
                                    : "Valider"
                            }}
                        </button>
                        <button
                            class="ml-2 rounded bg-danger text-danger px-3 py-2 bg-opacity-15"
                            type="submit"
                            variant="primary"
                            @click="clearPhone('phone_1')"
                        >
                            Effacer
                        </button>
                    </div>
                </div>
                <div class="flex w-1/2 mt-3">
                    <div class="flex flex-col w-4/5">
                        <label class="font-semibold" for="name"
                            >Téléphone 2</label
                        >
                        <div
                            class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                        >
                            <Field
                                v-slot="{ field }"
                                name="phone_2"
                                v-model="phones.phone_2"
                            >
                                <phone-number-input
                                    type="text"
                                    v-bind="field"
                                    :defaultCountry="countryCode"
                                    placeholder="Numero de télephone *"
                                    class="doctor-info-phone h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                />
                            </Field>
                        </div>
                    </div>
                    <div
                        v-if="validatingPhoneField === 'phone_2'"
                        class="w-1/5 pl-2"
                    >
                        <label class="font-semibold" for="name">Code</label>
                        <Field
                            v-slot="{ field }"
                            name="code_2"
                            v-model="code_2"
                        >
                            <input
                                type="text"
                                id="code_2"
                                v-bind="field"
                                placeholder="Code "
                                class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                        /></Field>
                    </div>
                    <div class="flex items-end w-3/5 justify-end">
                        <button
                            :class="`rounded bg-opacity-15 ${
                                selectedPortolio?.details?.phone_2 &&
                                validatingPhoneField !== 'phone_2'
                                    ? 'bg-success text-success'
                                    : 'bg-warning text-warning'
                            } px-3 py-2`"
                            type="submit"
                            variant="primary"
                            @click="handlePhone('phone_2')"
                        >
                            {{
                                selectedPortolio?.details
                                    ?.phone_2_verified_at &&
                                validatingPhoneField !== "phone_2"
                                    ? "Changer"
                                    : "Valider"
                            }}
                        </button>

                        <button
                            class="ml-2 rounded bg-opacity-15 bg-danger text-danger px-3 py-2"
                            type="submit"
                            variant="primary"
                            @click="clearPhone('phone_2')"
                        >
                            Effacer
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 bg-white px-3 py-4 rounded">
            <h3 class="text-gray">Souscriptions (Modules)</h3>

            <div class="flex mt-3">
                <div
                    v-for="(mod, index) in modules"
                    :key="index"
                    class="w-1/3"
                    @click.prevent="displaySubscriptionDetail(mod)"
                >
                    <div class="w-11/12 flex p-3 rounded hover:bg-slate-300/20">
                        <div class="w-14 h-14 rounded overflow-hidden">
                            <img
                                v-if="mod.slug == 'patients-managment'"
                                class="object-cover w-14 h-14 rounded overflow-hidden"
                                src="/images/hospital-b.png"
                            />
                            <img
                                v-if="
                                    mod.slug == 'medical-appointment-managment'
                                "
                                class="object-cover w-14 h-14 rounded overflow-hidden"
                                src="/images/doctor-b.png"
                            />
                            <img
                                v-if="mod.slug == 'emergency-managment'"
                                class="object-cover w-14 h-14 rounded overflow-hidden"
                                src="/images/doctor-c.png"
                            />
                        </div>
                        <div class="pl-2">
                            <p
                                class="text-md text-slate-800 font-semibold font-ibm"
                            >
                                {{ mod.name }}
                            </p>

                            <!-- <p class="text-sm text-slate-500">
                                {{ mod.description ?? "-" }}
                            </p> -->

                            <div class="flex flex-col justify-center mt-2">
                                <p
                                    v-if="
                                        selectedPortolio.modules.find(
                                            (x) => x.id == mod.id
                                        )
                                    "
                                >
                                    {{
                                        selectedPortolio.modules.find(
                                            (x) => x.id == mod.id
                                        )?.month_default_price
                                    }}f CFA/mois
                                </p>
                                <p v-else>
                                    {{ mod.month_default_price }}f CFA/mois
                                </p>
                                <span
                                    :class="`bg-${
                                        selectedPortolio.modules.find(
                                            (x) => x.id == mod.id
                                        )
                                            ? ''
                                            : ''
                                    } text-${
                                        selectedPortolio.modules.find(
                                            (x) => x.id == mod.id
                                        )
                                            ? 'success'
                                            : 'warning'
                                    } font-bold rounded-lg py-1 text-xs mt-2`"
                                >
                                    {{
                                        selectedPortolio.modules.find(
                                            (x) => x.id == mod.id
                                        )
                                            ? "Souscris"
                                            : "Non souscris"
                                    }}
                                    <i
                                        :class="`la la-${
                                            selectedPortolio.modules.find(
                                                (x) => x.id == mod.id
                                            )
                                                ? 'check'
                                                : 'times'
                                        } text-${
                                            selectedPortolio.modules.find(
                                                (x) => x.id == mod.id
                                            )
                                                ? 'success'
                                                : 'warning'
                                        } mx-2`"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="showSubscriptionModal"
        header="Souscription"
        :style="{ width: '40rem' }"
        :breakpoints="{ '1199px': '60vw', '575px': '90vw' }"
        position="bottom"
        :modal="true"
        :draggable="false"
    >
        <div class="h-54">
            <div class="h-5/6" style="height: 90%">
                <div class="w-full flex p-3 rounded">
                    <div class="w-14 h-14 rounded overflow-hidden">
                        <img
                            v-if="
                                showSubscriptionData?.slug ==
                                'patients-managment'
                            "
                            class="object-cover w-14 h-14 rounded overflow-hidden"
                            src="/images/hospital-b.png"
                        />
                        <img
                            v-if="
                                showSubscriptionData.slug ==
                                'medical-appointment-managment'
                            "
                            class="object-cover w-14 h-14 rounded overflow-hidden"
                            src="/images/doctor-b.png"
                        />
                        <img
                            v-if="
                                showSubscriptionData.slug ==
                                'emergency-managment'
                            "
                            class="object-cover w-14 h-14 rounded overflow-hidden"
                            src="/images/doctor-c.png"
                        />
                    </div>
                    <div class="pl-2">
                        <p
                            class="text-md text-slate-800 font-semibold font-ibm"
                        >
                            {{ showSubscriptionData.name }}
                        </p>

                        <p class="text-sm text-slate-500 py-2">
                            {{ showSubscriptionData.description ?? "-" }}
                        </p>

                        <div class="flex flex-col justify-center mt-2">
                            <p
                                v-if="
                                    selectedPortolio.modules.find(
                                        (x) => x.id == showSubscriptionData.id
                                    )
                                "
                            >
                                {{
                                    selectedPortolio.modules.find(
                                        (x) => x.id == showSubscriptionData.id
                                    )?.month_default_price
                                }}f CFA/mois
                            </p>
                            <p v-else>
                                {{ showSubscriptionData.month_default_price }}f
                                CFA/mois
                            </p>
                            <span
                                :class="`bg-${
                                    selectedPortolio.modules.find(
                                        (x) => x.id == showSubscriptionData.id
                                    )
                                        ? ''
                                        : ''
                                } text-${
                                    selectedPortolio.modules.find(
                                        (x) => x.id == showSubscriptionData.id
                                    )
                                        ? 'success'
                                        : 'warning'
                                } font-bold rounded-lg py-1 text-xs mt-2`"
                            >
                                {{
                                    selectedPortolio.modules.find(
                                        (x) => x.id == showSubscriptionData.id
                                    )
                                        ? "Souscris"
                                        : "Non souscris"
                                }}
                                <i
                                    :class="`la la-${
                                        selectedPortolio.modules.find(
                                            (x) =>
                                                x.id == showSubscriptionData.id
                                        )
                                            ? 'check'
                                            : 'times'
                                    } text-${
                                        selectedPortolio.modules.find(
                                            (x) =>
                                                x.id == showSubscriptionData.id
                                        )
                                            ? 'success'
                                            : 'warning'
                                    } mx-2`"
                                ></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button
                label="Fermer"
                icon="la la-times"
                class="shadow-none mr-2 outline-none font-medium px-3 py-1"
                @click="closeModal"
                text
            />
            <Button
                label="Valider"
                icon="la la-check"
                :disabled="disabledAddresBtn"
                class="bg-primary3 px-3 py-1 text-white rounded shadow-none font-medium"
                @click="showPositionModal = false"
                autofocus
            />
        </template>
    </Dialog>
</template>
<script lang="js">
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import PhoneInput from "@/components/PhoneInput";
import { ElTooltip } from "element-plus";
import { Field } from "vee-validate";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Button from "primevue/button";
export default {
    components: { Field, ElTooltip, PhoneInput, Button, Menu, Dialog },
    setup() {
        const showSubscriptionModal =ref(false)
        const showSubscriptionData =ref(null)
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const currentField = ref(null);
        const validatingPhoneField = ref(null);
        const code_1 = ref(null);
        const code_2 = ref(null);
        const name = ref(null);
        const selectedPortolio = computed(
            () => store.getters["config/portfolio"]
        );
        const modules = computed(() => store.getters["config/modules"]);
        const phones = ref({});

        const getPortfolio = () => {
            store.dispatch("config/getPortfolio", {
                id: route.params.portfolioId,
                onSuccess: () => {},
            });
        };

        const getModules = () => {
            store.dispatch("config/getModules", {
                id: route.params.portfolioId,
                onSuccess: () => {},
            });
        };

        const editPortfolioName = () => {
            store.dispatch("config/editPortfolio", {
                id: route.params.portfolioId,
                data: { name: name.value },
                onSuccess: () => {
                    getPortfolio();
                },
            });
        };

        const clearPhone = (field) => {
            store.dispatch("config/clearPortfolioPhone", {
                id: route.params.portfolioId,
                data: {
                    field,
                    value: phones.value[field]
                        ?.replaceAll(" ", "")
                        ?.replaceAll("+", ""),
                },
                onSuccess: () => {
                    getPortfolio();
                },
            });
        };

        const goBack = () => {
            router.push({ name: "account.portfolio" });
        };

        const displaySubscriptionDetail = (module) => {
            showSubscriptionData.value = {...module}
            showSubscriptionModal.value = true
        }

        const closeModal = () => {
            showSubscriptionModal.value = false
            showSubscriptionData.value =null
        }

        const handlePhone = (field) => {
            currentField.value = field;

            if (validatingPhoneField.value === field) {
                store.dispatch("config/validatePortfolioPhone", {
                    id: route.params.portfolioId,
                    data: {
                        code: field == "phone_1" ? code_1.value : code_2.value,
                        field,
                        value: phones.value[field]
                            ?.replaceAll(" ", "")
                            ?.replaceAll("+", ""),
                    },
                    onSuccess: () => {
                        getPortfolio();
                        validatingPhoneField.value = null;
                        code_1.value = null;
                        code_2.value = null;
                    },
                    onError: () => {},
                });
                return;
            }

            store.dispatch("config/setPortfolioPhone", {
                id: route.params.portfolioId,
                data: {
                    field,
                    value: phones.value[field]
                        ?.replaceAll(" ", "")
                        ?.replaceAll("+", ""),
                },
                onSuccess: () => {
                    validatingPhoneField.value = field;
                },
                onError: () => {},
            });
        };

        watch(
            () => selectedPortolio.value,
            () => {
                phones.value.phone_1 = selectedPortolio.value?.details?.phone_1
                    ? `+${selectedPortolio.value?.details?.phone_1}`
                    : "";
                phones.value.phone_2 = selectedPortolio.value?.details?.phone_2
                    ? `+${selectedPortolio.value?.details?.phone_2}`
                    : "";

                name.value = selectedPortolio.value?.name;
            }
        );

        onMounted(() => {
            getPortfolio();
            getModules()
        });

        return {
            code_2,
            code_1,
            validatingPhoneField,
            phones,
            name,showSubscriptionModal,showSubscriptionData,
            modules,
            selectedPortolio,
            handlePhone,
            clearPhone,closeModal,
            goBack,displaySubscriptionDetail,
            editPortfolioName,
        };
    },
};
</script>
