<template>
    <div class="mt-5 bg-white mb-10 p-3 rounded editor">
        <MdEditor
            v-model="content"
            language="en-US"
            :htmlPreview="false"
            :preview="true"
            previewTheme="cyanosis"
            :sanitize="sanitize"
            :toolbarsExclude="['github', 'catalog', 'htmlPreview']"
            @onChange="handleUpdate"
        />

        <div class="mt-3 mb-5 flex justify-end">
            <button
                :disabled="content == ''"
                @click.prevent="save()"
                class="border-none rounded bg-success text-white px-3 py-2"
            >
                Enrégistrer
            </button>
        </div>
    </div>
</template>
<script>
import {
    defineComponent,
    computed,
    onMounted,
    ref,
    watch,
} from "@vue/runtime-core";
import { MdEditor } from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import sanitizeHtml from "sanitize-html";
import { useStore } from "vuex";
export default defineComponent({
    components: { MdEditor },
    setup(props) {
        const store = useStore();
        const medicalCenter = computed(
            () => store.getters["medical/medicalCenter"]
        );
        const content = ref(medicalCenter.value?.profile?.presentation ?? "");
        const htmlContent = ref(
            medicalCenter.value?.profile?.presentation ?? ""
        );
        const handleUpdate = (data) => {
            content.value = data;
        };

        const save = () => {
            store.dispatch("medical/postHospitalPresentation", {
                medicalCenterId: medicalCenter.value?.id,
                data: { content: htmlContent.value },
                onSuccess: () => {},
                onError: () => {},
            });
        };

        const sanitize = (html) => {
            htmlContent.value = sanitizeHtml(html);
            return sanitizeHtml(html);
        };

        watch(
            () => medicalCenter.value,
            (val) => {
                content.value = val?.profile?.presentation;
            }
        );

        onMounted(() => {
            content.value = medicalCenter.value?.profile?.presentation;
        });

        return { content, handleUpdate, save, sanitize };
    },
});
</script>
<style lang="scss">
.editor {
    .ql-container {
        min-height: 500px !important;
    }
}
</style>
