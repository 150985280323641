<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Contactez-nous</title>
        <meta
            head-key="description"
            name="description"
            content="Besoin de discuter avec nous ?"
        />
    </Head>
    <app-bar></app-bar>

    <div class="min-h-full md:px-14 w-full pt-24 bg-color">
        <app-contact></app-contact>
    </div>

    <Footer></Footer>
</template>

<script>
import { Head } from "@inertiajs/vue3";
import Footer from "@/components/footer/index.vue";
export default {
    components: { Footer, Head },
    setup() {},
};
</script>
