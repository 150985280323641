<template>
    <div>
        <div
            class="rounded-sm flex flex-col pt-5 px-3 mb-5 w-full h-full overflow-y-full"
        >
            <div class="flex justify-between items-center mb-3 w-full">
                <div>
                    <h4 class="card-title font-semibold text-slate-700">
                        Les demandes d'informations
                    </h4>
                </div>
            </div>
            <div class="w-full h-full">
                <div v-if="verifications?.length" class="w-full h-full">
                    <div class="table-responsive">
                        <table
                            striped
                            outlined
                            class="table table-striped"
                            hover
                        >
                            <thead>
                                <tr>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Nom
                                    </th>

                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Utilisateur
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Date
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    ></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="verif in verifications"
                                    :key="verif.id"
                                >
                                    <td class="text-sm text-slate-700 font-500">
                                        {{ verif.name }}
                                    </td>

                                    <td class="text-sm text-slate-700 font-500">
                                        {{ verif.user?.full_name }}
                                    </td>
                                    <td class="text-sm text-slate-700 font-500">
                                        {{
                                            moment(verif.created_at).format(
                                                "LLL"
                                            )
                                        }}
                                    </td>

                                    <td>
                                        <div class="flex justify-end">
                                            <button
                                                class="border border-slate-400 text-sm px-2 py-1 rounded text-slate-400"
                                                size="sm"
                                                @click.prevent="
                                                    startEdition(verif)
                                                "
                                            >
                                                <i class="las la-eye m-0"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else class="p-3 h-full">
                    <div
                        class="flex flex-col justify-center items-center h-full"
                    >
                        <img class="w-1/5" src="/images/no-result.svg" />
                        <h4 class="text-primary font-medium my-3 text-md">
                            Aucune demande enrégistrée pour le moment
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end py-3">
            <pagination
                :pagination="pagination"
                @changed="getData"
            ></pagination>
        </div>
        <SampleModal
            :is-visible="showDetailsModal"
            @close="handleClose()"
            :hideValidate="true"
            @cancel="handleClose()"
            @submit="editVerification()"
            width="w-4/5"
            centered
            id="new-details"
            :title="`Vérification ${currentVerification?.name}`"
        >
            <div class="mb-2 w-full">
                <div class="no-scrollbar">
                    <div class="h-5/6 no-scrollbar" style="height: 90%">
                        <Form v-slot="{ errors }">
                            <div class="flex">
                                <div class="w-1/2 h-96" id="pdf-content">
                                    <vue-pdf-app
                                        :pdf="fileUrl"
                                        v-if="fileUrl.endsWith('.pdf')"
                                        :config="pdfViewerConfig"
                                    >
                                    </vue-pdf-app>
                                    <img
                                        v-else
                                        class="w-full h-96"
                                        :src="fileUrl"
                                        alt=""
                                        srcset=""
                                    />
                                </div>
                                <div
                                    class="pl-3 w-1/2"
                                    v-if="!currentVerification?.comments"
                                >
                                    <div class="mb-2 w-full mt-2">
                                        <label
                                            class="block capitalize tracking-wide text-left text-xs mb-1"
                                            for="grid-state"
                                        >
                                            Commentaire
                                        </label>
                                        <div class="h-20">
                                            <textarea
                                                v-model="response.comments"
                                                name="description"
                                                no-resize
                                                placeholder="Commentaire"
                                                id="exampleFormControlTextarea1"
                                                class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                rows="5"
                                                cols="5"
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div
                                        class="flex justify-end"
                                        v-if="isSaving"
                                    >
                                        <h3 class="text-sm">
                                            Enregistrement en cours...
                                        </h3>
                                    </div>
                                    <div v-else class="flex justify-end">
                                        <button
                                            class="bg-danger bg-opacity-15 text-danger text-sm px-2 py-1 rounded mr-3"
                                            @click.prevent="saveComments(false)"
                                        >
                                            Non
                                        </button>
                                        <button
                                            class="bg-primary1-700 bg-opacity-15 text-primary1-700 text-sm px-2 py-1 rounded"
                                            @click.prevent="saveComments(true)"
                                        >
                                            Oui
                                        </button>
                                    </div>
                                </div>

                                <div
                                    v-else
                                    class="w-1/2 bg-slate-100 rounded-xs pr-3 py-3"
                                >
                                    <div
                                        class="pl-3 w-full flex flex-col items-end"
                                    >
                                        <p
                                            :class="`text-md bg-opacity-15 ${
                                                currentVerification.accepted
                                                    ? 'bg-success text-success'
                                                    : 'bg-danger text-danger'
                                            } rounded px-3 py-2`"
                                        >
                                            {{ currentVerification.comments }}
                                        </p>
                                        <span class="text-xs mt-1"
                                            >Ajouté
                                            {{
                                                moment(
                                                    currentVerification.commented_at
                                                ).format("DD/MM/yyyy HH:mm:ss")
                                            }}</span
                                        >
                                    </div>

                                    <div
                                        class="pl-2 mt-2"
                                        v-for="(
                                            child, cIndex
                                        ) in currentVerification.children"
                                        :key="cIndex"
                                    >
                                        <div
                                            v-if="child.reserved_until"
                                            class="flex flex-col items-end"
                                        >
                                            <p
                                                class="bg-slate-200 bg-opacity-15 rounded px-2 py-1 text-slate-800 text-right"
                                            >
                                                Réservation jusqu'au
                                                {{
                                                    moment(
                                                        currentVerification.reserved_until
                                                    ).format(
                                                        "DD/MM/yyyy HH:mm:ss"
                                                    )
                                                }}
                                                ?
                                            </p>
                                            <span class="text-xs mt-1"
                                                >Demandé le
                                                {{
                                                    moment(
                                                        child.created_at
                                                    ).format(
                                                        "DD/MM/yyyy HH:mm:ss"
                                                    )
                                                }}</span
                                            >
                                        </div>

                                        <div
                                            class="pl-3 w-full"
                                            v-if="!child?.comments"
                                        >
                                            <div class="mb-2 w-full mt-2">
                                                <label
                                                    class="block capitalize tracking-wide text-left text-xs mb-1"
                                                    for="grid-state"
                                                >
                                                    Commentaire
                                                </label>
                                                <div class="h-20">
                                                    <textarea
                                                        v-model="
                                                            response.comments
                                                        "
                                                        name="description"
                                                        no-resize
                                                        placeholder="Commentaire"
                                                        id="exampleFormControlTextarea1"
                                                        class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        rows="5"
                                                        cols="5"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div
                                                class="flex justify-end"
                                                v-if="isSaving"
                                            >
                                                <h3 class="text-sm">
                                                    Enregistrement en cours...
                                                </h3>
                                            </div>
                                            <div
                                                v-else
                                                class="flex justify-end"
                                            >
                                                <button
                                                    class="bg-danger bg-opacity-15 text-danger text-sm px-2 py-1 rounded mr-3"
                                                    @click.prevent="
                                                        saveComments(
                                                            false,
                                                            child
                                                        )
                                                    "
                                                >
                                                    Non
                                                </button>
                                                <button
                                                    class="bg-primary1-700 bg-opacity-15 text-primary1-700 text-sm px-2 py-1 rounded"
                                                    @click.prevent="
                                                        saveComments(
                                                            true,
                                                            child
                                                        )
                                                    "
                                                >
                                                    Oui
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            class="pl-3 w-full flex flex-col items-end mt-2"
                                        >
                                            <p
                                                :class="`text-md bg-opacity-15 ${
                                                    child.accepted
                                                        ? 'bg-success text-success'
                                                        : 'bg-danger text-danger'
                                                } rounded px-3 py-2`"
                                            >
                                                {{ child.comments }}
                                            </p>
                                            <span class="text-xs mt-1"
                                                >Ajouté
                                                {{
                                                    moment(
                                                        child.commented_at
                                                    ).format(
                                                        "DD/MM/yyyy HH:mm:ss"
                                                    )
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </SampleModal>
    </div>
</template>
<script>
import ChatRoom from "@/components/chat/room.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { Form } from "vee-validate";
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
export default {
    components: { ChatRoom, SampleModal, Form, VuePdfApp },
    setup(props) {
        const pagination = ref();
        const verifications = ref([]);
        const store = useStore();
        const route = useRoute();
        const user = computed(() => store.getters["auth/user"]);
        const response = ref({ comments: "" });
        const showDetailsModal = ref(false);
        const isSaving = ref(false);
        const currentVerification = ref();
        const fileUrl = ref();
        const pdfViewerConfig = ref({
            toolbar: {
                toolbarViewerLeft: {
                    findbar: false,
                    previous: false,
                    next: false,
                    pageNumber: false,
                },
                toolbarViewerRight: {
                    presentationMode: false,
                    openFile: false,
                    print: false,
                    download: false,
                    viewBookmark: false,
                },
                toolbarViewerMiddle: {
                    zoomOut: false,
                    zoomIn: false,
                    scaleSelectContainer: false,
                },
            },
            sidebar: {
                viewThumbnail: false,
                viewOutline: false,
                viewAttachments: false,
            },
            secondaryToolbar: {
                secondaryPresentationMode: false,
                secondaryOpenFile: false,
                secondaryPrint: false,
                secondaryDownload: false,
                secondaryViewBookmark: false,
                firstPage: true,
                lastPage: true,
                pageRotateCw: false,
                pageRotateCcw: false,
                cursorSelectTool: false,
                cursorHandTool: false,
                scrollVertical: false,
                scrollHorizontal: false,
                scrollWrapped: false,
                spreadNone: false,
                spreadOdd: false,
                spreadEven: false,
                documentProperties: true,
            },
        });

        const get = () => {
            store.dispatch("medical/getMedicineVerifications", {
                medicalCenterId: route.params.slug,
                onSuccess: ({ data, from, to, total, current_page }) => {
                    pagination.value = { page: current_page, total };
                    verifications.value = [...data];
                    const verif = verifications.value.find(
                        (x) => x.id == currentVerification.value.id
                    );
                    if (verif) currentVerification.value = { ...verif };
                },
                onError: (err) => {},
            });
        };

        const saveComments = (value, child) => {
            isSaving.value = true;
            store.dispatch("medical/postMedicineVerificationComments", {
                id: child?.id ?? currentVerification.value.id,
                data: {
                    comments: response.value.comments,
                    accepted: value,
                },
                onSuccess: () => {
                    get();

                    isSaving.value = false;
                },
                onError: (err) => {
                    isSaving.value = false;
                },
            });
        };

        const handleClose = () => {
            stopListening(currentVerification.value?.id);
            showDetailsModal.value = false;
            currentVerification.value = null;
        };

        const editVerification = () => {};

        const startEdition = (data) => {
            showDetailsModal.value = false;
            console.log(data);
            fileUrl.value = `${window.location.origin}${data.file.url}`;
            currentVerification.value = { ...data };
            startListening(currentVerification.value.id);
            showDetailsModal.value = true;
        };

        const getVerification = () => {
            if (!currentVerification.value?.id) return;
            store.dispatch("medical/getMedicineVerification", {
                id: currentVerification.value.id,
                onSuccess: (response) => {
                    if (response) currentVerification.value = { ...response };
                },
                onError: (err) => {},
            });
        };

        const startListening = (id) => {
            if (!id) return;
            window.Echo.channel(`medicineVerifications.${id}`).listen(
                "NewMedicineVerification",
                (data) => {
                    getVerification();
                    console.log("NewMedicineVerification", data);
                }
            );
        };

        const stopListening = (id) => {
            if (!id) return;
            window.Echo.leaveChannel(`medicineVerifications.${id}`, () => {
                console.log("medicineVerifications leaved");
            });
        };

        onMounted(() => {
            get();
        });

        return {
            verifications,
            pagination,
            showDetailsModal,
            moment,
            pdfViewerConfig,
            fileUrl,
            response,
            currentVerification,
            editVerification,
            handleClose,
            saveComments,
            startEdition,
        };
    },
};
</script>
<style lang="scss">
#pdf-content {
    #viewerContainer {
        top: 0;
    }
    .pdf-app .toolbar {
        display: none !important;
    }

    .pdf-app #viewerContainer::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .pdf-app #viewerContainer {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .pdf-app .pdfViewer {
        .page {
            margin-top: 20px;
            margin-bottom: 20px;
            border: none !important;
            border-image: none !important;
        }
    }
}
</style>
