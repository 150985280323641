<template>
    <div class="w-full pb-20">
        <div class="flex">
            <div class="w-1/4 h-10 mr-2">
                <Calendar
                    v-model="date"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    inputClass="rounded border border-slate-300 w-full h-full w-full px-2 focus:border-slate-400 transition transition-all"
                    placeholder="Date de rendez-vous"
                    class="w-full h-full"
                />
            </div>
            <div class="w-1/4 h-10">
                <InputText
                    v-model="search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Rechercher..."
                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                />
            </div>
        </div>

        <div class="w-full bg-white rounded p-4 my-4">
            <div>
                <h3 class="text-lg font-semibold text-slate-800">
                    Les rendez-vous
                </h3>
            </div>
            <div v-if="isLoading" class="flex justify-center text-primary2">
                <Loader class="w-20" />
            </div>
            <div v-else class="mt-5">
                <DataTable
                    :value="products"
                    stripedRows
                    tableStyle="text-xs text-slate-500"
                    class="text-sm text-slate-500"
                    ><template #empty>Aucun rendez-vous </template>
                    <Column
                        field="patient"
                        header="Patient"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="flex items-center">
                                <img
                                    :src="`${slotProps.data?.patient?.avatar}`"
                                    :alt="slotProps.data?.image"
                                    class="w-10 h-10 rounded-full overflow-hidden shadow-2 border-round"
                                />
                                <div class="ml-2">
                                    <h3>
                                        {{ slotProps.data?.patient?.full_name }}
                                    </h3>
                                    <span class="text-slate-500 text-xs">{{
                                        slotProps.data?.patient?.username
                                    }}</span>
                                </div>
                            </div></template
                        ></Column
                    >

                    <Column
                        field="date"
                        header="Date"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="flex flex-col">
                                <span class="text-sm capitalize">{{
                                    moment(slotProps.data.start_date).format(
                                        "dddd DD MMMM"
                                    )
                                }}</span>
                                <span class="text-semibold text-sm">{{
                                    moment(slotProps.data.start_date).format(
                                        "HH:mm"
                                    )
                                }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="pratician"
                        header="Praticien"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="flex items-center">
                                <img
                                    :src="`${slotProps.data?.pratician?.avatar}`"
                                    :alt="slotProps.data?.image"
                                    class="w-10 h-10 rounded-full overflow-hidden shadow-2 border-round"
                                />
                                <div class="ml-2">
                                    <h3>
                                        {{
                                            slotProps.data?.pratician?.full_name
                                        }}
                                    </h3>
                                    <span class="text-slate-500 text-xs">{{
                                        slotProps.data?.pratician?.username
                                    }}</span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="center"
                        header="Établissement (médical)"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="ml-2">
                                <h3>
                                    {{ slotProps.data?.medical_center?.name }}
                                </h3>
                                <span class="text-slate-500 text-xs">{{
                                    slotProps.data?.medical_center?.city?.name
                                }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="service"
                        header="Service"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="ml-2 w-20">
                                <h3>
                                    {{ slotProps.data?.specialty?.name ?? "-" }}
                                </h3>
                            </div>
                        </template>
                    </Column>
                    <Column
                        header="Status"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <Tag
                                :value="statuses[slotProps.data.status]"
                                :severity="getSeverity(slotProps.data.status)"
                            />
                        </template>
                    </Column>
                    <Column header="" field="actions">
                        <template #body="slotProps">
                            <button
                                @click="
                                    $router.push({
                                        name: 'app.appointment',
                                        params: {
                                            appointmentId: slotProps.data.id,
                                        },
                                    })
                                "
                                class="px-3 py-1 bg-slate-300 border-none rounded"
                            >
                                <i
                                    class="la la-arrow-right text-lg text-slate-600 font-bold"
                                ></i>
                            </button>
                        </template>
                    </Column>
                    <template #footer>
                        <span v-if="pagination?.total"
                            >Au total {{ pagination?.total }} rendez-vous.</span
                        >
                    </template>
                </DataTable>

                <div class="mt-3" v-if="pagination">
                    <Paginator
                        :rows="pagination.per_page"
                        :totalRecords="pagination.total"
                        @page="updatePage"
                    ></Paginator>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "@/components/Datepicker/index.vue";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import Loader from "@/components/Loader/index.vue";
import Paginator from "primevue/paginator";
export default defineComponent({
    components: {
        Calendar,
        DataTable,
        Datepicker,
        Column,
        Paginator,
        ColumnGroup,
        Row,
        Tag,
        InputText,
        Loader,
    },

    setup() {
        const search = ref("");
        const date = ref([]);
        const isLoading = ref(true);
        const products = ref([]);
        const store = useStore();
        const pagination = ref();
        const statuses = ref({
            cancelled: "Rendez-vous annulé",
            confirmed: "Confirmé",
            done: "Présent",
            patient_absent: "Patient absent",
            pratician_absent: "Pratician absent",
        });

        const getAllAppointments = (page = 1) => {
            let filter = {
                page,
                search: search.value,
            };

            if (date.value.length == 2) {
                const start_date = date.value[0];
                const end_date = date.value[1];
                filter = {
                    ...filter,
                    end_date,
                    start_date,
                };
            }
            isLoading.value = true;
            store.dispatch("medical/listAppointments", {
                filter: {
                    ...filter,
                },
                onError: () => {
                    isLoading.value = false;
                },
                onSuccess: (resp) => {
                    isLoading.value = false;
                    pagination.value = { ...resp };
                    products.value = [...resp.data];
                    delete pagination.value.data;
                },
            });
        };

        const updatePage = (data) => {
            getAllAppointments(data?.page + 1);
        };

        const getSeverity = (status) => {
            switch (status) {
                case "cancelled":
                    return "danger";

                case "pratician_absent":
                    return "warning";

                case "pending":
                    return "info";

                case "patient_absent":
                    return "warning";

                case "confirmed":
                    return "success";
                case "done":
                    return "success";

                case "renewal":
                    return null;
            }
        };

        watch(
            () => search.value,
            (val) => {
                getAllAppointments();
            }
        );

        watch(
            () => date.value,
            (val) => {
                getAllAppointments();
            }
        );

        onMounted(() => {
            getAllAppointments();
        });

        return {
            products,
            moment,
            isLoading,
            statuses,
            pagination,
            search,
            date,
            updatePage,
            getSeverity,
        };
    },
});
</script>
<style lang="scss" scoped>
th {
    span {
        font-size: 14px !important;
    }
}
</style>
