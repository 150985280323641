import axios from "./axios";
import sourceAxios from "axios";
import { invoice, modules } from "./endpoint";


let listInvoicesCancelToken = null

export default {

    async getInvoices(data = {}) {
        if (typeof listInvoicesCancelToken != undefined) {
            listInvoicesCancelToken?.cancel(
                'Operation (GET_DELEGATIONS) canceled due to new request.'
            )
        }
        listInvoicesCancelToken = sourceAxios.CancelToken.source()
        const response = await axios.get(invoice.index(), data, listInvoicesCancelToken.token);
        return response.data;
    },

    async getModules(data = {}) {

        console.log("getModules ", 'getModules');

        const response = await axios.get(modules.get(), data,);

        return response.data;
    }
}
