import axios from "axios";
import { storageKeys } from "./endpoint";
import storage from "./Storage";
export default {
    async post(url, data, params = {}) {
        return await axios.post(url, data, { params });
    },

    async get(url, params = {}, token = null) {
        return await axios.get(url, { cancelToken: token, params });
    },
    async put(url, data, params = {}) {
        return await axios.put(url, data, { params });
    },
    async delete(url, data) {
        return await axios.delete(url, { params: data });
    },
    async file(url, data) {
        return await axios.post(url, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    CancelToken: axios.CancelToken,
    setToken(token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};

axios.interceptors.request.use(
    async config => {
        const token = await storage.get(storageKeys.authToken);
        config.headers.common["Authorization"] = `Bearer ${token}`;

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    config => {
        return config;
    },
    async error => {
        if (error?.response?.status == 401 && error?.response?.config?.url == '/api/auth/me/profile' && !error?.response?.request?.responseURL?.includes('https://blog.')) {
            await storage.clear();
            console.log(window.location);
            if (window.location.pathname.includes('/app'))
                window.location.replace("/login");
        }

        return Promise.reject(error.response);
    }
);
