<template>
    <div class="bg-white my-6">
        <div class="w-full">
            <div class="flex mb-20">
                <div class="w-2/5">
                    <div class="p-3">
                        <file-input
                            :url="hospital.img_url"
                            @fileChanged="fileChanged"
                        />
                    </div>
                    <form>
                        <div class="">
                            <h3 class="text text-black text-sm p-3">
                                Renseignez les informations du centre de santé
                            </h3>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Type <span class="text-danger px-1">*</span>
                            </label>
                            <div class="">
                                <Field
                                    v-slot="{ field }"
                                    name="type"
                                    v-model="hospital.type"
                                >
                                    <Select
                                        v-bind="field"
                                        name="type"
                                        v-model="hospital.type"
                                        :options="types"
                                        @changed="
                                            (city) => (hospital.city_id = city)
                                        "
                                    >
                                    </Select>
                                </Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Ville <span class="text-danger">*</span>
                            </label>
                            <div class="">
                                <Field
                                    v-slot="{ field }"
                                    name="city"
                                    v-model="hospital.city"
                                >
                                    <Select
                                        name="city"
                                        v-bind="field"
                                        v-model="hospital.city"
                                        :options="cities"
                                        @changed="
                                            (city) => (hospital.city = city)
                                        "
                                    >
                                    </Select
                                ></Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Nom <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="name"
                                    v-model="hospital.name"
                                >
                                    <input
                                        v-model="hospital.name"
                                        name="name"
                                        v-bind="field"
                                        class="h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="Le nom "
                                /></Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Spécialités
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="specialties"
                                    v-model="hospital.specialties"
                                >
                                    <v-select
                                        v-bind="field"
                                        placeholder="Sélectionnez les Spécialités"
                                        v-model="hospital.specialties"
                                        :value="hospital.specialties"
                                        :options="specialties"
                                        :multiple="true"
                                        :taggable="true"
                                    ></v-select>
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="grid-first-name-1"
                                >Numero Autorisation
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="authorization_number"
                                    v-model="hospital.authorization_number"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.authorization_number"
                                        name="authorization_number"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="Ex: BJ-004/89994/CTN/094884 "
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="grid-first-name-1"
                                >E-mail
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="email"
                                    v-model="hospital.email"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.email"
                                        name="email"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="hop_sourulere@gmail.bj"
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Adresse
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="address"
                                    v-model="hospital.address"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.address"
                                        name="address"
                                        disabled
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="L'adresse"
                                /></Field>
                            </div>
                        </div>

                        <div class="px-3 w-full mt-2">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name"
                            >
                                Téléphone (Ligne 1)
                                <span class="text-danger">*</span>
                            </label>
                            <div
                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                            >
                                <Field
                                    v-slot="{ field }"
                                    name="phone_1"
                                    v-model="hospital.phone_1"
                                >
                                    <!-- <PhoneInput
                                        v-bind="field"
                                        name="phone_1"
                                        v-model="hospital.phone_1"
                                        @phoneChanged="
                                            (phone1Updated) =>
                                                (hospital.phone_1 =
                                                    phone1Updated)
                                        "
                                /> -->

                                    <phone-number-input
                                        type="text"
                                        v-bind="field"
                                        :defaultCountry="countryCode"
                                        placeholder="Numero de télephone *"
                                        class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                    />
                                </Field>
                            </div>
                        </div>
                        <div class="px-3 mt-2 w-full">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-last-name"
                            >
                                Téléphone (Ligne 2)
                            </label>
                            <div
                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                            >
                                <Field
                                    v-slot="{ field }"
                                    name="phone_2"
                                    v-model="hospital.phone_2"
                                >
                                    <!-- <PhoneInput
                                        v-bind="field"
                                        v-model="hospital.phone_2"
                                        name="phone_2"
                                        @phoneChanged="
                                            (number) =>
                                                (hospital.phone_2 = number)
                                        "
                                    /> -->
                                    <phone-number-input
                                        type="text"
                                        v-bind="field"
                                        :defaultCountry="countryCode"
                                        placeholder="Numero de télephone *"
                                        class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col mt-2">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Référant <span class="text-danger">*</span>
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="doctor"
                                    v-model="hospital.doctor"
                                >
                                    <Select
                                        v-bind="field"
                                        name="doctor"
                                        v-model="hospital.doctor"
                                        :options="doctors"
                                        @changed="
                                            (city) => (hospital.doctor = city)
                                        "
                                    >
                                    </Select>
                                </Field>
                            </div>
                        </div>

                        <div class="flex justify-end mt-2 mr-4 mb-1 pt-2 pb-1">
                            <button
                                @click.prevent="
                                    $router.push({ name: 'config.hospitals' })
                                "
                                class="border-0 rounded-sm border-danger-200 mr-2 p-2 text-danger font-semibold"
                            >
                                Quitter
                            </button>
                            <button
                                :disabled="!meta.valid"
                                @click.prevent="createHospital()"
                                class="border-none rounded bg-success text-success bg-opacity-25 px-3 py-2 font-semibold"
                            >
                                Enrégistrer
                            </button>
                        </div>
                        <!-- <pre>{{ errors }}</pre> -->
                    </form>
                </div>
                <div class="w-3/5 p-3">
                    <gmap
                        class="h-full"
                        :center="center"
                        :zoom="12"
                        @onMapUpdate="updateMap"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HereMap from "@/components/maps/draggablemap";
import gmap from "@/components/maps/gmap";
import { mapActions, useStore } from "vuex";
import { mapCenter, hereMapApiKey } from "@/services/config";
import Select from "../../components/Select/index.vue";
import PhoneInput from "@/components/PhoneInput";
import { useForm, Field } from "vee-validate";
import * as yup from "yup";
import { computed, ref, onMounted, watch, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

export default {
    components: {
        HereMap,
        Select,
        Field,
        gmap,
        PhoneInput,
        Select,
    },

    setup(props) {
        const doctors = ref([]);
        const router = useRouter();
        const cities = computed(() => store.getters["config/allCities"]);
        const store = useStore();
        const specialties = computed(() => store.getters["config/specialties"]);
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const types = ref([
            { id: "public_hospital", name: "Hôpital Public" },
            { id: "health_center", name: "Centre de santé" },
            { id: "clinical", name: "Clinique (Centre de santé privé)" },
            { id: "pharmacy", name: "Pharmacie" },
            { id: "laboratory", name: "Laboratoire d'analyses médicales" },
        ]);
        const hospital = ref({
            img_url: null,
            specialties: [],
            doctor: null,
            type: null,
            address: "",
            name: "",
            city: null,
            latitude: null,
            longitude: null,
            phone_2: "",
            phone_1: "",
        });
        const platform = ref(null);
        const center = ref(mapCenter);
        const img = ref(null);
        const schema = computed(() =>
            yup.object({
                name: yup.string().required("Entrer le nom"),
                authorization_number: yup
                    .string()
                    .required("Numero autorisation des autorités sanitaires"),
                city: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez une ville"),
                doctor: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez un référant"),
                type: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez un type de centre"),
                phone_1: yup.string().required("Telephone obligatoire"),
            })
        );
        const { errors, values, meta } = useForm({
            validationSchema: schema.value,
            validateOnMount: true,
        });

        const fileChanged = (file) => {
            img.value = file;
        };

        const getAllDoctors = () => {
            store.dispatch("medical/getHospitalReferrers", {
                portfolioId: portfolio.value?.id,

                onSuccess: (ds) => {
                    doctors.value = [...ds].map((x) => ({
                        id: x.id,
                        name: x.full_name,
                    }));
                },
                onError: () => {},
            });
        };

        const updateMap = (map, position) => {
            hospital.value.latitude = position.lat;
            hospital.value.longitude = position.lng;
            console.log("positionn", position);
            if (position) {
                reverseGeocodePosition(position.lat, position.lng);
            }
        };
        const reverseGeocodePosition = (lat, lng) => {
            console.log("reverseGeocodePosition", lat, lng);
            var service = platform.value?.getSearchService();
            service.reverseGeocode(
                {
                    at: `${lat},${lng},150`,
                },
                (result) => {
                    const { position, address } = result.items[0];
                    hospital.value.address = address.label;
                },
                (err) => {
                    console.error("Error: [reverseGeocodePosition] => ", err);
                }
            );
        };

        const geocodePosition = (address) => {
            console.log("addressss", address);
            var service = platform.value?.getSearchService();
            service.geocode(
                {
                    q: `${address},150`,
                },
                (result) => {
                    console.log(result);
                    const point = result.items[0];
                    if (point) {
                        const { position } = point;
                        center.value = position;
                    }
                },
                (err) => {
                    console.log("Error: [geocodePosition] => ", err);
                }
            );
        };

        const saveImage = (id) => {
            const form = new FormData();
            form.append("image", img.value);
            store.dispatch("medical/attachMedicalCenterImage", {
                id: id,
                data: form,
                onSuccess: () => {
                    img.value = null;
                },
            });
        };

        const createHospital = () => {
            const formData = { ...hospital.value };
            formData.specialties = hospital.value.specialties.map((x) => x.id);
            formData.doctor_id = hospital.value.doctor?.id;
            formData.city_id = hospital.value.city?.id;
            formData.type = hospital.value.type?.id;
            formData.phone_1 = hospital.value.phone_1
                ?.replaceAll(" ", "")
                ?.replace("+", "");
            formData.portfolio_id = portfolio.value?.id;
            formData.phone_2 = hospital.value.phone_2
                ?.replaceAll(" ", "")
                ?.replace("+", "");
            store.dispatch("medical/postHospital", {
                data: formData,
                onSuccess: (data) => {
                    if (img.value && data?.id) {
                        saveImage(data.id);
                    }

                    store.dispatch("medical/displayToast", {
                        message: "Le centre médical créé.",
                        context: hospital.value?.name || portfolio.value?.name,
                        type: "TYPE.SUCCESS",
                    });

                    router.push({
                        name: "config.hospitals",
                    });
                },
                onError: () => {},
            });
        };

        watch(
            () => hospital.value.city,
            (val) => {
                if (val.name) {
                    geocodePosition(`${val.name}, ${val?.country?.name ?? ""}`);
                }
            }
        );

        onBeforeMount(() => {
            store.dispatch("config/getAllCities", {});
            store.dispatch("config/getSpecialties", {});
            store.dispatch("config/getResources");
        });

        onMounted(() => {
            getAllDoctors();
            platform.value = new H.service.Platform({
                apikey: hereMapApiKey,
                useHTTPS: false,
            });
        });

        return {
            specialties,
            hospital,
            types,
            cities,
            errors,
            values,
            center,
            meta,
            doctors,
            fileChanged,
            updateMap,
            createHospital,
        };
    },
};
</script>

<style lang="scss" scoped>
#box {
    margin: 20px 20px;
}
</style>
