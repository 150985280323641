<template>
    <div class="w-full h-full">
        <div class="w-full h-full">
            <div class="w-full h-full">
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5">
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Staff (Equipe)
                            </h4>
                        </div>
                        <button
                            @click="
                                $router.push({ name: 'config.staffCreate' })
                            "
                            class="flex justify-center text-sm items-center font-semibold border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div>
                        <div class="table-responsive">
                            <table
                                v-if="staff.length > 0"
                                striped
                                outlined
                                class="table table-striped"
                                hover
                            >
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap">
                                            Identifiant
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Nom
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Email
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Ville
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Rôles
                                        </th>
                                        <th class="whitespace-nowrap"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="user in staff" :key="user.id">
                                        <td>
                                            <span
                                                class="bg-pink-100 text-sm text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                                >{{ user.username }}</span
                                            >
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ user.full_name }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ user.email }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ user.city?.name }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            <div class="w-52 overflow-hidden">
                                                <span
                                                    v-for="role in user.roles"
                                                    :key="role.id"
                                                    class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                                                    >{{ role.name }}</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="flex justify-end">
                                                <button
                                                    class="bg-success text-sm bg-opacity-25 mr-1 px-2 py-1 rounded text-success"
                                                    size="sm"
                                                    @click="displayModal(user)"
                                                >
                                                    <i
                                                        class="las la-eye m-0"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="bg-warning text-sm mr-1 px-2 py-1 rounded text-warning bg-opacity-25"
                                                    size="sm"
                                                    @click="goto(user)"
                                                >
                                                    <i
                                                        class="las la-pen m-0"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="px-2 py-1 bg-danger text-sm rounded text-danger bg-opacity-25"
                                                    size="sm"
                                                    @click="openDialog(user)"
                                                >
                                                    <i
                                                        class="la la-trash m-0"
                                                    ></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                v-else
                                class="h-96 flex justify-center items-center"
                            >
                                <p class="text-md text-primary">
                                    Aucune membre d'équipe enrégistré
                                </p>
                            </div>
                            <div class="flex justify-end py-3 text-sm">
                                <pagination
                                    :pagination="pagination"
                                    @changed="getData"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SampleModal
            :is-visible="showUserDetails"
            :hide-validation="true"
            closeClass="text-danger border border-danger"
            @close="handleModalClose"
            @cancel="handleModalClose"
            centered
            id="new-car"
            :title="userName"
        >
            <div class="flex w-full bg-white">
                <div
                    class="w-2/5 flex flex-col items-center border-r border-gray-200"
                >
                    <img
                        class="w-24 h-24 mb-3 rounded-full"
                        :src="staffMember?.avatar"
                        alt="Bonnie image"
                    />
                    <h5
                        class="mb-1 text-xl font-medium text-gray-900 dark:text-white"
                    >
                        {{ staffMember.full_name }}
                    </h5>
                    <span class="text-sm text-gray-500 dark:text-gray-400">{{
                        staffMember?.roles.map((x) => x.name).join(", ")
                    }}</span>
                </div>
                <div class="w-3/5 px-3">
                    <div class="flex py-1">
                        <h5 class="w-2/5">Téléphone :</h5>
                        <h5 class="text-gray-500">
                            {{ formatPhone(staffMember.phone_1) }}
                        </h5>
                    </div>
                    <div class="flex" v-if="staffMember.phone_2">
                        <h5 class="w-2/5">Ligne 2 :</h5>
                        <h5 class="text-gray-500">
                            {{ formatPhone(staffMember.phone_2) }}
                        </h5>
                    </div>
                    <div class="flex py-1">
                        <h5 class="w-2/5">Email :</h5>
                        <h5 class="text-gray-500">
                            {{ staffMember.email }}
                        </h5>
                    </div>
                    <div class="flex py-1">
                        <h5 class="w-2/5">Adresse :</h5>
                        <h5 class="text-gray-500">
                            <span v-if="staffMember.address"
                                >{{ staffMember.address }},</span
                            >
                            <span
                                >{{ staffMember?.city?.name }}
                                {{ staffMember?.city?.country?.name }}</span
                            >
                        </h5>
                    </div>
                    <div class="flex py-1">
                        <h5 class="w-2/5">Dern. Conn. :</h5>
                        <h5 class="text-gray-500">
                            {{
                                staffMember.last_login
                                    ? moment(staffMember.last_login).format("L")
                                    : "-"
                            }}
                        </h5>
                    </div>
                </div>
            </div>
        </SampleModal>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { ref, computed, watch, onBeforeMount } from "vue";
import { AsYouType } from "libphonenumber-js";
import moment from "moment";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { TYPE } from "vue-toastification";
export default {
    components: { SampleModal, ConfirmDialog },
    beforeRouteEnter(to, from, next) {
        next();
    },
    setup(props) {
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const confirm = useConfirm();
        const currentUser = ref();
        const staffMember = ref(null);
        const userName = ref();
        const showUserDetails = ref(false);
        const displayModal = (user) => {
            showUserDetails.value = false;
            staffMember.value = user;
            userName.value = user.username;
            showUserDetails.value = true;
        };
        const handleModalClose = () => {
            showUserDetails.value = false;

            userName.value = "";
            staffMember.value = null;
        };
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };

        const getStaff = (page = 1) => {
            store.dispatch("config/getStaff", {
                page,
                portfolioId: portfolio.value.id,
            });
        };

        const removeUser = () => {
            store.dispatch("config/deleteStaff", {
                id: currentUser.value?.id,
                onSuccess: () => {
                    getStaff();
                    store.dispatch("medical/displayToast", {
                        message: "Membre bien supprimé.",
                        context: "Staff",
                        type: TYPE.SUCCESS,
                    });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Staff",
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const openDialog = (data) => {
            currentUser.value = data;

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeUser();
                },
                reject: () => {},
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                getStaff();
            }
        );

        onBeforeMount(() => {
            getStaff();
        });
        return {
            userName,
            showUserDetails,
            staffMember,
            moment,
            openDialog,
            handleModalClose,
            displayModal,
            openDialog,
            formatPhone,
        };
    },
    data() {
        return {
            canDelete: false,
            currentUser: null,
            variant: {
                nurse: "primary",
                agent: "secondary",
                doctor: "success",
                conductor: "warning",
                user: "info",
                patient: "light",
                manager: "dark",
                "owner-super-user": "danger",
                administrator: "danger",
            },
        };
    },
    methods: {
        getData(page) {
            store.dispatch("config/getStaff", { filter: { page } });
        },

        goto(user) {
            this.$router.push({
                name: "config.staffEdit",
                params: {
                    identifier:
                        user.username?.toString()?.toLowerCase() || user.id,
                },
            });
        },
    },
    computed: {
        ...mapGetters({
            staff: "config/users",
            pagination: "config/usersPagination",
        }),
    },
};
</script>
