<template>
    <div class="w-full">
        <div class="w-full">
            <div class="w-full">
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5">
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4 class="card-title font-semibold">
                                Créateur de contenu (Blog)
                            </h4>
                        </div>
                        <button
                            @click="$router.push({ name: 'blog.newWriter' })"
                            class="flex justify-center items-center font-semibold border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div>
                        <div class="table-responsive">
                            <table
                                v-if="users.length > 0"
                                striped
                                outlined
                                class="table table-striped"
                                hover
                            >
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap">
                                            Identifiant
                                        </th>
                                        <th class="whitespace-nowrap">Nom</th>
                                        <th class="whitespace-nowrap">Email</th>
                                        <th class="whitespace-nowrap">Ville</th>
                                        <th class="whitespace-nowrap">Rôles</th>
                                        <th class="whitespace-nowrap"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="user in users" :key="user.id">
                                        <td>
                                            <span
                                                class="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                                >{{ user.username }}</span
                                            >
                                        </td>
                                        <td>
                                            {{ user.full_name }}
                                        </td>
                                        <td>{{ user.email }}</td>
                                        <td>{{ user.city?.name }}</td>
                                        <td>
                                            <div class="w-52 overflow-hidden">
                                                <span
                                                    v-for="role in user.roles"
                                                    :key="role.id"
                                                    class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                                                    >{{ role.name }}</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div class="flex justify-end">
                                                <button
                                                    class="bg-success bg-opacity-25 mr-1 px-2 py-1 rounded text-success"
                                                    size="sm"
                                                    @click="displayModal(user)"
                                                >
                                                    <i
                                                        class="las la-eye m-0"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="bg-warning mr-1 px-2 py-1 rounded text-warning bg-opacity-25"
                                                    size="sm"
                                                    @click="goto(user)"
                                                >
                                                    <i
                                                        class="las la-pen m-0"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="px-2 py-1 bg-danger rounded text-danger bg-opacity-25"
                                                    size="sm"
                                                    @click="handlePopup(user)"
                                                >
                                                    <i
                                                        class="la la-trash m-0"
                                                    ></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                v-else
                                class="h-96 flex justify-center items-center"
                            >
                                <p class="text-lg text-primary">
                                    Aucune créateur de contenu pour le moment
                                </p>
                            </div>
                            <div class="flex justify-end py-3">
                                <pagination
                                    :pagination="pagination"
                                    @changed="getData"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <popup
            message="Êtes-vous sure de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeUser"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>

        <modal
            :is-visible="showUserDetails"
            :hide-validation="true"
            closeClass="text-danger border border-danger"
            @close="handleModalClose"
            @cancel="handleModalClose"
            centered
            id="new-car"
            :title="userName"
        >
            <template #content>
                <div class="flex w-full bg-white">
                    <div
                        class="w-2/5 flex flex-col items-center border-r border-gray-200"
                    >
                        <img
                            class="w-24 h-24 mb-3 rounded-full"
                            :src="staffMember?.avatar"
                            alt="Bonnie image"
                        />
                        <h5
                            class="mb-1 text-xl font-medium text-gray-900 dark:text-white"
                        >
                            {{ staffMember.full_name }}
                        </h5>
                        <span
                            class="text-sm text-gray-500 dark:text-gray-400"
                            >{{
                                staffMember?.roles.map((x) => x.name).join(", ")
                            }}</span
                        >

                        <!-- <div class="mt-4">
              </div> -->
                    </div>
                    <div class="w-3/5 px-3">
                        <div class="flex py-1 justify-between">
                            <h5 class="w-2/5">Téléphone :</h5>
                            <h5 class="text-gray-500 text-right">
                                {{ formatPhone(staffMember.phone_1) }}
                            </h5>
                        </div>
                        <div
                            class="flex justify-between"
                            v-if="staffMember.phone_2"
                        >
                            <h5 class="w-2/5">Ligne 2 :</h5>
                            <h5 class="text-gray-500 text-right">
                                {{ formatPhone(staffMember.phone_2) }}
                            </h5>
                        </div>
                        <div class="flex py-1 justify-between">
                            <h5 class="w-2/5">Email :</h5>
                            <h5 class="text-gray-500 text-right">
                                {{ staffMember.email }}
                            </h5>
                        </div>
                        <div class="flex py-1 justify-between">
                            <h5 class="w-2/5">Adresse :</h5>
                            <h5 class="text-gray-500 text-right">
                                <span v-if="staffMember.address"
                                    >{{ staffMember.address }},</span
                                >
                                <span
                                    >{{ staffMember?.city?.name }}
                                    {{ staffMember?.city?.country?.name }}</span
                                >
                            </h5>
                        </div>
                        <div class="flex py-1 justify-between">
                            <h5 class="w-2/5">Dern. Conn. :</h5>
                            <h5 class="text-gray-500 text-right">
                                {{
                                    staffMember.last_login
                                        ? moment(staffMember.last_login).format(
                                              "L"
                                          )
                                        : "-"
                                }}
                            </h5>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { ref, computed, watch, onBeforeMount } from "vue";
import { AsYouType } from "libphonenumber-js";
import moment from "moment";
export default {
    components: {},
    beforeRouteEnter(to, from, next) {
        next();
    },
    setup(props) {
        const portfolio = computed(() => store.getters["auth/portfolio"]);

        const pagination = ref({});
        const users = ref([]);
        const staffMember = ref(null);
        const userName = ref();
        const showUserDetails = ref(false);
        const displayModal = (user) => {
            showUserDetails.value = false;
            staffMember.value = user;
            userName.value = user.username;
            showUserDetails.value = true;
        };
        const handleModalClose = () => {
            showUserDetails.value = false;

            userName.value = "";
            staffMember.value = null;
        };
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };

        const getWriters = (page = 1) => {
            store.dispatch("config/getWriters", {
                page,
                portfolioId: portfolio.value.id,
                onSuccess: ({
                    data,
                    current_page,
                    last_page,
                    per_page,
                    total,
                }) => {
                    pagination.value = {
                        current_page,
                        last_page,
                        per_page,
                        total,
                    };
                    console.log("resss", data);
                    users.value = [...data];
                },
            });
        };

        onBeforeMount(() => {
            getWriters();
        });

        return {
            users,
            userName,
            showUserDetails,
            staffMember,
            moment,
            pagination,
            handleModalClose,
            displayModal,
            formatPhone,
        };
    },
};
</script>
