import service from '@/services/medical';
import setting from '@/services/setting';
import invoiceService from '@/services/invoice';
import { SET_ALERT, SET_ALERTS, SET_DOCTORS, SET_HOSPITAL, SET_HOSPITALS, SET_INTERVENTIONS, SET_PATIENT, SET_PATIENTS, SET_PLANNINGS, SET_PRESCRIPTIONS, SET_USER } from './mutations';
import { TYPE, useToast } from 'vue-toastification';


import ToastComponent from '@/components/Toast/index.vue'

export default {

    displayToast(
        ctx,
        {
            type = TYPE.INFO,
            icon = 'la-star-of-life',
            message,
            context = 'Allô Doto',
            loader = true,
            loadingIcon = false
        }
    ) {
        const toast = useToast()
        if (type === TYPE.DEFAULT) type = TYPE.INFO
        if (!loader)
            toast[type]({ component: ToastComponent, props: { message, context, icon, containerClassName: 'welcome', } }, { icon: `las ${icon} text-3xl`, })
        else {
            toast[type]({ component: ToastComponent, props: { message, context, type, icon, toastClassName: [], containerClassName: 'welcome' } }, { icon: `las ${icon} text-3xl`, })
            // toast[type](message, {
            //     icon: `la ${loadingIcon ? 'arrow-clockwise animate-spin' : 'check'
            //         }  text-xl font-bold`,
            //     timeout: false
            // })
        }

    },
    async getDoctors({ commit }, { filters }) {
        try {
            const { doctors } = await service.getDoctors(filters);
            commit({ type: SET_DOCTORS, doctors });
        } catch (error) {
            console.log(error)
        }
    },

    async getHospitalReferrers({ commit, }, { portfolioId, onSuccess, onError }) {
        try {
            const { users } = await service.getHospitalReferrers({ portfolioId });
            onSuccess(users)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getSpecialtyProcedures({ commit }, { idSpecialty, data, onSuccess, onError }) {
        try {

            const { procedures } = await setting.getSpecialtyProcedures(idSpecialty, data);
            onSuccess(procedures)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getAllDoctors({ commit }, { onSuccess, onError }) {
        try {
            const { doctors } = await service.getAllDoctors();
            onSuccess(doctors)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async postMedicineVerification({ commit }, { data, onSuccess, onError }) {
        try {
            const { verification } = await service.postMedicineVerification(data);
            onSuccess(verification)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getMedicineVerifications({ commit }, { medicalCenterId, onSuccess, onError }) {
        try {
            const verifications = await service.getMedicineVerifications(medicalCenterId);
            onSuccess(verifications)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getMedicineVerification({ commit }, { id, onSuccess, onError }) {
        try {
            const { verification } = await service.getMedicineVerification(id);
            onSuccess(verification)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },


    async postMedicineVerificationComments({ commit }, { id, data, onSuccess, onError }) {
        try {
            const verification = await service.postMedicineVerificationComments(id, data);
            onSuccess(verification)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getMedicalCenterAgents({ commit }, { medicalCenterId, onSuccess, onError }) {
        try {
            const { agents } = await service.getAgents(medicalCenterId);
            onSuccess(agents)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },
    async getMedicalCenterPatients({ commit }, { medicalCenterId, onSuccess, onError }) {
        try {
            const { patients } = await service.getPatientsByMedicalCenter(medicalCenterId);
            onSuccess(patients)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },
    async getMedicalCenterDocuments({ comit }, { medicalCenterId, onSuccess, onError }) {
        try {
            const { documents } = await service.getDocumentsByMedicalCenter(medicalCenterId);
            onSuccess(documents)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async updateMedicalCenterDocumentStatus({ comit }, { medicalCenterId, documentId, data, onSuccess, onError }) {
        try {
            await service.updateDocumentStatus(medicalCenterId, documentId, data);
            onSuccess()
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },
    async savePrescription({ dispatch }, { patientId, data, onSuccess, onError }) {
        try {
            await service.postPrescriptions(patientId, data);
            dispatch({ type: 'getPrescriptions', patientId })
            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async getPatientPrescriptions({ commit }, { patientId }) {
        try {
            const { prescriptions } = await service.getPatientPrescriptions(patientId);
            commit({ type: SET_PRESCRIPTIONS, prescriptions })
        } catch (error) {
            console.log(error)
        }
    },

    async getPrescriptions({ commit }) {
        try {
            const { prescriptions } = await service.getPrescriptions();
            commit({ type: SET_PRESCRIPTIONS, prescriptions })
        } catch (error) {
            console.log(error)
        }
    },

    async getPatients({ commit, state, rootState }, { page, portfolioId } = {}) {
        try {
            const params = { portfolioId: portfolioId ?? rootState.auth?.currentPortfolio?.id, page: page ?? 1, }

            const { patients } = await service.getPatients(params);
            commit({ type: SET_PATIENTS, patients });
        } catch (error) {
            console.log(error)
        }
    },

    async updatePatient({ commit }, { data, id, onSuccess, onError }) {
        try {
            await service.putPatient(id, data)
            if (onSuccess) onSuccess()
        } catch (error) {
            if (onError) onError(error)
        }
    },

    async loadPatient({ commit }, { identifier, onSuccess, onError }) {
        try {
            const { patient } = await service.getPatient(identifier);
            commit({ type: SET_PATIENT, patient });
            if (onSuccess) onSuccess(patient)
        } catch (error) {
            console.log(error)
            if (onError) onError(error)
        }
    },

    async getUser({ commit }, { identifier }) {
        try {
            const { user } = await service.getUser(identifier);
            commit({ type: SET_USER, user });
        } catch (error) {
            console.log(error)
        }
    },

    async updateStaff({ commit }, { data, identifier, onSuccess, onError }) {
        try {
            await service.editStaff(identifier, data)


            if (onSuccess) onSuccess()
        } catch (error) {
            if (onError) onError(error)
        }
    },

    async getStaff({ commit }, { identifier, onSuccess, onError }) {
        try {
            const { user } = await service.getStaff(identifier);
            commit({ type: SET_USER, user });
            if (onSuccess) onSuccess(user)
        } catch (error) {
            if (onError) onError()
            console.log(error)
        }
    },

    async attachMedicalCenterImage({ commit }, { id, data, onSuccess, onError }) {
        try {
            await service.postHospitalImage(id, data)
            onSuccess()
        } catch (error) {
            onError(error.data);
        }
    },

    async addPatient({ commit }, { data, onSuccess, onError }) {
        try {
            const { patient } = await service.postPatient(data);
            onSuccess(patient);
        } catch (error) {
            onError(error.data);
        }
    },

    async getAlerts({ commit }, { portfolioId, onSuccess, onError }) {
        try {
            const { alerts } = await service.getAlerts({ portfolioId });
            commit({ type: SET_ALERTS, alerts });

            if (onSuccess) onSuccess(alerts)
        } catch (error) {

            console.log(error)

            onError()
        }
    },

    async deleteAlert({ commit }, { portfolioId, alertId, onSuccess, onError }) {
        try {
            await service.removeAlert(alertId, { portfolioId });
            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async interventionCarMoved({ commit }, { portfolioId, alertId, interventionId, carId, data, onSuccess, onError }) {
        try {
            await service.carMoved(alertId, interventionId, carId, { ...data, portfolioId });
            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async postAlert({ }, { form, onSuccess, onError }) {
        try {
            await service.postAlert(form);
            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async getAlert({ commit }, { id, portfolioId, onSuccess }) {
        try {
            const { alert } = await service.getAlert(id, { portfolioId });
            commit({ type: SET_ALERT, alert });
            if (onSuccess) onSuccess()
        } catch (error) {
            console.log(error)
        }
    },

    async getAlertInterventions({ commit }, { portfolioId, id, onSuccess, onError }) {
        try {
            const { interventions } = await service.getAlertInterventions(id, { portfolioId });
            onSuccess(interventions)
        } catch (error) {
            console.log(error)
            onError(error)
        }
    },

    async getInterventions({ commit }) {
        try {
            const { interventions } = await service.getInterventions();
            commit({ type: SET_INTERVENTIONS, interventions });
        } catch (error) {
            console.log(error)
        }
    },

    async getPendingInterventions({ commit }, { portfolioId, onSuccess, onError }) {
        try {
            const { interventions } = await service.getPendingInterventions({ portfolioId });
            onSuccess(interventions)
        } catch (error) {
            console.log(error)

            onError(error)
        }
    },

    async postIntervention({ }, { data, onSuccess, onError }) {
        try {
            await service.postIntervention(data);
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async putIntervention({ }, { interventionId, data, onSuccess, onError }) {
        try {
            await service.putIntervention(interventionId, data);
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async removeIntervention({ }, { interventionId, onSuccess, onError }) {
        try {
            await service.deleteIntervention(interventionId);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async getHospitals({ commit }, { filter, onSuccess, onError }) {
        try {
            const { hospitals } = await service.getHospitals(filter);
            commit({ type: SET_HOSPITALS, hospitals });

            if (onSuccess) onSuccess()
        } catch (error) {
            console.log(error)
            if (onError) onError()
        }
    },

    async getHospital({ commit }, { slug, onSuccess, onError }) {
        try {
            const { hospital } = await service.getHospitalById(slug);
            commit({ type: SET_HOSPITAL, hospital });

            if (onSuccess) onSuccess(hospital);
        } catch (error) {
            console.log(error)
            if (onError) onError();
        }
    },

    async removeHospital({ }, { id, onSuccess, onError }) {
        try {
            await service.deleteHospital(id);

            if (onSuccess) onSuccess();
        } catch (error) {
            console.log(error)
            if (onError) onError();
        }
    },

    async postHospitalPresentation({ dispatch }, { medicalCenterId, data, onSuccess, onError }) {
        try {
            await service.postHospitalPresentation(medicalCenterId, data);
            dispatch({ type: 'getHospital', slug: medicalCenterId });
            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async performSearch({ dispatch }, { data, onSuccess, onError }) {
        try {
            const response = await service.searchAppointments(data);
            onSuccess(response.results);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getPlannings({ commit }, { filter, onSuccess, onError }) {
        try {
            const response = await service.getPlannings(filter);
            commit({ type: SET_PLANNINGS, plannings: response.plannings });

            onSuccess(response.plannings);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async removePlanning({ commit }, { id, onSuccess, onError }) {
        try {
            await service.deletePlanning(id);

            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getPlanning({ commit }, { planningId, onSuccess, onError }) {
        try {
            const response = await service.getPlanning(planningId);
            onSuccess(response.planning);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async getAppointment({ commit }, { appointmentId, onSuccess, onError }) {
        try {
            const { appointment } = await service.getAppointment(appointmentId);
            onSuccess(appointment);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async startAppointment({ commit }, { appointmentId, onSuccess, onError }) {
        try {
            const { appointment } = await service.startAppointment(appointmentId, {});
            onSuccess(appointment);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getAppointments({ commit }, { filter, onSuccess, onError }) {
        try {
            const { appointments } = await service.getAppointments(filter);
            onSuccess(appointments);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async listAppointments({ commit }, { filter, onSuccess, onError }) {
        try {
            const { appointments } = await service.listAppointments(filter);
            onSuccess(appointments);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async listTimedAppointments({ commit }, { filter, onSuccess, onError }) {
        try {
            const { appointments } = await service.listTimedAppointments(filter);
            onSuccess(appointments);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async buildShare({ commit }, { id, onSuccess, onError }) {
        try {
            const { hash } = await service.buildShare(id);
            onSuccess(hash);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getByHash({ commit }, { hash, onSuccess, onError }) {
        try {
            const { appointments } = await service.getAppointmentByHash(hash);
            onSuccess(appointments);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async attachFiles({ commit }, { appointmentId, data, onSuccess, onError }) {
        try {
            await service.addFileToAppointment(appointmentId, data);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async addMedicalCenterDocuments({ commit }, { medicalCenterId, data, onSuccess, onError }) {
        try {
            await service.addMedicalCenterDocuments(medicalCenterId, data);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async sendDocumentsMailUpdate({ commit }, { medicalCenterId, onSuccess, onError }) {
        try {
            await service.sendDocumentsMail(medicalCenterId);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },


    async getDocuments({ commit }, { appointmentId, onSuccess, onError }) {
        try {
            const response = await service.getDocuments(appointmentId);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getAllDocuments({ commit }, { filter, onSuccess, onError }) {
        try {
            const { documents } = await service.getAllDocuments(filter);
            onSuccess(documents);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getSlots({ commit }, { planningId, onSuccess, onError }) {
        try {
            const { slots } = await service.getSlots(planningId);
            onSuccess(slots);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async savePlanning({ dispatch }, { planningId, data, onSuccess, onError }) {
        try {
            const response = await service.savePlanning(data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async createAppointment({ dispatch }, { data, onSuccess, onError }) {
        try {
            const response = await service.saveAppointment(data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async editAppointmentTime({ dispatch }, { appointmentId, data, onSuccess, onError }) {
        try {
            const response = await service.updateAppointmentTime(appointmentId, data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async cancelAppointmentTime({ dispatch }, { appointmentId, data, onSuccess, onError }) {
        try {
            const response = await service.cancelAppointmentTime(appointmentId, data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError();
        }
    },


    async editPlanning({ dispatch }, { planningId, data, onSuccess, onError }) {
        try {
            const response = await service.editPlanning(planningId, data);
            onSuccess(response?.planning);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async postHospital({ dispatch }, { data, onSuccess, onError }) {
        try {
            const { medicalCenter } = await service.postHospital(data);
            onSuccess(medicalCenter);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async countHospitals({ }, { portfolioId, onSuccess, onError }) {
        try {
            const { data } = await service.countHospitals({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async countPatients({ }, { portfolioId, onSuccess, onError }) {
        try {
            const { data } = await service.countPatients({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async countAlerts({ }, { portfolioId, onSuccess, onError }) {
        try {
            const { data } = await service.countAlerts({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async countCars({ }, { portfolioId, onSuccess, onError }) {
        try {
            const { data } = await service.countCars({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getInterventionsData({ }, { portfolioId, onSuccess, onError }) {
        try {
            const data = await service.getInterventionsData({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async getInterventionsAnalytics({ }, { portfolioId, onSuccess, onError }) {
        try {
            const data = await service.getInterventionsAnalytics({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async getStaffAnalytics({ }, { portfolioId, onSuccess, onError }) {
        try {
            const data = await service.getStaffAnalytics({ portfolioId });
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
    async editHospital({ dispatch }, { id, data, onSuccess, onError }) {
        try {
            await service.editHospital(id, data);
            dispatch({ type: 'getHospitals' });
            onSuccess();
        } catch (error) {
            console.log(error)
            onError();
        }
    },

    async getRequirements({ }, { onSuccess, onError }) {
        try {
            const data = await service.getRequirements();
            onSuccess(data?.requirements || []);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async checkAppointmentPrice({ }, { onSuccess, onError }) {
        try {
            const data = await service.checkAppoitmentPrice();
            onSuccess(data);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async rateAppointment({ }, { id, data, onSuccess, onError }) {
        try {
            const response = await service.rateAppointment(id, data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async validateRequirements({ dispatch }, { hospitalId, data, onSuccess, onError }) {
        try {
            await service.validateHospitalRequirements(hospitalId, data);
            onSuccess()
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },

    async getInvoices({ commit }, { filter, onSuccess, onError }) {
        try {
            const { invoices } = await invoiceService.getInvoices(filter);
            onSuccess(invoices);
        } catch (error) {
            console.log(error)
            onError();
        }
    },
}
