<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />

        <title>
            Allô Doto - Votre rendez-vous avec le Dr.
            {{ appointment?.pratician?.full_name }}
        </title>
        <meta
            head-key="description"
            name="description"
            :content="` Allô Doto - Votre rendez-vous avec le Dr.
            ${appointment?.pratician?.full_name}`"
        />
    </Head>
    <div id="app" class="bg-[#F8F9FC] min-h-screen">
        <!-- <app-bar></app-bar> -->

        <div class="bg-[#F8F9FC] min-h-full">
            <header class="w-full px-5 sticky top-0 bg-white">
                <nav class="border-gray-200 py-2.5 dark:bg-gray-800">
                    <div class="flex flex-wrap justify-center items-center">
                        <a href="/" class="flex items-center">
                            <img
                                src="/images/allo_logo.svg"
                                class="mr-3 w-28"
                                alt="AlloDoto Logo"
                            />
                        </a>
                    </div>
                </nav>
            </header>

            <div v-if="!code" class="w-full flex justify-center">
                <div class="w-3/5 mt-10">
                    <div class="text-center flex flex-col items-center">
                        <div class="w-1/3 rounded-full p-4 bg-white my-5">
                            <img src="/images/forbidden.svg" alt="" />
                        </div>
                        <h3 class="text-2xl font-medium text-slate-600">
                            Partage de session terminé.
                        </h3>
                    </div>
                </div>
            </div>

            <anonymous-appointment
                v-else
                :data="appointment"
            ></anonymous-appointment>
        </div>
    </div>

    <!-- <Footer></Footer> -->
</template>

<script setup>
import Footer from "@/components/footer/index.vue";
import { Head } from "@inertiajs/vue3";

import { onMounted } from "vue";
const props = defineProps({ appointment: Object, code: String });

onMounted(() => {});
</script>
