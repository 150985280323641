<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />

        <title>Allô Doto - Tableau de board</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Tableau de board"
        />
    </Head>
    <div id="allo-app-dashboard" class="w-full h-screen allo-app-dashboard">
        <div class="px-2">
            <topbar></topbar>
        </div>
        <!-- END: Top Bar -->
        <div class="flex overflow-hidden max-h-screen h-full">
            <!-- BEGIN: Side Menu -->
            <div class="bg-tertiary overflow-scroll">
                <sidebar></sidebar>
            </div>

            <!-- END: Side Menu -->
            <!-- BEGIN: Content -->
            <div class="content min-h-full overflow-y-scroll">
                <div class="flex justify-between items-center mt-8">
                    <div class="pl-1">
                        <h3 class="font-ibm">
                            Bonjour <strong>{{ user?.last_name }}</strong> !
                        </h3>
                    </div>
                    <div
                        class="search sm:block w-1/4"
                        v-if="portfolios.length > 1"
                    >
                        <Select
                            class="w-full"
                            name="city"
                            v-model="selectedPortfolio"
                            :value="selectedPortfolio"
                            :options="portfolios"
                            @selected="handlePortfolioChange"
                        />
                    </div>
                </div>
                <dashboard />
            </div>
            <!-- END: Content -->
        </div>
    </div>
</template>

<script>
// import "#/theme/js/app.js";
// import "#/theme/js/ckeditor-classic.js";
// import "#/theme/js/ckeditor-inline.js";
// import "#/theme/js/ckeditor-balloon.js";
// import "#/theme/js/ckeditor-balloon-block.js";

import { watch } from "vue";
import { useStore } from "vuex";
import Select from "@/components/Select/index.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { ref } from "vue";

// import "#/theme/js/ckeditor-document.js";
export default {
    components: { Select },

    setup() {
        const store = useStore();
        const route = useRoute();
        const portfolios = computed(() => store.getters["auth/portfolios"]);
        const user = computed(() => store.getters["auth/user"]);
        const selectedPortfolio = ref();

        const handlePortfolioChange = (data) => {
            store.commit("auth/SET_PORTFOLIO", { portfolio: data });
        };

        watch(
            () => portfolios.value,
            (val) => {
                if (!selectedPortfolio.value) {
                    handlePortfolioChange(val[0]);
                    selectedPortfolio.value = val[0];
                }
            }
        );

        return {
            portfolios,
            selectedPortfolio,
            user,
            handlePortfolioChange,
        };
    },
};
</script>
<style lang="scss">
@import "#/theme/css/app.scss";
</style>
