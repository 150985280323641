<template>
    <div class="w-full">
        <div class="w-full pb-20">
            <div class="w-full">
                <div
                    class="rounded-sm bg-white flex flex-col p-5 mb-5 w-full overflow-y-scroll"
                >
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4
                                class="card-title font-ibm font-semibold text-slate-800"
                            >
                                Les alertes
                            </h4>
                        </div>
                        <button
                            @click="$router.push({ name: 'app.alertCreate' })"
                            class="flex justify-center font-semibold items-center border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Créer une alerte
                        </button>
                    </div>
                    <div v-if="alerts.length > 0 && !isLoading">
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped font-ibm"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Identifiant
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Type
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Adresse
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Patient
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Status
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Date de demande
                                            </th>
                                            <!-- <th class="whitespace-nowrap">Date de création</th> -->
                                            <!-- <th class="whitespace-nowrap">Dernière conn.</th> -->
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="user in alerts"
                                            :key="user.id"
                                        >
                                            <td>
                                                <span
                                                    class="bg-pink-100 font-ibm text-slate-700 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                                    >{{ user.identifier }}</span
                                                >
                                            </td>
                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{ types[user.type] }}
                                            </td>
                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{ user.address }}
                                            </td>
                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{ user.patient?.full_name }}
                                            </td>

                                            <td class="font-ibm">
                                                <p
                                                    :class="`text-sm text-${
                                                        statusColors[
                                                            user.status
                                                        ]
                                                    }`"
                                                >
                                                    {{ status[user.status] }}
                                                </p>
                                            </td>

                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{
                                                    user.requested_at
                                                        ? moment(
                                                              user.requested_at
                                                          ).format("l hh:mm:ss")
                                                        : "-"
                                                }}
                                            </td>

                                            <td>
                                                <div
                                                    class="flex justify-end font-ibm"
                                                >
                                                    <button
                                                        class="bg-warning bg-opacity-25 mr-1 px-2 py-1 rounded text-warning"
                                                        size="sm"
                                                        @click="
                                                            $router.push({
                                                                name: 'app.alerts_get',
                                                                params: {
                                                                    id: user.id,
                                                                },
                                                            })
                                                        "
                                                    >
                                                        <i
                                                            class="las la-eye m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="px-2 py-1 bg-danger bg-opacity-25 rounded text-danger"
                                                        size="sm"
                                                        @click="
                                                            handleRemove(user)
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="isLoading"
                        class="flex w-full h-full justify-center items-center"
                    >
                        <Loader class="w-20 text-slate-500" />
                    </div>

                    <div v-else class="p-3 h-full">
                        <div
                            class="flex flex-col justify-center items-center h-full"
                        >
                            <img class="w-1/4" src="/images/no-result.svg" />
                            <h4
                                class="text-primary font-medium my-3 text-xl font-ibm"
                            >
                                Aucune alerte
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <popup
            message="Êtes-vous sûre de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeAlert"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup> -->
        <Modal
            title="Suppression"
            width="w-2/5"
            :isVisible="canDelete"
            @cancel="cancelDeletion"
            @submit="removeAlert"
        >
            <div>
                <p class="font-qs text-md">Cette action est définitive.</p>
                <h3 class="text-danger font-ibm text-lg font-bold py-2">
                    Êtes-vous sûre de vouloir continuer ?
                </h3>
            </div>
        </Modal>
    </div>
</template>

<script>
import { useStore } from "vuex";
import moment from "moment";
import {
    ref,
    computed,
    onBeforeMount,
    onMounted,
    onBeforeUnmount,
    watch,
} from "vue";
import Loader from "@/components/Loader/index.vue";
import Modal from "@/components/Modal/SampleModal.vue";

export default {
    components: { Loader, Modal },

    setup() {
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const types = computed(() => ({
            "heart-attack": "Attaque cardiaque",
            accident: "Accident",
            hypertension: "Hyper/Hypo Tension artérielle",
            pregnant: "Incident avec une femme enceinte",
        }));
        const statusColors = computed(() => ({
            pending: "pending",
            ended: "success",
            intervention_started: "warning",
            arrived: "primary",
            picked_up: "primary",
            supported: "black",
            moved: "primary",
        }));
        const status = computed(() => ({
            pending: "En attente",
            ended: "Terminé",
            intervention_started: "En cours",
            arrived: "Arrivée chez le patient",
            picked_up: "Patient pris en charge",
            supported: "Pris en charge",
            moved: "En route pour l'hôpital",
        }));
        const canDelete = ref(false);
        const isLoading = ref(false);
        const currentId = ref(null);
        const store = useStore();
        const alerts = computed(() => store.getters["medical/alerts"]);

        const handleRemove = (data) => {
            currentId.value = data.id;
            canDelete.value = true;
        };

        const cancelDeletion = () => {
            currentId.value = null;
            canDelete.value = false;
        };

        const removeAlert = () => {
            store.dispatch("medical/deleteAlert", {
                portfolioId: portfolio.value?.id,
                alertId: currentId.value,
                onSuccess: () => {
                    cancelDeletion();

                    getAlerts();
                },
                onError: () => {},
            });
        };

        const listenToNewAlerts = () => {
            window.Echo.channel(`alerts.new`).listen(".NewAlert", (data) => {
                console.log("NewAlert", data);
                getAlerts();
            });
        };

        const getAlerts = () => {
            store.dispatch("medical/getAlerts", {
                portfolioId: portfolio.value.id,
                onSuccess: () => {
                    isLoading.value = false;
                },
            });
        };

        onBeforeMount(() => {
            store.dispatch("config/getResources", { page: 1 });
        });

        onBeforeUnmount(() => {
            window.Echo.leaveChannel(`alerts.new`, () => {
                console.log("alerts.new leaved");
            });
        });

        watch(
            () => portfolio.value,
            (val) => {
                getAlerts();
            }
        );

        onMounted(() => {
            isLoading.value = true;
            getAlerts();
            listenToNewAlerts();
        });

        return {
            statusColors,
            moment,
            isLoading,
            status,
            alerts,
            handleRemove,
            types,
            removeAlert,
            canDelete,
            cancelDeletion,
        };
    },
};
</script>
