<template>
    <div
        v-if="isLoading"
        class="flex justify-center items-center h-full w-full my-4 min-h-[500px]"
    >
        <NewLoader />
    </div>
    <div class="flex justify-center pb-10 p-details" v-else-if="planning">
        <div class="w-11/12 mt-4">
            <div
                class="sticky z-50 flex justify-center items-center top-16 bg-white shadow-md w-10 rounded-full"
            >
                <a href="/search-praticians">
                    <i class="la la-arrow-left text-3xl text-primary"></i>
                </a>
            </div>

            <div
                v-if="currentStep == 0"
                class="flex w-full mt-10 justify-between relative"
            >
                <div :class="`w-${isSmall || isMedium ? 'full' : '8/12'}`">
                    <div class="flex justify-between">
                        <div class="flex items-center">
                            <div
                                v-if="
                                    !planning.pratician &&
                                    planning?.medical_center?.profile.img
                                "
                                class="w-14 h-14 rounded-full mb-3 bg-no-repeat bg-slate-400"
                                :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${planning?.medical_center?.profile.img});
                `"
                            ></div>
                            <div
                                v-else-if="
                                    !planning?.pratician?.avatar.includes(
                                        '-default.'
                                    )
                                "
                                class="w-14 h-14 rounded-full bg-no-repeat bg-slate-300"
                                :style="`
                      background-size: cover;
                      background-position: center;
                      background-image: url(${planning?.pratician?.avatar});
                  `"
                            ></div>

                            <div
                                v-else
                                class="w-14 h-14 text-xl font-bold font-inter rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                            >
                                {{ initials }}
                            </div>
                            <div>
                                <h3
                                    v-if="planning?.pratician"
                                    class="font-bold text-slate-800 font-inter text-2xl px-2"
                                >
                                    Dr. {{ planning?.pratician?.full_name }}
                                </h3>
                                <h3
                                    v-else
                                    class="font-bold font-inter text-xl px-2 text-slate-600"
                                >
                                    -
                                </h3>
                                <p class="text-sm px-2 text-slate-400">
                                    {{ planning?.medical_center?.name }}
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="isSmall || isMedium"
                            class="bg-primary3 text-white cursor-pointer rounded-lg font-ibm px-3 py-2 flex justify-center items-center"
                            @click="hideAvailability = !hideAvailability"
                        >
                            <i class="la la-calendar mr-1"></i>
                            Voir les disponibilités
                        </div>
                    </div>

                    <div
                        class="my-10"
                        v-if="planning.medical_center?.profile?.phone_1"
                    >
                        <div class="flex">
                            <div class="flex">
                                <img
                                    src="/images/phone.svg"
                                    class="w-5 h-5"
                                    alt=""
                                />
                                <span class="mx-1 text-slate-600 text-sm">{{
                                    formatPhone(
                                        planning.medical_center?.profile
                                            ?.phone_1
                                    )
                                }}</span>
                            </div>
                            <div
                                class="ml-3 flex"
                                v-if="planning.medical_center?.profile?.phone_2"
                            >
                                <img
                                    src="/images/phone.svg"
                                    class="w-5 h-5"
                                    alt=""
                                />
                                <span class="mx-1 text-slate-600 text-sm">{{
                                    formatPhone(
                                        planning.medical_center?.profile
                                            ?.phone_2
                                    )
                                }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="my-4 bg-white rounded">
                        <div class="flex items-start">
                            <i
                                class="las la-microscope mr-1 text-lg text-primary01"
                            ></i>
                            <div>
                                <h3
                                    class="text-md text-slate-800 font-normal font-medium"
                                >
                                    Expertises et actes
                                </h3>
                                <div class="mt-2">
                                    <Chip
                                        class="mt-1.5 mr-2 text-xs text-primary2 bg-primary2/[.1]"
                                        :label="sp.name"
                                        v-for="sp in planning.specialties"
                                        :key="sp.id"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="flex items-start mt-5 py-4 text-primary01">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-currency-exchange"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z"
                                />
                            </svg>
                            <div>
                                <h3
                                    class="text-slate-600 px-3 text-md font-inter font-medium"
                                >
                                    Tarifs
                                </h3>
                                <div class="mt-2">
                                    <h3
                                        class="text-sm font-medium"
                                        v-if="planning.price"
                                    >
                                        {{ priceMin }} à {{ priceMax }}
                                        <i
                                            v-tooltip.bottom="
                                                'Ce prix représente une foruchette mais peut différer en fonction du moment de la consultation'
                                            "
                                            class="la la-info-circle text-md text-primary01"
                                        ></i>
                                    </h3>
                                    <h3 class="text-sm font-medium" v-else>
                                        N/A
                                    </h3>

                                    <p
                                        v-if="planning.price"
                                        class="text-xs font-medium text-slate-400 mt-1"
                                    >
                                        Ces honoraires vous sont communiqués à
                                        titre indicatif par le praticien. Ils
                                        peuvent varier selon le type de soins
                                        finalement réalisés en cabinet, le
                                        nombre de consultations et les actes
                                        additionnels nécessaires. En cas de
                                        dépassement des tarifs, le praticien
                                        doit en avertir préalablement le
                                        patient.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-4 bg-white rounded w-full">
                        <div class="flex items-start mt-5 w-full">
                            <div class="flex w-full">
                                <div class="w-3/5 pt-4 flex text-primary01">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.30129 4.40426C5.39736 5.24534 4.79199 6.56573 4.79199 8.5C4.79199 10.4025 5.66287 12.5297 6.81646 14.1978C7.38946 15.0264 8.01441 15.7153 8.60247 16.1899C9.21178 16.6816 9.69628 16.875 10.0003 16.875C10.3044 16.875 10.7889 16.6816 11.3982 16.1899C11.9862 15.7153 12.6112 15.0264 13.1842 14.1978C14.3378 12.5297 15.2087 10.4025 15.2087 8.5C15.2087 6.56573 14.6033 5.24534 13.6994 4.40426C12.7871 3.55545 11.4957 3.125 10.0003 3.125C8.50499 3.125 7.21352 3.55545 6.30129 4.40426ZM5.44979 3.48914C6.64881 2.37348 8.274 1.875 10.0003 1.875C11.7267 1.875 13.3518 2.37348 14.5509 3.48914C15.7582 4.61252 16.4587 6.29214 16.4587 8.5C16.4587 10.7396 15.4545 13.1125 14.2123 14.9088C13.5874 15.8124 12.8842 16.5969 12.1832 17.1626C11.5035 17.7112 10.7379 18.125 10.0003 18.125C9.2627 18.125 8.4972 17.7112 7.81745 17.1626C7.11645 16.5969 6.41327 15.8124 5.78836 14.9088C4.54612 13.1125 3.54199 10.7396 3.54199 8.5C3.54199 6.29214 4.24246 4.61252 5.44979 3.48914Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 6.45825C8.96447 6.45825 8.125 7.29772 8.125 8.33325C8.125 9.36879 8.96447 10.2083 10 10.2083C11.0355 10.2083 11.875 9.36879 11.875 8.33325C11.875 7.29772 11.0355 6.45825 10 6.45825ZM6.875 8.33325C6.875 6.60736 8.27411 5.20825 10 5.20825C11.7259 5.20825 13.125 6.60736 13.125 8.33325C13.125 10.0591 11.7259 11.4583 10 11.4583C8.27411 11.4583 6.875 10.0591 6.875 8.33325Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    <div class="w-full">
                                        <h3
                                            class="text-slate-600 text-md font-inter font-medium"
                                        >
                                            Carte et informations d'accès
                                        </h3>

                                        <div>
                                            <p class="py-2 text-slate-500">
                                                {{ planning?.address?.address }}
                                            </p>
                                        </div>

                                        <div class="mt-2">
                                            <h3
                                                class="font-semibold text-sm text-slate-600"
                                            >
                                                Indications
                                            </h3>
                                            <p class="py-2 text-slate-500">
                                                {{
                                                    planning?.address
                                                        ?.address_indications ??
                                                    address?.indications ??
                                                    "-"
                                                }}
                                            </p>
                                        </div>

                                        <div class="mt-2">
                                            <h3
                                                class="font-semibold text-sm text-slate-600"
                                            >
                                                Informations pratiques
                                            </h3>
                                            <p
                                                class="py-2 text-sm text-slate-500"
                                            >
                                                Étage:
                                                <strong>{{
                                                    planning?.address
                                                        ?.address_floor >= 0
                                                        ? floors.find(
                                                              (x) =>
                                                                  x.id ==
                                                                  planning
                                                                      ?.address
                                                                      ?.address_floor
                                                          )?.name ?? "-"
                                                        : "-"
                                                }}</strong>
                                            </p>
                                            <p
                                                class="py-2 text-sm text-slate-500"
                                            >
                                                Entrée sans code :
                                                <strong>{{
                                                    planning?.address
                                                        .address_accessibility
                                                        ? "Oui"
                                                        : "Non"
                                                }}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5">
                            <div class="w-full flex rounded overflow-hidden">
                                <div class="w-full" style="min-height: 300px">
                                    <gmap
                                        class="h-full"
                                        :center="center"
                                        :zoom="15"
                                        :can-move-marker="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-4 bg-white rounded">
                        <div class="flex items-start py-4">
                            <i
                                class="las la-align-left mr-1 text-lg text-primary01"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-500 text-md font-inter font-medium"
                                >
                                    Présentation
                                </h3>
                                <div
                                    class="mt-2 text-slate-700"
                                    v-html="
                                        planning.medical_center?.profile
                                            ?.presentation
                                    "
                                ></div>
                            </div>
                        </div>

                        <div
                            class="flex items-start mt-5 py-4 border-t-2 border-[#F8F9FC]"
                        >
                            <i
                                class="las la-graduation-cap mr-1 text-lg text-primary01"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-500 text-md font-inter font-medium"
                                >
                                    Diplômes nationaux et universitaires
                                </h3>
                                <div class="mt-2">
                                    <p
                                        class="text-sm font-medium text-slate-600"
                                    >
                                        N/A
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-start mt-5 py-4 border-t-2 border-[#F8F9FC]"
                        >
                            <i
                                class="las la-info-circle mr-1 text-lg text-primary01"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-500 text-lg font-ibm font-medium"
                                >
                                    Informations légales
                                </h3>
                                <div class="mt-2 flex">
                                    <h3 class="text-md text-slate-600 font-ibm">
                                        Numéro d'identification :
                                    </h3>
                                    <p
                                        class="text-md font-inter text-slate-600 px-1"
                                    >
                                        {{
                                            planning?.medical_center
                                                ?.authorization_number
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!hideAvailability || isLarge"
                    :class="`transition transition-all w-${
                        isSmall || isMedium ? '7/12' : '3/12'
                    } ${isSmall || isMedium ? 'absolute right-0 top-14' : ''}`"
                >
                    <div
                        class="w-full rounded bg-white pt-3 pb-5 px-6 b-shadow"
                    >
                        <div
                            v-if="displayedSlots.length > 0"
                            class="mb-4 mt-2 flex justify-between items-center"
                        >
                            <h3 class="text-md font-bold text-slate-500">
                                Sélectionnez un créneau
                            </h3>
                            <div class="flex justify-end">
                                <i
                                    :class="`la la-arrow-left mr-3  ${
                                        currentIndex <= 4
                                            ? 'text-slate-400'
                                            : ' text-slate-500 cursor-pointer'
                                    }`"
                                    @click="handlePrev"
                                    style="font-size: 1.5rem"
                                ></i>
                                <i
                                    :class="`la la-arrow-right  ${
                                        currentIndex >= allSlots.length
                                            ? 'text-slate-400'
                                            : ' text-slate-500 cursor-pointer'
                                    }`"
                                    style="font-size: 1.5rem"
                                    @click="handleNext"
                                ></i>
                            </div>
                        </div>

                        <div
                            v-else
                            class="mb-4 mt-2 flex justify-between items-center"
                        >
                            <p>Aucun créneau disponible pour le moment</p>
                        </div>
                        <div class="grid grid grid-cols-4 gap-4">
                            <div
                                v-for="(item, index) in displayedSlots"
                                :key="index"
                                :class="`flex flex-col items-center ${
                                    displayedSlots.length < 4 ? 'mr-5' : ''
                                }`"
                            >
                                <div
                                    class="mx-2 flex flex-col items-center"
                                    v-if="item.date"
                                >
                                    <h3
                                        class="text-sm capitalize text-slate-500 font-bold font-ibm"
                                    >
                                        {{ moment(item.date).format("ddd") }}
                                    </h3>
                                    <h4
                                        class="text-xs text-center text-slate-500 font-ibm capitalize"
                                    >
                                        {{ moment(item.date).format("DD MMM") }}
                                    </h4>
                                </div>
                                <div class="flex flex-col items-center">
                                    <button
                                        v-for="(slot, slotIndex) in item.slots"
                                        :key="slotIndex"
                                        :class="`w-14 my-2 rounded bg-primary01 bg-opacity-15 border ${
                                            selectedSlot?.start &&
                                            moment(slot?.start).isSame(
                                                moment(selectedSlot.start)
                                            )
                                                ? 'border-slate-500 opacity-[55]'
                                                : 'border-primary01'
                                        } text-primary01 px-1 py-2 mx-1 opacity-75 slot hover:opacity-[95]`"
                                        @click="handleTime(slot)"
                                    >
                                        {{
                                            moment(slot?.start).format("HH:mm")
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="currentStep == 1"
                class="w-full flex justify-center"
            >
                <div class="w-5/6 flex">
                    <div class="w-full">
                        <div class="py-3">
                            <h3
                                class="font-ibm font-semibold text-slate-700 text-2xl"
                            >
                                Prenez votre rendez-vous en ligne
                            </h3>
                            <p class="py-3 text-sm text-slate-500 font-inter">
                                Renseignez les informations suivantes
                            </p>
                        </div>
                        <div
                            :class="`flex ${
                                isSmall || isMedium ? 'flex-col' : ''
                            }`"
                        >
                            <div
                                :class="`bg-white w-${
                                    isSmall || isMedium ? 'full' : '7/12'
                                } rounded py-10 pr-8 mt-2 self-start`"
                            >
                                <div>
                                    <span
                                        class="p-1 rounded hover:bg-slate-300 text-sm cursor-pointer text-slate-700"
                                        @click.prevent="currentStep--"
                                    >
                                        <i class="la la-arrow-left"></i> Étape
                                        précédente</span
                                    >

                                    <div class="w-full text-xs my-3">
                                        <Message
                                            class="text-xs"
                                            severity="warn"
                                            :closable="false"
                                            ><p class="text-sm">
                                                Une prise de rendez-vous en
                                                ligne est une réservation de
                                                créneau,cette action vous engage
                                                donc et doit être honorée.
                                            </p></Message
                                        >
                                    </div>
                                    <h3
                                        class="text-slate-700 font-semibold py-5"
                                    >
                                        Choisissez une spécialité
                                    </h3>

                                    <div
                                        v-for="(
                                            spec, spIndex
                                        ) in planning.specialties"
                                        :key="spIndex"
                                        @click.prevent="
                                            selectSpecialty(spec.id)
                                        "
                                        :class="`overflow-hidden flex justify-end rounded-2xl cursor-pointer border  ${
                                            spec?.id == selectedSpecialty
                                                ? 'bg-primary2 border-primary2'
                                                : 'bg-primary01 bg-opacity-25 border-[#EBEEF3]'
                                        } mb-4`"
                                    >
                                        <div
                                            style="width: 97%"
                                            class="h-full bg-white"
                                        >
                                            <div
                                                style=""
                                                :class="`flex py-6 w-full px-2 h-full justify-between items-center ${
                                                    spec?.id ==
                                                    selectedSpecialty
                                                        ? 'bg-primary01 bg-opacity-15'
                                                        : 'bg-white'
                                                } ${
                                                    spIndex !=
                                                    planning.specialties
                                                        ?.length -
                                                        1
                                                        ? ''
                                                        : ''
                                                }`"
                                            >
                                                <p
                                                    class="text-slate-800 font-normal text-md font-ibm"
                                                >
                                                    {{ spec.name }}
                                                </p>

                                                <div class="mr-5">
                                                    <Checkbox
                                                        v-model="
                                                            selectedSpecialty
                                                        "
                                                        variant="filled"
                                                        :inputId="spec.id"
                                                        name="selectedSpecialty"
                                                        :value="spec.id"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class=""
                                        v-if="
                                            selectedSpecialty.length == 1 &&
                                            procedures.length > 0
                                        "
                                    >
                                        <h3
                                            class="text-slate-700 font-semibold py-5"
                                        >
                                            Choisissez les actes à pratiquer
                                        </h3>

                                        <div class="overflow-hidden">
                                            <div
                                                :class="`overflow-hidden flex justify-end rounded-2xl cursor-pointer border  ${
                                                    selectedProcedures.includes(
                                                        proc.id
                                                    )
                                                        ? 'bg-primary2 border-primary2'
                                                        : 'bg-primary01 bg-opacity-25 border-[#EBEEF3]'
                                                } mb-4`"
                                                v-for="(
                                                    proc, mIndex
                                                ) in procedures"
                                                :key="mIndex"
                                                @click="
                                                    selectProcedures(proc.id)
                                                "
                                            >
                                                <div
                                                    style="width: 97%"
                                                    class="h-full bg-white"
                                                >
                                                    <div
                                                        style=""
                                                        :class="`flex py-6 w-full px-2 h-full justify-between items-center ${
                                                            selectedProcedures.includes(
                                                                proc?.id
                                                            )
                                                                ? 'bg-primary01 bg-opacity-15'
                                                                : 'bg-white'
                                                        }`"
                                                    >
                                                        <p
                                                            class="text-slate-800 font-normal text-md font-ibm"
                                                        >
                                                            {{ proc.name }}
                                                        </p>

                                                        <div class="mr-5">
                                                            <Checkbox
                                                                v-model="
                                                                    selectedProcedures
                                                                "
                                                                variant="filled"
                                                                :inputId="
                                                                    proc.id
                                                                "
                                                                name="selectedProcedures"
                                                                :value="proc.id"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3
                                        class="text-slate-700 font-semibold py-5"
                                    >
                                        Choisissez votre motif de consultation
                                    </h3>

                                    <div class="overflow-hidden">
                                        <div
                                            :class="`overflow-hidden flex justify-end rounded-2xl cursor-pointer border  ${
                                                motive?.id == selectedMotive
                                                    ? 'bg-primary2 border-primary2'
                                                    : 'bg-primary01 bg-opacity-25 border-[#EBEEF3]'
                                            } mb-4`"
                                            v-for="(motive, mIndex) in motives"
                                            :key="mIndex"
                                            @click="selectMotive(motive.id)"
                                        >
                                            <div
                                                style="width: 97%"
                                                class="h-full bg-white"
                                            >
                                                <div
                                                    style=""
                                                    :class="`flex py-6 w-full px-2 h-full justify-between items-center ${
                                                        motive?.id ==
                                                        selectedMotive
                                                            ? 'bg-primary01 bg-opacity-15'
                                                            : 'bg-white'
                                                    } ${
                                                        mIndex !=
                                                        (planning.motives
                                                            ?.length ?? 1) -
                                                            1
                                                            ? ''
                                                            : ''
                                                    }`"
                                                >
                                                    <p
                                                        class="text-slate-800 font-normal text-md font-ibm"
                                                    >
                                                        {{ motive.name }}
                                                    </p>

                                                    <div class="mr-5">
                                                        <Checkbox
                                                            v-model="
                                                                selectedMotive
                                                            "
                                                            variant="filled"
                                                            :inputId="motive.id"
                                                            name="selectedMotive"
                                                            :value="motive.id"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                :class="`w-${
                                    isSmall || isMedium ? 'full mt-5' : '5/12'
                                } flex flex justify-end`"
                            >
                                <div
                                    :class="`w-${
                                        isSmall || isMedium ? 'full' : '5/6'
                                    }`"
                                >
                                    <div class="">
                                        <h3
                                            class="mb-1 text-slate-600 font-inter text-md"
                                            v-if="user"
                                        >
                                            Patient
                                        </h3>
                                        <div
                                            class="w-full mb-4 bg-white rounded-lg self-start p-4 shadow-none border border-slate-100"
                                        >
                                            <div
                                                class="flex flex-col items-start"
                                            >
                                                <div
                                                    class="flex items-center"
                                                    v-if="user"
                                                >
                                                    <div
                                                        v-if="user?.avatar"
                                                        class="w-12 h-12 rounded-full bg-no-repeat bg-slate-200"
                                                        :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${user?.avatar});
                `"
                                                    ></div>
                                                    <div
                                                        v-else
                                                        class="w-14 h-14 text-lg font-bold font-ibm rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                                                    >
                                                        {{ patientInitials }}
                                                    </div>

                                                    <div>
                                                        <h3
                                                            class="font-semibold font-ibm text-lg px-2 text-slate-600"
                                                        >
                                                            {{
                                                                user?.full_name
                                                            }}
                                                        </h3>
                                                        <span
                                                            class="text-xs px-2 text-slate-400"
                                                        >
                                                            {{
                                                                user.username ??
                                                                "-"
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div v-else class="w-full">
                                                    <div
                                                        class="flex justify-between items-center border-b border-slate-100 w-full"
                                                    >
                                                        <h3
                                                            class="text-danger text-sm font-inter text-slate-700"
                                                        >
                                                            Vous êtes nouveau
                                                            ici ?
                                                        </h3>

                                                        <Button
                                                            label="M'inscrire"
                                                            class="bg-primary2 rounded-lg outline-0 px-3 py-2 text-white my-4"
                                                        ></Button>
                                                    </div>
                                                    <div
                                                        class="mt-3 w-full flex justify-between items-center"
                                                    >
                                                        <h3
                                                            class="text-danger text-sm font-inter text-slate-700"
                                                        >
                                                            J'ai déjà un compte
                                                            sur Allô Doto ?
                                                        </h3>

                                                        <Button
                                                            label="Se connecter"
                                                            class="bg-warning rounded-lg outline-0 px-3 py-2 text-white my-4"
                                                            @click="
                                                                showLogin = true
                                                            "
                                                        ></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="mb-1 text-slate-600 font-inter">
                                        Rendez-vous avec:
                                    </h3>
                                    <div
                                        class="w-full bg-white rounded-lg border border-slate-100 self-start p-4"
                                    >
                                        <div class="flex flex-col items-start">
                                            <div class="flex items-center mb-4">
                                                <div
                                                    v-if="
                                                        !planning?.pratician?.avatar.includes(
                                                            '-default.'
                                                        )
                                                    "
                                                    class="w-12 h-12 rounded-full bg-no-repeat"
                                                    :style="`
                                                    background-size: cover;
                                                    background-position: center;
                                                    background-image: url(${planning?.pratician?.avatar});
                                                `"
                                                ></div>
                                                <div
                                                    v-else
                                                    class="w-14 h-14 text-lg font-bold font-ibm rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                                                >
                                                    {{ initials }}
                                                </div>

                                                <div>
                                                    <h3
                                                        class="font-bold font-inter text-lg px-2 text-slate-600"
                                                    >
                                                        {{
                                                            planning?.pratician
                                                                ?.full_name
                                                        }}
                                                    </h3>
                                                    <span
                                                        class="text-xs px-2 text-slate-500"
                                                        v-if="
                                                            planning.medical_center
                                                        "
                                                    >
                                                        {{
                                                            planning
                                                                .medical_center
                                                                .name
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flex my-1">
                                                <div class="flex items-start">
                                                    <img
                                                        src="/images/phone.svg"
                                                        class="w-5 h-5"
                                                        alt=""
                                                    />
                                                    <span
                                                        class="mx-1 text-slate-600 text-sm"
                                                        >{{
                                                            formatPhone(
                                                                planning
                                                                    .medical_center
                                                                    ?.profile
                                                                    ?.phone_1
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                                <div
                                                    class="ml-3 flex"
                                                    v-if="
                                                        planning.medical_center
                                                            ?.profile?.phone_2
                                                    "
                                                >
                                                    <img
                                                        src="/images/phone.svg"
                                                        class="w-5 h-5"
                                                        alt=""
                                                    />
                                                    <span
                                                        class="mx-1 text-slate-600 text-sm"
                                                        >{{
                                                            formatPhone(
                                                                planning
                                                                    .medical_center
                                                                    ?.profile
                                                                    ?.phone_2
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                            </div>

                                            <div
                                                v-if="appointmentSpecialty"
                                                class="my-2"
                                            >
                                                <div
                                                    class="flex items-start text-slate-600"
                                                >
                                                    <img
                                                        src="/images/check-ok.svg"
                                                        class="w-6"
                                                        alt=""
                                                    />
                                                    <p
                                                        class="mx-1 font-inter text-sm"
                                                    >
                                                        {{
                                                            appointmentSpecialty.name
                                                        }}
                                                        <span
                                                            v-if="
                                                                appointmentProcedures.length
                                                            "
                                                        >
                                                            ({{
                                                                appointmentProcedures
                                                                    .map(
                                                                        (x) =>
                                                                            x.name
                                                                    )
                                                                    .join(", ")
                                                            }})
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                v-if="appointmentMotive"
                                                class="my-2"
                                            >
                                                <div
                                                    class="flex items-start text-slate-600"
                                                >
                                                    <img
                                                        src="/images/check-ok.svg"
                                                        class="w-6"
                                                        alt=""
                                                    />
                                                    <p
                                                        class="mx-1 font-inter text-sm"
                                                    >
                                                        {{
                                                            appointmentMotive.name
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="planning?.address"
                                            class="my-2"
                                        >
                                            <div
                                                class="flex items-start text-slate-600"
                                            >
                                                <img
                                                    src="/images/check-ok.svg"
                                                    class="w-6"
                                                    alt=""
                                                />
                                                <p
                                                    class="mx-1 font-inter text-sm"
                                                >
                                                    {{
                                                        planning.address.address
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            v-if="selectedSlot?.start"
                                            class="mt-2"
                                        >
                                            <div
                                                class="flex items-start text-slate-600"
                                            >
                                                <img
                                                    src="/images/check-ok.svg"
                                                    class="w-6"
                                                    alt=""
                                                />
                                                <p
                                                    class="mx-1 font-inter text-sm"
                                                >
                                                    {{
                                                        moment(
                                                            selectedSlot?.start
                                                        ).format(
                                                            "dddd DD MMMM à HH:mm"
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="appointmentCoast"
                                        class="w-full flex justify-between items-center my-6"
                                    >
                                        <div
                                            class="my-4 rounded px-3 py-2 border border-info flex justify-between w-9/12"
                                        >
                                            <h3 class="text-info text-sm">
                                                Prix du service :
                                                {{ formattedCost }}
                                            </h3>

                                            <el-tooltip
                                                content="<span>Prix du <strong>service</strong></span><br>Ce prix n'inclus pas le montant de la consultation<br><br>Les frais de la consultation seront <br>payés sur place avec votre praticien"
                                                raw-content
                                            >
                                                <i
                                                    class="la la-info-circle text-info"
                                                ></i>
                                            </el-tooltip>
                                        </div>
                                        <div>
                                            <a
                                                href="/faq"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i
                                                    class="la la-external-link-alt text-info text-2xl"
                                                ></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        class="my-5"
                                        v-if="
                                            selectedMotive.length &&
                                            selectedSpecialty.length
                                        "
                                    >
                                        <Button
                                            label="Enregistrer"
                                            severity="primary"
                                            class="w-full bg-primary py-2 px-3 text-white font-normal"
                                            :disabled="!user"
                                            @click="handleSave"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="visible"
        :draggable="false"
        modal
        :closable="false"
        :style="{ width: isSmall || isMedium ? '50vw' : '40vw' }"
    >
        <template #header>
            <div class="w-full flex justify-center">
                <h1
                    class="text-slate-700 text-xl font-semibold font-ibm text-center"
                >
                    À lire avant votre prise de rendez-vous
                </h1>
            </div>
        </template>
        <div class="font-inter text-sm p-modal-container">
            <h3
                class="py-4 font-semibold text-slate-600 text-xl font-ibm text-center"
            >
                3 Consignes
            </h3>
            <div class="flex items-start">
                <img src="/images/check-ok.svg" class="w-6 mr-3" alt="" />
                Le créneau vous sera réservé.Veuillez donc à ne pas être en
                retard à votre rendez-vous.
            </div>
            <Divider />
            <div class="flex items-start">
                <img src="/images/check-ok.svg" class="w-6 mr-3" alt="" />
                <p>
                    Il est conseillé de venir au moins
                    <strong>20 minutes</strong> avant votre rendez-vous afin de
                    vous assurer de faire les formalités d'usage avant le début
                    de la consultation proprement dite.
                </p>
            </div>
            <Divider />
            <div class="flex items-start">
                <img src="/images/check-ok.svg" class="w-6 mr-3" alt="" />
                Venez au rendez-vous avec votre dossier médical si possible dans
                le cadre d'une consultation de suivi.
            </div>
            <Divider />
            <div>
                Tout <strong>mauvais traitement</strong> (décallage assez long
                de votre créneau, absence du praticien,...) ou geste déplacé du
                praticien envers vous durant la consultation doit être
                immédiatement signalé afin de nous permettre de prendre les
                mesures nécessaires.
            </div>

            <p class="py-4 text-warning">
                NB: Vous devez vous munir d'une pièce d'identité.
            </p>

            <div class="my-5">
                <div class="flex align-items-center items-center">
                    <Field
                        v-slot="{ field }"
                        name="acceptTerms"
                        type="checkbox"
                    >
                        <Checkbox
                            v-bind="field"
                            v-model="acceptTerms"
                            inputId="acceptTerms"
                            name="acceptTerms"
                            :binary="true"
                        />
                    </Field>
                    <label
                        for="acceptTerms"
                        class="ml-2 text-sm text-slate-500"
                    >
                        J'accepte les
                        <a href="/terms" class="text-primary3" target="_blank"
                            >conditions d'utilisation</a
                        >
                    </label>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="w-full flex justify-center">
                <Button
                    v-if="!isSubmitingApointment"
                    label="Fermer"
                    class="outline border border-danger outline-danger mr-3 py-2 px-3 text-danger font-normal"
                    @click="visible = false"
                    autofocus
                />
                <Button
                    v-if="!isSubmitingApointment"
                    label="J'accepte"
                    class="bg-primary3 outline-primary3 shadow-primary3 py-2 px-3 text-white font-normal"
                    @click="saveAppointment"
                    :disabled="!acceptTerms"
                    autofocus
                />
                <div class="" v-if="isSubmitingApointment">
                    <button
                        type="button"
                        class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-success hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                        disabled=""
                    >
                        <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Sauvegarde en cours...
                    </button>
                </div>
            </div>
        </template>
    </Dialog>
    <LoginModal
        :show="showLogin"
        @onLoggedIn="handleUserLoggedIn"
        @cancel="showLogin = false"
    />
    <PToast />
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useStore } from "vuex";
import gmap from "@/components/maps/gmap";
import Message from "primevue/message";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import moment from "moment";
import LoginModal from "@/views/auth/modal.vue";
import { useToast } from "primevue/usetoast";
import NewLoader from "@/components/Loader/index-2.vue";
import { AsYouType } from "libphonenumber-js";
import { useWindowSize } from "@vueuse/core";
import Storage, { storageKeys } from "../../services/Storage";
import { ElTooltip } from "element-plus";
import Checkbox from "primevue/checkbox";
import { Field } from "vee-validate";

// import {
//     openKkiapayWidget,
//     addKkiapayListener,
//     removeKkiapayListener,
//     addFailedListener,
//     addSuccessListener,
// } from "kkiapay";

export default {
    components: {
        Chip,
        Field,
        gmap,
        Toast,
        Message,
        Button,
        Dialog,
        ElTooltip,
        Divider,
        Checkbox,
        LoginModal,
        NewLoader,
    },
    setup() {
        // const {
        //     openKkiapayWidget,
        //     addKkiapayListener,
        //     removeKkiapayListener,
        //     addFailedListener,
        //     addSuccessListener,
        // } = import.meta.glob("kkiapay");
        const toast = useToast();
        const link = computed(() =>
            typeof window != "undefined" ? window.location.href : ""
        );
        const isLoading = ref(true);
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const planning = ref(null);
        const visible = ref(false);
        const appointmentCoast = ref(0);

        const formattedCost = computed(() =>
            new Intl.NumberFormat("fr", {
                style: "currency",
                currency: "XOF",
            }).format(appointmentCoast.value)
        );
        const center = ref(null);
        const selectedSlot = ref(null);
        const countSteps = ref(4);
        const showLogin = ref(false);
        const selectedSpecialty = ref([]);
        const selectedMotive = ref([]);
        const selectedProcedures = ref([]);
        const isSubmitingApointment = ref(false);
        const currentStep = ref(0);

        const hideAvailability = ref(false);
        const procedures = ref([]);
        const { width, height } = useWindowSize();

        const isSmall = computed(() => width.value <= 540);
        const isMedium = computed(
            () => width.value <= 1000 && width.value > 540
        );
        const isLarge = computed(() => width.value > 1000);
        const user = computed(() => store.getters["auth/user"]);
        const appointmentProcedures = computed(() =>
            selectedProcedures.value.map((x) =>
                procedures.value.find((p) => p.id == x)
            )
        );
        const floors = computed(() => [
            { id: 0, name: "Rez de chaussez" },
            { id: 1, name: "1er Étage" },
            { id: 2, name: "2ème Étage" },
            ...Array.from(Array(45).keys())
                .filter((x) => x > 2)
                .map((x) => ({ id: x, name: `${x}ème Étage` })),
        ]);
        const motives = computed(() => [
            {
                id: "FIRST_CONSULTATION",
                name: "Première consultation chez ce praticien",
            },
            {
                id: "MEDICAL_MONITORING",
                name: "Consultation de suivi médical (suite à une consultation préalable avec ce praticien)",
            },
            {
                id: "MEDICAL_MONITORING_OTHER",
                name: "Consultation de suivi médical",
            },
            {
                id: "EMERGENCY",
                name: "Urgence",
            },
        ]);
        const initials = computed(() =>
            planning.value?.pratician?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };

        const isProduction = computed(
            () =>
                window.location.host.split(".com").length > 1 ||
                window.location.host.split(".app").length > 1 ||
                window.location.host.split(".africa").length > 1
        );
        const patientInitials = computed(() =>
            user.value?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const currentTime = ref();
        const currentIndex = ref(4);
        const address = computed(() =>
            (planning.value.medical_center.addresses ?? []).find(
                (x) => x.address == planning.value.address.address
            )
        );

        const acceptTerms = ref(false);
        const specialties = computed(() =>
            (planning.value?.specialties ?? []).map((x) => x.name)
        )?.toString();

        const xOf = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "XOF",
        });
        const slotsData = ref(null);
        const allSlots = ref([]);
        const displayedSlots = computed(() => {
            const slots = allSlots.value.slice(
                currentIndex.value - 4,
                currentIndex.value
            );

            if (slots.length < 4) {
                for (let index = 0; index < 4 - slots.length; index++) {
                    slots.push({ date: null, slots: [] });
                }
            }
            return slots;
        });
        const priceMin = computed(() =>
            xOf.format(planning.value?.price?.min ?? 0)
        );
        const priceMax = computed(() =>
            xOf.format(planning.value?.price?.max ?? 0)
        );

        const appointmentMotive = computed(() =>
            selectedMotive.value.length == 0
                ? null
                : motives.value.find((x) => x.id == selectedMotive.value[0])
        );
        const appointmentSpecialty = computed(() =>
            selectedSpecialty.value.length == 0
                ? null
                : (planning.value?.specialties ?? []).find(
                      (x) => x.id == selectedSpecialty.value[0]
                  )
        );

        const selectSpecialty = (data) => {
            selectedSpecialty.value = [data];
        };

        const selectMotive = (m) => {
            selectedMotive.value = [m];
        };

        const selectProcedures = (m) => {
            if (selectedProcedures.value.includes(m))
                selectedProcedures.value = selectedProcedures.value.filter(
                    (x) => x != m
                );
            else selectedProcedures.value.push(m);
        };

        const saveAppointment = () => {
            // visible.value = false;
            isSubmitingApointment.value = true;
            const data = {
                patient_id: user.value.id,
                slot_start_unix: currentTime.value,
                specialty: selectedSpecialty.value[0],
                procedures: selectedProcedures.value,
                motive: selectedMotive.value[0],
                planning_id: planning.value.id,
                slot: selectedSlot.value,
            };
            store.dispatch("medical/createAppointment", {
                data,
                onSuccess: (response) => {
                    isSubmitingApointment.value = false;

                    if (response.invoice?.amount == 0) {
                        window.location.replace(
                            `/appointments/${response.appointment?.id}/success`
                        );
                        return;
                    } else {
                        payNow(response.appointment?.id, response.invoice?.id);
                    }
                    visible.value = false;
                },
                onError: (error) => {
                    isSubmitingApointment.value = false;

                    visible.value = false;
                    const errorMessage =
                        error?.error == "busy_slot"
                            ? "Désolé, ce créneau est déjà reservé."
                            : "Ooops, une erreur est survenue. Veuillez rééssayer plus tard.";

                    toast.add({
                        severity: "error",
                        summary: "Prise de rendez-vous",
                        detail: errorMessage,
                        life: 5000,
                    });

                    console.log(window.location);
                },
            });
        };

        const handleUserLoggedIn = () => {
            store.dispatch("auth/getUser", {});
        };
        const getPlanning = () => {
            const id =
                window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                ];
            if (!id) return;

            store.dispatch("medical/getPlanning", {
                planningId: id,
                onSuccess: (data) => {
                    planning.value = { ...data };
                    center.value = {
                        lat: data.address.latitude,
                        lng: data.address.longitude,
                    };
                    allSlots.value = [...data.slots];
                    buildSlots(data.slots ?? []);
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };
        const buildSlots = (slots) => {
            const groups = slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            allSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())

                .map((s) => ({
                    date: s.date,
                    slots: s.slots.sort(
                        (a, b) =>
                            moment(a.start).unix() - moment(b.start).unix()
                    ),
                }));

            if (currentTime.value) {
                currentStep.value = 1;
                selectedSlot.value = [...allSlots.value]
                    .flatMap((s) => s.slots)
                    .find((x) =>
                        moment.unix(currentTime.value).isSame(moment(x.start))
                    );
            }
        };

        const handleNext = () => {
            let next = 0;
            if (currentIndex.value + 4 > allSlots.value.length)
                next = allSlots.value.length - currentIndex.value;
            if (currentIndex.value > allSlots.value.length) return;

            if (currentIndex.value >= 4) next = 4;
            currentIndex.value += next;
        };

        const handlePrev = () => {
            if (currentIndex.value == 4) return;
            let next = 4;
            if (currentIndex.value > 4) next = 4;
            currentIndex.value -= next;
        };

        const handleTime = (slot) => {
            const unix = moment(slot.start).unix();

            router.push({ path: route.fullPath, query: { time: unix } });
            selectedSlot.value = slot;
            currentStep.value += 1;
        };

        const handleSave = () => {
            if (!user.value) {
                showLogin.value = true;

                return;
            }
            visible.value = !!user.value;
        };

        const payNow = (appointmentId, invoiceId) => {
            const param = { appointmentId, invoiceId };
            let phone = "";
            if (user.value.phone_1) {
                const phoneInternational = new AsYouType().input(
                    `+${user.value.phone_1}`
                );

                console.log(phoneInternational);
                // phone = phoneInternational.formatNational();
            }

            if (typeof window !== "undefined") {
                openKkiapayWidget({
                    amount: appointmentCoast.value,
                    api_key: isProduction.value
                        ? "6b78eac1e2f2cf9be304d04f719052b3a9fd44b6"
                        : "b322c859242c8c9544f40c31a994d2f480682045",
                    key: isProduction.value
                        ? "sk_d23ade45538368386b9c86dac96cd9b2c0f2cffc7b2073c975e3cc5d759030b0"
                        : "sk_4d66897a5f8a310b29532e5f3bab6e7df9e65effdab70abf2cb5c68b7fcd7fe6",
                    sandbox: !isProduction.value,
                    theme: "#1ba8dc",
                    data: JSON.stringify(param),
                    callback: `${window.location.origin}/payments-callback/kkiapay`,
                    name: user.value?.full_name,
                    phone: phone,
                });
            }
        };

        const checkAppointmentPrice = () => {
            store.dispatch("medical/checkAppointmentPrice", {
                onSuccess: (data) => {
                    console.log(data, "price");
                    appointmentCoast.value = data?.price ?? 0;
                },
                onError: () => {},
            });
        };

        const updateTransaction = (method, invoiceId, transactionId) => {
            store.dispatch("auth/updateTransaction", {
                method,
                invoiceId,
                transactionId,
                onSuccess: (data) => {
                    console.log(data, "price");
                    appointmentCoast.value = data?.price ?? 0;
                },
                onError: () => {},
            });
        };

        const getSpecialtyProcedures = (idSpecialty) => {
            if (!idSpecialty) return;
            store.dispatch("medical/getSpecialtyProcedures", {
                idSpecialty,
                data: { portfolio_id: planning.value.portfolio_id },
                onSuccess: (data) => {
                    procedures.value = [...data];
                },
                onError: () => {},
            });
        };

        const onKkiapayError = () => {
            if (typeof window == "undefined") return;
            addSuccessListener((response) => {
                console.log(response);
            });

            addFailedListener((error) => {
                const data = JSON.parse(error.data);
                updateTransaction(
                    "kkiapay",
                    data.invoiceId,
                    error?.transactionId
                );
            });
        };

        onMounted(async () => {
            onKkiapayError();
            getPlanning();
            checkAppointmentPrice();
            const token = Storage.get(storageKeys.authToken);
            if (token) handleUserLoggedIn();
            if (typeof window != "undefined") {
                const urlParams = new URLSearchParams(window.location.search);
                let timeParam = urlParams.get("time");

                if (timeParam) {
                    currentTime.value = parseInt(timeParam);
                }
            }

            console.log("window.location.origin", window.location.origin);
        });

        watch(
            () => selectedSpecialty.value,
            ([val]) => {
                selectedProcedures.value = [];
                getSpecialtyProcedures(val);
            }
        );

        return {
            initials,
            patientInitials,
            priceMin,
            user,
            acceptTerms,
            procedures,
            payNow,
            link,
            isSubmitingApointment,
            floors,
            priceMax,
            center,
            selectedSpecialty,
            currentStep,
            selectedSlot,
            specialties,
            planning,
            moment,
            appointmentCoast,
            motives,
            formattedCost,
            showLogin,
            currentIndex,
            allSlots,
            visible,
            selectedMotive,
            displayedSlots,
            isLoading,
            address,
            isSmall,
            isMedium,
            isLarge,
            selectedProcedures,
            hideAvailability,
            appointmentSpecialty,
            appointmentMotive,
            appointmentProcedures,
            formatPhone,
            selectProcedures,
            saveAppointment,
            handleNext,
            handleSave,
            handlePrev,
            selectMotive,
            selectSpecialty,
            handleTime,
            handleUserLoggedIn,
        };
    },
};
</script>
<style lang="scss">
.p-details,
.p-modal-container {
    .p-highlight {
        background: transparent;
        .p-checkbox-box {
            @apply border border-primary01 bg-white text-primary01;
            .p-icon {
                @apply text-primary01;
            }
        }
    }
}
.b-shadow {
    box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.1);
}
</style>
