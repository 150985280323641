import AuthService from '@/services/user';
import { SET_PORTFOLIOS, SET_TOKEN, SET_USER } from './mutations';
import Storage, { storageKeys } from "@/services/Storage";

export const LOGIN = 'LOGIN';


export default {
    async initApp({ commit, dispatch }, { onSuccess, onError }) {
        try {
            let token = null

            if (typeof window !== 'undefined')
                token = localStorage.getItem(storageKeys.authToken)
            commit({ type: SET_TOKEN, token: token });
            dispatch({ type: "getUser" })
            dispatch({
                type: "getPortfolios", onSuccess: () => {
                    onSuccess()
                }
            })
        } catch (error) {
            onError(error)
        }
    },
    async login({ commit }, { login, password, onSuccess, onError }) {
        try {
            const response = await AuthService.authenticate({ login, password });
            // window.initPusher(api_token);
            commit({ type: SET_TOKEN, token: response.api_token });
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async register({ commit }, { data, onSuccess, onError }) {
        try {
            await AuthService.register(data);

            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error?.data);
        }
    },
    async reset({ commit }, { data, onSuccess, onError }) {
        try {
            const { requestToken } = await AuthService.resetAccount({ ...data });
            onSuccess(requestToken);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },


    async generateAgoraToken({ commit }, { channelName, onSuccess, onError },) {
        try {
            const response = await AuthService.generateAgoraToken(channelName,);
            onSuccess(response);
        } catch (error) {
            onError()
        }
    },

    async agoraCallUser({ commit }, { channelName, userToCall, username, onSuccess, onError },) {
        try {
            const response = await AuthService.callUser({ channelName, userToCall, username },);
            onSuccess(response);
        } catch (error) {
            console.log(error);

            onError()
        }
    },

    async completeMedicalCenter({ commit }, { id, data, token, onSuccess, onError }) {
        try {
            const response = await AuthService.completeMedicalCenter(id, data, token);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async getUserByRequestToken({ commit }, { token, onSuccess, onError }) {
        try {
            const response = await AuthService.getResetAccount(token);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async contactUs({ commit }, { data, onSuccess, onError }) {
        try {
            const response = await AuthService.contactUs(data);
            onSuccess(response);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async resetValidateUser({ commit }, { data, onSuccess, onError }) {
        try {
            const { requestToken } = await AuthService.resetValidateAccount(data);
            onSuccess(requestToken);
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async resetResendCode({ commit }, { data, onSuccess, onError }) {
        try {
            await AuthService.resetResendCode(data);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },

    async changePassword({ commit }, { data, onSuccess, onError }) {
        try {
            await AuthService.sendNewPassword(data);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },

    async updatePassword({ commit }, { data, onSuccess, onError }) {
        try {
            await AuthService.updatePassword(data);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },

    async updateProfile({ dispatch }, { data, onSuccess, onError }) {
        try {
            await AuthService.updateProfile(data);
            dispatch({ type: "getUser" })
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },

    async updateAvatar({ dispatch }, { data, onSuccess, onError }) {
        try {
            await AuthService.updateAvatar(data);
            dispatch({ type: "getUser" })
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    async updateTransaction({ dispatch }, { method, invoiceId, transactionId, onSuccess, onError }) {
        try {
            await AuthService.updateTransaction(method, invoiceId, transactionId);
            onSuccess();
        } catch (error) {
            console.log(error)
            onError(error);
        }
    },
    // async logout({ commit }, { onSuccess, onError }) {
    //     try {
    //         await AuthService.disconnect();
    //         // window.initPusher(api_token);
    //         onSuccess();
    //     } catch (error) {
    //         console.log(error)
    //         onError(error);
    //     }
    // },
    async getUser({ commit }) {
        try {

            const token = Storage.get(storageKeys.authToken);

            if (!token) return
            const { user, action } = await AuthService.getProfile();
            commit({ type: SET_USER, user });
            return user
        } catch (error) {
            console.log(error)
            return null
        }
    },

    async logout({ commit }, { onSuccess, onError }) {
        try {
            const token = Storage.get(storageKeys.authToken);
            // if (!token) return
            await AuthService.disconnect();

            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async getSilentlyUser({ commit }, { onSuccess, onError }) {
        try {

            const token = Storage.get(storageKeys.authToken);

            const response = await AuthService.getProfile();
            commit({ type: SET_USER, user: response.user });
            onSuccess(response)
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async createUser({ commit }, { data, onSuccess, onError }) {
        try {
            await AuthService.createUser(data);
            onSuccess()
        } catch (error) {
            console.log(error)
            onError()
        }
    },

    async validatePhone({ commit }, { phoneNumber, code, onSuccess, onError }) {
        try {

            if (!phoneNumber) return
            const response = await AuthService.validatePhone(phoneNumber, code);
            onSuccess(response)
        } catch (error) {
            console.log(error)

            onError(error?.data)
        }
    },

    async sendPhoneValidationCode({ commit }, { phoneNumber, mode, onSuccess, onError }) {
        try {
            if (!phoneNumber) return
            const response = await AuthService.sendPhoneValidationCode(phoneNumber, mode);
            onSuccess(response)
        } catch (error) {
            console.log(error)

            onError(error?.data)
        }
    },


    async getPortfolios({ commit }, { onSuccess }) {
        try {
            const { portfolios } = await AuthService.getPortfolios();
            commit({ type: SET_PORTFOLIOS, portfolios });
            onSuccess()
        } catch (error) {
            console.log(error)
        }
    }
}
