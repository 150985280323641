<template>
    <div class="w-full pb-10" id="allo-calendar">
        <div class="w-full flex flex-col py-4 px-2 mb-5">
            <div class="w-full">
                <div class="mb-3">
                    <h4 class="card-title font-semibold text-md text-slate-800">
                        {{
                            planning.id
                                ? "Éditer votre disponibilité"
                                : "Nouveau planning"
                        }}
                    </h4>
                </div>
                <div class="bg-white shadow-md p-3 rounded-xl">
                    <h3 class="py-3">Praticien</h3>
                    <div class="mb-4 flex">
                        <div class="w-1/3 mr-2">
                            <label class="text-slate-400 text-sm"
                                >Hôpital</label
                            >
                            <Select
                                class="w-full h-10"
                                name="hospital_id"
                                placeholder="Hôpital"
                                v-model="selectedHospital"
                                :value="selectedHospital"
                                :options="hospitals"
                            />
                        </div>
                        <div class="mr-2 w-1/3">
                            <label class="text-slate-400 text-sm"
                                >Médécin</label
                            >
                            <Select
                                class="w-full h-10"
                                name="doctor_id"
                                placeholder="Médécin concerné"
                                v-model="planning.doctorId"
                                :value="planning.doctorId"
                                :options="doctors"
                            />
                        </div>
                        <div class="w-1/3">
                            <label class="text-slate-400 text-sm"
                                >Spécialité(s)</label
                            >
                            <Select
                                v-model="selectedSpecialties"
                                class="w-full h-10"
                                name="specialties"
                                placeholder="Hôpital"
                                :multiple="true"
                                :value="selectedSpecialties"
                                :options="
                                    selectedHospital?.id
                                        ? specialties
                                        : allSpecialties
                                "
                            />
                        </div>
                    </div>
                </div>

                <div class="mb-4 mt-5 flex flex-col p-3 rounded-lg bg-white">
                    <h3 class="py-3">Adresse et prix de consultation</h3>
                    <div class="flex">
                        <div class="w-1/4 mr-2">
                            <label class="text-slate-400 text-sm"
                                >Adresse
                            </label>
                            <div class="flex">
                                <Select
                                    class="w-full h-10"
                                    name="address"
                                    placeholder="Adresse du rendez-vous"
                                    v-model="selectedAddress"
                                    :value="selectedAddress"
                                    :options="addresses"
                                />
                                <div
                                    class="w-10 rounded bg-white border border-slate-300 mx-2 px-2 cursor-pointer flex justify-center items-center"
                                    @click="showAddress"
                                >
                                    <i
                                        class="la la-map-marker text-lg text-slate-500"
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2 flex flex-col justify-start relative">
                            <div class="w-full flex justify-between">
                                <div class="w-1/2 mx-2">
                                    <label class="text-slate-400 text-sm"
                                        >Minumum</label
                                    >
                                    <div class="flex">
                                        <input
                                            class="h-10 price w-full"
                                            type="text"
                                            placeholder="Minimum"
                                            v-model="planning.price.min"
                                        />
                                    </div>
                                </div>

                                <div class="w-1/2">
                                    <label class="text-slate-400 text-sm"
                                        >Maximum</label
                                    >
                                    <div class="flex">
                                        <input
                                            class="h-10 price w-full"
                                            type="text"
                                            placeholder="Minimum"
                                            v-model="planning.price.max"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/4 ml-2">
                            <label class="text-slate-400 text-sm"
                                >Moyens acceptés
                            </label>
                            <div class="flex">
                                <Select
                                    class="w-full h-10"
                                    name="ways"
                                    :multiple="true"
                                    placeholder="Moyens de paienent"
                                    v-model="selectedWays"
                                    :value="selectedWays"
                                    :options="payment_ways"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label
                            @click="hiddenIndications = !hiddenIndications"
                            for=""
                            class="text-sm font-qs text-primary cursor-pointer select-none my-3"
                            >Informations complémentaires de l'adresse</label
                        >
                        <div
                            v-show="hiddenIndications"
                            :class="`${hiddenIndications ? 'flex' : ''}`"
                        >
                            <div class="w-1/2 mr-2">
                                <div class="w-full">
                                    <div class="flex flex-col">
                                        <div class="w-full">
                                            <label
                                                for=""
                                                class="text-slate-400 text-sm cursor-pointer select-none"
                                                >Étage</label
                                            >
                                            <Select
                                                class="w-full h-10"
                                                name="floor"
                                                :multiple="false"
                                                placeholder="Étage"
                                                :value="selectFloor"
                                                v-model="selectFloor"
                                                :options="floors"
                                            />
                                        </div>
                                        <div
                                            class="flex align-items-center mt-5"
                                        >
                                            <!-- <Checkbox
                                                class=""
                                                v-model="accessibleEntrance"
                                                inputId="accessibleEntrance"
                                                name="accessible_entrance"
                                                :binary="true"
                                            /> -->
                                            <Checkbox
                                                v-model="accessibleEntrance"
                                                inputId="accessibleEntrance"
                                                name="accessibleEntrance"
                                                inputClass="bg-primary"
                                                :binary="true"
                                            />

                                            <label
                                                for="ingredient4"
                                                class="ml-2"
                                            >
                                                Entrée accessible
                                            </label>
                                        </div>

                                        <div class="w-full mt-5">
                                            <label
                                                class="text-slate-400 text-sm cursor-pointer select-none mb-1"
                                                >Indication sur l'adresse</label
                                            >
                                            <Textarea
                                                class="w-full bg-white p-2 mt-1 rounded-none resize-none transition outline-none border border-slate-300 focus:border-slate-400 transition-all duration-300 ease-in-out focus:outline-[#ced4da]"
                                                v-model="addressIndications"
                                                placeholder="Indications sur l'adresse"
                                                autoResize
                                                rows="5"
                                                cols="30"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex rounded-lg bg-white justify-between">
                    <div class="mb-4 mt-3 flex flex-col p-3 w-1/3">
                        <h3 class="py-3">Couleur</h3>
                        <div class="mt-2 mb-3 w-full pr-4">
                            <div class="flex flex-col">
                                <label class="text-slate-400 mr-3 text-sm"
                                    >Couleur de différentiation</label
                                >
                                <input
                                    type="color"
                                    v-model="planning.colorCode"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mb-4 mt-3 p-3">
                        <h3 class="py-3">
                            Modes de consultations possibles sur ces crénaux
                        </h3>
                        <div class="flex mt-2">
                            <div class="flex align-items-center mb-2 mt-2 mr-5">
                                <Checkbox
                                    class=""
                                    v-model="planning.modes"
                                    inputId="modes"
                                    name="modes"
                                    value="in_office"
                                    :binary="false"
                                />
                                <label for="ingredient4" class="ml-2">
                                    En cabinet
                                </label>
                            </div>
                            <div class="flex align-items-center mb-2 mt-2">
                                <Checkbox
                                    class=""
                                    v-model="planning.modes"
                                    inputId="modes"
                                    name="modes"
                                    value="on_video"
                                    :binary="false"
                                />
                                <label for="ingredient4" class="ml-2">
                                    En Visio
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="mb-4 mt-3 p-3 w-1/3"
                        v-if="selectedHospital?.type == 'pharmacy'"
                    >
                        <h3 class="py-3 h-11"></h3>
                        <div class="flex mt-2">
                            <div class="flex align-items-center mb-2 mt-2 mr-5">
                                <Checkbox
                                    class=""
                                    v-model="planning.acceptUserRequests"
                                    inputId="acceptUserRequests"
                                    name="acceptUserRequests"
                                    :binary="true"
                                />
                                <label for="ingredient4" class="ml-2">
                                    Accepter les demandes des utilisateurs
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-4 mt-5 flex flex-col p-3 rounded-lg bg-white">
                    <h3 class="py-3">Créneaux</h3>

                    <div class="mt-2 w-full">
                        <div class="flex w-full">
                            <div class="mr-2">
                                <label class="text-slate-400 text-sm"
                                    >Plage</label
                                >
                                <div class="flex w-full">
                                    <div
                                        class="mr-2 h-10 w-1/2 rounded-sm bg-white px-2 text-base border-slate-300 font-medium text-[#6B7280] outline-none border focus:border focus:border-blue-200"
                                    >
                                        <Datepicker
                                            v-model="planning.fromDate"
                                            placeholder="De"
                                        />
                                    </div>
                                    <div
                                        class="w-1/2 border-slate-300 h-10 rounded-sm bg-white px-2 text-base font-medium text-[#6B7280] outline-none border focus:border focus:border-blue-200"
                                    >
                                        <Datepicker
                                            v-model="planning.toDate"
                                            placeholder="À"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/5 mr-1">
                                <label class="text-slate-400 text-sm"
                                    >Début</label
                                >
                                <Select
                                    class="w-full h-10"
                                    name="startHour"
                                    placeholder="Début de journée"
                                    v-model="planning.startHour"
                                    :value="planning.startHour"
                                    :options="hours"
                                />
                            </div>
                            <div class="w-1/5 mr-1">
                                <label class="text-slate-400 text-sm"
                                    >Fin</label
                                >
                                <Select
                                    class="w-full h-10"
                                    name="endHour"
                                    placeholder="Fin de journée"
                                    v-model="planning.endHour"
                                    :value="planning.endHour"
                                    :options="hours"
                                />
                            </div>
                            <div class="w-1/5 mr-2">
                                <label class="text-slate-400 text-sm"
                                    >Durée</label
                                >
                                <Select
                                    class="w-full h-10"
                                    name="duration"
                                    placeholder="Durée"
                                    v-model="planning.duration"
                                    :value="planning.duration"
                                    :options="durations"
                                    @changed="handleMode"
                                />
                            </div>
                            <div class="w-1/5 pr-2">
                                <label class="text-slate-400 text-sm"
                                    >Pause</label
                                >
                                <div class="flex items-center">
                                    <input
                                        class="w-1/2 break-duration h-10"
                                        type="number"
                                        step="1"
                                        v-model="planning.breakDuration"
                                    />
                                    <h3 class="px-2 text-sm text-slate-500">
                                        Minutes
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col mt-5 w-full">
                            <div class="w-full flex flex-col">
                                <div class="flex flex-col">
                                    <label class="text-slate-400 text-sm"
                                        >Exclusions</label
                                    >
                                    <div
                                        class="flex items-center mb-2"
                                        v-for="(curr, dayIndex) in planning
                                            .excludes.everyDay"
                                        :key="dayIndex"
                                    >
                                        <div class="w-2/5 pr-2">
                                            <Select
                                                class="w-full h-10"
                                                name="day"
                                                placeholder="Jours (Semaine)"
                                                v-model="curr.day"
                                                :value="curr.day"
                                                :options="days"
                                            />
                                        </div>
                                        <div class="flex w-3/5">
                                            <div class="w-1/2 mr-2">
                                                <Select
                                                    class="w-full h-10"
                                                    name="startHour"
                                                    placeholder="Début (Heure)"
                                                    v-model="curr.from"
                                                    :value="curr.from"
                                                    :options="dayExcludedHours"
                                                />
                                            </div>
                                            <div class="w-1/2">
                                                <Select
                                                    class="w-full h-10"
                                                    name="endHour"
                                                    placeholder="Fin (Heure)"
                                                    v-model="curr.to"
                                                    :options="dayExcludedHours"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="w-3 h-3 flex cursor-pointer justify-center items-center p-5 rounded"
                                            @click.prevent="
                                                removeEveryElm(dayIndex)
                                            "
                                        >
                                            <i
                                                class="la la-trash text-xl text-danger"
                                            ></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex justify-end mt-1">
                                    <div
                                        class="w-4 h-4 cursor-pointer flex justify-center items-center bg-slate-300 p-5 rounded"
                                        @click.prevent="insertNewAll()"
                                    >
                                        <i
                                            class="la la-plus text-lg text-secondary"
                                        ></i>
                                    </div>
                                </div>

                                <div
                                    class="mt-2"
                                    v-for="(ex, index) in excludes"
                                    :key="index"
                                >
                                    <div class="flex items-start justify-end">
                                        <div
                                            class="w-2/5 mr-2 h-10 rounded bg-white px-2 text-base border-slate-300 font-medium text-[#6B7280] outline-none border focus:border focus:border-blue-200"
                                        >
                                            <Field
                                                v-slot="{ field }"
                                                :name="excludes[index].date"
                                                v-model="ex.date"
                                            >
                                                <Datepicker
                                                    class=""
                                                    v-bind="field"
                                                    v-model="ex.date"
                                                    id="excludes"
                                                    placeholder="Jour"
                                                />
                                            </Field>
                                        </div>
                                        <div
                                            class="flex flex-col justify-between items-end w-3/5"
                                        >
                                            <div
                                                v-for="(time, i) in ex.times"
                                                :key="i"
                                                class="flex justify-between items-end mb-2 w-full"
                                            >
                                                <div class="w-5/12 mr-1">
                                                    <Select
                                                        class="w-full h-10"
                                                        name="startHour"
                                                        placeholder="Début (Heure)"
                                                        v-model="time.startHour"
                                                        :value="time.startHour"
                                                        :options="
                                                            dayExcludedHours
                                                        "
                                                    />
                                                </div>
                                                <div class="w-5/12">
                                                    <Select
                                                        class="w-full h-10"
                                                        name="endHour"
                                                        placeholder="Fin (Heure)"
                                                        v-model="time.endHour"
                                                        :value="time.endHour"
                                                        :options="
                                                            dayExcludedHours
                                                        "
                                                    />
                                                </div>

                                                <div
                                                    class="w-1/12 h-3 flex cursor-pointer justify-center items-center bg-danger p-5 rounded"
                                                    @click.prevent="
                                                        removeElm(index, i)
                                                    "
                                                >
                                                    <i
                                                        class="la la-trash text-xl text-white"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div class="flex justify-end mt-2">
                                                <div
                                                    class="w-4 h-4 cursor-pointer flex justify-center items-center bg-slate-300 bg-opacity-25 hover:bg-opacity-50 p-5 rounded"
                                                    @click.prevent="
                                                        insertNew(index, i)
                                                    "
                                                >
                                                    <i
                                                        class="la la-plus text-lg text-slate-300"
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-5">
                                <div
                                    class="w-4 h-4 cursor-pointer flex justify-center items-center bg-secondary bg-opacity-25 hover:bg-opacity-50 p-5 rounded"
                                    @click.prevent="addNew"
                                >
                                    <i
                                        class="la la-plus text-xl text-secondary"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between my-5 items-center text-sm">
                    <div>
                        <h4 class="text-slate-400">
                            Vous avez configuré {{ countSlots }} crénaux
                        </h4>
                    </div>

                    <div class="flex justify-end">
                        <button
                            @click="$router.go(-1)"
                            :class="`rounded outline-none bg-primary mr-2 bg-opacity-50
                            text-white px-3 py-2`"
                        >
                            Retour
                        </button>
                        <button
                            v-if="planning.id"
                            @click="openDialog(planning)"
                            :disabled="saveDisabled"
                            :class="`rounded outline-none bg-danger mr-2
                            text-white px-3 py-2`"
                        >
                            Supprimer
                        </button>
                        <button
                            @click="save"
                            :disabled="saveDisabled"
                            :class="`rounded outline-none bg-opacity-25 hover:bg-opacity-50 ${
                                saveDisabled
                                    ? 'bg-slate-500 cursor-not-allowed text-slate-500'
                                    : 'bg-success text-success'
                            } px-3 py-2`"
                        >
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <SampleModal
            id="map-modal"
            width="w-3/5"
            :title="`Adresse du praticien`"
            :isVisible="showPositionModal"
            @close="closeModal"
            @submit="validateModal"
            @cancel="closeModal"
        >
            <div class="h-64">
                <div class="h-5/6" style="height: 90%">
                    <gmap
                        class="h-full"
                        :center="mapCenter"
                        :locate="mapCenter.lat == null"
                        @onMapUpdate="updateMap"
                        :delay="1000"
                    />
                </div>

                <div class="my-3">
                    <h3 class="text-sm text-primary">
                        {{ selectedAddress?.address ?? "-" }}
                    </h3>
                </div>
            </div>
        </SampleModal>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import Select from "@/components/Select/index.vue";
import Datepicker from "@/components/Datepicker/index.vue";
import { Field } from "vee-validate";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";
import pkg from "lodash";
import { useStore } from "vuex";
import { ElTooltip } from "element-plus";
import gmap from "@/components/maps/gmap.vue";
import { reverseGeocode } from "@/services/map";
import { mapCenter } from "@/services/config";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import { useRoute, useRouter } from "vue-router";
import { TYPE } from "vue-toastification";
const center = mapCenter;
import SampleModal from "@/components/Modal/SampleModal.vue";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

const { range } = pkg;
export default {
    components: {
        Dropdown,
        Textarea,
        SampleModal,
        FullCalendar,
        Select,
        Field,
        gmap,
        ConfirmDialog,
        Checkbox,
        Datepicker,
        ElTooltip,
    },
    setup() {
        const confirm = useConfirm();

        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const plannings = computed(() => store.getters["medical/plannings"]);
        const hospitals = computed(() => store.getters["medical/hospitals"]);
        const allDoctors = computed(() => store.getters["medical/doctors"]);
        const doctors = ref([...allDoctors.value]);
        const allSpecialties = computed(() =>
            store.getters["config/specialties"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );

        const search = ref("");
        const mapCenter = ref(null);
        const hiddenIndications = ref(false);
        const showPositionModal = ref(false);
        const accessibleEntrance = ref(true);
        const selectedHospital = ref(null);
        const addresses = computed(() =>
            selectedHospital.value
                ? (
                      hospitals.value.find(
                          (x) => x.id == selectedHospital.value?.id
                      )?.addresses ?? []
                  ).map((x) => ({ id: x._id, ...x }))
                : [{ id: 0, name: "Nouvelle adresse" }]
        );
        const selectFloor = ref(null);
        const selectedSpecialties = ref([]);
        const excludes = ref([]);
        const countSlots = ref(0);
        const isLoadingPlanning = ref(false);
        const canDelete = ref(false);
        const planning = ref({
            acceptUserRequests: false,
            modes: ["in_office"],
            price: { min: "8000", max: "12000" },
            colorCode: "#54a4de",
            doctorId: null,

            breakDuration: 0,
            fromDate: new Date().toISOString(),
            toDate: new Date().toISOString(),
            startHour: "",
            endHour: "",
            duration: { id: 30, name: "30 Min" },
            excludes: {
                everyDay: [{}],
                "11-12-34": [
                    {
                        from: 12,
                        to: 14,
                    },
                ],
            },
        });

        const durations = computed(() => [
            { id: 15, name: "15 Min" },
            { id: 20, name: "20 Min" },
            { id: 25, name: "25 Min" },
            { id: 30, name: "30 Min" },
            { id: 45, name: "45 Min" },
            { id: 60, name: "1h" },
        ]);

        const days = computed(() => [
            { id: 0, name: "Tous les jours" },
            { id: 1, name: "Lundi" },
            { id: 2, name: "Mardi" },
            { id: 3, name: "Mercredi" },
            { id: 4, name: "Jeudi" },
            { id: 5, name: "Vendredi" },
            { id: 6, name: "Samedi" },
            { id: 7, name: "Dimanche" },
        ]);
        const payment_ways = computed(() => [
            { id: "CHECK", name: "Chèques" },
            { id: "CASH", name: "Espèces" },
            { id: "CARD", name: "Cartes bancaires" },
            { id: "M_MOMEY", name: "Portefeuille mobile GSM (Mobile Money)" },
        ]);
        const floors = computed(() => [
            { id: 0, name: "Rez de chaussez" },
            { id: 1, name: "1er Étage" },
            { id: 2, name: "2ème Étage" },
            ...Array.from(Array(45).keys())
                .filter((x) => x > 2)
                .map((x) => ({ id: x, name: `${x}ème Étage` })),
        ]);
        const selectedWays = ref([]);
        const addressIndications = ref("");
        const specialties = ref([]);
        const hours = computed(() =>
            Array.from(Array(24).keys()).map((x) => ({ id: x, name: `${x} h` }))
        );
        const dayExcludedHours = computed(() =>
            range(
                planning.value.startHour.id,
                planning.value.endHour.id,
                1
            ).map((x) => ({ id: x, name: `${x} h` }))
        );
        const selectedAddress = ref(null);
        const currentPlanning = ref(null);
        const viewMode = ref("dayGridYear");
        const events = computed(() => [
            {
                title: "Advanced algebra",
                with: "Chandler Bing",
                time: { start: "2022-05-16 12:05", end: "2022-05-16 13:35" },
                color: "yellow",
                isEditable: true,
                id: "753944708f0f",
                description:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores assumenda corporis doloremque et expedita molestias necessitatibus quam quas temporibus veritatis. Deserunt excepturi illum nobis perferendis praesentium repudiandae saepe sapiente voluptatem!",
            },
            {
                title: "Ralph on holiday",
                with: "Rachel Greene",
                time: { start: "2023-05-10 12:05", end: "2023-12-22 12:35" },
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
                colorScheme: "meetings",
            },
            {
                time: {
                    start: "2023-07-16 12:45",
                    end: "2023-07-16 13:00",
                    isCustom: true,
                },
                duration: 60,
                title: "meeting",
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
            },
            {
                title: "Ralph on holiday",
                with: "-",
                time: {
                    start: "2023-07-16 12:45",
                    end: "2023-07-16 13:00",
                    isCustom: true,
                },
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
                colorScheme: "sports",
            },
        ]);

        const saveDisabled = computed(
            () =>
                selectedSpecialties.value.length == 0 &&
                (!planning.value.doctorId?.id || !selectedHospital.value.id) &&
                !planning.value.duration.id
        );

        const config = computed(() => ({
            locale: "fr-FR",
            defaultMode: "month",
            week: {
                startsOn: "monday",
                nDays: 7,
                scrollToHour: 5,
            },
            dayIntervals: {
                length: 15, // Length in minutes of each interval. Accepts values 15, 30 and 60 (the latter is the default)
                height: 50, // The height of each interval
                displayClickableInterval: true, // Needs to be set explicitly to true, if you want to display clickable intervals
                intervalStyles: { backgroundColor: "red", color: "white" },
            },
            dayBoundaries: {
                start: 8,
                end: 18,
            },
            isEditable: true,
            showCurrentTime: true,
            style: {
                colorSchemes: {
                    meetings: {
                        color: "#fff",
                        backgroundColor: "#131313",
                    },
                    sports: {
                        color: "#fff",
                        backgroundColor: "#ff4081",
                    },
                },
            },
        }));
        const removePlanning = () => {
            store.dispatch("medical/removePlanning", {
                id: currentPlanning.value?.id,
                onSuccess: () => {
                    canDelete.value = false;
                    currentPlanning.value = null;

                    router.go(-1);
                },
                onError: () => {},
            });
        };

        const openDialog = (planning) => {
            currentPlanning.value = planning;

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer ce planning ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removePlanning();
                },
                reject: () => {
                    currentPlanning.value = null;
                },
            });
        };

        const handleMode = (data) => {};

        const insertNew = (index, position) => {
            excludes.value[index].times.push({ endHour: "", startHour: "" });
        };
        const insertNewAll = () => {
            planning.value.excludes.everyDay.push({
                endHour: "",
                startHour: "",
            });
        };
        const removeElm = (index, position) => {
            excludes.value[index].times.splice(position, 1);
        };
        const removeEveryElm = (index) => {
            planning.value.excludes.everyDay.splice(index, 1);
        };
        const addNew = () => {
            const nowString = new Date().toISOString();

            excludes.value.push({
                date: nowString,
                times: [{ endHour: "", startHour: "" }],
            });
        };
        const getDoctors = () => {
            store.dispatch("medical/getDoctors", {
                filters: { portfolioId: portfolio.value?.id },
            });
        };
        const remove = (index) => {
            excludes.value.splice(index, 1);
        };

        const calculateSlots = () => {
            if (
                !planning.value.duration.id ||
                !planning.value.fromDate ||
                !planning.value.toDate ||
                !planning.value.endHour.id ||
                !planning.value.startHour.id
            )
                return;
            const slotDuration = parseInt(planning.value.duration.id);

            const slots = [];
            let startDate = moment(planning.value.fromDate)
                .set("hour", planning.value.startHour.id)
                .set("minute", 0);
            const endDate = moment(planning.value.toDate)
                .set("hour", planning.value.endHour.id)
                .set("minute", 0);
            let excluded = false;
            let isLastExcluded = false;

            let lastDate = startDate.clone();
            while (startDate < endDate) {
                let s = startDate.clone();

                startDate = startDate.add("minute", slotDuration);
                let h = `${s.format("HH:mm")} - ${startDate.format("HH:mm")}`;

                if (planning.value.excludes.everyDay.length > 0) {
                    planning.value.excludes.everyDay.forEach((elm) => {
                        if (
                            elm.day &&
                            elm.from &&
                            [startDate.isoWeekday(), 0].includes(elm?.day.id) &&
                            s >=
                                s
                                    .clone()
                                    .set("hour", elm.from?.id)
                                    .set("minute", 0) &&
                            startDate <=
                                startDate
                                    .clone()
                                    .set(
                                        "hour",
                                        elm.to?.id ?? planning.value.endHour.id
                                    )
                                    .set("minute", 0)
                        )
                            excluded = true;
                    });
                }
                if (excludes.value.length > 0) {
                    excludes.value.forEach((elm) => {
                        (elm?.times ?? []).forEach((time) => {
                            if (
                                elm.date &&
                                time.startHour &&
                                moment(elm.date).isSame(s, "day") &&
                                s <=
                                    s
                                        .clone()
                                        .set(
                                            "hour",
                                            time?.endHour?.id ??
                                                planning.value.endHour.id
                                        )
                                        .set("minute", 0) &&
                                s >=
                                    s
                                        .clone()
                                        .set("hour", time.startHour?.id)
                                        .set("minute", 0)
                            )
                                excluded = true;
                        });
                    });
                }
                if (
                    !excluded &&
                    s >=
                        s
                            .clone()
                            .set("hour", planning.value.startHour.id)
                            .set("minute", 0) &&
                    s <=
                        s
                            .clone()
                            .set("hour", planning.value.endHour.id)
                            .set("minute", 0) &&
                    startDate <=
                        startDate
                            .clone()
                            .set("hour", planning.value.endHour.id)
                            .set("minute", 0)
                ) {
                    if (isLastExcluded) {
                        s = s.set("minute", 0);
                        startDate = s.clone().add("minute", slotDuration);
                        h = `${s.format("HH:mm")} - ${startDate.format(
                            "HH:mm"
                        )}`;
                    }

                    slots.push({
                        start: s,
                        end: startDate,
                        human: h,
                    });
                }
                isLastExcluded = excluded;

                excluded = false;
                lastDate = startDate.clone();
                startDate.add("minute", parseInt(planning.value.breakDuration));
            }

            countSlots.value = slots.length;
        };

        const save = () => {
            const excludedDays = {};

            excludes.value.forEach((el) => {
                excludedDays[moment(el.date).format("DD/MM/yyyy")] = [
                    ...el.times.map((x) => ({
                        from: x.startHour?.id,
                        to: x.endHour?.id,
                    })),
                ];
            });

            const data = {
                id: planning.value?._id ?? planning.value?.id,
                color_code: planning.value.colorCode,
                portfolio_id: portfolio.value.id,
                doctor_id: planning.value.doctorId?.id,
                specialties: selectedSpecialties.value,
                medical_center_id: selectedHospital.value?.id,
                address: selectedAddress.value,
                payments: selectedWays.value.map((x) => x.id),
                modes: planning.value.modes,
                price: planning.value.price,
                can_handle_user_requests: planning.value.acceptUserRequests,
                planning: {
                    fromDate: moment(planning.value.fromDate)
                        .set("hour", planning.value.startHour?.id)
                        .set("minute", 0),
                    toDate: moment(planning.value.toDate)
                        .set("hour", planning.value.endHour?.id)
                        .set("minute", 0),
                    duration: parseInt(planning.value.duration?.id),
                    startHour: planning.value.startHour?.id,
                    endHour: planning.value.endHour?.id,
                    breakDuration: planning.value.breakDuration ?? 0,
                    excludes: {
                        everyDay: planning.value.excludes.everyDay.map((x) => ({
                            day: x.day?.id ?? 0,
                            from: x.from?.id,
                            to: x.to?.id,
                        })),
                        ...excludedDays,
                    },
                },
            };
            let action = "savePlanning";
            if (currentPlanning.value?.id) action = "editPlanning";

            store.dispatch(`medical/${action}`, {
                planningId: currentPlanning.value?.id,
                data,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Planning sauvegardé avec succès",
                        context: "Planning rendez-vous",
                        type: TYPE.SUCCESS,
                    });
                    planning.value = {
                        colorCode: "#54a4de",
                        doctorId: null,
                        breakDuration: 0,
                        fromDate: new Date().toISOString(),
                        toDate: new Date().toISOString(),
                        startHour: "",
                        endHour: "",
                        duration: 30,
                        excludes: {
                            everyDay: [{}],
                            "11-12-34": [
                                {
                                    from: 12,
                                    to: 14,
                                },
                            ],
                        },
                    };
                    selectedWays.value = [];
                    selectedHospital.value = null;
                    selectedSpecialties.value = [];
                    currentPlanning.value = null;
                    router.push({ name: "app.plannings" });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message:
                            "Oooops, une erreur est survenue lors de la sauvegarde",
                        context: "Planning rendez-vous",
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const closeModal = () => {
            showPositionModal.value = false;
        };

        const validateModal = () => {
            showPositionModal.value = false;
        };

        const getAddress = (lat, lng) => {
            if (!lat || !lng) return;
            reverseGeocode(lat, lng).then((result) => {
                const { position, address } = result.items[0];
                console.log(address);
                selectedAddress.value = {
                    latitude: lat,
                    longitude: lng,
                    address: address.label,
                    ...address,
                };
            });
        };

        const updateMap = (map, position) => {
            mapCenter.value = { ...position };
            getAddress(position.lat, position.lng);
        };

        const getSpecialties = (page = 1) => {
            store.dispatch("config/getSpecialties", {});
        };

        const getHospitals = (page = 1) => {
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value?.id, page },
            });
        };

        const getPlanning = () => {
            const id = route.params.planningId;
            if (!id) return;
            isLoadingPlanning.value = true;
            store.dispatch("medical/getPlanning", {
                planningId: id,
                onSuccess: (data) => {
                    startEdition(data);
                    isLoadingPlanning.value = false;
                },
                onError: () => {},
            });
        };

        watch(
            () => selectedHospital.value,
            (val) => {
                selectedSpecialties.value = [];
                if (!val?.slug) return;
                store.dispatch("medical/getHospital", {
                    slug: val?.slug,
                    onError: () => {},
                    onSuccess: (data) => {
                        doctors.value = [
                            ...data.doctors,
                            // ...allDoctors.value,
                        ].map((x) => ({
                            id: x.id,
                            name: x.full_name,
                        }));
                        specialties.value = [...data.specialties];
                    },
                });
            }
        );

        const startEdition = (data) => {
            selectPlanning(data);

            planning.value = { ...currentPlanning.value };
        };

        const selectPlanning = (data) => {
            selectedHospital.value = data.medical_center;
            setTimeout(() => {
                selectedSpecialties.value = [...data.specialties];
            }, 2000);
            selectedWays.value = (data?.payments ?? []).map((x) =>
                payment_ways.value.find((y) => y.id == (x?.id ?? x))
            );

            selectedAddress.value = data?.address;
            currentPlanning.value = {
                acceptUserRequests: data?.can_handle_user_requests ?? true,
                id: data?._id ?? data.id,
                price: data.price ?? { min: 8000, max: 12000 },
                colorCode: data.color_code,
                doctorId: {
                    // ...data.pratician,
                    id: data.pratician?.id,
                    name: data.pratician?.full_name,
                },
                modes: data.modes,
                breakDuration: data.planning.breakDuration,
                address: data.address,
                fromDate: data.planning.fromDate,
                toDate: data.planning.toDate,
                startHour: hours.value.find(
                    (x) => x.id == data.planning?.startHour
                ),
                endHour: {
                    id: data.planning?.endHour ?? 18,
                },
                duration: durations.value.find(
                    (x) => x.id == data.planning.duration
                ),
                excludes: {
                    everyDay: [...data.planning?.excludes.everyDay].map(
                        (x) => ({
                            day: { id: x?.day },
                            from: { id: x?.from ?? data.planning.startHour },
                            to: { id: x?.to ?? data.planning?.endHour },
                        })
                    ),
                    "11-12-34": [
                        {
                            from: 12,
                            to: 14,
                        },
                    ],
                },
            };

            console.log(currentPlanning.value);
        };

        const showAddress = () => {
            mapCenter.value = selectedAddress.value
                ? {
                      lng: selectedAddress.value.longitude,
                      lat: selectedAddress.value.latitude,
                  }
                : center;
            showPositionModal.value = true;
        };

        watch(
            () => excludes.value,
            (val) => {
                calculateSlots();
            },
            { deep: true }
        );

        watch(
            () => planning.value,
            (val) => {
                calculateSlots();
            },
            { deep: true }
        );

        watch(
            () => allDoctors.value,
            (val) => {
                doctors.value = [...doctors.value, ...val].map((x) => ({
                    id: x.id,
                    name: x.full_name,
                }));
            }
        );

        watch(
            () => selectedAddress.value,
            (val) => {
                selectFloor.value = floors.value.find((x) => x.id == val.floor);
                accessibleEntrance.value = val?.accessibleEntrance ?? true;
                addressIndications.value = val.indications;
                selectedAddress.value.address_indications = val.indications;
                selectedAddress.value.address_floor = val.floor ?? 0;
                selectedAddress.value.address_accessibility =
                    accessibleEntrance.value;
            }
        );

        watch(
            () => addressIndications.value,
            (val) => {
                if (selectedAddress.value)
                    selectedAddress.value.address_indications = val;
            }
        );

        onMounted(() => {
            getPlanning();

            getHospitals();
            getDoctors();
            getSpecialties();
        });

        return {
            config,
            hours,
            selectedWays,
            payment_ways,
            dayExcludedHours,
            events,
            showPositionModal,
            addNew,
            days,
            hiddenIndications,
            selectedAddress,
            currentPlanning,
            search,
            save,
            mapCenter,
            saveDisabled,
            selectedHospital,
            hospitals,
            doctors,
            allSpecialties,
            durations,
            excludes,
            viewMode,
            selectFloor,
            planning,
            moment,
            canDelete,
            accessibleEntrance,
            isLoadingPlanning,
            countSlots,
            selectedSpecialties,
            specialties,
            plannings,
            addresses,
            floors,
            addressIndications,
            currentPlanning,
            selectedHospital,
            remove,
            openDialog,
            validateModal,
            closeModal,
            updateMap,
            showAddress,
            startEdition,
            removeEveryElm,
            removeElm,
            insertNew,
            insertNewAll,
            handleMode,
        };
    },
};
</script>
<style lang="scss">
#allo-calendar {
    .calendar-root {
        background: white;
    }

    .p-inputtext,
    .p-dropdown {
        border: 1px solid #e1e1e1 !important;

        &:focus {
            outline-color: none !important;
        }
        &:focus-within {
            outline-color: none !important;
        }
    }
    .break-duration,
    .price {
        color: rgb(107 114 128) !important;
        @apply rounded-sm border border-[#ddd];
        font-size: 0.9rem !important;
        &::placeholder {
            color: rgb(148 163 184) !important;
            font-weight: 400 !important;
        }
        &:focus {
            // border-color: #80bdff !important;
        }
    }

    .planning-card .planning-actions {
        transition: all 0.3s;
        visibility: hidden;
    }

    .planning-card:hover .planning-actions {
        visibility: visible;
    }
}
</style>
