<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Mentions légales</title>
        <meta
            head-key="description"
            name="description"
            content="mentions légales Allô Doto - Bénin, Côte d'Ivoire"
        />
    </Head>
    <app-bar></app-bar>

    <div
        class="w-full flex justify-center pt-10 bg-color mx-1 pb-20"
        id="notice"
    >
        <div class="w-3/5 xs:w-11/12 px-5 text-justify">
            <div class="flex items-center justify-center mt-5 mb-2">
                <img src="/images/section_icon.svg" class="w-14" alt="" />
            </div>
            <div class="text-center my-10 text-opacity-60 text-tertiary2">
                <h3 class="text-4xl font-semibold text-center">
                    Mentions légales
                </h3>
                <span class="text-sm"
                    >Dernière mise à jour le
                    <strong>10 juillet 2023</strong></span
                >
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Édition du site (et applications mobiles)
                </h3>

                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p>
                        Conformément aux dispositions de l’article 328 de la Loi
                        N°2017-20 du 20 Avril 2018 portant code du numérique en
                        République du Bénin, il est précisé aux utilisateurs du
                        site internet https://allodoto.com et de l'application
                        mobile l'identité des différents intervenants dans le
                        cadre de sa réalisation et de son suivi:
                    </p>

                    <p class="py-4">
                        <strong>Propriétaire du site</strong> :
                        <a target="_blank" href="https://habilis.bj">Habilis</a>
                        Bénin - Contact : info@habilis.bj 97793131 - Adresse :
                        Cotonou Bénin
                    </p>

                    <p>
                        <strong>Identification de l'entreprise</strong> : SARL
                        <a target="_blank" href="https://habilis.bj">Habilis</a>
                        Bénin au capital social de 2.000.000 f CFA - RCCM :
                        RB/COT/20 B 27698 - Adresse postale : Cotonou Bénin -
                        <a
                            href="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Conditions d'utilisation</a
                        >
                    </p>

                    <p class="py-4">
                        <strong>Directeur de la publication</strong> : Cyr Raoul
                        - Contact : cyr@habilis.bj.
                    </p>
                    <p>
                        <strong>Hébergeur</strong> : AWS (Amazon Web Service) -
                        Adresse: 410 Terry Avenue North, Seattle, WA 98109-5210
                    </p>

                    <p class="py-4">
                        <strong>Délégué à la protection des données</strong> :
                        Arland AKPASSOU - hello@habilis.bj
                    </p>
                    <!-- <p class="font-bold pb-5">Autres contributeurs :</p> -->
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg font-semibold text-left text-tertiary2 py-3">
                    Propriété intellectuelle et contrefaçons.
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="pt-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        est propriétaire des droits de propriété intellectuelle
                        et détient les droits d’usage sur tous les éléments
                        accessibles sur le site internet, notamment les textes,
                        images, graphismes, logos, vidéos, architecture, icônes
                        et sons.
                    </p>

                    <p class="pt-4">
                        Toute reproduction, représentation, modification,
                        publication, adaptation de tout ou partie des éléments
                        du site, quel que soit le moyen ou le procédé utilisé,
                        est interdite, sauf autorisation écrite préalable de
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        à <strong>info@habilis.bj</strong>.
                    </p>
                    <p class="py-4">
                        Conformément aux dispositions des articles 529 et 530 de
                        la Loi N°2017-20 du 20 Avril 2018 portant code du
                        numérique en République du Bénin, toute exploitation non
                        autorisée du site web ou de l’un quelconque des éléments
                        qu’il contient, sera considérée comme constitutive d’une
                        contrefaçon et poursuivie selon les dispositions des
                        articles 531 et suivants de la section 1 traitant de
                        l’atteinte aux droits de propriété intellectuelle de la
                        Loi N°2017-20 du 20 Avril 2018 portant code du numérique
                        en République du Bénin.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Propriété intellectuelle et contrefaçons.
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="pt-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        est propriétaire des droits de propriété intellectuelle
                        et détient les droits d’usage sur tous les éléments
                        accessibles sur le site internet, notamment les textes,
                        images, graphismes, logos, vidéos, architecture, icônes
                        et sons.
                    </p>

                    <p class="pt-4">
                        Toute reproduction, représentation, modification,
                        publication, adaptation de tout ou partie des éléments
                        du site, quel que soit le moyen ou le procédé utilisé,
                        est interdite, sauf autorisation écrite préalable de
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        à <strong>info@habilis.bj</strong>.
                    </p>
                    <p class="py-4">
                        Conformément aux dispositions des articles 529 et 530 de
                        la Loi N°2017-20 du 20 Avril 2018 portant code du
                        numérique en République du Bénin, toute exploitation non
                        autorisée du site web ou de l’un quelconque des éléments
                        qu’il contient, sera considérée comme constitutive d’une
                        contrefaçon et poursuivie selon les dispositions des
                        articles 531 et suivants de la section 1 traitant de
                        l’atteinte aux droits de propriété intellectuelle de la
                        Loi N°2017-20 du 20 Avril 2018 portant code du numérique
                        en République du Bénin.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Limitations de responsabilité.
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        ne pourra être tenu pour responsable des dommages
                        directs et indirects causés au matériel de
                        l’utilisateur, lors de l’accès au site
                        https://allodoto.com.
                    </p>
                    <p class="py-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        décline toute responsabilité quant à l’utilisation qui
                        pourrait être faite des informations et contenus
                        présents sur https://allodoto.com.
                    </p>
                    <p class="py-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        s’engage à sécuriser au mieux le site
                        https://allodoto.com, cependant sa responsabilité ne
                        pourra être mise en cause si des données indésirables
                        sont importées et installées sur son site à son insu.
                    </p>
                    <p>
                        Des espaces interactifs (espace contact ou commentaires)
                        sont à la disposition des utilisateurs.
                    </p>
                    <p class="py-4">
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        se réserve le droit de supprimer, sans mise en demeure
                        préalable, tout contenu déposé dans cet espace qui
                        contreviendrait à la législation applicable en France,
                        en particulier aux dispositions relatives à la
                        protection des données.
                    </p>

                    <p class="py-4">
                        Le cas échéant,
                        <strong
                            ><a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            Bénin</strong
                        >
                        se réserve également la possibilité de mettre en cause
                        la responsabilité civile et/ou pénale de l’utilisateur,
                        notamment en cas de message à caractère raciste,
                        injurieux, diffamant, ou pornographique, quel que soit
                        le support utilisé (texte, photographie …).
                    </p>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import Footer from "@/components/footer/index.vue";
export default {
    components: { Footer, Head },
    setup() {},
};
</script>
