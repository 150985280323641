export const host = `/api/`;
export const version = `v1/`;
export const admin = `admin/`;

export const auth = {
    login() {
        return `${host}auth/login`;
    },
    agoraToken() {
        return `${host}agora-call/token`;
    },
    agoraCallUser() {
        return `${host}agora/call-user`;
    },
    update() {
        return `${host}auth/me/profile/update`;
    },
    updatePassword() {
        return `${host}auth/me/profile/password`;
    },
    avatar() {
        return `${host}auth/me/profile/update-avatar`;
    },
    contactUs() {
        return `${host}auth/contact`;
    },
    register() {
        return `${host}auth/register`;
    },
    sendOtpCode() {
        return `${host}auth/reset/otp`;
    },
    validatePhoneNumber() {
        return `${host}auth/phone-validation`;
    },
    reset() {
        return `${host}auth/reset`;
    },
    resetGetAccount() {
        return `${host}auth/reset/account`;
    },
    resetResendCode() {
        return `${host}auth/reset/resend-code`;
    },
    logout() {
        return `${host}auth/logout`;
    },
    documents() {
        return `${host}auth/documents`;
    },
    profile() {
        return `${host}auth/me/profile`;
    },
    completeMedical(id) {
        return `${host}auth/medical-centers/${id}/complete`;
    },
}

export const editor = {
    categories() {
        return `${host}${version}categories`;
    },
    category(id) {
        return `${host}${version}categories/${id}`;
    },
    tags() {
        return `${host}${version}tags`;
    },
    tag(id) {
        return `${host}${version}tags/${id}`;
    }
};

export const cities = {
    get() {
        return `${host}${version}cities`;
    },
    create() {
        return `${host}${version}cities`;
    },
    one(id) {
        return `${host}${version}cities/${id}`;
    },
}

export const modules = {
    get() {
        return `${host}${version}modules`;
    },
    create() {
        return `${host}${version}modules`;
    },
    one(id) {
        return `${host}${version}modules/${id}`;
    },
}

export const transactions = {
    update(method, invoiceId, transactionId) {
        return `${host}${version}transactions/callback/${method}/${invoiceId}/${transactionId}`;
    },

}

export const countries = {
    get() {
        return `${host}${version}countries`;
    },
    create() {
        return `${host}${version}countries`;
    },
    one(id) {
        return `${host}${version}countries/${id}`;
    },
}

export const staff = {
    get() {
        return `${host}${version}staff`;
    },
    create() {
        return `${host}${version}staff`;
    },
    one(id) {
        return `${host}${version}staff/members/${id}`;
    },
    analytics() {
        return `${host}${version}staff/analytics/get`;
    },
}

export const blog = {
    postWriter() {
        return `${host}${version}${admin}users`;
    },
    writers() {
        return `${host}${version}${admin}users/editor`;
    },
    media() {
        return `${host}${version}medias`;
    },
    posts() {
        return `${host}${version}posts`;
    },
    postComments(id) {
        return `${host}${version}posts/${id}/comments`;
    },
    postsSearch() {
        return `${host}${version}posts/search`;
    },
    post(id) {
        return `${host}${version}posts/${id}`;
    }
}

export const role = {
    get() {
        return `${host}${version}roles`;
    },
    create() {
        return `${host}${version}roles`;
    },
    one(id) {
        return `${host}${version}roles/${id}`;
    },
}

export const car = {
    get() {
        return `${host}${version}cars`;
    },
    count() {
        return `${host}${version}cars/count/get`;
    },
    upload(id) {
        return `${host}${version}cars/${id}/upload`;
    },
    create() {
        return `${host}${version}cars`;
    },
    one(id) {
        return `${host}${version}cars/${id}`;
    },
    position(id) {
        return `${host}${version}cars/${id}/position`;
    },
    resources(id) {
        return `${host}${version}cars/${id}/resources`;
    },
}

export const patient = {
    get() {
        return `${host}${version}patients`;
    },
    create() {
        return `${host}${version}patients`;
    },
    single(id) {
        return `${host}${version}patients/${id}`;
    },
    count() {
        return `${host}${version}patients/count`;
    },
    one(id) {
        return `${host}${version}${admin}patients/${id}`;
    },
}

export const portfolio = {
    get(page = 1) {
        return `${host}${version}portfolios?page=${page}`;
    },
    create() {
        return `${host}${version}portfolios`;
    },
    list() {
        return `${host}${version}portfolios/list`;
    },
    one(id) {
        return `${host}${version}portfolios/${id}/get`;
    },
    edit(id) {
        return `${host}${version}portfolios/${id}`;
    },
    phones(id) {
        return `${host}${version}portfolios/${id}/phones`;
    },
    phoneValidation(id) {
        return `${host}${version}portfolios/${id}/phones-validation`;
    },
    clearPhone(id) {
        return `${host}${version}portfolios/${id}/phones-clear`;
    },
}

export const hospital = {
    get() {
        return `${host}${version}hospitals`;
    },
    create() {
        return `${host}${version}hospitals`;
    },
    count() {
        return `${host}${version}hospitals/count/get`;
    },
    image(id,) {
        return `${host}${version}hospitals/${id}/image`;
    },
    one(id) {
        return `${host}${version}hospitals/${id}`;
    },
}

export const medicalCenter = {
    get() {
        return `${host}${version}medical-centers`;
    },
    create() {
        return `${host}${version}medical-centers`;
    },
    one(id) {
        return `${host}${version}medical-centers/${id}`;
    },
    validate(id) {
        return `${host}${version}medical-centers/${id}/validate-center`;
    },
    agents(id) {
        return `${host}${version}medical-centers/${id}/agents`;
    },
    count() {
        return `${host}${version}medical-centers/count/get`;
    },
    patients(id) {
        return `${host}${version}medical-centers/${id}/patients`;
    },
    documents(id) {
        return `${host}${version}medical-centers/${id}/documents`;
    },
    documentsMail(id) {
        return `${host}${version}medical-centers/${id}/documents-mail`;
    },
    documentStatus(centerId, documentId) {
        return `${host}${version}medical-centers/${centerId}/documents/${documentId}/status`;
    },
    presentation(id) {
        return `${host}${version}medical-centers/${id}/presentation`;
    },
}
export const specialty = {
    get() {
        return `${host}${version}specialties`;
    },
    create() {
        return `${host}${version}specialties/create`;
    },
    one(id) {
        return `${host}${version}${admin}specialties/${id}`;
    },
    procedures(id) {
        return `${host}${version}specialties/${id}/procedures`;
    }
}

export const medicineVerification = {
    get(medicalCenterId) {
        return `${host}${version}medicine-verifications/${medicalCenterId}`;
    },
    create() {
        return `${host}${version}medicine-verifications`;
    },
    one(id) {
        return `${host}${version}medicine-verifications/${id}`;
    },
    single(id) {
        return `${host}${version}medicine-verifications/${id}/get`;
    },
    file(id) {
        return `${host}${version}medicine-verifications/${id}/file`;
    }
}

export const procedures = {
    index() {
        return `${host}${version}procedures`;
    },
    get(id) {
        return `${host}${version}procedures/${id}`;
    },
}

export const requirements = {
    get() {
        return `${host}${version}requirements`;
    },
    create() {
        return `${host}${version}requirements/create`;
    },
    one(id) {
        return `${host}${version}${admin}requirements/${id}`;
    },
}

export const alert = {
    get(isAdmin = false) {
        return `${host}${version}${admin}alerts`;
    },
    interventions(id) {
        return `${host}${version}alerts/${id}/interventions`;
    },
    carMovmentRegister(id, interventionId, carId) {
        return `${host}${version}alerts/${id}/interventions/${interventionId}/cars/${carId}`;
    },
    create() {
        return `${host}${version}alerts/create`;
    },
    one(id) {
        return `${host}${version}alerts/${id}`;
    },
    count() {
        return `${host}${version}alerts/count/get`;
    },
}

export const intervention = {
    get() {
        return `${host}${version}interventions`;
    },
    create() {
        return `${host}${version}${admin}interventions`;
    },
    one(id) {
        return `${host}${version}interventions/${id}`;
    },
    data() {
        return `${host}${version}interventions/data/get`;
    },
    pendings() {
        return `${host}${version}interventions/pending/get`;
    },
    analytics() {
        return `${host}${version}interventions/analytics/get`;
    },
}




export const doctor = {
    get() {
        return `${host}${version}doctors`;
    },
    getAll() {
        return `${host}${version}admin/doctors`;
    },
    create() {
        return `${host}${version}doctors`;
    },
    one(id) {
        return `${host}${version}doctors/${id}`;
    },
}

export const resource = {
    getTypes() {
        return `${host}${version}resources/types`;
    },
    getAllTypes() {
        return `${host}${version}resources/all-types`;
    },
    createTypes() {
        return `${host}${version}resources/types`;
    },
    oneType(id) {
        return `${host}${version}resources/types/${id}`;
    },
    get() {
        return `${host}${version}resources`;
    },
    all() {
        return `${host}${version}resources/all`;
    },
    create() {
        return `${host}${version}resources`;
    },
    one(id) {
        return `${host}${version}resources/${id}`;
    },
}

export const prescriptions = {
    new() {
        return `${host}${version}${admin}prescriptions`;
    },
    single(id) {
        return `${host}${version}${admin}patients/${id}/prescriptions`;
    }
}
export const users = {
    create() {
        return `${host}${version}${admin}users`;
    },
    byRole(slug) {
        return `${host}${version}${admin}users/${slug}/role`;
    },
    single(id) {
        return `${host}${version}${admin}patients/${id}/prescriptions`;
    },
    get(id) {
        return `${host}${version}users/${id}`;
    },
}

export const record = {
    getTypes() {
        return `${host}${version}records/types`;
    },
    createTypes() {
        return `${host}${version}records/types`;
    },
    allTypes() {
        return `${host}${version}records/types-all`;
    },
    oneType(id) {
        return `${host}${version}records/types/${id}`;
    },
    create() {
        return `${host}${version}records`;
    },
    one(id) {
        return `${host}${version}records/${id}`;
    },
}

export const invoice = {
    index() {
        return `${host}${version}invoices/get`;
    },
}

export const appointment = {
    index() {
        return `${host}${version}appointments/get`;
    },
    list() {
        return `${host}${version}appointments/get-list`;
    },
    timedList() {
        return `${host}${version}appointments/get-timed-list`;
    },
    rate(id) {
        return `${host}${version}appointments/${id}/ratings`;
    },
    start(id) {
        return `${host}${version}appointments/${id}/start`;
    },
    checkPrice() {
        return `${host}${version}appointments/check-price`;
    },
    files(id) {
        return `${host}${version}appointments/${id}/files`;
    },

    documents() {
        return `${host}${version}appointments/${id}/documents`;
    },
    create() {
        return `${host}${version}appointments`;
    },
    get(id) {
        return `${host}${version}appointments/${id}/get`;
    },
    makeShare(id) {
        return `${host}${version}appointments/${id}/make-anonymous`;
    },
    getByHash(id) {
        return `${host}${version}appointments/${id}/hash`;
    },
    single(id) {
        return `${host}${version}appointments/${id}`;
    },
    updateTime(id) {
        return `${host}${version}appointments/${id}/update-time`;
    },
    cancel(id) {
        return `${host}${version}appointments/${id}/cancel`;
    },
    plannings() {
        return `${host}${version}plannings/get`;
    },
    planningSlots(id) {
        return `${host}${version}plannings/${id}/slots`;
    },
    createPlanning() {
        return `${host}${version}plannings`;
    },
    editPlanning(id) {
        return `${host}${version}plannings/${id}`;
    },
    planning(id) {
        return `${host}${version}plannings/get/${id}`;
    },
    search() {
        return `${host}${version}plannings/search`;
    }
}

export const storageKeys = {
    authToken: "@authToken"
};

export const stripe = {
    key: "pk_test_RUYIrVLm6luH9fXWZlUvCTZD"
};

export const pusher = {
    key: "86a0254e2c3bc8c1522f",
    cluster: "us3"
};
