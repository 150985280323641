<template>
    <Head>
        <title>Blog Allô Doto - {{ post.title }}</title>
        <meta
            head-key="description"
            name="description"
            :content="`Blog santé en Afrique: Bénin , Côte d'Ivoire; ${post.excerpt}`"
        />
    </Head>
    <Header></Header>
    <header
        class="w-full z-50 flex justify-center sticky top-0 bg-white py-3 mt-16"
    >
        <div
            class="lg:w-3/5 xl:w-3/5 md:w-full sm:w-full xs:w-full mx-auto px-4"
        >
            <div>
                <span
                    class="py-2 px-3 bg-primary2 rounded-md bg-opacity-20 text-primary2 cursor-pointer mr-3 mb-3"
                >
                    {{ post.category.name }}
                </span>
            </div>

            <h1
                class="text-slate-800 py-10 md:py-5 xl:text-5xl md:text-3xl lg:text-5xl md:text-5xl sm:text-xl font-bold"
            >
                {{ post.title }}
            </h1>

            <div
                class="flex xl:w-1/3 lg:w-1/2 sm:w-1/2 md:w-1/2 xs:w-full justify-between"
            >
                <h3 class="text-slate-400 text-sm">Par {{ post.signature }}</h3>
                <span class="text-slate-500 font-medium text-sm">{{
                    moment(post.published_at).format("LL")
                }}</span>
            </div>
        </div>
    </header>

    <div
        class="lg:w-3/5 xl:w-3/5 sm:w-4/5 mx-auto px-4 border-b-2 border-slate-100"
    >
        <div class="my-10">
            <img
                class="object-cover object-center rounded-lg w-full h-48 lg:h-80"
                :src="post.thumbnail_url"
                alt=""
            />
        </div>

        <MdPreview :editorId="id" :modelValue="post.content" />

        <div
            class="hidden mx-10 bg-primary bg-opacity-10 rounded-xl flex flex-col items-center justify-center px-3 py-5 my-10"
        >
            <p class="text-slate-400 py-1 text-sm">Besoin de consulter ?</p>
            <a
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
                class="text-primary2 font-bold underline"
                >C'est ici et maintenant</a
            >
        </div>

        <div
            class="mx-auto bg-primary2 rounded-xl flex items-center justify-between px-3 py-5 my-10"
            style="background-image: url('/images/_bg2.png')"
        >
            <p class="text-white font-semibold text-sm">
                Vous avez aimé la lecture ? Partagez
            </p>
            <div class="flex text-white">
                <div v-for="(s, index) in socials" :key="index" class="mx-1">
                    <ShareNetwork
                        :network="s.plateform"
                        :url="url"
                        :media="post.thumbnail_url"
                        :title="`Blog Allô Doto : ${post.title}`"
                        :description="post.excerpt"
                        quote="Powered and designed by Habilis (hello@habilis.bj)"
                        :hashtags="s.hashtags"
                    >
                        <div v-html="s.iconLink" class="w-10"></div>
                    </ShareNetwork>
                </div>
            </div>
        </div>
    </div>

    <div class="lg:w-3/5 xl:w-3/5 sm:w-4/5 mx-auto px-4 my-10">
        <h2 class="text-3xl text-slate-600 font-semibold mt-5 mb-3">
            Commentaires ({{ comments.length }})
        </h2>

        <div class="flex flex-col items-end">
            <div
                v-for="(comment, i) in comments"
                :key="i"
                class="flex flex-col w-11/12 mb-3 hover:bg-slate-100 bg-opacity-25 px-3 rounded py-2 transition transition-all"
            >
                <div class="flex justify-between py-2">
                    <h3 class="text-slate-600 font-semibold">
                        {{
                            comment?.author?.middle_name ??
                            comment?.author?.full_name ??
                            "-"
                        }}
                    </h3>

                    <span class="text-slate-500 font-medium text-sm">{{
                        moment(comment.published_at).format("LLL")
                    }}</span>
                </div>

                <p class="text-slate-400 text-sm">
                    {{ comment.body }}
                </p>
            </div>
        </div>

        <div class="my-10">
            <h2 class="text-xl font-semibold text-slate-500">
                Laissez un commentaire
            </h2>

            <textarea
                name=""
                class="w-full resize-none h-40 bg-slate-100 rounded-xl p-3 mt-2"
                id=""
                v-model="commentForm.message"
                placeholder="Votre message..."
            ></textarea>

            <div
                class="flex md:flex-row lg:flex-row xl:flex-row mt-2 justify-between sm:flex-col xs:flex-col"
            >
                <div
                    class="bg-slate-100 rounded-xl p-3 md:w-1/3 lg:w-1/3 xl:mr-2 my-2 lg:mr-2 md:mr-2 xs:w-full"
                >
                    <input
                        type="text"
                        :disabled="canDisableUserData && userData.pseudonyme"
                        v-model="commentForm.pseudonyme"
                        placeholder="Pseudo"
                        class="bg-transparent w-full"
                    />
                </div>

                <div
                    class="bg-slate-100 rounded-xl p-3 md:w-1/3 xl:mr-2 lg:w-1/3 my-2 lg:mr-2 md:mr-2 xs:w-full"
                >
                    <input
                        type="text"
                        :disabled="canDisableUserData && userData.fullName"
                        v-model="commentForm.fullName"
                        placeholder="Nom Prénoms"
                        class="bg-slate-100 w-full"
                    />
                </div>

                <div
                    class="bg-slate-100 rounded-xl lg:w-1/3 p-3 md:w-1/3 my-2 xs:w-full"
                >
                    <input
                        type="text"
                        placeholder="Email"
                        :disabled="canDisableUserData && userData.email"
                        v-model="commentForm.email"
                        class="bg-slate-100 w-full"
                    />
                </div>
            </div>

            <div>
                <!-- component -->
                <div class="inline-flex items-center">
                    <label
                        class="relative flex cursor-pointer items-center rounded-full p-3"
                        for="checkbox"
                        data-ripple-dark="true"
                    >
                        <input
                            type="checkbox"
                            v-model="commentForm.isPrivate"
                            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-primary before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                            id="checkbox"
                        />
                        <div
                            class="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3.5 w-3.5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </label>

                    <p class="text-primary text-sm ml-1">
                        Envoyer en tant que demande privée
                    </p>
                </div>
            </div>

            <div class="mt-5">
                <button
                    :disabled="!canComment"
                    class="p-3 w-full font-semibold text-center text-white bg-primary2 rounded-xl"
                    @click="saveComment"
                >
                    Commenter le post
                </button>
            </div>
        </div>
    </div>

    <div class="bg-primary2 w-full flex flex-col items-center py-20">
        <div class="flex flex-col justify-center items-center sm:w-4/5">
            <h1 class="font-bold text-4xl text-slate-800 text-center">
                Besoin de conseils ou d'astuces en matière de santé ?
            </h1>

            <p class="py-5 text-white font-medium text-2xl text-center">
                Recevez des infos pour améliorer votre qualité de vie.
            </p>

            <div
                class="lg:w-3/5 md:w-3/5 sm:w-full rounded-lg bg-slate-100 flex py-2 px-2 justify-between mt-5"
            >
                <input
                    placeholder="Entrer votre adresse email"
                    class="bg-slate-100 w-full mr-2"
                />
                <button
                    class="bg-primary2 h-10 text-center rounded-md text-primary2 px-4 bg-opacity-25"
                >
                    M'abonner
                </button>
            </div>
        </div>
    </div>
    <Footer></Footer>
    <Echo />
</template>

<script setup>
import Footer from "@/components/footer/index.vue";

import Header from "./Header.vue";
import Echo from "@/components/echo";
import { MdPreview, MdCatalog } from "md-editor-v3";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import "md-editor-v3/lib/style.css";
import { useRoute, useRouter } from "vue-router";
const props = defineProps({ post: Object });
const comments = ref([...(props.post.comments ?? [])]);

const user = computed(() => store.getters["auth/user"]);

const canDisableUserData = ref(false);
const store = useStore();
const route = useRoute();
const userData = ref({});
const commentForm = ref({
    fullName: "",
    pseudonyme: "",
    isPrivate: false,
    email: "",
    author_id: 0,
    message: "",
});

const canComment = computed(
    () =>
        commentForm.value.message &&
        ((commentForm.value.fullName && commentForm.value.email) ||
            commentForm.value.author_id)
);

const socials = computed(() => [
    {
        hashtags: "allodoto, medical,Benin,cotedivoire",
        plateform: "facebook",
        iconLink: `<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-facebook text-white mr-0"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                        /></svg
                >`,
    },

    {
        hashtags: "allodoto,medical,Benin,cotedivoire",
        plateform: "twitter",
        iconLink: `<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-twitter-x text-white mr-2"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                        /></svg
                >`,
    },
    {
        hashtags: "allodoto,medical,Benin,cotedivoire",
        plateform: "WhatsApp",
        iconLink: `<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-whatsapp text-white mr-2"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                        /></svg
                >`,
    },
    {
        hashtags: "allodoto,medical,Benin,cotedivoire",
        plateform: "sms",
        iconLink: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chat-right" viewBox="0 0 16 16">
                                                                                                                            <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                                                                                                                          </svg>`,
    },
    {
        hashtags: "allodoto,medical,Benin,cotedivoire",
        plateform: "linkedin",
        iconLink: `<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-linkedin ml-1"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                        /></svg
                >`,
    },
]);

const url = computed(
    () =>
        `${
            typeof window != "undefined"
                ? window.location.origin
                : "https://blog.allodoto.com"
        }${route.fullPath}`
);
const listenToComments = () => {
    console.log(window.Echo.channel);

    window.Echo.channel(`posts.${props.post.id}.comments`)

        .listen("NewComment", (event) => {
            console.log("NewPostComment", event);
        })

        // .leaving((user) => {
        //     console.log(user.name);
        // })
        .error((error) => {
            console.error(error);
        });
};

const saveComment = () => {
    store.dispatch("blog/savePostComment", {
        postId: props.post.id,
        data: {
            author_id: commentForm.value.author_id,
            post_id: props.post.id,
            message: commentForm.value.message,
            name: commentForm.value.fullName,
            email: commentForm.value.email,
            pseudonyme: commentForm.value.pseudonyme,
            is_public: !commentForm.value.isPrivate,
        },
        onSuccess: () => {
            commentForm.value.message = "";
        },
        onError: () => {},
    });
};

watch(
    () => user.value,
    (val) => {
        if (val) {
            commentForm.value.fullName = val.full_name;
            commentForm.value.email = val.email;
            commentForm.value.pseudonyme = val.middle_name;
            commentForm.value.author_id = val.id;

            canDisableUserData.value = true;

            userData.value = {
                fullName: val.full_name,
                email: val.email,
                pseudonyme: val.middle_name,
            };
        }
    }
);

onMounted(() => {
    listenToComments();
});
</script>
<style lang="scss">
.md-editor {
    p,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        word-break: keep-all !important;
    }
}
</style>
