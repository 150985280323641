<template>
    <!-- component -->
    <div class="bg-white dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div
                class="hidden bg-cover bg-no-repeat bg-primary lg:block lg:w-2/3 bg-[url(https://newmibridges.michigan.gov/resource/1679148661000/ISD_Icons/landing-page/group-3.svg)]"
            >
                <div
                    class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40"
                >
                    <div>
                        <h2 class="text-4xl font-bold text-blackp">
                            <img
                                src="/images/logo_white.svg"
                                class="mr-3 w-32"
                                alt="Allô Doto Logo"
                            />
                        </h2>

                        <!-- <p class="max-w-xl mt-3 text-white text-xl">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. In autem
              ipsa, nulla laboriosam dolores, repellendus perferendis libero
              suscipit nam temporibus molestiae.
            </p> -->
                    </div>
                </div>
            </div>

            <div
                class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6"
            >
                <div class="flex-1 mx-12">
                    <div class="flex flex-col justify-center">
                        <div class="mb-3">
                            <p
                                class="mt-3 mb-2 text-secondary dark:text-primary text-xl"
                            >
                                Entrer le code
                            </p>
                        </div>
                        <div
                            class="flex flex-row text-sm font-medium text-gray-400"
                        >
                            <p v-if="phone">
                                Nous avons envoyé un code par SMS à votre numéro
                                {{ phone }}
                            </p>
                            <p v-if="email" class="text-md">
                                Nous avons envoyé un code à votre adresse email
                                {{ email }}
                            </p>
                        </div>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="save">
                            <div class="flex flex-col space-y-16">
                                <div
                                    class="flex flex-row items-center justify-between mx-auto w-full max-w-xs"
                                >
                                    <div class="w-16 h-16">
                                        <input
                                            class="w-full h-full border border-slate-400 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-secondary dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                                            type="text"
                                            name="code_1"
                                            id="code_1"
                                            autofocus
                                            v-model="form.code_1"
                                            @input="
                                                ($event) =>
                                                    handleNext($event, 'code_1')
                                            "
                                        />
                                    </div>
                                    <div class="w-16 h-16">
                                        <input
                                            class="w-full h-full flex border border-slate-400 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-secondary dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 flex-col items-center justify-center text-center px-5 outline-none rounded-xl border text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                                            type="text"
                                            name="code_2"
                                            id="code_2"
                                            v-model="form.code_2"
                                            @input="
                                                ($event) =>
                                                    handleNext($event, 'code_2')
                                            "
                                        />
                                    </div>
                                    <div class="w-16 h-16">
                                        <input
                                            class="w-full h-full border border-slate-400 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-secondary dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                                            type="text"
                                            name="code_3"
                                            id="code_3"
                                            v-model="form.code_3"
                                            @input="
                                                ($event) =>
                                                    handleNext($event, 'code_3')
                                            "
                                        />
                                    </div>
                                    <div class="w-16 h-16">
                                        <input
                                            class="w-full h-full border border-slate-400 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-secondary dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl text-lg bg-white focus:ring-1 ring-blue-700"
                                            type="text"
                                            name="code_4"
                                            id="code_4"
                                            v-model="form.code_4"
                                            @input="
                                                ($event) =>
                                                    handleNext($event, 'code_4')
                                            "
                                        />
                                    </div>
                                </div>

                                <div class="flex flex-col space-y-5">
                                    <button
                                        type="submit"
                                        :class="`
                      w-full
                      px-4
                      py-2 outline-none
                      tracking-wide
                       pointer-cursor
                      transition-colors
                      duration-200
                      transform rounded-md ${
                          disabled
                              ? 'bg-slate-300 text-slate-500'
                              : 'bg-primary hover:bg-blue-400 text-white focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                      } `"
                                        :disabled="disabled"
                                    >
                                        Valider
                                    </button>

                                    <div
                                        class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500"
                                    >
                                        <p>Vous n'avez pas reçu de code?</p>
                                        <button
                                            type="button"
                                            class="px-2 py-2 tracking-wide text-primary pointer-cursor transition-colors duration-200 transform rounded-md"
                                            @click.prevent.stop="resendCode()"
                                        >
                                            Renvoyer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { TYPE } from "vue-toastification";

export default {
    components: {},
    props: [],
    setup() {
        const form = ref({ code_1: "", code_2: "", code_3: "", code_4: "" });
        const isSuccess = ref(true);
        const route = useRoute();
        const instance = getCurrentInstance();
        const store = instance.proxy.$store;
        const phone = ref("");
        const email = ref("");
        const isSubmitting = ref(false);
        const disabled = computed(
            () =>
                !form.value?.code_1 ||
                !form.value?.code_2 ||
                !form.value?.code_4 ||
                !form.value?.code_3 ||
                isSubmitting.value
        );

        const handleNext = (elem, key) => {
            form.value[key] = elem.target.value.substr(
                elem.target.value?.length - 1
            );

            if (!form.value[key]) return;
            const currentIndex = Array.from(elem.target.form.elements).indexOf(
                elem.target
            );
            elem.target.form.elements
                .item(
                    currentIndex < elem.target.form.elements.length - 1
                        ? currentIndex + 1
                        : 0
                )
                .focus();
        };

        const save = () => {
            let uri =
                typeof window !== "undefined"
                    ? window.location.search.substring(1)
                    : "";
            let params = new URLSearchParams(uri);
            const token = params.get("rToken");
            isSubmitting.value = true;
            store.dispatch("auth/resetValidateUser", {
                data: {
                    token,
                    code: `${form.value.code_1}${form.value.code_2}${form.value.code_3}${form.value.code_4}`,
                },
                onSuccess: (token) => {
                    if (typeof window != "undefined")
                        window.location.replace(
                            `/reset/password?rToken=${token}`
                        );
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;

                    store.dispatch("medical/displayToast", {
                        message: "Code incorrect.",
                        context: "Mot de passe ",
                        type: TYPE.ERROR,
                        icon: "la-user",
                    });
                },
            });
        };

        const resendCode = () => {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("rToken");
            isSubmitting.value = true;
            store.dispatch("auth/resetResendCode", {
                data: {
                    token,
                },
                onSuccess: (token) => {
                    store.dispatch("medical/displayToast", {
                        message: "Code envoyé avec succès.",
                        context: "Mot de passe ",
                        type: "TYPE.SUCCESS",
                        icon: "la-user",
                    });
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;

                    store.dispatch("medical/displayToast", {
                        message: "Oooops,Une erreur est survenue.",
                        context: "Mot de passe ",
                        type: "TYPE.ERROR",
                        icon: "la-user",
                    });
                },
            });
        };

        const getResettingUser = () => {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("rToken");
            store.dispatch("auth/getUserByRequestToken", {
                token,
                onSuccess: (resp) => {
                    phone.value = resp?.phone_1;
                    email.value = resp?.email;
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        onMounted(() => {
            getResettingUser();
        });

        return {
            isSuccess,
            disabled,
            form,
            isSubmitting,
            phone,
            email,
            handleNext,
            resendCode,
            save,
        };
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
@media (max-width: 1100px) {
    .codes {
        margin: 0px !important;
    }
}
</style>
