<template>
    <VueDatePicker
        :name="name"
        v-model="date"
        text-input
        :utc="true"
        class="w-full text-base font-medium date-container"
        :placeholder="placeholder"
        :text-input-options="{ enterSubmit: false }"
        :clearable="true"
        v-bind="$props"
        preview-format="dd/MM/yyyy"
        format="dd/MM/yyyy"
        :auto-apply="true"
        locale="fr"
        input-class-name="sd-custom-input font-normal"
        @update:modelValue="dateChanged"
    ></VueDatePicker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref } from "vue";
export default {
    components: { VueDatePicker },
    props: ["value", "name", "placeholder"],
    setup(props) {
        const date = ref(props.value);
        const lang = {
            formatLocale: {
                firstDayOfWeek: 1,
            },
            monthBeforeYear: false,
        };
        return { date, lang };
    },
    methods: {
        dateChanged() {
            this.$emit("input", this.date);
        },
    },
};
</script>
<style lang="scss">
.sd-custom-input {
    border-radius: 5px;
}
.dp__theme_light {
    --dp-background-color: #ffffff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1284e7;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: none;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-highlight-color: rgba(25, 118, 210, 0.1);

    .dp__input_wrap {
        height: 100% !important;
        @apply text-sm;
        svg {
            display: none;
        }
        .dp__input_icon_pad {
            padding-left: 0;
        }
        input {
            border: none;
            color: black;
            @apply rounded-sm font-medium;

            padding-left: 35px;
            height: 100%;
            font-size: 0.9rem !important;
            &::placeholder {
                color: rgb(148 163 184) !important;
                font-weight: 400 !important;
            }
            &:focus {
                // border-color: rgb(148 163 184) !important;
            }
        }
    }
}
.sd-custom-input {
    font-weight: 400;
    @apply font-ibm;
}
.dp__main,
.dp__main > div {
    height: 100%;
}
.dp__calendar_wrap,
.dp__instance_calendar {
    @apply font-ibm;
}
.dp__active_date {
    @apply bg-primary2;
}
.dp__today {
    @apply border border-primary3 text-primary3 font-normal;
}
.mx-datepicker {
    width: 100%;
    background: white;
}
</style>
