<template>
    <div class="w-full pb-20">
        <div class="w-full h-full">
            <div class="w-full h-full">
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5 h-full">
                    <div class="flex justify-between items-center mb-3">
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Les Patients
                            </h4>
                        </div>
                        <button
                            @click="$router.push({ name: 'app.patientCreate' })"
                            class="flex justify-center items-center text-sm font-semibold border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div v-if="patients.length > 0" class="w-full">
                        <table
                            striped
                            outlined
                            class="table table-striped"
                            hover
                        >
                            <thead>
                                <tr>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Identifiant
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Nom
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Email
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Ville
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Rôles
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Naissance
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Création
                                    </th>
                                    <th
                                        class="whitespace-nowrap font-medium text-sm"
                                    >
                                        Dernière conn.
                                    </th>
                                    <th class="whitespace-nowrap"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="user in patients" :key="user.id">
                                    <td class="text-sm text-slate-700">
                                        <span
                                            class="text-blackp text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                            >{{ user.username }}</span
                                        >
                                    </td>

                                    <td class="text-sm text-slate-700">
                                        <span>{{
                                            user.gender === "m" ? "Mr." : "Mme."
                                        }}</span>
                                        {{ user.full_name }}
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        {{ user.email }}
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        {{ user.city?.name }}
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        <span
                                            v-for="role in user.roles"
                                            :key="role.id"
                                            class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                                            >{{ role.name }}</span
                                        >
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        {{
                                            user.birth_date
                                                ? moment(
                                                      user.birth_date
                                                  ).format("L")
                                                : "-"
                                        }}
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        {{ moment(user.createdAt).format("L") }}
                                    </td>
                                    <td class="text-sm text-slate-700">
                                        {{
                                            user.last_login
                                                ? moment(
                                                      user.last_login
                                                  ).format("L")
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        <div class="flex justify-end">
                                            <button
                                                class="bg-slate-400 text-sm bg-opacity-25 mr-1 px-2 py-1 rounded text-slate-400"
                                                size="sm"
                                                @click="gotoDetails(user.id)"
                                            >
                                                <i class="las la-eye m-0"></i>
                                            </button>
                                            <button
                                                class="bg-secondary text-sm bg-opacity-25 mr-1 px-2 py-1 rounded text-secondary"
                                                size="sm"
                                                @click="
                                                    $router.push({
                                                        name: 'patient.edit',
                                                        params: {
                                                            patientId:
                                                                user.username
                                                                    ?.toString()
                                                                    ?.toLowerCase(),
                                                        },
                                                    })
                                                "
                                            >
                                                <i class="las la-pen"></i>
                                            </button>
                                            <button
                                                class="px-2 text-sm py-1 bg-danger rounded text-danger bg-opacity-25"
                                                size="sm"
                                                @click="handlePopup(user)"
                                            >
                                                <i class="la la-trash m-0"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-else class="p-3 h-full">
                        <div
                            class="flex flex-col justify-center items-center h-full"
                        >
                            <img class="w-1/4" src="/images/no-result.svg" />
                            <h4 class="text-primary font-medium my-3 text-md">
                                Aucun patient enrégistré pour le moment
                            </h4>
                        </div>
                    </div>
                </div>
                <pagination
                    :pagination="pagination"
                    @changed="getData"
                ></pagination>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";
export default {
    components: {},
    beforeRouteEnter(to, from, next) {
        store.dispatch("medical/getPatients", {});
        next();
        // store.dispatch("config/getResources", {}).then((res) => next());
    },
    watch: {},
    setup() {
        const router = useRouter();
        const portfolio = computed(() => store.getters["auth/portfolio"]);

        const pagination = computed(
            () => store.getters["medical/patientsPagination"]
        );
        const gotoDetails = (id) => {
            router.push({ name: "patient.details", params: { id } });
        };
        const getData = (page = 1) => {
            store.dispatch("medical/getPatients", {
                page,
                portfolioId: portfolio.value.id,
            });
        };

        const listenToAlerts = () => {
            window.Echo.channel("alerts.new").listen("NewAlert", (event) => {
                console.log("New Alert", event);

                getData(1);
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                getData();
            }
        );

        onMounted(() => {
            listenToAlerts();
        });

        return { moment, pagination, getData, gotoDetails };
    },

    computed: {
        ...mapGetters({
            patients: "medical/patients",
            pagination: "medical/patientsPagination",
        }),
    },
    mounted() {},
};
</script>
