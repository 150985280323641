<template>
    <Listbox as="div" :multiple="multiple" class="w-full" :disabled="disabled">
        <div class="relative h-full" style="max-height: 40px">
            <ListboxButton
                class="relative w-full h-full bg-white border border-slate-300 rounded pl-3 pr-5 py-2 text-left cursor-default focus:outline-none focus:ring-slate-500 focus:border-slate-300 hover:border-slate-300 sm:text-sm"
            >
                <span class="flex items-center h-5">
                    <img
                        v-if="withImage"
                        :src="selectedValue[imageProp]"
                        alt=""
                        class="flex-shrink-0 h-6 w-6 rounded-full"
                    />
                    <span
                        v-if="multiple && (selectedValue || [])?.length"
                        class="ml-1 block truncate"
                    >
                        {{
                            (selectedValue || []).map((x) => x.name).join(", ")
                        }}
                    </span>
                    <span
                        v-else-if="!multiple && selectedValue"
                        class="ml-1 block truncate"
                    >
                        {{ selectedValue.name }}
                    </span>
                    <span v-else class="ml-1 block truncate text-slate-400"
                        >{{ placeholder }}
                    </span>
                </span>
                <span
                    class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                    <ChevronDownIcon
                        class="h-5 w-5 text-slate-400"
                        aria-hidden="true"
                    />
                </span>
            </ListboxButton>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <ListboxOptions
                    class="absolute border-slate-300 border z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        as="template"
                        v-for="person in options"
                        :key="person.id"
                        :value="person"
                        v-slot="{ selected }"
                        @click.prevent.stop="newSelection(person)"
                    >
                        <li
                            :class="[
                                selected || optionSelected(person.id)
                                    ? 'text-slate-700 bg-slate-100'
                                    : 'text-slate-700',
                                'cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer',
                            ]"
                        >
                            <div class="flex items-center">
                                <img
                                    v-if="withImage"
                                    :src="selectedValue[imageProp]"
                                    alt=""
                                    class="flex-shrink-0 h-6 w-6 rounded-full"
                                />
                                <span
                                    :class="[
                                        selected || optionSelected(person.id)
                                            ? 'font-medium'
                                            : 'font-normal',
                                        'ml-3 block truncate',
                                    ]"
                                >
                                    {{ person.name }}
                                </span>
                            </div>

                            <span
                                v-if="selected || optionSelected(person.id)"
                                :class="[
                                    selected || optionSelected(person.id)
                                        ? 'text-slate-300'
                                        : 'secondary',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                            >
                                <CheckIcon
                                    class="h-5 w-5 text-slate-500"
                                    aria-hidden="true"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/24/solid";
import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from "@headlessui/vue";

export default {
    components: {
        Listbox,
        CheckIcon,
        ChevronDownIcon,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
    },
    props: {
        options: {
            type: Array,
        },
        withImage: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        name: {
            type: String,
        },
        imageProp: {
            type: String,
        },
        placeholder: {
            type: String,
            default: "Sélectionner",
        },
        value: {
            type: [Object, Array, String],
            default: "",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selected", "removed"],
    setup(props, { emit }) {
        const selectedValue = ref();
        const newSelection = (option) => {
            if (!props.multiple && option?.id === selectedValue.value?.id) {
                selectedValue.value = null;
            } else if (
                props.multiple &&
                selectedValue.value.map((x) => x.id).includes(option.id)
            ) {
                selectedValue.value = selectedValue.value.filter(
                    (x) => x.id !== option.id
                );
            } else if (props.multiple) {
                selectedValue.value.push(option);
            } else selectedValue.value = option;

            const isSelected = props.multiple
                ? selectedValue.value.map((x) => x.id).includes(option.id)
                : selectedValue.value?.id === option?.id;
            if (isSelected) emit("selected", option, option.id);
            else emit("removed", option, option.id);
        };

        const optionSelected = (value) => {
            if (props.multiple) {
                return (selectedValue.value || [])
                    .map((x) => x.id)
                    .includes(value);
            } else return value === selectedValue.value?.id;
        };

        watch(
            () => props.value,
            (val) => {
                const single = !props.value?.id
                    ? props.options.find((x) => x.id === val)
                    : !props.value?.name
                    ? props.options.find((x) => x.id === props.value?.id)
                    : val;
                const multiple = (!props.multiple ? [] : props.value).map(
                    (v) => {
                        return !v?.id
                            ? props.options.find((x) => x.id === v)
                            : !v?.name && v?.id
                            ? props.options.find((x) => x.id === v?.id)
                            : v;
                    }
                );

                selectedValue.value = props.multiple ? multiple : single;
            }
        );

        onMounted(() => {
            selectedValue.value = props.multiple
                ? (props.value || []).map((v) => {
                      return !v?.id
                          ? props.options.find((x) => x.id === v)
                          : !v?.name && v?.id
                          ? props.options.find((x) => x.id === v?.id)
                          : v;
                  })
                : !props.value?.id
                ? props.options.find((x) => x.id === props.value)
                : !props.value?.name
                ? props.options.find((x) => x.id === props.value?.id)
                : props.value;
        });

        return {
            selectedValue,
            newSelection,
            optionSelected,
        };
    },
};
</script>
