<template>
    <div class="flex items-center h-screen xs:pl-5 pl-12">
        <div
            class="flex flex-col left-box overflow-visible sm:w-full xs:w-full"
        >
            <div class="mb-10 mt-10">
                <div
                    class="flex items-start flex-col"
                    style="white-space: nowrap"
                >
                    <h1 class="text-5xl font-medium text-primary leading-snug">
                        Vous recherchez
                    </h1>

                    <div class="border-primary1 px-1 rounded mr-2 flex">
                        <h1
                            class="inline-block font-black list-enter-active text-5xl px-1 font-ibm text-primary2 leading-snug transition transition-all"
                        >
                            <transition-fade>
                                <VueTyper :text="list" class="" />
                            </transition-fade>
                        </h1>

                        <h3
                            class="text-5xl font-medium text-primary2 leading-snug"
                        >
                            ?
                        </h3>
                    </div>
                </div>
                <div class="w-3/5 py-4">
                    <p class="text-slate-500">
                        Retrouvez vos praticiens près de chez vous. Ils sont
                        facilement accessible et sont à votre écoute.Mieux
                        prendre soins de la santé des Africains
                    </p>
                </div>
            </div>

            <form id="welcome-form ">
                <div class="bg-white rounded-lg py-4 px-4">
                    <div
                        class="flex search-box justify-between xs:items-center sm:items-center"
                    >
                        <div
                            class="name-box w-1/2 flex mx-2 justify-start items-center bg-slate-100 rounded-lg xs:w-full sm:w-full"
                        >
                            <div
                                class="text-xs mx-1 text-[#a3abb0] border-right"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                            </div>

                            <input
                                class="w-full"
                                type="text"
                                v-model="text"
                                placeholder="Touver votre praticien"
                            />
                        </div>
                        <div
                            class="place-box w-2/5 flex items-center bg-slate-100 rounded-lg mr-2 xs:mr-0 sm:mr-0 xs:mt-2"
                        >
                            <div class="ml-3 text-xs text-[#a3abb0]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                    />
                                </svg>
                            </div>
                            <input
                                class="w-full"
                                type="text"
                                placeholder="Cotonou"
                                v-model="place"
                            />
                        </div>
                        <div class="py-1 mx-1">
                            <button
                                type="submit"
                                class="rounded-lg flex py-3 px-4 text-white hover:bg-opacity-50 justify-center items-center h-full bg-primary01"
                                @click.prevent="search"
                            >
                                <span class="la la-search text-3xl"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            class="bg-right xs:hidden sm:hidden right-box flex items-end h-full justify-end relative overflow-y-hidden"
        >
            <div
                class="w-4/5 bg-primary01 h-full absolute right-0 flex justify-center"
            >
                <div
                    class="bg-white rounded-xl shadow-2xl w-1/2 sm:w-4/5 h-fit mt-10 p-5"
                >
                    <div class="flex mt-3">
                        <div
                            class="h-10 w-10 rounded rounded bg-slate-300 bg-opacity-25 flex justify-center items-center"
                        >
                            <i class="la la-users text-xl text-primary02"></i>
                        </div>
                        <div class="ml-2">
                            <p class="text-sm font-semibold text-slate-800">
                                Gérer votre patientèle
                            </p>
                            <p class="text-xs text-slate-500">
                                With more captions
                            </p>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div
                            class="h-10 w-10 rounded rounded bg-slate-300 bg-opacity-25 flex justify-center items-center"
                        >
                            <i
                                class="la la-ambulance text-xl text-primary02"
                            ></i>
                        </div>
                        <div class="ml-2">
                            <p class="text-sm font-semibold text-slate-800">
                                Prise en charge ambulatoire
                            </p>
                            <p class="text-xs text-slate-500">
                                With more captions
                            </p>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <div
                            class="h-10 w-10 rounded rounded bg-slate-300 bg-opacity-25 flex justify-center items-center"
                        >
                            <i class="la la-user-md text-xl text-primary02"></i>
                        </div>
                        <div class="ml-2">
                            <p class="text-sm font-semibold text-slate-800">
                                Soins de meilleure qualité
                            </p>
                            <p class="text-xs text-slate-500">
                                With more captions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="absolute bottom-0 -left-[90px]"
                style="height: 100%; width: 80%"
            >
                <img
                    src="/images/home-a-doctor.png"
                    class="absolute bottom-0 z-20"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    defineComponent,
    computed,
    ref,
    onMounted,
    onBeforeUnmount,
} from "vue";
import VueTyper from "vue3-typer";
import "vue3-typer/dist/vue-typer.css";
import { TransitionFade } from "@morev/vue-transitions";
export default defineComponent({
    components: { VueTyper, TransitionFade },
    setup() {
        const interval = ref(null);
        const index = ref(0);
        const text = ref("");
        const place = ref("");
        const list = computed(() => [
            "un médécin généraliste",
            "une pédiatre",
            "un psychiâtre",
            "une dermatologue",
            "une gynécologue",
            "une sage-femme",
            "une kinésithérapeute",
            "une dentiste",
            "un radiologue",
        ]);

        const images = computed(() => [
            "/images/bg-general-4.svg",
            "/images/bg-5.svg",
            "/images/bg-6.svg",
            "/images/bg-7.svg",
            "/images/bg-8.svg",
        ]);

        const currentElm = computed(() => list.value[index.value]);

        const search = () => {
            let query = "";
            if (text.value) query += `?search=${text.value}`;
            if (place.value) query += `?where=${place.value}`;

            if (typeof window != "undefined")
                window.location.replace(`/search-praticians${query}`);
        };

        onMounted(() => {
            interval.value = setInterval(() => {
                if (index.value === list.value.length - 1) index.value = 0;
                else index.value += 1;

                console.log("dededed", index.value);
            }, 5000);
        });

        onBeforeUnmount(() => {
            clearInterval(interval.value);
        });
        return { place, text, list, currentElm, images, index, search };
    },
});
</script>
<style lang="scss">
.vue-typer {
    .typed {
        @apply text-primary2  #{!important};
    }
    .selected {
        @apply text-primary2  #{!important};
    }
    .erased {
        @apply text-primary2  #{!important};
    }
    .caret {
        @apply bg-primary2  #{!important};
    }
}
.search-box {
    width: 100%;
    input[type="text"],
    textarea {
        //@apply bg-slate-100 #{!important};
    }
}

.list-enter-active {
    animation: fade-in 0.5s ease-in-out;
}

.list-leave-active {
    animation: fade-in 0.5s ease-in-out reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes fade-out {
    0% {
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(30px);
    }
}
.left-box {
    width: 70%;
}
.right-box {
    width: 30%;
}

@media (max-width: 500px) {
    .left-box {
        width: 100% !important;
        .text-5xl {
            font-size: 20px !important;
        }
        .text-6xl {
            font-size: 40px !important;
        }
    }
    .right-box {
        width: 0 !important;
    }
    .search-box {
        flex-direction: column;

        .name-box,
        .place-box {
            width: 90% !important;
            height: 50px;
            border: none;
            @apply py-2;
        }
        button {
            width: 99%;
            border-radius: 8px !important;
            @apply ml-1;
        }
    }
}
</style>
