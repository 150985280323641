<template>
    <div class="h-screen overflow-hidden bg-primary0">
        <div class="flex justify-center h-full">
            <div class="w-2/5 relative h-full sm:hidden xs:hidden">
                <a href="/">
                    <img
                        src="/images/allo_logo.svg"
                        class="w-32 absolute z-50 left-[50px] top-[50px]"
                        alt="AlloDoto Logo"
                    />
                    <img
                        src="/images/login-overlay.png"
                        class="absolute -top-0 left-0"
                    />

                    <img
                        src="/images/login-bg.png"
                        class="w-full h-full object-cover"
                        alt=""
                    />
                </a>
            </div>
            <div
                class="flex items-center justify-center w-3/5 sm:w-full xs:w-full"
            >
                <div
                    class="flex flex-col bg-white rounded-xl w-7/12 border border-slate-300 px-8 xs:px-4 py-4"
                >
                    <div class="flex flex-col justify-center">
                        <div
                            class="mt-3 mb-10 flex justify-start items-center md:hidden lg:hidden xl:hidden"
                        >
                            <a href="/" class="text-5xl font-bold text-white">
                                <img
                                    src="/images/allo_logo.svg"
                                    class="w-32"
                                    alt="AlloDoto Logo"
                                />
                            </a>
                        </div>

                        <h2 class="text-3xl text-slate-800">Connexion</h2>

                        <p class="mt-3 text-slate-500 dark:text-primary">
                            Entrez vos informations pour vous connecter
                        </p>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="save">
                            <div>
                                <label
                                    for="email"
                                    class="block mb-1 text-sm text-gray-600 dark:text-gray-200"
                                    >Email
                                </label>
                                <div
                                    class="flex items-center shadow-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                >
                                    <img
                                        src="/images/email.svg"
                                        alt=""
                                        class="px-1"
                                    />
                                    <input
                                        v-model="form.email"
                                        autocomplete="off"
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="mon-compte@allodoto.com"
                                        class="h-10 bg-white px-4 w-full"
                                    />
                                </div>
                            </div>

                            <div class="mt-6">
                                <div class="flex justify-between mb-1">
                                    <label
                                        for="password"
                                        class="text-sm text-gray-600 dark:text-gray-200"
                                        >Mot de passe</label
                                    >
                                    <a
                                        href="/reset"
                                        class="text-sm text-primary2 focus:text-blue-500 hover:text-blue-500 hover:underline"
                                        >Mot de passe oublié ?</a
                                    >
                                </div>

                                <div
                                    class="flex items-center shadow-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                >
                                    <img
                                        src="/images/lock.svg"
                                        alt=""
                                        class="px-1"
                                    />

                                    <input
                                        v-model="form.password"
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Mot de passe"
                                        class="h-10 bg-white px-4 w-full"
                                    />
                                </div>
                            </div>

                            <div class="mt-6">
                                <button
                                    type="submit"
                                    :class="`
                    w-full
                    px-4
                    py-2
                    tracking-wide
                    text-primary pointer-cursor
                    transition-colors
                    duration-200
                    transform rounded-md ${
                        disabled
                            ? 'bg-slate-300 text-slate-600'
                            : 'bg-primary01 text-white'
                    } `"
                                    :disabled="disabled"
                                >
                                    Se Connecter
                                </button>
                            </div>
                        </form>

                        <p class="mt-6 text-sm text-center text-slate-400">
                            Pas encore de compte ?
                            <a
                                href="/create-my-account"
                                class="text-primary01 focus:outline-none focus:underline hover:underline"
                                >Créer mon compte</a
                            >.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, ref } from "vue";
export default {
    components: {},
    props: [],
    setup() {
        const form = ref({});
        const isSuccess = ref(true);

        const instance = getCurrentInstance();
        const store = instance.proxy.$store;

        const isSubmitting = ref(false);
        const disabled = computed(
            () =>
                !form.value?.email ||
                !form.value?.password ||
                isSubmitting.value
        );

        const save = () => {
            isSubmitting.value = true;
            store.dispatch("auth/login", {
                login: form.value.email,
                password: form.value.password,
                onSuccess: async (response) => {
                    isSubmitting.value = false;
                    isSuccess.value = true;

                    const user = await store.dispatch("auth/getUser");

                    const roles = (user.roles ?? []).map((x) => x.slug);
                    if (typeof window != "undefined")
                        if (roles.includes("patient")) {
                            window.location.replace("/account");
                        } else window.location.replace("/app");
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        return { isSuccess, disabled, form, isSubmitting, save };
    },
    methods: {},
};
</script>
