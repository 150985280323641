<template>
    <div class="w-full">
        <div>
            <div>
                <div class="rounded-sm bg-white flex flex-col p-3 mb-5">
                    <div class="flex flex-row justify-content-between mb-3">
                        <div class="px-2">
                            <h4 class="card-title">
                                Modifier un membre du staff (Equipe)
                            </h4>
                        </div>
                    </div>
                    <div>
                        <div class="mt-2">
                            <div class="table-responsive">
                                <form
                                    novalidate
                                    autocomplete="false"
                                    @submit.prevent="updateMember"
                                >
                                    <div class="flex mb-2">
                                        <div class="flex pl-2 w-1/3">
                                            <div class="mb-2 w-full">
                                                <label
                                                    class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                    for="grid-state"
                                                >
                                                    Sexe
                                                </label>
                                                <Field
                                                    name="gender"
                                                    v-slot="{ field }"
                                                    v-model="user.gender"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="gender"
                                                        v-model="user.gender"
                                                        :options="options"
                                                        @changed="
                                                            (gender) =>
                                                                (user.gender =
                                                                    gender)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Rôles
                                            </label>
                                            <div class="relative">
                                                <Field
                                                    name="roles"
                                                    v-slot="{ field }"
                                                    v-model="user.roles"
                                                >
                                                    <Select
                                                        :multiple="true"
                                                        v-bind="field"
                                                        name="roles"
                                                        v-model="user.roles"
                                                        :options="roles"
                                                        @changed="
                                                            (city) =>
                                                                (user.roles =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-zip"
                                            >
                                                Adresse
                                            </label>
                                            <div style="height: 38px">
                                                <Field
                                                    name="address"
                                                    v-slot="{ field }"
                                                    v-model="user.address"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="address"
                                                        v-model="user.address"
                                                        class="bg-slate-100 h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        type="text"
                                                        placeholder="Adresse complète"
                                                /></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-2 flex mb-2">
                                        <div class="w-1/3 mb-3 md:mb-0">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-first-name"
                                            >
                                                Prénom(s)
                                            </label>
                                            <div style="height: 38px">
                                                <Field
                                                    name="first_name"
                                                    v-slot="{ field }"
                                                    v-model="user.first_name"
                                                >
                                                    <input
                                                        name="first_name"
                                                        v-model="
                                                            user.first_name
                                                        "
                                                        v-bind="field"
                                                        style="height: 38px"
                                                        class="form-control appearance-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-first-name"
                                                        type="text"
                                                        placeholder="Jane"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2 mb-3 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Nom de famille
                                            </label>
                                            <div style="height: 38px">
                                                <Field
                                                    name="last_name"
                                                    v-slot="{ field }"
                                                    v-model="user.last_name"
                                                >
                                                    <input
                                                        style="height: 38px"
                                                        name="last_name"
                                                        v-model="user.last_name"
                                                        v-bind="field"
                                                        class="form-control appearance-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-last-name"
                                                        type="text"
                                                        placeholder="Doe"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/3 pl-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Email
                                            </label>
                                            <div style="height: 38px">
                                                <Field
                                                    name="email"
                                                    v-slot="{ field }"
                                                    v-model="user.email"
                                                >
                                                    <input
                                                        name="email"
                                                        v-bind="field"
                                                        v-model="user.email"
                                                        class="form-control h-10 appearance-none border rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-last-name"
                                                        type="text"
                                                        placeholder="alokpessi.jonjon@allodoto.com"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex mb-3">
                                        <div class="pl-2" style="width: 33%">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-first-name"
                                            >
                                                Téléphone (Ligne 1)
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    name="phone_1"
                                                    v-slot="{ field }"
                                                    v-model="user.phone_1"
                                                >
                                                    <!-- <PhoneInput
                                                        v-bind="field"
                                                        v-model="user.phone_1"
                                                        name="phone_1"
                                                        @phoneChanged="
                                                            (number) =>
                                                                (user.phone_1 =
                                                                    number)
                                                        "
                                                    /> -->

                                                    <phone-number-input
                                                        type="text"
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2" style="width: 33%">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Téléphone (Ligne 2)
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <!-- <PhoneInput
                                                    v-model="user.phone_2"
                                                    name="phone_2"
                                                    @phoneChanged="
                                                        (number) =>
                                                            (user.phone_2 =
                                                                number)
                                                    "
                                                /> -->

                                                <phone-number-input
                                                    type="text"
                                                    v-bind="field"
                                                    :defaultCountry="
                                                        countryCode
                                                    "
                                                    placeholder="Numero de télephone *"
                                                    class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                />
                                            </div>
                                        </div>

                                        <div class="pl-2" style="width: 33%">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Ville
                                            </label>
                                            <div>
                                                <Field
                                                    name="city"
                                                    v-slot="{ field }"
                                                    v-model="user.city"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="city"
                                                        class="h-full"
                                                        v-model="user.city"
                                                        :options="cities"
                                                        @changed="
                                                            (city) =>
                                                                (user.city =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex">
                                        <div class="flex px-2 w-1/3">
                                            <div class="mb-2 w-full">
                                                <label
                                                    class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                    for="grid-state"
                                                >
                                                    Centre médical
                                                </label>
                                                <div>
                                                    <Field
                                                        name="hospital"
                                                        v-slot="{ field }"
                                                        v-model="user.hospital"
                                                    >
                                                        <Select
                                                            v-bind="field"
                                                            name="hospital"
                                                            v-model="
                                                                user.hospital
                                                            "
                                                            :options="hospitals"
                                                            @changed="
                                                                (value) =>
                                                                    (user.hospital =
                                                                        value)
                                                            "
                                                        />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-1/3 pr-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Date de début
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor_from"
                                                    v-model="user.doctor_from"
                                                >
                                                    <Datepicker
                                                        class="h-full px-2"
                                                        v-bind="field"
                                                        v-model="
                                                            user.doctor_from
                                                        "
                                                        id="excludes"
                                                        :min-date="
                                                            user.doctor_from
                                                        "
                                                        placeholder="Date"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/3 pr-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Date de fin
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor_to"
                                                    v-model="user.doctor_to"
                                                >
                                                    <Datepicker
                                                        class="h-full px-2"
                                                        v-bind="field"
                                                        v-model="user.doctor_to"
                                                        :min-date="
                                                            user.doctor_from
                                                        "
                                                        id="excludes"
                                                        placeholder="Date"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-end px-2 my-4">
                                        <button
                                            @click="
                                                $router.push({
                                                    name: 'config.staff',
                                                })
                                            "
                                            class="border-none rounded bg-danger mx-3 px-3 text-white"
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            :disabled="!meta.valid"
                                            type="submit"
                                            class="border-none rounded hover:bg-primary-500 bg-success text-white px-3 py-2"
                                        >
                                            Enrégistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div>
                            <!-- <pre>{{ errors }}</pre>
                <pre>{{ values }}</pre> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import store from "@/store";
import Select from "@/components/Select/index.vue";
import PhoneInput from "@/components/PhoneInput";
import * as yup from "yup";
import { useForm, Field } from "vee-validate";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import Datepicker from "@/components/Datepicker/index.vue";

export default {
    components: { Field, Select, PhoneInput, Datepicker },
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getAllCities", {});
        store.dispatch("config/getRoles").then((res) => next());
    },
    setup() {
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const hospitals = computed(() =>
            store.getters["medical/hospitals"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );

        const hospital = ref();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const user = ref({ doctor_from: new Date(), doctor_to: null });
        const options = computed(() => [
            {
                name: "Homme",
                id: "m",
            },
            {
                name: "Femme",
                id: "f",
            },
        ]);
        const schema = computed(() =>
            yup.object({
                last_name: yup.string().required(),
                first_name: yup.string().required(),
                email: yup.string().required(),
                address: yup.string().optional().nullable(),
                phone_1: yup.string().required("phoneRequired"),
                phone_2: yup.string().optional().default("").nullable(),
                role: yup.object({ id: yup.string() }).required(),
                gender: yup.object({ id: yup.string() }).required(),
                city: yup
                    .object({ id: yup.string() })
                    .required("cityRequired")
                    .typeError("cityRequired"),
                hospital: yup
                    .object({ id: yup.number() })
                    .required("hospitalRequired")
                    .typeError("hospitalRequired"),
            })
        );

        const { errors, values, meta, handleSubmit } = useForm({
            validationSchema: schema.value,
            validateOnMount: true,
        });

        const getUser = () => {
            store.dispatch("medical/getStaff", {
                identifier: route.params.identifier,
                onSuccess: (data) => {
                    user.value = {
                        last_name: data.last_name,
                        first_name: data.first_name,
                        address: data.address,
                        latest_medical_account: data.latest_medical_account,
                        hospital: data.latest_medical_account
                            ? {
                                  ...hospitals.value.find(
                                      (x) =>
                                          x.id ==
                                          data?.latest_medical_account
                                              ?.medical_center_id
                                  ),
                              }
                            : {},
                        doctor_from: data?.latest_medical_account?.from,
                        doctor_to: data?.latest_medical_account?.to,
                        email: data.email,
                        phone_1: `+${data.phone_1}`,
                        phone_2: data.phone_2 ? `+${data.phone_2}` : "",
                        city: data.city,
                        gender: {
                            id: data.gender?.toString()?.toLowerCase(),
                            name: options.value.find(
                                (x) =>
                                    x.id ==
                                    data.gender?.toString()?.toLowerCase()
                            )?.name,
                        },
                        roles: data.roles.filter((r) => r.level > 2),
                    };
                },
            });
        };

        const updateMember = handleSubmit((values, actions) => {
            const data = {
                last_name: values.last_name,
                first_name: values.first_name,
                email: values.email,
                phone_1: values.phone_1
                    ?.toString()
                    .replaceAll(" ", "")
                    .replaceAll("+", ""),
                address: values.address,
                portfolio_id: portfolio.value.id,
                phone_2: (values.phone_2 || user.value.phone_2)
                    ?.toString()
                    .replaceAll(" ", "")
                    .replaceAll("+", ""),
                roles: values.roles.map((x) => x.id),
                gender: values.gender.id,
                member_to: values.doctor_to,
                member_from: values.doctor_from,
                medical_center_id: values.hospital?.id,
                city_id: values.city?.id,
            };

            if (hospital.value && !data?.medical_center_id)
                data.medical_center_id = hospital?.value.id;

            store.dispatch("medical/updateStaff", {
                identifier: route.params.identifier,
                data,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Utilisateur mis à jour avec succès.",
                        context: "Staff",
                        type: "TYPE.SUCCESS",
                    });

                    if (hospital.value?.id) {
                        router.push({
                            name: "config.hospitalDetails",
                            params: { slug: hospital.value?.slug },
                        });
                    } else
                        router.push({
                            name: "config.staff",
                        });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Staff",
                        type: "TYPE.ERROR",
                    });
                },
            });
        });

        const getHospital = () => {
            if (route.params.slug) return;
            store.dispatch("medical/getHospital", {
                slug: route.params.slug,
                onSuccess: (data) => {
                    hospital.value = { ...data };
                },
                onError: () => {},
            });
        };

        const getHospitals = () => {
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value.id },
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                router.push({ name: "config.staff" });
            }
        );

        watch(
            () => hospitals.value,
            (val) => {
                user.value.hospital = val.find(
                    (x) =>
                        x.id ==
                        user.value?.latest_medical_account?.medical_center_id
                );
            }
        );

        onMounted(() => {
            getUser();
            getHospital();
            getHospitals();
        });

        return {
            schema,
            errors,
            meta,
            values,
            user,
            options,
            updateMember,
            hospitals,
        };
    },

    computed: {
        ...mapGetters({
            allRoles: "config/roles",
            cities: "config/allCities",
            staff: "config/users",
        }),

        roles() {
            return this.allRoles
                .filter((x) => x.level > 2)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
    },
};
</script>
