import axios from "./axios";
import { cities, countries, staff, role, car, resource, record, specialty, portfolio, blog, procedures, medicineVerification } from "./endpoint";

export default {
    async getCities(credentials = {}) {
        const response = await axios.get(cities.get(), credentials);
        return response.data;
    },

    async getAllCities(data) {
        const response = await axios.get(cities.get(), data);
        return response.data;
    },

    async getCountries(credentials = {}) {
        const response = await axios.get(countries.get(), credentials);
        return response.data;
    },

    async saveCountry(data) {
        const response = await axios.post(countries.create(), data);
        return response.data;
    },

    async saveCity(data) {
        const response = await axios.post(cities.create(), data);
        return response.data;
    },

    async putCity(id, data) {
        const response = await axios.put(cities.one(id), data);
        return response.data;
    },

    async removeCity(id) {
        const response = await axios.delete(cities.one(id));
        return response.data;
    },

    async removeCountry(id) {
        const response = await axios.delete(countries.one(id));
        return response.data;
    },


    async putCountry(id, data) {
        const response = await axios.put(countries.one(id), data);
        return response.data;
    },

    async addStaff(data) {
        const response = await axios.post(staff.create(), data);
        return response.data;
    },

    async getSpecialties(data = {}) {
        const response = await axios.get(specialty.get(), data);
        return response.data;
    },

    async getProcedures(data = {}) {
        const response = await axios.get(procedures.index(), data);
        return response.data;
    },

    async addProcedures(data = {}) {
        const response = await axios.post(procedures.index(), data);
        return response.data;
    },

    async removeProcedures(id) {
        const response = await axios.delete(procedures.get(id),);
        return response.data;
    },

    async editProcedures(id, data = {}) {
        const response = await axios.put(procedures.get(id), data);
        return response.data;
    },

    async getSpecialtyProcedures(id, data = {}) {
        const response = await axios.get(specialty.procedures(id), data);
        return response.data;
    },

    async getSpecialty(identifier) {
        const response = await axios.get(specialty.one(identifier));
        return response.data;
    },

    async postSpecialty(data) {
        const response = await axios.post(specialty.create(), data);
        return response.data;
    },

    async getStaff(data) {
        const response = await axios.get(staff.get(), data);
        return response.data;
    },

    async getWriters(data) {
        const response = await axios.get(blog.writers(), data);
        return response.data;
    },

    async postWriter(data) {
        const response = await axios.post(blog.postWriter(), data);
        return response.data;
    },

    async removeStaff(id) {
        const response = await axios.delete(staff.one(id));
        return response.data;
    },

    async getRoles() {
        const response = await axios.get(role.get());
        return response.data;
    },

    async addAmbulance(data) {
        const response = await axios.post(car.create(), data);
        return response.data;
    },

    async attachCarFile(id, formData) {
        const response = await axios.file(car.upload(id), formData);
        return response.data;
    },

    async editAmbulance(data, id) {
        const response = await axios.put(car.one(id), data);
        return response.data;
    },

    async editAmbulanceResources(data, id) {
        const response = await axios.put(car.resources(id), data);
        return response.data;
    },

    async getAmbulances(params) {
        const response = await axios.get(car.get(), { ...params });
        return response.data;
    },

    async updateAmbulancePosition(id, data) {
        const response = await axios.put(car.position(id), data);
        return response.data;
    },

    async deleteAmbulance(id) {
        const response = await axios.delete(car.one(id));
        return response.data;
    },

    async addResourceTypes(data) {
        const response = await axios.post(resource.createTypes(), data);
        return response.data;
    },

    async editResourceTypes(id, data) {
        const response = await axios.put(resource.oneType(id), data);
        return response.data;
    },

    async getResourceTypes(page) {
        const response = await axios.get(resource.getTypes(), { page });
        return response.data;
    },
    async getAllResourceTypes() {
        const response = await axios.get(resource.getAllTypes());
        return response.data;
    },

    async getAllResources(data) {
        const response = await axios.get(resource.all(), data);
        return response.data;
    },

    async removeResourceType(id) {
        const response = await axios.delete(resource.oneType(id));
        return response.data;
    },

    async addRecordTypes(data) {
        const response = await axios.post(record.createTypes(), data);
        return response.data;
    },

    async editRecordType(id, data) {
        const response = await axios.put(record.oneType(id), data);
        return response.data;
    },

    async getRecordTypes(page = 1) {
        const response = await axios.get(record.getTypes(), { page });
        return response.data;
    },

    async getAllRecordTypes() {
        const response = await axios.get(record.allTypes());
        return response.data;
    },

    async removeRecordType(id) {
        const response = await axios.delete(record.oneType(id));
        return response.data;
    },

    async getResources(data) {
        const response = await axios.get(resource.get(), data);
        return response.data;
    },

    async addResources(data) {
        const response = await axios.post(resource.create(), data);
        return response.data;
    },

    async editResources(id, data, params) {
        const response = await axios.put(resource.one(id), data, params);
        return response.data;
    },

    async removeResource(id, data) {
        const response = await axios.delete(resource.one(id), data);
        return response.data;
    },

    async getPortfolios(data) {
        const response = await axios.get(portfolio.list(), data);
        return response.data;
    },

    async getPortfolio(id) {
        const response = await axios.get(portfolio.one(id));
        return response.data;
    },

    async editPortfolio(id, data) {
        const response = await axios.put(portfolio.edit(id), data);
        return response.data;
    },

    async setPortfolioPhone(id, data) {
        const response = await axios.put(portfolio.phones(id), data);
        return response.data;
    },

    async validatePortfolioPhone(id, data) {
        const response = await axios.put(portfolio.phoneValidation(id), data);
        return response.data;
    },

    async clearPhone(id, data) {
        const response = await axios.put(portfolio.clearPhone(id), data);
        return response.data;
    },

}
