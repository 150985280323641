<template>
    <div class="w-full my-6">
        <div>
            <div>
                <div class="rounded-sm bg-white flex flex-col p-3 mb-5">
                    <div class="flex flex-row justify-content-between mb-3">
                        <div class="px-3 mb-3">
                            <h4 class="card-title font-semibold">
                                Ajouter un nouveau membre du staff (Equipe)
                            </h4>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div md="12" class="table-responsive">
                                <form
                                    novalidate
                                    autocomplete="false"
                                    @submit.prevent="createNewMember"
                                >
                                    <div class="flex mb-2">
                                        <div class="pl-2 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                            >
                                                Sexe
                                            </label>
                                            <div class="flex mb-2">
                                                <Field
                                                    name="gender"
                                                    v-slot="{ field }"
                                                    v-model="user.gender"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="gender"
                                                        v-model="user.gender"
                                                        :options="options"
                                                        @changed="
                                                            (city) =>
                                                                (user.gender =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                            >
                                                Rôle
                                            </label>
                                            <div class="relative">
                                                <Field
                                                    name="role"
                                                    v-slot="{ field }"
                                                    v-model="user.role"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="role"
                                                        v-model="user.role"
                                                        :options="roles"
                                                        @changed="
                                                            (city) =>
                                                                (user.role =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="px-2 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-zip"
                                            >
                                                Adresse
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="address"
                                                    v-slot="{ field }"
                                                    v-model="user.address"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="address"
                                                        v-model="user.address"
                                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        type="text"
                                                        placeholder="Adresse complète"
                                                /></Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-2 flex mb-2">
                                        <div class="w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                            >
                                                Prénom(s)
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="first_name"
                                                    v-slot="{ field }"
                                                    v-model="user.first_name"
                                                >
                                                    <input
                                                        name="first_name"
                                                        v-model="
                                                            user.first_name
                                                        "
                                                        v-bind="field"
                                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-first-name"
                                                        type="text"
                                                        placeholder="Jane"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2 mb-3 w-1/3">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                            >
                                                Nom de famille
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="last_name"
                                                    v-slot="{ field }"
                                                    v-model="user.last_name"
                                                >
                                                    <input
                                                        name="last_name"
                                                        v-model="user.last_name"
                                                        v-bind="field"
                                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-last-name"
                                                        type="text"
                                                        placeholder="Doe"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/3 pl-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                            >
                                                Email
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="email"
                                                    v-slot="{ field }"
                                                    v-model="user.email"
                                                >
                                                    <input
                                                        name="email"
                                                        v-bind="field"
                                                        v-model="user.email"
                                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-last-name"
                                                        type="text"
                                                        placeholder="john.doe@allodoto.com"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex mb-3">
                                        <div class="w-1/3 pl-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                            >
                                                Téléphone (Ligne 1)
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    name="phone_1"
                                                    v-slot="{ field }"
                                                    v-model="user.phone_1"
                                                >
                                                    <phone-number-input
                                                        type="text"
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="pl-2" style="width: 33%">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                            >
                                                Téléphone (Ligne 2)
                                            </label>
                                            <div
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    name="phone_2"
                                                    v-slot="{ field }"
                                                    v-model="user.phone_2"
                                                >
                                                    <!-- <PhoneInput
                                                        v-bind="field"
                                                        name="phone_2"
                                                        @phoneChanged="
                                                            (number) =>
                                                                (user.phone_2 =
                                                                    number)
                                                        "
                                                    /> -->

                                                    <phone-number-input
                                                        type="text"
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="doctor-info-phone transition transition-all h-10 w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="pl-2" style="width: 33%">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                            >
                                                Ville
                                            </label>
                                            <div class="" id="tetet">
                                                <Field
                                                    name="city"
                                                    v-slot="{ field }"
                                                    v-model="user.city"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="city"
                                                        v-model="user.city"
                                                        :options="cities"
                                                        @changed="
                                                            (city) =>
                                                                (user.city =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex">
                                        <div class="flex w-1/3">
                                            <div class="mb-2 w-full pl-2">
                                                <label
                                                    class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                    for="grid-state"
                                                >
                                                    Centre médical
                                                </label>
                                                <div class="h-10">
                                                    <Field
                                                        name="hospital"
                                                        v-slot="{ field }"
                                                        v-model="user.hospital"
                                                    >
                                                        <Select
                                                            v-bind="field"
                                                            class="rounded h-full"
                                                            name="hospital"
                                                            v-model="
                                                                user.hospital
                                                            "
                                                            :options="hospitals"
                                                            :disabled="
                                                                $route.params
                                                                    ?.slug !==
                                                                null
                                                            "
                                                            @changed="
                                                                (value) =>
                                                                    (user.hospital =
                                                                        value)
                                                            "
                                                        />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-1/3 pl-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                            >
                                                Date de début
                                            </label>
                                            <div
                                                style="height: 38px"
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor_from"
                                                    v-model="user.doctor_from"
                                                >
                                                    <Datepicker
                                                        class="h-full px-2"
                                                        v-bind="field"
                                                        v-model="
                                                            user.doctor_from
                                                        "
                                                        id="excludes"
                                                        :min-date="
                                                            user.doctor_from
                                                        "
                                                        placeholder="Date"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/3 ml-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                            >
                                                Date de fin
                                            </label>
                                            <div
                                                style="height: 38px"
                                                class="h-10 border border-slate-300 focus:border-slate-400 rounded"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor_to"
                                                    v-model="user.doctor_to"
                                                >
                                                    <Datepicker
                                                        class="h-full px-2"
                                                        v-bind="field"
                                                        v-model="user.doctor_to"
                                                        :min-date="
                                                            user.doctor_from
                                                        "
                                                        id="excludes"
                                                        placeholder="Date"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-end px-2 my-5">
                                        <button
                                            type="button"
                                            @click="
                                                $router.push({
                                                    name: 'config.staff',
                                                })
                                            "
                                            class="border-none rounded bg-danger bg-opacity-25 hover:bg-opacity-50 mx-3 px-3 font-semibold text-danger"
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            :disabled="!meta.valid"
                                            type="submit"
                                            class="border-none rounded hover:bg-opacity-50 bg-opacity-25 bg-success text-success font-semibold px-3 py-2"
                                        >
                                            Enrégistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div>
                            <!-- <pre>{{ errors }}</pre>
              <pre>{{ values }}</pre> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import Select from "../../../components/Select/index.vue";
import PhoneInput from "@/components/PhoneInput";
import * as yup from "yup";
import { useForm, Field } from "vee-validate";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import Datepicker from "@/components/Datepicker/index.vue";
import { TYPE } from "vue-toastification";

export default {
    components: { Field, Select, PhoneInput, Datepicker },
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getAllCities", {});
        store.dispatch("config/getRoles").then((res) => next());
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const hospital = ref();
        const user = ref({
            doctor_from: new Date(),
            doctor_to: null,
            gender: null,
            city: null,
            phone_2: "",
            phone_1: null,
            role: null,
        });
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const hospitals = computed(() =>
            store.getters["medical/hospitals"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );

        const options = computed(() => [
            {
                name: "Homme",
                id: "m",
            },
            {
                name: "Femme",
                id: "f",
            },
        ]);

        const schema = computed(() =>
            yup.object({
                last_name: yup.string().required(),
                first_name: yup.string().required(),
                email: yup.string().required(),
                phone_1: yup.string().required("phoneRequired"),
                role: yup.object({ id: yup.string() }).required(),
                gender: yup.object({ id: yup.string() }).required(),
                hospital: yup
                    .object({ id: yup.number() })
                    .required("hospitalRequired")
                    .typeError("hospitalRequired"),
                city: yup
                    .object({ id: yup.string() })
                    .required("cityRequired")
                    .typeError("cityRequired"),
            })
        );

        const { errors, values, meta } = useForm({
            validationSchema: schema.value,
            validateOnMount: true,
        });

        const getHospitals = () => {
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value.id },
            });
        };

        const getHospital = () => {
            if (!route.params.slug) return;
            store.dispatch("medical/getHospital", {
                slug: route.params.slug,
                onSuccess: (data) => {
                    hospital.value = { ...data };
                    user.value.hospital = { id: data.id, name: data.name };
                },
                onError: () => {},
            });
        };

        const createNewMember = () => {
            const userData = { ...user.value };
            userData.phone_1 = user.value.phone_1
                ?.replace("+", "")
                ?.replaceAll(" ", "");
            userData.phone_2 = user.value.phone_2
                ?.replace("+", "")
                ?.replaceAll(" ", "");
            userData.gender = user.value?.gender?.id;
            userData.city_id = user.value?.city?.id;
            userData.role_id = user.value?.role?.id;
            userData.medical_center_id =
                hospital.value?.id ?? user.value?.hospital?.id;
            userData.member_from = user.value.doctor_from;
            userData.member_to = user.value.doctor_to;
            userData.portfolio_id = portfolio.value?.id;
            delete userData.role;
            delete userData.city;
            store.dispatch("config/addStaffMember", {
                data: userData,
                onSuccess: () => {
                    user.value = {
                        phone_2: "",
                        phone_1: "",
                        role: null,
                    };

                    store.dispatch("medical/displayToast", {
                        message: "Membre du staff créé.",
                        context: "Staff",
                        type: TYPE.SUCCESS,
                    });
                    if (hospital.value?.id) {
                        router.push({
                            name: "config.hospitalDetails",
                            params: { slug: hospital.value?.slug },
                        });
                    } else
                        router.push({
                            name: "config.staff",
                        });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Staff",
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        onMounted(() => {
            getHospital();
            getHospitals();
        });

        watch(
            () => hospitals.value,
            (val) => {
                user.value.hospital = val.find(
                    (x) =>
                        x.id ==
                        user.value?.latest_medical_account?.medical_center_id
                );
            }
        );

        return {
            schema,
            user,
            errors,
            meta,
            options,
            values,
            hospitals,
            createNewMember,
        };
    },

    computed: {
        ...mapGetters({
            allRoles: "config/roles",
            cities: "config/allCities",
            staff: "config/users",
            portfolio: "auth/portfolio",
        }),

        roles() {
            return this.allRoles.filter(
                (x) =>
                    ![
                        "owner-super-user",
                        "administrator",
                        "patient",
                        "user",
                    ].includes(x.slug)
            );
        },
    },
};
</script>
