import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    cities: [],
    countries: [],
    allCities: [],
    roles: [],
    users: [],
    cars: [],
    modules: [],
    resourceTypes: [],
    recordTypes: [],
    allRecordTypes: [],
    allRecordSections: [],
    recordSections: [],
    resources: [],
    allResources: [],
    specialties: [],
    portfolios: [],
    portfolio: null,
    resourcePagination: {
        page: 1,
        total: 0,
    },
    portfoliosPagination: {
        page: 1,
        total: 0,
    },
    recordTypesPagination: {
        last_page: 1,
        current_page: 1,
        total: 0,
    },
    resourceTypesPagination: {
        last_page: 1,
        current_page: 1,
        total: 0,
    },
    usersPagination: {
        last_page: 1,
        current_page: 1,
        total: 0,
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
