<template>
    <div class="mb-20">
        <div class="pb-4">
            <p class="text-slate-400 font-qs">
                Vous avez le résumé de votre activité.
            </p>
        </div>
        <div class="flex justify-between">
            <div class="w-full md:w-1/2 xl:w-1/3 md:pr-2">
                <div class="bg-white rounded shadow p-2">
                    <div class="flex flex-row items-center">
                        <div
                            class="bg-[#574f7d] bg-opacity-20 w-10 h-10 rounded-full flex justify-center items-center"
                        >
                            <i class="la la-users text-2xl text-[#574f7d]"></i>
                        </div>
                        <div class="flex-1 text-right py-1">
                            <h5 class="text-[#574f7d] py-1 font-primary">
                                Nombre de patients
                            </h5>
                            <h3 class="text-[#574f7d] text-3xl font-primary">
                                {{ patients }}
                                <span class="text-green-400">
                                    <i class="fas fa-caret-down"></i>
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/2 xl:w-1/3 px-3 md:pl-2">
                <div class="bg-white rounded shadow p-2">
                    <div class="flex flex-row items-center">
                        <div
                            class="bg-[#1ac0c6] bg-opacity-20 w-10 h-10 rounded-full flex justify-center items-center"
                        >
                            <i
                                class="la la-hospital text-2xl text-[#1ac0c6]"
                            ></i>
                        </div>
                        <div class="flex-1 text-right py-1">
                            <h5 class="text-[#1ac0c6] py-1 font-primary">
                                Nombre Hôpital
                            </h5>
                            <h3 class="text-[#1ac0c6] text-3xl font-primary">
                                {{ hospitals }}
                                <span class="text-blue-400">
                                    <i class="fas fa-caret-up"></i>
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/2 xl:w-1/3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                <div class="bg-white rounded shadow p-2">
                    <div class="flex flex-row items-center">
                        <div
                            class="bg-[#facd60] bg-opacity-20 w-10 h-10 rounded-full flex justify-center items-center"
                        >
                            <i class="la la-bell text-2xl text-[#facd60]"></i>
                        </div>
                        <div class="flex-1 text-right pr-1 py-1">
                            <h5 class="text-[#facd60] py-1 font-primary">
                                Nombre d'alertes
                            </h5>
                            <h3 class="text-[#facd60] text-3xl font-primary">
                                {{ alerts }}
                                <span class="text-orange-400">
                                    <i class="fas fa-caret-up"></i>
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/2 xl:w-1/3 px-3 md:pl-2 xl:pl-3 xl:pr-2">
                <div class="bg-white rounded shadow p-2">
                    <div class="flex flex-row items-center">
                        <div
                            class="bg-[#e74645] bg-opacity-20 w-10 h-10 rounded-full flex justify-center items-center"
                        >
                            <i
                                class="la la-ambulance text-2xl text-[#e74645]"
                            ></i>
                        </div>
                        <div class="flex-1 text-right py-1">
                            <h5 class="text-[#e74645] py-1 font-primary">
                                Ambulances
                            </h5>
                            <h3 class="text-[#e74645] text-3xl font-primary">
                                {{ cars }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2 xl:pl-3 xl:pr-2">
            <div class="bg-white rounded shadow p-2">
                <div class="flex flex-row items-center">
                    <div class="bg-[#0a2f35] bg-opacity-20 w-10 h-10 rounded-full flex justify-center items-center"><i class="la la-ambulance text-2xl text-white"></i></div>
                    <div class="flex-1 text-right py-1">
                        <h5 class="text-[#0a2f35] py-1">Ambulances</h5>
                        <h3 class="text-[#0a2f35] text-3xl">76</h3>
                    </div>
                </div>
            </div>
        </div>-->
        </div>

        <div class="flex flex-row justify-between mt-5">
            <div class="w-3/5 bg-white rounded mr-5">
                <div class="py-3 border-b border-tertiary px-3">
                    <h3 class="font-normal text-md text-slate-700">
                        Analytics Interventions
                    </h3>
                </div>
                <div
                    class="flex w-full border-b border-tertiary bg-slate-50 bg-opacity-10"
                >
                    <div
                        class="w-1/4 flex flex-col justify-center items-center p-3 border-r border-tertiary font-primary"
                    >
                        <h3 class="text-2xl text-center">
                            {{ interventionData.count }}
                        </h3>
                        <p class="text-slate-400 text-center">
                            Nombre d'interventions
                        </p>
                    </div>
                    <div
                        class="w-1/4 flex flex-col justify-center items-center p-3 border-r border-tertiary font-primary"
                    >
                        <h3 class="text-2xl text-center">
                            {{ getTimeInHoursAndMins(interventionData.time) }}
                        </h3>
                        <p class="text-slate-400 text-center">
                            Moyenne temps d'intervention
                        </p>
                    </div>
                    <div
                        class="w-1/4 flex flex-col justify-center items-center p-3 border-r border-tertiary"
                    >
                        -
                        <!-- <h3 class="text-2xl text-center">
                            {{ interventionData.monthCount }}
                        </h3>
                        <p class="text-slate-400 text-center">Interventions</p> -->
                    </div>
                    <div
                        class="w-1/4 flex flex-col justify-center items-center p-3"
                    >
                        -
                        <!-- <h3 class="text-2xl text-center">
                            {{ getTimeInHoursAndMins(interventionData.monthTime) }}
                        </h3>
                        <p class="text-slate-400 text-center">Interventions</p> -->
                    </div>
                </div>

                <div class="">
                    <Chart
                        v-show="countData.length"
                        class="p-2 chart"
                        :data="countData"
                        :margin="margin"
                        :direction="direction"
                        :axis="axis"
                    >
                        <template #layers>
                            <Grid strokeDasharray="1,1" />
                            <Bar
                                strokeWidth="2"
                                :dataKeys="['name', 'interventions']"
                                :barStyle="{ fill: '#90e0ef', strokeWidth: 5 }"
                            />
                            <!-- <Bar
                                :dataKeys="['name', 'avg']"
                                :barStyle="{ fill: '#0096c7' }"
                            />
                            <Bar
                                :dataKeys="['name', 'inc']"
                                :barStyle="{ fill: 'green' }"
                            />
                            <Marker
                                :value="1000"
                                label="Avg."
                                color="#e76f51"
                                strokeWidth="2"
                                strokeDasharray="6 6"
                            /> -->
                            <Line
                                :dataKeys="['name', 'duration']"
                                type="monotone"
                            />
                            <!-- <Line
                                :dataKeys="['name', 'avg']"
                                :lineStyle="{ stroke: 'red' }"
                                type="natural"
                            />
                            <Line
                                :dataKeys="['name', 'inc']"
                                :lineStyle="{ stroke: 'green' }"
                                type="natural"
                            /> -->
                        </template>

                        <template #widgets>
                            <Tooltip
                                borderColor="#48CAE4"
                                :config="{
                                    name: { label: 'Mois', hide: false },
                                    pl: { color: '#0077b6' },
                                    avg: { label: 'averange', color: 'red' },
                                    inc: { hide: true },
                                }"
                            />
                        </template>
                    </Chart>
                    <div
                        v-if="countData.length == 0"
                        class="flex justify-center items-center py-10"
                    >
                        Aucune donnée
                    </div>
                </div>
            </div>
            <div class="w-2/5 mr-5">
                <div
                    v-if="pendingInterventions.length > 0"
                    class="bg-white rounded flex flex-col items-center justify-center mb-3"
                >
                    <div
                        v-if="
                            !loadingPInterventions &&
                            pendingInterventions.length == 0
                        "
                        class="text-danger py-10 font-primary mb-3"
                    >
                        Aucune intervention en cours
                    </div>

                    <div
                        class="w-full mb-3"
                        v-if="pendingInterventions.length > 0"
                    >
                        <div class="border-b border-slate-200">
                            <h3 class="p-3 font-roboto text-lg">
                                Interventions en cours
                            </h3>
                        </div>

                        <div class="relative max-w-full">
                            <div class="overflow-x-scroll">
                                <table
                                    class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                                >
                                    <thead
                                        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                                    >
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                Patient
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Code
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Status
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                interv, index
                                            ) in pendingInterventions"
                                            :key="interv.id"
                                            :class="`bg-white ${
                                                pendingInterventions.length >
                                                index + 1
                                                    ? 'border-b'
                                                    : ''
                                            }  border-tertiary dark:bg-gray-800 dark:border-gray-700`"
                                        >
                                            <th
                                                scope="row"
                                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                <div
                                                    class="flex justify-start items-center"
                                                >
                                                    <div
                                                        :class="`rounded w-8 h-8 bg-[url('${interv.alert?.patient?.avatar}')]`"
                                                    ></div>
                                                    <div>
                                                        <h4>
                                                            {{
                                                                interv.alert
                                                                    ?.patient
                                                                    ?.full_name
                                                            }}
                                                        </h4>
                                                        <span
                                                            class="text-slate-500 text-xs font-roboto"
                                                            >{{
                                                                interv.alert
                                                                    ?.patient
                                                                    ?.username
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </th>
                                            <td class="px-6 py-4">
                                                <div>
                                                    {{ interv.code }}
                                                    <span
                                                        class="text-warning text-xs font-roboto"
                                                        >{{
                                                            interv.alert
                                                                ?.identifier
                                                        }}</span
                                                    >
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <span
                                                    class="text-danger font-roboto"
                                                >
                                                    {{
                                                        statusesText[
                                                            interv.status
                                                        ]
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="bg-white rounded mb-3"
                    v-if="agentsInInterventions.length"
                >
                    <div
                        class="flex justify-between items-center border-b border-slate-200"
                    >
                        <h3 class="p-3 font-roboto text-lg">
                            Agents en interventions
                        </h3>
                        <i class="la la-users text-xl mx-2"></i>
                    </div>
                    <div
                        class="flex px-2 items-center justify-between mb-2 mt-1 py-2"
                        v-for="agent in agentsInInterventions"
                        :key="agent.id"
                    >
                        <div class="flex">
                            <div
                                :class="`rounded w-10 h-10 bg-[url('${agent.avatar}')]`"
                            ></div>
                            <div class="pt-1 px-1">
                                <h4 class="">{{ agent.full_name }}</h4>
                                <p class="text-slate-400">
                                    {{
                                        agent.roles
                                            .map((x) => x.name)
                                            .join(", ")
                                    }}
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center ml-3">
                            <div
                                class="flex flex-col justify-start items-center"
                            >
                                <h4 class="text-primary text-sm font-qs">
                                    {{ agent.intervention.code }}
                                </h4>
                                <span
                                    class="text-slate-500 font-roboto text-xs text-start"
                                >
                                    {{ agent.intervention?.ambulance?.model }} /
                                    {{
                                        agent.intervention?.ambulance
                                            ?.registration
                                    }}
                                    / {{ agent.intervention?.ambulance?.code }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white rounded py-2">
                    <div
                        class="flex px-2 items-center mb-2"
                        v-for="agent in agents"
                        :key="agent.id"
                    >
                        <div class="flex w-4/5">
                            <div
                                :class="`rounded w-10 h-10 bg-[url('${agent.avatar}')]`"
                            ></div>
                            <div class="pt-1 px-1">
                                <h4 class="">{{ agent.full_name }}</h4>
                                <p class="text-slate-400">
                                    {{
                                        agent.roles
                                            .map((x) => x.name)
                                            .join(", ")
                                    }}
                                </p>
                            </div>
                        </div>

                        <div class="w-1/5 flex justify-between items-center">
                            <div class="w-1/2 flex items-center">
                                <p class="text-danger text-lg font-primary">
                                    {{ agent.interventions_count }}
                                </p>
                                <i class="la la- text-xl text-warning"></i>
                            </div>
                            <div class="w-1/2 flex items-center">
                                <p class="text-warning text-lg font-primary">
                                    {{ agent.alerts_count }}
                                </p>
                                <i class="la la-bell text-xl text-warning"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Chart, Grid, Line, Bar, Marker, Tooltip } from "vue3-charts";
export default defineComponent({
    components: { Chart, Grid, Line, Marker, Bar, Tooltip },
    setup() {
        const statusesText = computed(() => ({
            pending: "En cours (départ)",
            picked_up: "Patient récupéré",
            arrived: "Arrivée chez le Patient",
            intervention_started: "En cours (démarré)",
        }));
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const user = computed(() => store.getters["auth/user"]);
        const alerts = ref(0);
        const hoursText = ref("hr");
        const minsText = ref("mins");
        const patients = ref(0);
        const hospitals = ref(0);
        const cars = ref(0);
        const loadingPInterventions = ref(false);
        const pendingInterventions = ref([]);
        const agentsInInterventions = ref([]);

        const countData = ref([]);
        const agents = ref([]);
        const globalDurationAvg = ref(0);
        const interventionData = ref({
            monthCount: 0,
            count: 0,
            time: 0,
            monthTime: 0,
        });
        const store = useStore();

        const direction = ref("horizontal");
        const margin = ref({
            left: 0,
            top: 20,
            right: 20,
            bottom: 0,
        });

        const axis = ref({
            primary: {
                type: "band",
                format: (val) => {
                    return val;
                },
            },
            secondary: {
                domain: ["dataMin", "dataMax + 100"],
                type: "linear",
                ticks: 2,
            },
        });

        const countAlert = () => {
            store.dispatch("medical/countAlerts", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    alerts.value = data;
                },
                onError: () => {},
            });
        };

        const countHospitals = () => {
            store.dispatch("medical/countHospitals", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    hospitals.value = data;
                },
                onError: () => {},
            });
        };

        const countCars = () => {
            store.dispatch("medical/countCars", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    cars.value = data;
                },
                onError: () => {},
            });
        };

        const countPatients = () => {
            store.dispatch("medical/countPatients", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    patients.value = data;
                },
                onError: () => {},
            });
        };

        const getInterventionsData = () => {
            store.dispatch("medical/getInterventionsData", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    console.log("dededede", data);
                    interventionData.value = {
                        time: data.time,
                        count: data.count,
                        monthCount: data.monthCount,
                        monthTime: data.monthTime,
                    };
                },
                onError: () => {},
            });
        };

        const getInterventionsAnalytics = () => {
            store.dispatch("medical/getInterventionsAnalytics", {
                portfolioId: portfolio.value?.id,
                onSuccess: (data) => {
                    countData.value = [];
                    console.log(data);
                    for (const key in data.count) {
                        countData.value.push({
                            name: key,
                            interventions: data.count[key],
                            duration: data.timeAvg[key],
                        });
                    }

                    globalDurationAvg.value = data.globalDurationAvg;
                },
                onError: () => {},
            });
        };

        const getStaffAnalytics = () => {
            store.dispatch("medical/getStaffAnalytics", {
                portfolioId: portfolio.value?.id,
                onSuccess: (result) => {
                    agents.value = [...result.agents];
                },
                onError: () => {},
            });
        };

        const getPendingInterventions = () => {
            store.dispatch("medical/getPendingInterventions", {
                portfolioId: portfolio.value?.id,
                onSuccess: (result) => {
                    pendingInterventions.value = [...result];
                    agentsInInterventions.value = [
                        ...result.flatMap((x) =>
                            [...x.agents].map((a) => ({
                                ...a,
                                intervention: x,
                            }))
                        ),
                    ];

                    loadingPInterventions.value = false;
                },
                onError: () => {
                    loadingPInterventions.value = false;
                },
            });
        };

        const getTimeInHoursAndMins = (timeInsSeconds) => {
            if (timeInsSeconds && timeInsSeconds > 0) {
                const minsTemp = timeInsSeconds / 60;
                let hours = Math.floor(minsTemp / 60);
                const mins = minsTemp % 60;

                if (hours !== 0 && mins !== 0) {
                    if (mins >= 59) {
                        hours += 1;
                        return +`${hours} ${hoursText.value} `;
                    } else {
                        return `${hours} ${hoursText.value} ${mins.toFixed(
                            0
                        )} ${minsText.value || 0}`;
                    }
                } else if (hours === 0 && mins !== 0) {
                    return `${mins.toFixed(0)} ${minsText.value}`;
                } else if (hours !== 0 && mins === 0) {
                    return `${hours} ${hoursText.value}`;
                }
            } else {
                return "-";
            }
        };
        onMounted(() => {
            loadingPInterventions.value = true;
            getPendingInterventions();
            getStaffAnalytics();
            countAlert();
            countPatients();
            countCars();
            countHospitals();
            getInterventionsData();
            getInterventionsAnalytics();
        });

        return {
            statusesText,
            loadingPInterventions,
            direction,
            margin,
            pendingInterventions,
            globalDurationAvg,
            countData,
            axis,
            agents,
            agentsInInterventions,
            interventionData,
            getTimeInHoursAndMins,
            alerts,
            patients,
            hospitals,
            cars,
            user,
        };
    },
});
</script>

<style lang="scss">
.chart {
    svg {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
