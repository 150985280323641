<template>
    <div class="mt-5">
        <div class="flex justify-end items-center">
            <Button
                v-if="isAdmin"
                label="Envoyer le mail"
                class="bg-primary3 text-sm shadow-primary3 py-2 px-3 text-white font-normal mr-2"
                @click="sendDocumentsMail"
            />
            <Button
                class="border border-primary3 text-sm px-3 py-2 rounded text-primary3 shadow-primary"
                size="sm"
                label=" Ajouter des documents"
                @click="showDocsUpload()"
            >
            </Button>
        </div>
        <div
            class="table-responsive bg-white p-2 mt-4"
            v-if="!isLoading && documents.length"
        >
            <table striped outlined class="table table-striped" hover>
                <thead>
                    <tr>
                        <th class="whitespace-nowrap font-medium text-sm">
                            Identifiant
                        </th>
                        <th class="whitespace-nowrap font-medium text-sm">
                            Type
                        </th>
                        <th class="whitespace-nowrap font-medium text-sm">
                            Status
                        </th>
                        <th class="whitespace-nowrap font-medium text-sm">
                            Date
                        </th>
                        <th class="whitespace-nowrap"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="document in documents" :key="document.id">
                        <td>
                            <span
                                class="uppercase text-sm font-medium mr-2 px-2.5 py-1.5 rounded dark:bg-pink-200 dark:text-pink-900"
                            >
                                {{ document.identifier }}
                            </span>
                        </td>

                        <td class="text-sm font-medium">
                            {{ types[document.type] }}
                        </td>

                        <td class="text-sm font-medium">
                            <span
                                :class="`
                            text-${status[document.status]}
                           text-xs
                            font-semibold
                            mr-2
                            px-2.5
                            py-1.5
                            rounded
                            dark:bg-pink-200 dark:text-pink-900`"
                            >
                                {{ statusText[document.status] }}
                            </span>
                        </td>
                        <td>{{ moment(document.created_at).format("LLL") }}</td>
                        <td>
                            <div class="flex justify-end">
                                <a
                                    v-if="
                                        document.url.split('.').includes('pdf')
                                    "
                                    :href="`https://allodoto.com${document.url}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="border border-slate-400 px-2 py-1 text-sm rounded text-slate-400"
                                >
                                    <i class="las la-eye m-0"></i
                                ></a>
                                <button
                                    v-else
                                    class="border border-slate-400 px-2 py-1 text-sm rounded text-slate-400"
                                    size="sm"
                                    @click="showDocument(document)"
                                >
                                    <i class="las la-eye m-0"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            v-else-if="!isLoading && documents.length == 0"
            class="flex justify-center"
        >
            <p>Aucun document envoyé</p>
        </div>
    </div>
    <Dialog
        v-model:visible="displayDocument"
        modal
        :header="currentDoc?.original_name"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="w-full h-full">
            <img :src="fileUrl" alt="" class="w-full h-96" />
        </div>

        <div class="mt-4 mb-2 flex justify-end items-center">
            <Button
                label="Rejeter"
                icon="pi pi-times"
                class="px-2 py-2 text-medium mr-2 text-white bg-danger shadow-danger"
                @click="setStatus({ status: 'rejected' })"
                text
            />
            <Button
                label="Accepter"
                class="bg-success shadow-success py-2 px-3 text-white font-normal"
                @click="setStatus({ status: 'validated' })"
            />
        </div>
    </Dialog>
    <Dialog
        v-model:visible="displayNewDocs"
        modal
        header=""
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
        <div class="w-full h-full">
            <div class="mt-4 mb-2 flex justify-end items-center">
                <FileUpload
                    mode="basic"
                    name="demo[]"
                    class="bg-primary"
                    chooseLabel="Nouveau "
                    :multiple="true"
                    customUpload
                    @select="onFilesChange"
                />
            </div>
            <div
                v-for="(file, i) in loadedFiles"
                :key="i"
                class="px-2 py-1 rounded w-full mb-2 flex bg-slate-100 justify-between items-center"
            >
                <div class="w-7/12">{{ file.file.name }}</div>
                <div class="w-4/12">
                    <Field v-slot="{}" v-model="file.type" name="type">
                        <Dropdown
                            v-model="file.type"
                            :options="docTypes"
                            optionLabel="name"
                            placeholder="Sélectionner un type"
                            :inputStyle="{ paddingTop: '8px' }"
                            class="rounded shadow-none border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            @update:modelValue="file.type = $event"
                        />
                    </Field>
                </div>
                <div class="w-2/12 flex justify-end">
                    <button
                        class="p-3 rounded text-sm"
                        @click="handleRemove(i)"
                    >
                        <i class="la la-times text-danger"></i>
                    </button>
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                @click="displayNewDocs = false"
                label="Fermer"
                icon="pi pi-times"
                class="px-2 py-2 text-medium mr-2 text-danger shadow-none"
                text
            />
            <Button
                @click="saveFiles"
                :disabled="typesDefined"
                label="Valider"
                class="bg-success shadow-none py-2 px-3 text-white font-normal"
            />
        </template>
    </Dialog>
    <Toast />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import { Field } from "vee-validate";
import { useAcl } from "vue-simple-acl";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import moment from "moment";
export default {
    components: { Dialog, Toast, Button, Dropdown, FileUpload, Field },
    setup() {
        const acl = useAcl();
        const toast = useToast();
        const isAdmin = computed(() => acl.can("is-admin"));
        const displayDocument = ref(false);
        const documents = ref([]);
        const loadedFiles = ref([]);
        const pagination = ref({});
        const store = useStore();
        const route = useRoute();
        const files = ref();
        const displayNewDocs = ref(false);
        const isLoading = ref(false);
        const fileUrl = ref();
        const currentDoc = ref();
        const status = ref({
            pending: "warning",
            validated: "success",
            rejected: "danger",
        });

        const types = ref({
            IDENTITY: "Pièce d'identité",
            ESTABLISHMENT_FRONT: "Devanture",
            ESTABLISHMENT_INSIDE: "Intérieur",
            ACTIVITY: "Preuve d'exercice d'activité",
        });

        const docTypes = ref([
            { id: "IDENTITY", name: "Pièce d'identité" },
            { id: "ESTABLISHMENT_FRONT", name: "Photo de la devanture" },
            { id: "ESTABLISHMENT_INSIDE", name: "Photo de l'accueil" },
            { id: "ACTIVITY", name: "Preuve d'exercice d'activité" },
        ]);
        const statusText = ref({
            pending: "En attente de validation",
            validated: "Validé",
            rejected: "Refusé",
        });
        const documentType = computed(
            () => fileUrl.value.split(".")[fileUrl.value.split(".").length - 1]
        );
        const typesDefined = computed(
            () =>
                loadedFiles.value.length > 0 &&
                loadedFiles.value.some((x) => x.type?.id == "")
        );
        const showDocument = (doc) => {
            fileUrl.value = `${window.location.origin}${doc.url}`;

            currentDoc.value = { ...(doc ?? {}) };
            displayDocument.value = true;
        };
        const getMedicalCenterDocuments = () => {
            isLoading.value = true;
            store.dispatch("medical/getMedicalCenterDocuments", {
                medicalCenterId: route.params.slug,
                onSuccess: (response) => {
                    documents.value = response?.data || [];
                    pagination.value = {
                        total: response.total,
                        currentPage: response.current_page,
                        lastPage: response.last_page,
                    };

                    isLoading.value = false;
                },
                onError: (error) => {
                    console.log("error");
                    isLoading.value = false;
                },
            });
        };
        const handleRemove = (i) => {
            loadedFiles.value.splice(i, 1);
        };
        const sendDocumentsMail = () => {
            store.dispatch("medical/sendDocumentsMailUpdate", {
                medicalCenterId: route.params.slug,
                onSuccess: (response) => {
                    toast.add({
                        severity: "success",
                        summary: "Mail récapitulatif",
                        detail: "Mail envoyé",
                        life: 3000,
                    });
                },
                onError: (error) => {
                    console.log("error");

                    toast.add({
                        severity: "error",
                        summary: "Mail récapitulatif",
                        detail: "Oooops, une erreur est sruvenue",
                        life: 3000,
                    });
                },
            });
        };
        const setStatus = (data) => {
            store.dispatch("medical/updateMedicalCenterDocumentStatus", {
                medicalCenterId: currentDoc.value.documentable_id,
                documentId: currentDoc.value.id,
                data,
                onSuccess: (response) => {
                    currentDoc.value = null;
                    getMedicalCenterDocuments();
                    displayDocument.value = false;
                },
                onError: (error) => {
                    console.log("error");
                },
            });
        };

        const onFilesChange = (e) => {
            (e?.files ?? []).forEach((element) => {
                loadedFiles.value.push({ file: element, type: "" });
            });
        };

        const saveFiles = () => {
            const formData = new FormData();

            loadedFiles.value.forEach((el) => {
                formData.append("files[][file]", el.file); // check this out for more info: https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
                formData.append("files[][type]", el.type?.id);
            });

            store.dispatch("medical/addMedicalCenterDocuments", {
                medicalCenterId: route.params.slug,
                data: formData,
                onSuccess: (response) => {
                    currentDoc.value = null;
                    getMedicalCenterDocuments();
                    displayNewDocs.value = false;
                },
                onError: (error) => {
                    console.log("error");
                },
            });
        };

        const showDocsUpload = () => {
            displayNewDocs.value = true;
        };

        onMounted(() => {
            getMedicalCenterDocuments();
        });

        return {
            documents,
            types,
            files,
            displayDocument,
            status,
            displayNewDocs,
            statusText,
            onFilesChange,
            sendDocumentsMail,
            pagination,
            currentDoc,
            fileUrl,
            moment,
            isLoading,
            isAdmin,
            loadedFiles,
            docTypes,
            typesDefined,
            setStatus,
            handleRemove,
            showDocsUpload,
            documentType,
            saveFiles,
            showDocument,
        };
    },
};
</script>
