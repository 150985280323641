<template>
    <div class="w-full font-ibm">
        <div>
            <div md="12">
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5">
                    <div class="flex justify-between mb-3">
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Les Ressources
                            </h4>
                        </div>

                        <button
                            class="flex justify-center text-sm items-center border-none font-semibold rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                            @click.prevent="openModal"
                        >
                            <icon name="plus"></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div>
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    v-if="resourcesList.length > 0"
                                    striped
                                    outlined
                                    class="table table-striped"
                                    thead-class="text-md font-normal"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Name
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Quantité
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Type
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Description
                                            </th>
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="rType in resourcesList"
                                            :key="rType.id"
                                        >
                                            <td class="text-sm text-slate-700">
                                                {{ rType.name }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.quantity }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.type?.name || "-" }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.description }}
                                            </td>
                                            <td>
                                                <div class="flex justify-end">
                                                    <button
                                                        class="bg-warning text-sm bg-opacity-25 mr-2 px-2 py-1 rounded text-warning"
                                                        size="sm"
                                                        @click="
                                                            handleTypeSelection(
                                                                rType
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="bg-danger text-sm bg-opacity-10 mr-1 px-2 py-1 rounded text-danger"
                                                        @click="
                                                            handleRemoveResource(
                                                                rType
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div
                                    v-else
                                    class="h-96 flex justify-center items-center"
                                >
                                    <p class="text-md text-primary">
                                        Aucune ressource enrégistrée
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-5 flex justify-end font-ibm text-sm">
                    <pagination
                        v-model="pagination.current_page"
                        :pagination="pagination"
                        @changed="getData"
                    ></pagination>
                </div>
            </div>
        </div>
        <SampleModal
            :is-visible="showResourceModal"
            @close="closeResourceModal"
            @cancel="closeResourceModal"
            @submit="isEditing ? handleEdit() : handleCreate()"
            centered
            id="new-car"
            :title="
                isEditing
                    ? 'Mise à jour d\'une ressource'
                    : 'Nouvelle ressource'
            "
        >
            <div
                :class="`my-2 w-full p-3 relative ${
                    isEditing ? '' : 'border border-slate-300'
                }`"
                v-for="(resource, index) in resources"
                :key="index"
            >
                <div
                    class="absolute top-0 right-0 p-2"
                    v-if="resources.length > 1"
                >
                    <button
                        class="ml-4 rounded bg-danger text-white px-2 py-1"
                        type="submit"
                        @click="removeElement(index)"
                    >
                        <i class="la la-trash text-lg"></i>
                    </button>
                </div>
                <div class="mb-2 w-full pr-2">
                    <label
                        class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                        for="grid-state"
                    >
                        Nom de la ressource
                    </label>
                    <div class="h-10">
                        <Field
                            v-slot="{ field }"
                            :name="`resources[${index}][name]`"
                            v-model="resource.name"
                        >
                            <input
                                type="text"
                                id="name"
                                v-bind="field"
                                placeholder="Entre le nom de la ressource"
                                class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                        /></Field>
                    </div>
                </div>
                <div class="w-full flex mb-2">
                    <div class="w-1/3">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="grid-last-name-1"
                            >Quantité</label
                        >
                        <div class="w-full">
                            <div class="w-full h-10">
                                <Field
                                    v-slot="{ field }"
                                    :name="`resources[${index}][quantity]`"
                                    v-model="resource.quantity"
                                >
                                    <input
                                        v-bind="field"
                                        :name="`resources[${index}][quantity]`"
                                        v-model="resource.quantity"
                                        type="number"
                                        id="quantity"
                                        class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>
                    </div>

                    <div class="w-2/3 pl-2 mr-2">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs mb-1 font-bold"
                            for="grid-state"
                        >
                            Type
                        </label>
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                :name="`resources[${index}][type]`"
                                v-model="resource.type"
                            >
                                <Select
                                    class="h-full"
                                    v-bind="field"
                                    :name="`resources[${index}][type]`"
                                    v-model="resource.type"
                                    :options="types"
                                    @selected="
                                        ($event) => (resource.type = $event)
                                    "
                                />
                            </Field>
                        </div>
                    </div>
                </div>
                <div class="mb-2 mr-2">
                    <label
                        class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                        for="grid-state"
                    >
                        Description
                    </label>
                    <Field
                        v-slot="{ field }"
                        :name="`resources[${index}][description]`"
                        v-model="resource.description"
                    >
                        <textarea
                            v-bind="field"
                            v-model="resource.description"
                            :name="`resources[${index}][description]`"
                            no-resize
                            class="w-full bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            rows="5"
                        ></textarea>
                    </Field>
                </div>
            </div>
            <div></div>
            <template #buttons>
                <button
                    v-if="!isEditing"
                    class="ml-4 rounded bg-warning bg-opacity-10 text-warning px-3 py-2"
                    type="submit"
                    @click="handleNew"
                >
                    Ajouter
                </button>
            </template>
        </SampleModal>
    </div>
    <popup
        message="Êtes-vous sure de vouloir supprimer ?"
        :isVisible="canDelete"
        @submit="validateRemoval"
        @cancel="cancelDeletion"
        @close="cancelDeletion"
    ></popup>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import Select from "@/components/Select/index.vue";
import { Field } from "vee-validate";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
export default {
    components: { Select, Field, SampleModal, ConfirmDialog },
    setup() {
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const canDelete = ref(false);
        const confirm = useConfirm();
        const selected = ref(null);
        const showResourceModal = ref(false);
        const resources = ref([]);
        const store = useStore();
        const isEditing = ref(false);
        const pagination = computed(
            () => store.getters["config/resourcePagination"]
        );
        const types = ref([]);

        const removeResource = (resource) => {
            selected.value = { ...resource };
            canDelete.value = true;
        };
        const cancelDeletion = () => {
            canDelete.value = false;

            selected.value = null;
        };

        const getData = (page = 1) => {
            store.dispatch("config/getResources", {
                portfolioId: portfolio.value.id,
                page,
            });
        };
        const closeResourceModal = () => {
            showResourceModal.value = false;
            resources.value = [];
        };
        const handleNew = () => {
            resources.value.push({
                name: "",
                quantity: 0,
                description: "",
                remaining_quantity: 0,
                type: "",
            });
        };
        const openModal = () => {
            isEditing.value = false;
            resources.value = [
                {
                    name: "",
                    quantity: 0,
                    description: "",
                    remaining_quantity: 0,
                    type: "",
                },
            ];
            showResourceModal.value = true;
        };
        const handleTypeSelection = (data) => {
            showResourceModal.value = true;
            const value = [
                {
                    name: data.name,
                    id: data.id,
                    quantity: data.remaining_quantity,
                    description: data.description,
                    remaining_quantity: data.remaining_quantity,
                    type: data.type,
                },
            ];
            resources.value = value;
            // currentTypeId.value = data.id;
            // replace(value);
            // form.value = value;
            // // types.value = value;
            isEditing.value = true;
            // showNewModal.value = true;
        };
        const removeElement = (index) => {
            resources.value = resources.value.filter((x, i) => i !== index);
        };
        const getAlltypes = () => {
            store.dispatch("config/getAllResourceTypes", {
                onSuccess: (list) => {
                    types.value = [...list];
                },
            });
        };

        const handleCreate = () => {
            const data = [...resources.value].map((x) => {
                const value = {
                    ...x,
                    type_id: x.type.id,
                    portfolio_id: portfolio.value.id,
                };
                delete value.remaining_quantity;
                delete value.type;
                return value;
            });
            store.dispatch("config/addResources", {
                resources: data,
                onSuccess: () => {
                    closeResourceModal();
                },
                onError: () => {},
            });
        };

        const handleEdit = () => {
            const [data] = resources.value;
            const formData = {
                ...data,
                type_id: data.type?.id,
                portfolio_id: portfolio.value.id,
            };
            store.dispatch("config/editResource", {
                portfolioId: portfolio.value.id,
                id: data.id,
                data: formData,
                onSuccess: () => {
                    closeResourceModal();
                },
                onError: () => {},
            });
        };

        const validateRemoval = () => {
            store.dispatch("config/removeResource", {
                portfolioId: portfolio.value.id,
                id: selected.value.id,
                onSuccess: () => {
                    canDelete.value = false;
                    selected.value = null;
                },
                onError: () => {},
            });
        };

        const handleRemoveResource = (resource) => {
            selected.value = { ...resource };

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer cette ressource ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    validateRemoval();
                },
                reject: () => {},
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                getData();
            }
        );

        onMounted(() => {
            getData();
            getAlltypes();
        });
        return {
            showResourceModal,
            pagination,
            resources,
            types,
            canDelete,
            isEditing,
            handleEdit,
            removeResource,
            openModal,
            cancelDeletion,
            validateRemoval,
            handleCreate,
            removeElement,
            handleNew,
            handleRemoveResource,
            closeResourceModal,
            handleTypeSelection,
            getData,
        };
    },

    computed: {
        ...mapGetters({
            resourceTypes: "config/resourceTypes",
            resourcesList: "config/resources",
        }),
    },
    mounted() {},
};
</script>
