<template>
    <div class="w-full">
        <div class="w-full">
            <div class="w-full">
                <div
                    class="rounded-sm bg-white flex flex-col p-5 mb-5 w-full overflow-y-scroll"
                >
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4 class="card-title font-ibm font-semibold">
                                Les catégories
                            </h4>
                        </div>
                        <button
                            @click="showCatModal = true"
                            class="flex justify-center font-semibold items-center border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter une categorie
                        </button>
                    </div>
                    <div v-if="categories.length > 0">
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped font-ibm"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Name
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Parent
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Section
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Color
                                            </th>

                                            <!-- <th class="whitespace-nowrap">Date de création</th> -->
                                            <!-- <th class="whitespace-nowrap">Dernière conn.</th> -->
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="(
                                                category, index
                                            ) in categories"
                                            :key="index"
                                        >
                                            <td>
                                                <p>{{ category.name }}</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {{
                                                        category?.parent
                                                            ?.name ?? "-"
                                                    }}
                                                </p>
                                            </td>
                                            <td class="font-ibm">
                                                {{ category.menu_relative }}
                                            </td>
                                            <td class="font-ibm">
                                                <div
                                                    :style="`background-color:${category.color}`"
                                                    :class="`w-10 h-5 rounded`"
                                                ></div>
                                            </td>

                                            <td>
                                                <div
                                                    class="flex justify-end font-ibm"
                                                >
                                                    <button
                                                        class="bg-warning bg-opacity-25 mr-1 px-2 py-1 rounded text-warning"
                                                        size="sm"
                                                        @click="
                                                            startCategoryEdition(
                                                                category
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="bg-primary bg-opacity-25 mr-1 px-2 py-1 rounded text-primary"
                                                        size="sm"
                                                        @click="
                                                            addSubCategory(
                                                                category
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-sitemap m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="px-2 py-1 bg-danger bg-opacity-25 rounded text-danger"
                                                        size="sm"
                                                        @click="
                                                            handleCategoryRemove(
                                                                category
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="isLoading"
                        class="flex h-full justify-center items-center"
                    >
                        <Loader />
                    </div>

                    <div v-else class="p-3 h-full">
                        <div
                            class="flex flex-col justify-center items-center h-full"
                        >
                            <img class="w-1/4" src="/images/no-result.svg" />
                            <h4 class="text-primary font-medium my-3 text-md">
                                Aucune catégorie
                            </h4>
                        </div>
                    </div>
                </div>

                <div
                    class="rounded-sm bg-white flex flex-col p-5 mb-5 mt-5 w-full overflow-y-scroll"
                >
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4 class="card-title font-ibm font-semibold">
                                Les tags
                            </h4>
                        </div>
                        <button
                            @click="showTagModal = true"
                            class="flex justify-center font-semibold items-center border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-200 text-doto-200 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un tag
                        </button>
                    </div>
                    <div v-if="tags.length > 0">
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped font-ibm"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Name
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Categorie
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Section
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-ibm"
                                            >
                                                Color
                                            </th>

                                            <!-- <th class="whitespace-nowrap">Date de création</th> -->
                                            <!-- <th class="whitespace-nowrap">Dernière conn.</th> -->
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="(tag, index) in tags"
                                            :key="index"
                                        >
                                            <td>
                                                <p>{{ tag.name }}</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {{
                                                        tag?.category?.name ??
                                                        "-"
                                                    }}
                                                </p>
                                            </td>
                                            <td class="font-ibm">
                                                {{ tag.menu_relative }}
                                            </td>
                                            <td class="font-ibm">
                                                <div
                                                    :style="`background-color:${tag.color}`"
                                                    :class="`w-10 h-5 rounded`"
                                                ></div>
                                            </td>

                                            <td>
                                                <div
                                                    class="flex justify-end font-ibm"
                                                >
                                                    <button
                                                        class="bg-warning bg-opacity-25 mr-1 px-2 py-1 rounded text-warning"
                                                        size="sm"
                                                        @click="
                                                            startTagEdition(tag)
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen m-0"
                                                        ></i>
                                                    </button>

                                                    <button
                                                        class="px-2 py-1 bg-danger bg-opacity-25 rounded text-danger"
                                                        size="sm"
                                                        @click="
                                                            handleTagRemove(tag)
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="isLoadingTags"
                        class="flex h-full justify-center items-center"
                    >
                        <Loader />
                    </div>

                    <div v-else class="p-3 h-full">
                        <div
                            class="flex flex-col justify-center items-center h-full"
                        >
                            <img class="w-1/4" src="/images/no-result.svg" />
                            <h4 class="text-primary font-medium my-3 text-md">
                                Aucun tag créé
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            title="Suppression"
            width="w-2/5"
            :isVisible="canDeleteCategory"
            @cancel="cancelCategoryDeletion"
            @submit="removeCategory"
        >
            <div>
                <p class="font-qs text-md">Cette action est définitive.</p>
                <h3 class="text-danger font-ibm text-md font-semibold py-2">
                    Êtes-vous sûre de vouloir continuer ?
                </h3>
            </div>
        </Modal>

        <Modal
            title="Suppression"
            width="w-2/5"
            :isVisible="canDeleteTag"
            @cancel="cancelTagDeletion"
            @submit="removeTag"
        >
            <div>
                <p class="font-qs text-md">Cette action est définitive.</p>
                <h3 class="text-danger font-ibm text-md font-semibold py-2">
                    Êtes-vous sûre de vouloir continuer ?
                </h3>
            </div>
        </Modal>

        <SampleModal
            :is-visible="showCatModal"
            @close="closeCatModal"
            @cancel="closeCategoryModal"
            @submit="
                isEditingCategory ? handleCategoryEdition() : handleCreate()
            "
            width="w-1/3"
            centered
            id="new-category"
            :title="
                isEditingCategory
                    ? 'Mise à jour d\'une catégorie'
                    : `Nouvelle ${
                          newCategory.parent_id ? 'sous-' : ''
                      }catégorie`
            "
        >
            <div class="mb-2 w-full pr-2">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Nom
                </label>
                <div class="h-10">
                    <Field
                        v-slot="{ field }"
                        name="name"
                        v-model="newCategory.name"
                    >
                        <input
                            type="text"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                            placeholder="Entre le nom de la catégorie"
                            class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                    /></Field>
                </div>
            </div>

            <div class="mb-2 w-full pr-2">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Section Menu (slug)
                </label>
                <div class="h-10">
                    <Field
                        v-slot="{ field }"
                        name="name"
                        v-model="newCategory.menu_relative"
                    >
                        <input
                            type="text"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                            placeholder="Ex: menu"
                            class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                    /></Field>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full pr-2">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Couleur
                </label>
                <div class="h-10">
                    <Field
                        v-slot="{ field }"
                        name="name"
                        v-model="newCategory.color"
                    >
                        <input
                            type="color"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                    /></Field>
                </div>
            </div>

            <div></div>
            <template #buttons> </template>
        </SampleModal>

        <SampleModal
            :is-visible="showTagModal"
            @close="closeTagModal"
            @cancel="closeTagModal"
            @submit="isEditingTag ? handleTagEdition() : handleCreateTag()"
            width="w-1/3"
            centered
            id="new-tag"
            :title="isEditingTag ? 'Mise à jour d\'un tag' : `Nouvelle tag`"
        >
            <div class="mb-2 w-full">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Nom
                </label>
                <div class="h-10">
                    <Field v-slot="{ field }" name="name" v-model="newTag.name">
                        <input
                            type="text"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                            placeholder="Entre le nom de la catégorie"
                            class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                    /></Field>
                </div>
            </div>
            <div class="w-full mb-2 flex flex-col">
                <label
                    class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                    for="grid-state"
                >
                    Catégorie
                </label>
                <div class="">
                    <Field
                        v-slot="{ field }"
                        name="type"
                        v-model="newTag.category"
                    >
                        <Select
                            v-bind="field"
                            name="type"
                            v-model="newTag.category"
                            :options="categories"
                            @changed="(value) => (newTag.category = value)"
                        >
                        </Select>
                    </Field>
                </div>
            </div>

            <div class="mb-2 w-full">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Section Menu (slug)
                </label>
                <div class="h-10">
                    <Field
                        v-slot="{ field }"
                        name="name"
                        v-model="newTag.menu_relative"
                    >
                        <input
                            type="text"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                            placeholder="Ex: menu"
                            class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                    /></Field>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full pr-2">
                <label
                    class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                    for="grid-state"
                >
                    Couleur
                </label>
                <div class="h-10">
                    <Field
                        v-slot="{ field }"
                        name="name"
                        v-model="newTag.color"
                    >
                        <input
                            type="color"
                            id="name"
                            autocomplete="off"
                            v-bind="field"
                    /></Field>
                </div>
            </div>

            <div></div>
            <template #buttons> </template>
        </SampleModal>
    </div>
    <Toast />
</template>

<script>
import { useStore } from "vuex";
import moment from "moment";
import {
    ref,
    computed,
    onBeforeMount,
    onMounted,
    onBeforeUnmount,
    watch,
} from "vue";
import Loader from "@/components/Loader/index.vue";
import Modal from "@/components/Modal/SampleModal.vue";
import Select from "@/components/Select/index.vue";
import { Field } from "vee-validate";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
export default {
    components: { Loader, Modal, SampleModal, Field, Toast, Select },

    setup() {
        const toast = useToast();
        const showCatModal = ref(false);
        const showTagModal = ref(false);
        const canDeleteTag = ref(false);
        const canDeleteCategory = ref(false);
        const isEditingCategory = ref(false);
        const isEditingTag = ref(false);
        const canDelete = ref(false);
        const isLoadingTags = ref(false);
        const currentCategoryId = ref(null);
        const currentTagId = ref(null);
        const store = useStore();
        const categories = ref([]);
        const tags = ref([]);

        const newCategory = ref({ color: "#ffffff" });
        const newTag = ref({ color: "#ffffff" });

        const closeCategoryModal = () => {
            showCatModal.value = false;
        };

        const closeTagModal = () => {
            showTagModal.value = false;
        };

        const startCategoryEdition = (data) => {
            isEditingCategory.value = true;
            newCategory.value = { ...data };
            showCatModal.value = true;
        };

        const startTagEdition = (data) => {
            isEditingTag.value = true;
            newTag.value = { ...data };
            showTagModal.value = true;
        };

        const handleCategoryRemove = (data) => {
            currentCategoryId.value = data.id;
            canDeleteCategory.value = true;
        };

        const handleTagRemove = (data) => {
            currentCategoryId.value = data.id;
            canDeleteTag.value = true;
        };

        const cancelCategoryDeletion = () => {
            currentCategoryId.value = null;
            canDeleteCategory.value = false;
        };

        const cancelTagDeletion = () => {
            currentCategoryId.value = null;
            canDeleteTag.value = false;
        };

        const addSubCategory = (data) => {
            isEditingCategory.value = false;
            newCategory.value = { color: "#ffffff", parent_id: data.id };
            showCatModal.value = true;
        };

        const closeCatModal = () => {
            showCatModal.value = false;
            newCategory.value = { color: "#ffffff" };
        };

        const removeCategory = () => {
            store.dispatch("blog/deleteCategory", {
                categoryId: currentCategoryId.value,
                onSuccess: () => {
                    cancelCategoryDeletion();

                    get();
                },
                onError: () => {},
            });
        };

        const removeTag = () => {
            store.dispatch("blog/deleteTag", {
                tagId: currentCategoryId.value,
                onSuccess: () => {
                    cancelTagDeletion();
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "Le tag supprimé",
                        life: 3000,
                    });
                    getTags();
                },
                onError: () => {
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "Ooops, une erreur est survenue",
                        life: 3000,
                    });
                },
            });
        };

        const handleCreate = () => {
            store.dispatch("blog/createCategory", {
                data: {
                    ...newCategory.value,
                    menu_relative: newCategory.value.menu_relative ?? "",
                },
                onSuccess: () => {
                    showCatModal.value = false;
                    newCategory.value = { color: "#ffffff" };
                    get();
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "Nouvelle catégorie ajoutée",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Blog",
                        detail: "Ooops, une erreur est survenue",
                        life: 3000,
                    });
                },
            });
        };

        const handleCreateTag = () => {
            const data = {
                ...newTag.value,
                menu_relative: newTag.value.menu_relative ?? "",
                category_id: newTag.value?.category?.id,
            };
            delete newTag.value?.category;
            store.dispatch("blog/createTag", {
                data,
                onSuccess: () => {
                    showTagModal.value = false;
                    newTag.value = { color: "#ffffff" };
                    getTags();
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "Nouveau Tag ajouté",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Blog",
                        detail: "Ooops, une erreur est survenue",
                        life: 3000,
                    });
                },
            });
        };

        const handleCategoryEdition = () => {
            store.dispatch("blog/editCategory", {
                data: {
                    ...newCategory.value,
                    menu_relative: newCategory.value.menu_relative ?? "",
                },
                categoryId: newCategory.value.id,
                onSuccess: () => {
                    showCatModal.value = false;
                    newCategory.value = { color: "#ffffff" };
                    isEditingCategory.value = false;
                    get();
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "La catégorie a été modifiée avec succès",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Blog",
                        detail: "Ooops, une erreur est survenue",
                        life: 3000,
                    });
                },
            });
        };

        const handleTagEdition = () => {
            const data = {
                ...newTag.value,
                menu_relative: newTag.value.menu_relative ?? "",
                category_id: newTag.value?.category?.id,
            };
            delete newTag.value?.category;
            store.dispatch("blog/editTag", {
                data,
                tagId: newTag.value.id,
                onSuccess: () => {
                    showTagModal.value = false;
                    newTag.value = { color: "#ffffff" };
                    isEditingTag.value = false;
                    getTags();
                    toast.add({
                        severity: "success",
                        summary: "Blog",
                        detail: "Le tag a été modifié avec succès",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Blog",
                        detail: "Ooops, une erreur est survenue",
                        life: 3000,
                    });
                },
            });
        };

        const get = () => {
            store.dispatch("blog/getCategories", {
                onSuccess: (response) => {
                    console.log(response);
                    categories.value = [...response];
                },
                onError: () => {},
            });
        };
        const getTags = () => {
            store.dispatch("blog/getTags", {
                onSuccess: (response) => {
                    tags.value = [...response];
                },
                onError: () => {},
            });
        };

        onMounted(() => {
            get();
            getTags();
        });

        return {
            isEditingCategory,
            showCatModal,
            showTagModal,
            newCategory,
            newTag,
            canDeleteCategory,
            categories,
            isEditingTag,
            closeCategoryModal,
            handleCreate,
            closeTagModal,
            closeCatModal,
            canDeleteTag,
            removeCategory,
            startCategoryEdition,
            handleCategoryEdition,
            handleCategoryRemove,
            handleCreateTag,
            cancelTagDeletion,
            cancelCategoryDeletion,
            isLoadingTags,
            tags,
            startTagEdition,
            removeTag,
            handleTagEdition,
            handleTagRemove,
            addSubCategory,
            canDeleteTag,
        };
    },
};
</script>
