<template>
    <div class="w-full p-3">
        <vue-advanced-chat
            class="border-none shadow-none"
            style="box-shadow: none !important; background: white"
            :single-room="true"
            :current-user-id="currentUserId"
            :rooms-loaded="false"
            room-id="1"
            :rooms-list-opened="false"
            :messages-loaded="false"
            :show-new-messages-divider="true"
            :rooms="JSON.stringify(rooms)"
            :messages="JSON.stringify(messages)"
            :room-actions="JSON.stringify([])"
            :text-messages="
                JSON.stringify({
                    ROOMS_EMPTY: 'Aucune conversation',
                    ROOM_EMPTY: 'Aucune conversation sélectionnée',
                    NEW_MESSAGES: 'Nouveaux messages',
                    MESSAGE_DELETED: 'Ce message a été supprimé',
                    MESSAGES_EMPTY: 'Aucun message',
                    CONVERSATION_STARTED: 'La conversation a commencée le :',
                    TYPE_MESSAGE: 'Tapez votre message',
                    SEARCH: 'Rechercher',
                    IS_ONLINE: 'est en ligne',
                    LAST_SEEN: 'Dernière connexion ',
                    IS_TYPING: 'est en train de taper...',
                    CANCEL_SELECT_MESSAGE: 'Annuler Sélection',
                })
            "
            :styles="
                JSON.stringify({
                    general: {
                        color: '#0a0a0a',
                        colorSpinner: '#333',
                        borderStyle: '1px solid #e1e4e8',
                    },

                    footer: {
                        background: '#f8f9fa',
                        backgroundReply: 'rgba(0, 0, 0, 0.08)',
                    },

                    icons: {
                        search: '#9ca6af',
                    },
                })
            "
            @fetchMessages="fetchMessages"
            @send-message="(params) => handleSendMessage(params)"
        />
    </div>
</template>
<script>
import Chat from "vue3-beautiful-chat";
import AC from "agora-chat";
import axios from "axios";
import { onMounted, ref } from "vue";
// import { buildUserToken } from "../../Utils/data";
import agoraToken from "agora-token";
import { buildAppToken } from "../../Utils/data";
import Storage, { storageKeys } from "../../services/Storage";
import { register } from "vue-advanced-chat";
register();
export default {
    props: {
        userId: { type: String },

        authUser: { type: Object },
    },
    components: { "beautiful-chat": Chat },
    setup(props) {
        const currentUserId = ref("1234");
        const rooms = ref([
            {
                roomId: "1",
                roomName: "Room 1",
                unreadCount: 4,
                index: 3,
                lastMessage: {
                    _id: "xyz",
                    content: "Last message received",
                    senderId: "1234",
                    username: "John Doe",
                    timestamp: "10:20",
                    saved: true,
                    distributed: false,
                    seen: false,
                    new: true,
                },
                users: [
                    {
                        _id: "1234",
                        username: "John Doe",
                        status: {
                            state: "online",
                            lastChanged: "today, 14:30",
                        },
                    },
                    {
                        _id: "4321",
                        username: "John Snow",
                        status: {
                            state: "offline",
                            lastChanged: "14 July, 20:00",
                        },
                    },
                ],
                typingUsers: [],
            },
        ]);
        const messages = ref([]);

        const currentAgoraUser = ref();
        const appKey = ref("411253114#1444934");
        // const userId = ref("");
        const token = ref("aaa0aad74b0a42af9a4c9af9260a8c3c");
        const isLoggedIn = ref(false);
        const peerId = ref("PAT7601776".toLowerCase());
        const message = ref();
        const chatClient = ref();

        const handleLogin = async (uid, userToken) => {
            try {
                chatClient.value.open({
                    user: props.authUser?.username?.toLowerCase(),
                    accessToken: userToken,
                });
            } catch (error) {
                console.log(" handleLogin ===> ");
            }
        };

        const fetchMessages = ({ roomId, options }) => {
            console.log("options ===> ", roomId, options);
        };

        const handleLogout = () => {
            chatClient.value.close();
            isLoggedIn.value = false;
            // setUserId("");
            // setToken("");
            // setPeerId("");
        };

        const handleSendMessage = async (data) => {
            console.log(data.detail[0].content);

            if (data.detail[0]?.content?.trim()) {
                try {
                    const option = {
                        chatType: "singleChat", // Sets the chat type as a one-to-one chat.
                        type: "txt", // Sets the message type.
                        to: peerId.value, // Sets the recipient of the message with user ID.
                        msg: text, // Sets the message content.
                    };
                    let msg = AC.message.create(option);

                    await chatClient.value.send(msg);
                    console.log(
                        `Message send to ${peerId.value}: ${message.value}`
                    );
                    message.value = "";
                } catch (error) {
                    console.log(`Message send failed: ${error.message}`);
                }
            } else {
                console.log("Please enter message content");
            }
        };

        const loadMessages = async () => {
            chatClient.value.getHistoryMessages({
                targetId: peerId.value, // The user ID of the peer user for one-to-one chat or group ID for group chat.
                chatType: "singleChat", // The chat type: `singleChat` for one-to-one chat or `groupChat` for group chat.
                pageSize: 20, // The number of messages to retrieve per page. The value range is [1,50] and the default value is 20.
                searchDirection: "down", // The message search direction: `up` means to retrieve messages in the descending order of the message timestamp and `down` means to retrieve messages in the ascending order of the message timestamp.
                searchOptions: {
                    from: props.authUser?.username.toLowerCase(), // The user ID of the message sender. This parameter is used only for group chat.
                    msgTypes: [], // An array of message types for query. If no value is passed in, all types of message will be queried.
                    startTime: new Date("2023,11,9").getTime(), // The start timestamp for query. The unit is millisecond.
                    endTime: new Date("2024,12,10").getTime(), // The end timestamp for query. The unit is millisecond.
                },
                success: (mgs) => {
                    console.log("loadMessages", mgs);
                },
                error: (error) => {
                    console.log("error ==> ", error);
                },
            });
        };

        const initCall = () => {
            chatClient.value = new AC.connection({
                appKey: appKey.value,
                grantType: "password",
            });

            console.log("chatClient.value ", chatClient.value);

            // Adds the event handler.
            chatClient.value.addEventHandler("connection&message", {
                // Occurs when the app is connected to Agora Chat.
                onConnected: () => {
                    // setIsLoggedIn(true);
                    console.log(
                        `User ${props.authUser?.username} Connect success !`
                    );

                    loadMessages();
                },
                // Occurs when the app is disconnected from Agora Chat.
                onDisconnected: () => {
                    // setIsLoggedIn(false);
                    console.log(`User Logout!`);
                },
                // Occurs when a text message is received.
                onTextMessage: (message) => {
                    console.log(`${message.from}: ${message.msg}`);
                },
                // Occurs when the token is about to expire.
                onTokenWillExpire: () => {
                    console.log("Token is about to expire");
                },
                // Occurs when the token has expired.
                onTokenExpired: () => {
                    console.log("Token has expired");
                },
                onError: (error) => {
                    console.log(`on error: ${error.message}`);
                },
            });
            registerUser();
        };

        const getUser = async () => {
            const orgName = appKey.value.split("#")[0];
            const appName = appKey.value.split("#")[1];
            const domain = "a41.chat.agora.io";
            const url =
                "https://" +
                domain +
                "/" +
                orgName +
                "/" +
                appName +
                "/users/" +
                props.authUser?.username?.toLowerCase();

            try {
                const response = await axios.get(
                    url,

                    { headers: { Authorization: `Bearer ${token.value}` } }
                );

                currentAgoraUser.value =
                    response.data.count == 1 ? response.data.entities[0] : null;
                getUserToken();
                // handleLogin(currentAgoraUser.value.uuid);
            } catch (error) {
                console.log("response ", error);

                if (error.data.error == "duplicate_unique_property_exists") {
                    // handleLogin();
                }
            }
        };

        const getUserToken = async () => {
            const appToken = await Storage.get(storageKeys.authToken);
            console.log("appToken", appToken);

            const orgName = appKey.value.split("#")[0];
            const appName = appKey.value.split("#")[1];
            const domain = "a41.chat.agora.io";
            // const url =
            //     "https://" +
            //     domain +
            //     "/" +
            //     orgName +
            //     "/" +
            //     appName +
            //     "/users/" +
            //     props.authUser?.username?.toLowerCase();

            try {
                const response = await axios.get(
                    "/api/agora-chat/token",

                    { headers: { Authorization: `Bearer ${appToken}` } }
                );
                console.log(response);

                // currentAgoraUser.value =
                //     response.data.count == 1 ? response.data.entities[0] : null;

                // getUserToken();
                handleLogin(currentAgoraUser.value.uuid, response.data);
            } catch (error) {
                console.log("response ", error);

                if (error.data.error == "duplicate_unique_property_exists") {
                    // handleLogin();
                }
            }
        };

        const registerUser = async () => {
            const orgName = appKey.value.split("#")[0];
            const appName = appKey.value.split("#")[1];
            const domain = "a41.chat.agora.io";
            const url =
                "https://" + domain + "/" + orgName + "/" + appName + "/users";

            try {
                const response = await axios.post(
                    url,
                    {
                        nickname: props.authUser?.full_name,
                        password: ``,
                        username: props.authUser?.username?.toLowerCase(),
                    },
                    { headers: { Authorization: `Bearer ${token.value}` } }
                );
                // handleLogin();
            } catch (error) {
                console.log("response ", error);

                if (error.data.error == "duplicate_unique_property_exists") {
                    getUser();
                    // handleLogin();
                }
            }
        };

        onMounted(() => {
            initCall();
        });

        return {
            currentUserId,
            messages,
            rooms,
            fetchMessages,
            handleSendMessage,
        };
    },
};
</script>
