<template>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header">Members</div>

                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li
                                class="list-group-item"
                                v-for="(member, id) in members"
                                :key="id"
                            >
                                {{ member.user.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">Chats</div>

                    <div class="card-body">
                        <dl v-for="message in messages" v-bind:key="message.id">
                            <dt
                                :class="{
                                    'text-right': message.user.id === username,
                                }"
                            >
                                {{ message.user.name }}
                            </dt>
                            <dd
                                :class="{
                                    'text-right': message.user.id === username,
                                }"
                            >
                                {{ message.text }}
                            </dd>
                        </dl>

                        <hr />

                        <form @submit.prevent="sendMessage" method="post">
                            <div class="input-group">
                                <input
                                    type="text"
                                    v-model="newMessage"
                                    class="form-control"
                                    placeholder="Type your message..."
                                />

                                <div class="input-group-append">
                                    <button class="btn btn-primary">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { StreamChat } from "stream-chat";
import { computed, ref, onMounted } from "vue";
import axios from "@/services/axios";
export default {
    props: {
        authUser: {
            type: Object,
            required: true,
        },
    },

    setup(props) {
        const token = ref(null);

        const channel = ref(null);

        const members = ref([]);

        const client = ref();

        const messages = ref([]);

        const newMessage = ref("");

        const username = computed(() => props.authUser?.username);

        const getToken = async () => {
            const { data } = await axios.post("/api/chats/generate-token", {
                user_id: props.authUser.id,
            });

            token.value = data.token;
        };

        const initializeStream = async () => {
            client.value = new StreamChat("sbztz2u66w6c", {
                timeout: 9000,
            });

            console.log("props.authUser.id", props.authUser);

            const res = await client.value.setUser(
                {
                    id: `${props.authUser.id}-${props.authUser.username}`,
                    name: props.authUser.full_name,
                },
                token.value
            );

            console.log("dedededed", client.value, res);
        };

        const initializeChannel = async () => {
            channel.value = client.value.channel("messaging", "chatroom");

            const { members, messages } = await channel.value.watch();

            members.value = members;

            messages.value = messages;

            // listen for new messages
            channel.value.on("message.new", (event) => {
                messages.value.push({
                    text: event.message.text,
                    user: event.message.user,
                });
            });

            // listen for when a new member is added to channel
            channel.on("member.added", (event) => {
                members.value.push(event);
            });
        };

        const sendMessage = async () => {
            await channel.value.sendMessage({
                text: newMessage.value,
            });

            newMessage.value = "";
        };

        onMounted(async () => {
            await getToken();
            await initializeStream();
            await initializeChannel();
        });

        return {
            sendMessage,
            messages,
            members,
        };
    },
};
</script>
