<template>
    <div
        class="become-doctor flex justify-center h-screen bg-primary0 overflow-hidden relative"
    >
        <div
            class="absolute top-0 right-0 left-0 bg-primary2 z-0"
            style="height: 45%"
        >
            <img
                src="/images/doctor-bg-a.png"
                class="w-full h-full object-cover"
                alt=""
            />
        </div>
        <div
            v-if="isSuccess"
            class="flex flex-col z-50 justify-center items-center h-fit bg-white rounded-xl px-5 mt-44 py-5"
        >
            <div
                class="w-20 h-20 rounded-full flex items-center justify-center text-white"
            >
                <img src="/images/check-ok.svg" class="w-20" alt="" />
            </div>

            <h3 class="pt-4 pb-2 text-lg text-center font-ibm">
                Merci!!! Vos informations ont bien été reçues.
            </h3>

            <p class="text-slate-500 font-ibm">
                Nous les traiterons dans les meilleurs délais et nous vous
                reviendrons très bientôt.
            </p>

            <span class="text-slate-500 font-ibm"
                >Vous pourrez configurer votre compte une fois connecté.</span
            >

            <a
                href="/login"
                class="bg-primary01 rounded mt-10 font-ibm font-bold text-white px-5 py-3 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >Se connecter</a
            >
        </div>
        <div
            v-else
            class="flex justify-center large-header w-2/5 overflow-scroll no-scrollbar"
            id="large-header"
        >
            <div
                class="flex flex-col large-header z-50 overflow-scroll no-scrollbar"
            >
                <div class="my-5 flex justify-center items-center">
                    <a href="/">
                        <h1 class="font-bold text-white text-5xl">Allô Doto</h1>
                    </a>
                </div>
                <div
                    class="bg-white rounded-xl flex-col w-full h-fit py-10 mb-10"
                >
                    <h3
                        class="text-slate-800 text-3xl px-14 pt-5 font-ibm font-bold"
                    >
                        Complètez votre inscription
                    </h3>
                    <div class="w-full px-14">
                        <h3 class="py-4 text-slate-500 text-md font-ibm">
                            Preuves (documents et images)
                        </h3>
                        <div class="my-4">
                            <div class="flex flex-col justify-between">
                                <FileUpload
                                    chooseLabel="Pièce d'identité Recto/Verso"
                                    mode="basic"
                                    class="w-full text-sm font-medium bg-white border border-slate-300 rounded-xl text-slate-500"
                                    name="identity[]"
                                    accept="image/*"
                                    :multiple="true"
                                    customUpload
                                    @select="identityFilesSelected"
                                />

                                <span class="text-slate-300 text-sm py-1"
                                    >{{ identityFiles.length }} fichiers</span
                                >
                            </div>

                            <div class="my-5 flex flex-col justify-between">
                                <FileUpload
                                    chooseLabel="Acte(s) vous permettant d'exercer"
                                    mode="basic"
                                    name="work[]"
                                    class="w-full text-sm font-medium bg-white border border-slate-300 rounded-xl text-slate-500"
                                    accept="image/*"
                                    :multiple="true"
                                    customUpload
                                    @select="workFilesSelected"
                                />
                                <span class="text-slate-300 text-sm py-1"
                                    >{{ workFiles.length }} fichiers</span
                                >
                            </div>
                            <div class="my-5 flex flex-col">
                                <FileUpload
                                    chooseLabel="Photo de la devanture de votre établissement"
                                    mode="basic"
                                    name="proof1"
                                    class="w-full text-sm font-medium bg-white border border-slate-300 rounded-xl text-slate-500"
                                    accept="image/*"
                                    :multiple="false"
                                    customUpload
                                    @select="handleCover"
                                />
                            </div>

                            <div class="my-5">
                                <FileUpload
                                    chooseLabel="Photo de l'accueil de votre établissement (intérieur)"
                                    mode="basic"
                                    name="proof2"
                                    class="w-full text-sm font-medium bg-white border border-slate-300 rounded-xl text-slate-500"
                                    url="/api/upload"
                                    accept="image/*"
                                    :multiple="false"
                                    customUpload
                                    @select="handleInside"
                                />
                            </div>
                        </div>

                        <h3 class="py-4 text-slate-500 text-lg font-ibm">
                            Informations complémentaires
                        </h3>

                        <div class="flex justify-between">
                            <div class="mb-2 mr-1 w-full">
                                <label
                                    for="last_name"
                                    class="block text-base font-tight text-slate-500"
                                >
                                    Adresse de l'établissement
                                </label>
                                <div class="flex">
                                    <div
                                        class="flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                    >
                                        <img
                                            src="/images/map-pin.svg"
                                            alt=""
                                            class="px-1"
                                        />

                                        <Field
                                            v-slot="{ field }"
                                            name="address"
                                            :value="selectedAddress.label"
                                            ><InputText
                                                type="text"
                                                v-bind="field"
                                                :value="selectedAddress.label"
                                                :disabled="true"
                                                id="address"
                                                name="address"
                                                placeholder="Adresse *"
                                                class="h-10 bg-white px-4 w-full"
                                        /></Field>
                                    </div>
                                    <button
                                        class="h-10 w-10 bg-primary02 rounded-lg ml-3 text-center"
                                        @click="showPositionModal = true"
                                    >
                                        <i
                                            class="la la-map-marker text-white text-xl"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-end justify-between my-5">
                            <div class="w-4/5">
                                <label
                                    class="block text-base font-tight text-slate-500"
                                >
                                    Téléphone personel ({{
                                        medicalCenter?.creator?.full_name
                                    }})
                                </label>
                                <div
                                    class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                >
                                    <Field
                                        v-slot="{ field }"
                                        name="userPhone"
                                        v-model="userPhone.value"
                                    >
                                        <phone-number-input
                                            type="text"
                                            v-bind="field"
                                            name="userPhone"
                                            id="userPhone"
                                            :default-country="countryCode"
                                            v-model="userPhone.value"
                                            placeholder="Téléphone *"
                                            class="doctor-info-phone h-10 w-full bg-white px-2 text-base font-medium text-[#6B7280] outline-none"
                                        />
                                    </Field>
                                </div>
                            </div>

                            <div class="h-10">
                                <Button
                                    label="Valider"
                                    :disabled="userPhone?.validated"
                                    :class="`${
                                        userPhone?.validated
                                            ? 'bg-primary02'
                                            : 'bg-danger'
                                    } px-3 h-full text-white rounded-lg shadow-none font-normal`"
                                    @click="toggle"
                                    aria-haspopup="true"
                                    aria-controls="overlay_menu"
                                />
                                <Menu
                                    ref="menu"
                                    id="overlay_menu"
                                    :model="items"
                                    :popup="true"
                                />
                            </div>
                        </div>
                        <div class="flex items-end justify-between">
                            <div class="w-4/5">
                                <label
                                    class="block text-base font-tight text-slate-500"
                                >
                                    Ligne principale
                                </label>
                                <div
                                    class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                >
                                    <Field
                                        v-slot="{ field }"
                                        name="phone_1"
                                        v-model="phone_1.value"
                                    >
                                        <phone-number-input
                                            type="text"
                                            v-bind="field"
                                            name="phone_2"
                                            id="phone_2"
                                            :default-country="countryCode"
                                            v-model="phone_1.value"
                                            placeholder="Téléphone 1 *"
                                            class="doctor-info-phone h-10 w-full bg-white px-2 text-base font-medium text-[#6B7280] outline-none"
                                        />
                                    </Field>
                                </div>
                            </div>

                            <div class="h-10">
                                <Button
                                    label="Valider"
                                    :disabled="phone_1?.validated"
                                    :class="`${
                                        phone_1?.validated
                                            ? 'bg-primary02'
                                            : 'bg-danger'
                                    } px-3 h-full text-white rounded-lg shadow-none font-normal`"
                                    @click="toggle1"
                                    aria-haspopup="true"
                                    aria-controls="overlay_menu1"
                                />
                                <Menu
                                    ref="menu1"
                                    id="overlay_menu1"
                                    :model="items1"
                                    :popup="true"
                                />
                            </div>
                        </div>

                        <div class="mt-10 h-10 flex justify-start">
                            <Button
                                v-if="!isSubmitting"
                                label="Envoyer"
                                :disabled="!isReadyForSubmit"
                                class="bg-primary2 w-full px-3 h-full text-white text-center rounded-lg shadow-none font-normal"
                                @click="submitForm"
                            >
                            </Button>
                            <button
                                v-else
                                type="button"
                                class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                                disabled=""
                            >
                                <svg
                                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Sauvegarde en cours...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="showPositionModal"
        :style="{ width: '40rem' }"
        :breakpoints="{ '1199px': '60vw', '575px': '90vw' }"
        position="bottom"
        :modal="true"
        :draggable="false"
    >
        <template #header>
            <div
                class="inline-flex align-items-center justify-content-center gap-2 font-ibm"
            >
                <h1 class="text-xl font-bold">Adresse du praticien</h1>
            </div>
        </template>
        <div class="h-64">
            <div class="h-5/6" style="height: 90%">
                <gmap
                    class="h-full"
                    :center="center"
                    :locate="center.lat == null"
                    @onMapUpdate="updateMap"
                    :delay="1000"
                />
            </div>

            <div class="my-3">
                <h3 class="text-sm text-primary02">
                    {{ selectedAddress?.label ?? "-" }}
                </h3>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-center w-full">
                <Button
                    label="Fermer"
                    class="shadow-none border border-danger mr-2 outline-none text-danger font-medium px-3 py-1"
                    @click="closeModal"
                    text
                />
                <Button
                    label="Valider"
                    :disabled="disabledAddresBtn"
                    class="bg-primary2 px-3 py-1 text-white rounded shadow-none font-medium"
                    @click="showPositionModal = false"
                    autofocus
                />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="isCodeModalDisplay"
        modal
        :draggable="false"
        header="Validation"
        :style="{ width: '30vw', background: 'white' }"
    >
        <template #container="">
            <div class="card px-5 py-5"></div>
            <div class="px-5">
                <h3
                    class="text-sm text-center text-slate-700 font-medium font-ibm px-5"
                >
                    Entrez le code à <strong>4 chiffres</strong> que vous avez
                    reçu par
                    {{ `${selectedMode == "sms" ? "SMS" : "Appel"}` }} sur ce
                    numero
                    <strong>{{ selectedPhone }}</strong>
                </h3>
            </div>

            <div class="py-10 flex justify-center items-center">
                <v-otp-input
                    ref="otpInput"
                    v-model:value="bindModal"
                    input-classes="otp-input"
                    separator=" "
                    :num-inputs="4"
                    :should-auto-focus="true"
                    input-type="letter-numeric"
                    :conditionalClass="[
                        'border border-slate-300 w-10 h-10 rounded-lg px-1 mr-4 text-center text-slate-600 font-semibold',
                        'border border-slate-300 w-10 h-10 rounded-lg px-1 mr-4 text-center text-slate-600 font-semibold',
                        'border border-slate-300 w-10 h-10 rounded-lg px-1 text-center text-slate-600 font-semibold mr-4',
                        'border border-slate-300 w-10 h-10 rounded-lg px-1 text-center text-slate-600 font-semibold',
                        'four',
                    ]"
                    :placeholder="['*', '*', '*', '*']"
                    @on-complete="handleOnComplete"
                />
            </div>

            <div class="h-10 mt-4 mb-10">
                <div class="text-center px-5 font-qs" v-if="!isSending">
                    Vous n'avez pas reçu de code ?
                    <button
                        class="text-primary shadow-none border-none"
                        @click="resendCode"
                    >
                        Renvoyer
                    </button>
                </div>

                <div v-else class="flex justify-center items-center">
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            </div>
        </template>
    </Dialog>
    <Toast />
</template>

<script>
import { useForm, Field } from "vee-validate";
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import * as yup from "yup";
import moment from "moment";
import gmap from "@/components/maps/gmap.vue";
import Storage, { storageKeys } from "../../services/Storage";
import { mapCenter, hereMapApiKey } from "@/services/config";
import * as primePkg from "primevue/usetoast";
import Toast from "primevue/toast";
import VOtpInput from "vue3-otp-input";

const { useToast } = primePkg;

export default defineComponent({
    components: {
        Field,
        InputText,
        Button,
        Dialog,
        Dropdown,
        gmap,
        Menu,
        Toast,
        VOtpInput,
        FileUpload,
    },
    props: {
        allCities: { type: Array, required: true },
        medicalCenter: { type: Object, required: true },
    },
    setup(props) {
        const otpInput = ref(null);
        const isSending = ref(false);
        const selectedMode = ref("");
        const selectedPhone = ref("");
        const selectedField = ref();
        const showPositionModal = ref(false);
        const identityFiles = ref([]);
        const bindModal = ref("");
        const workFiles = ref([]);
        const insideFile = ref();
        const coverFile = ref();
        const menu = ref();
        const menu1 = ref();
        const center = ref(mapCenter);
        const selectedAddress = ref({});
        const userPhone = ref({
            value: `${props.medicalCenter?.creator?.phone_1 ? "+" : ""}${
                props.medicalCenter?.creator?.phone_1
            }`,
            validated: false,
            code: "",
        });
        const phone_1 = ref({
            value: `${props.medicalCenter?.profile?.phone_1 ? "+" : ""}${
                props.medicalCenter?.profile?.phone_1
            }`,
            validated: false,
            code: "",
        });
        const clearInput = () => {
            otpInput.value?.clearInput();
        };

        const isReadyForSubmit = computed(
            () =>
                userPhone.value?.validated &&
                phone_1.value?.validated &&
                identityFiles.value.length &&
                insideFile.value &&
                workFiles.value.length
        );
        const toast = useToast();
        const country = ref(Storage.get(storageKeys.country_name));
        const emoji = ref(Storage.get(storageKeys.country_emoji));
        const countryCode = ref(Storage.get(storageKeys.country_code));
        const schema = computed(() =>
            yup.object({
                center_name: yup
                    .string()
                    .required("Le nom du centre est obligatoire"),
                last_name: yup.string().required("Votre nom est obligatoire"),
                first_name: yup.string().required("Prénom(s) obligatoire(s)"),
                city: yup
                    .object({
                        id: yup.number().required("Sélectionnez votre ville"),
                    })
                    .required("Sélectionnez votre ville"),
                authorization_number: yup
                    .string()
                    .required("Le numero d'autorisation est obligatoire"),
                birth_date: yup
                    .string()
                    .required("La date de naissance est obligatoire"),
                birth_place: yup.string().optional(),
                phone_1: yup
                    .string()
                    .required("Le numéro du déclarant est obligatoire"),
                phone_2: yup
                    .string()
                    .required(
                        "Veuillez fournir au moins un numero de contact de l'établissement"
                    ),
                phone_3: yup.string().optional(),
                email: yup
                    .string()
                    .required("Veuillez entrer votre adresse mail"),
                center_email: yup
                    .string()
                    .required(
                        "Il nous faut une adresse mail de l'établissement"
                    ),
            })
        );
        const cities = computed(() =>
            props.allCities.filter(
                (x) => x?.country?.code === countryCode.value?.toLowerCase()
            )
        );
        const isCodeModalDisplay = ref(false);
        const form = ref({ phone_1: "", phone_2: "", city: {} });
        const isSuccess = ref(false);
        const store = useStore();
        const disabledAddresBtn = computed(
            () =>
                !selectedAddress.value?.latitude &&
                !selectedAddress.value?.longitude
        );
        const isSubmitting = ref(false);
        const { errors, values, handleSubmit, meta, setFieldValue } = useForm({
            initialValues: form.value,
            validationSchema: schema.value,
            validateOnMount: false,
        });
        const handleCity = (data) => {
            setFieldValue("city", data);
        };

        const handleType = (data) => {
            setFieldValue("type", data);
        };
        const toggle = (event) => {
            menu.value.toggle(event);
        };

        const toggle1 = (event) => {
            menu1.value.toggle(event);
        };

        const closeModal = () => {
            showPositionModal.value = false;
        };

        const items = ref([
            {
                label: "Sms",
                icon: "la la-sms",
                command: () => {
                    validateUserPhone("sms");
                    selectedMode.value = "sms";
                    selectedField.value = "userPhone";
                },
            },
            {
                label: "Appel",
                icon: "la la-phone",
                command: () => {
                    validateUserPhone("call");
                    selectedMode.value = "call";
                    selectedField.value = "userPhone";
                },
            },
        ]);

        const items1 = ref([
            {
                label: "Sms",
                icon: "la la-sms",
                command: () => {
                    validatePhone1("sms");
                    selectedMode.value = "sms";
                    // selectedField.value = "phone_1";
                },
            },
            {
                label: "Appel",
                icon: "la la-phone",
                command: () => {
                    validatePhone1("call");
                    selectedMode.value = "call";
                    // selectedField.value = "phone_1";
                },
            },
        ]);

        const types = ref([
            { id: "public_hospital", name: "Hôpital Public" },
            { id: "health_center", name: "Centre de santé" },
            { id: "clinical", name: "Clinique (Centre de santé privé)" },
            { id: "pharmacy", name: "Pharmacie" },
            { id: "laboratory", name: "Laboratoire d'analyses médicales" },
        ]);
        const currentError = computed(() => {
            const [firstKey] = Object.keys(errors.value);
            console.log(errors.value[firstKey]);
            return errors.value[firstKey] ?? "";
        });

        const onSubmit = handleSubmit((values) => {
            save(values);
        });

        const resendCode = () => {
            isSending.value = true;
            store.dispatch("auth/sendPhoneValidationCode", {
                phoneNumber: selectedPhone.value
                    ?.replaceAll("+", "")
                    ?.replaceAll(" ", ""),
                mode: selectedMode.value,
                onSuccess: () => {
                    clearInput();
                    isSending.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Validation du numero de téléphone",
                        detail: "Nouveau code envoyé avec succès",
                        life: 3000,
                    });
                },
                onError: () => {
                    isSending.value = false;

                    toast.add({
                        severity: "error",
                        summary: "Validation du numero de téléphone",
                        detail: "Oooops, une erreur est survenue, veuillez recommencer ultérieurement",
                        life: 3000,
                    });
                },
            });
        };

        const handleOnComplete = (data) => {
            validatePhone(
                selectedPhone.value,
                data,
                (resp) => {
                    console.log(selectedField.value, "selectedField.value");
                    if (selectedField.value == "phone_1") {
                        phone_1.value.validated = true;
                    } else userPhone.value.validated = true;

                    clearInput();
                    isCodeModalDisplay.value = false;
                },
                (error) => {
                    toast.add({
                        severity: "error",
                        summary: "Validation du numero de téléphone",
                        detail: "Le code entré n'est pas le bon.",
                        life: 3000,
                    });
                }
            );
            // if (data == code.value) isCodeModalDisplay.value = false;
        };
        const save = (formData) => {
            const data = { ...formData };
            data.phone_1 = formData?.phone_1
                ?.replaceAll("+", "")
                ?.replaceAll(" ", "");
            data.phone_2 = formData?.phone_2
                ?.replaceAll("+", "")
                ?.replaceAll(" ", "");
            data.phone_3 = formData?.phone_3
                ?.replaceAll("+", "")
                ?.replaceAll(" ", "");
            data.city_id = formData?.city.id;
            data.type = formData?.type.id;

            delete data.city;

            isSubmitting.value = true;

            store.dispatch("centers/create", {
                data,
                onSuccess: () => {
                    isSuccess.value = true;
                    isSubmitting.value = false;
                },
                onError: (error) => {
                    console.log(error);
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        const validatePhone1 = (mode) =>
            sendCode(
                phone_1.value.value,
                mode,
                (resp) => {
                    selectedField.value = "phone_1";
                    selectedPhone.value = phone_1.value.value;
                    isCodeModalDisplay.value = true;
                    console.log("validatePhone1", selectedField.value);
                },
                (error) => {}
            );

        const validateUserPhone = (mode) =>
            sendCode(
                userPhone.value.value,
                mode,
                (resp) => {
                    console.log("rettt", resp);
                    selectedField.value = "userPhone";

                    selectedPhone.value = userPhone.value.value;
                    isCodeModalDisplay.value = true;
                },
                (error) => {
                    console.log(error);
                    toast.add({
                        severity: "error",
                        summary: "Validation du numero de téléphone",
                        detail: "Ooops, nous navons pas pu envoyer le code de validation.Veuiller rééssayer plus tard",
                        life: 3000,
                    });
                }
            );
        const sendCode = (phone, mode, onSuccess, onError) => {
            store.dispatch("auth/sendPhoneValidationCode", {
                phoneNumber: phone?.replaceAll("+", "")?.replaceAll(" ", ""),
                mode,
                onSuccess: onSuccess,
                onError,
            });
        };
        const validatePhone = (phone, code, onSuccess, onError) => {
            store.dispatch("auth/validatePhone", {
                phoneNumber: phone?.replaceAll("+", "")?.replaceAll(" ", ""),
                code,
                onSuccess,
                onError,
            });
        };
        const validateModal = () => {};
        const identityFilesSelected = (data) => {
            identityFiles.value = data.files;
        };
        const workFilesSelected = (data) => {
            workFiles.value = data.files;
        };
        const handleInside = (data) => {
            const [file] = data.files;
            insideFile.value = file;
        };
        const handleCover = (data) => {
            const [file] = data.files;
            coverFile.value = file;
        };

        const updateMap = (map, position) => {
            selectedAddress.value.latitude = position.lat;
            selectedAddress.value.longitude = position.lng;
            if (position) {
                reverseGeocodePosition(position.lat, position.lng);
            }
        };

        const submitForm = () => {
            const formData = new FormData();
            for (let index = 0; index < workFiles.value.length; index++) {
                const element = workFiles.value[index];
                console.log(element, "file");
                formData.append("workFiles[]", element);
            }

            for (let index = 0; index < identityFiles.value.length; index++) {
                const element = identityFiles.value[index];
                console.log(element, "file identify");
                formData.append("identityFiles[]", element);
            }

            if (insideFile.value)
                formData.append("insideFile", insideFile.value);
            if (coverFile.value) formData.append("coverFile", coverFile.value);

            formData.append("address", JSON.stringify(selectedAddress.value));
            formData.append(
                "userPhone",
                userPhone.value.value?.replaceAll("+", "")?.replaceAll(" ", "")
            );
            formData.append(
                "phone_1",
                phone_1.value.value?.replaceAll("+", "")?.replaceAll(" ", "")
            );

            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("token");
            isSubmitting.value = true;

            formData.append("token", token);
            store.dispatch("auth/completeMedicalCenter", {
                id: props.medicalCenter.id,
                data: formData,
                token,
                onSuccess: () => {
                    isSubmitting.value = false;
                    isSuccess.value = true;
                },
                onError: () => {
                    isSubmitting.value = false;
                    toast.add({
                        severity: "error",
                        summary: "Inscription",
                        detail: "Ooops, une erreur est survenue lors de la sauvegarde.Veuiller reéssayer plus tard",
                        life: 3000,
                    });
                },
            });
        };
        const reverseGeocodePosition = (lat, lng) => {
            const platform = new H.service.Platform({
                apikey: hereMapApiKey,
                useHTTPS: false,
            });
            var service = platform?.getSearchService();
            service.reverseGeocode(
                {
                    at: `${lat},${lng},150`,
                },
                (result) => {
                    const { position, address } = result.items[0];
                    // hospital.value.address = address.label;
                    selectedAddress.value = {
                        ...selectedAddress.value,
                        ...address,
                    };
                },
                (err) => {
                    console.error("Error: [reverseGeocodePosition] => ", err);
                }
            );
        };

        const showAddress = () => {
            mapCenter.value = selectedAddress.value
                ? {
                      lng: selectedAddress.value.longitude,
                      lat: selectedAddress.value.latitude,
                  }
                : center;
            showPositionModal.value = true;
        };

        const initPosition = () => {
            const unix_time = Storage.get(storageKeys.position_time);
            const now_unix = moment().unix();
            if (unix_time && now_unix - unix_time < 3600 * 10) {
                return;
            }
            fetch("https://api.ipregistry.co/?key=c33s648p0gjrije7")
                .catch(function (err) {
                    console.log("Error", err);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    console.log("loccc", payload);
                    if (payload?.location) {
                        countryCode.value =
                            payload.location?.country?.code.toLowerCase() ?? "";

                        Storage.set(
                            storageKeys.country_code,
                            countryCode.value
                        );
                        Storage.set(
                            storageKeys.country_emoji,
                            payload.location?.country?.flag.emoji
                        );
                        Storage.set(
                            storageKeys.country_name,
                            payload.location?.country?.name
                        );
                        emoji.value = payload.location?.country?.flag.emoji;
                        country.value = {
                            name: payload.location?.country?.name,
                            capital: payload.location?.country.capital,
                        };
                    }

                    if (payload.currency) {
                        Storage.set(
                            storageKeys.currency_code,
                            payload.currency?.code?.toLowerCase() ?? ""
                        );
                        Storage.set(
                            storageKeys.currency_symbol,
                            payload.currency?.symbol?.toLowerCase() ?? ""
                        );
                    }

                    Storage.set(storageKeys.position_time, moment().unix());
                });
        };

        onMounted(() => {
            initPosition();
            console.log(window.location);

            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("token");

            console.log(token, window.location.pathname.split("/"));
        });

        return {
            countryCode,
            isSuccess,
            form,
            handleOnComplete,
            cities,
            meta,
            country,
            types,
            errors,
            currentError,
            resendCode,
            moment,
            center,
            workFiles,
            selectedAddress,
            identityFiles,
            showPositionModal,
            identityFilesSelected,
            handleType,
            isSubmitting,
            save,
            selectedMode,
            items,
            isReadyForSubmit,
            toggle1,
            phone_1,
            items1,
            menu,
            clearInput,
            otpInput,
            isSending,
            bindModal,
            toggle,
            isCodeModalDisplay,
            userPhone,
            disabledAddresBtn,
            updateMap,
            closeModal,
            showAddress,
            menu1,
            handleCover,
            selectedPhone,
            handleInside,
            workFilesSelected,
            handleCity,
            validateUserPhone,
            validatePhone1,
            validateModal,
            onSubmit,
            submitForm,
        };
    },
});
</script>
<style lang="scss">
label {
    @apply text-slate-500;
}

.become-doctor {
    height: 100vh;
    // background: linear-gradient(90deg, #e52e71, #ff8a00);
    .bg-doct {
        @apply bg-white;
        border-top-left-radius: 10% 50%;
        border-bottom-left-radius: 10% 50%;
    }
    .slider-thumb::before {
        position: absolute;
        content: "";
        left: 30%;
        top: 20%;
        width: 450px;
        height: 450px;
        background: #17141d;
        border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
        will-change: border-radius, transform, opacity;
        animation: sliderShape 5s linear infinite;
        display: block;
        z-index: -1;
        -webkit-animation: sliderShape 5s linear infinite;
    }
}

@keyframes sliderShape {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}

.context {
    left: 0;
    position: absolute;
    top: 50vh;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}

.area {
    @apply bg-primary;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
</style>
