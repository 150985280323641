<template>
    <div class="content-data pt-4 h-fit mb-20">
        <router-view v-slot="{ Component }">
            <transition name="nested">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
    <!-- <div v-else>
        <Error401 />
    </div> -->
</template>

<script>
import store from "@/store";
import { onMounted, computed, watch } from "vue";
import Echo from "laravel-echo";
import Error401 from "./auth/403.vue";
import { useRoute, useRouter } from "vue-router";
import Pusher from "pusher-js";
import { useStore } from "vuex";
import { ref } from "vue";
import Select from "@/components/Select/index.vue";

export default {
    components: { Error401, Select },

    beforeRouteEnter(to, from, next) {
        store.dispatch("auth/initApp", {
            onSuccess: () => {
                next();
            },
            onError: () => {},
        });
    },
    setup(props) {
        const store = useStore();
        const selectedPortfolio = ref();
        const route = useRoute();
        const router = useRouter();
        const portfolios = computed(() => store.getters["auth/portfolios"]);

        const display401 = computed(() => !route.meta?.anyCan);
        const handlePortfolioChange = (data) => {
            store.commit("auth/SET_PORTFOLIO", { portfolio: data });
        };

        const initEcho = () => {
            window.Pusher = Pusher;
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: "6976bcfeeaba692f8cb7",
                // cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                cluster: "us3",
                encrypted: true,
                wsHost: window.location.host,
                wssHost: window.location.host,
                wsPort: window.location.host.includes(".com") ? 6002 : 6001,
                wssPort: window.location.host.includes(".com") ? 6002 : 6001,
                forceTLS: true,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
            });

            window.Echo.connector.pusher.connection.bind(
                "connected",
                (event) => {
                    console.log("Status: connected", event);
                    //this.isConnected = true;
                }
            );
            window.Echo.connector.pusher.connection.bind("disconnected", () =>
                console.log("Status: disconnected")
            );

            window.Echo.connector.pusher.connection.bind("error", () =>
                console.log("Status: Error")
            );
        };

        onMounted(() => {
            if (!window.Echo) initEcho();
        });
        return { display401, portfolios };
    },
};
</script>
