<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <img
                        src="img/agora-logo.png"
                        alt="Agora Logo"
                        class="img-fuild"
                    />
                </div>
            </div>
        </div>
        <div class="container my-5">
            <div class="row">
                <div class="col">
                    <div class="btn-group" role="group">
                        <button
                            type="button"
                            class="btn btn-primary mr-2"
                            v-for="user in allUsers"
                            :key="user?.id"
                            @click="placeCall(user?.id, user.full_name)"
                        >
                            Call {{ user.full_name }}
                            <span class="badge badge-light">{{
                                getUserOnlineStatus(user.id)
                            }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Incoming Call  -->
            <div class="row my-5" v-if="incomingCall">
                <div class="col-12">
                    <p>
                        Incoming Call From <strong>{{ incomingCaller }}</strong>
                    </p>
                    <div class="btn-group" role="group">
                        <button
                            type="button"
                            class="btn btn-danger"
                            data-dismiss="modal"
                            @click="declineCall"
                        >
                            Decline
                        </button>
                        <button
                            type="button"
                            class="btn btn-success ml-5"
                            @click="acceptCall"
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
            <!-- End of Incoming Call  -->
        </div>

        <div class="w-full flex justify-between rounded overflow-hidden">
            <div class="relative w-3/5 rounded overflow-hidden">
                <section id="video-container" v-if="callPlaced">
                    <div class="relative h-10">
                        <div class="absolute h-10 z-50 mt-5 ml-5">
                            <img
                                alt="Allô Doto Image"
                                class="logo__image w-20"
                                src="/images/logo_white.svg"
                            />
                        </div>
                    </div>
                    <div class="absolute w-full bottom-0">
                        <div class="flex mx-5 items-end mb-5">
                            <div
                                id="local-video"
                                ref="localPlayerContainer"
                                class="rounded overflow-hidden w-1/3"
                            ></div>

                            <div class="flex w-2/3 justify-end items-center">
                                <div
                                    style="width: 95%"
                                    class="bg-opacity-50 rounded bottom-10 h-10 z-50 flex justify-center items-center"
                                >
                                    <div
                                        class="rounded-full bg-white/30 backdrop-blur-xl text-lg flex items-center justify-center mx-3 cursor-pointer"
                                        style="width: 40px; height: 40px"
                                    >
                                        <i
                                            class="la la-microphone-slash text-white"
                                        ></i>
                                    </div>
                                    <div
                                        class="rounded-lg bg-danger text-xl flex items-center justify-center cursor-pointer"
                                        style="width: 45px; height: 45px"
                                    >
                                        <i class="la la-tty text-white"></i>
                                    </div>

                                    <div
                                        class="rounded-full bg-white/30 backdrop-blur-xl text-lg flex items-center justify-center mx-3 cursor-pointer"
                                        style="width: 40px; height: 40px"
                                    >
                                        <i
                                            class="la la-video-slash text-white"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="remote-video" ref="localPlayerContainer"></div>

                    <!-- <div class="action-btns">
                        <button
                            type="button"
                            class="btn btn-info"
                            @click="handleAudioToggle"
                        >
                            {{ mutedAudio ? "Unmute" : "Mute" }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary mx-4"
                            @click="handleVideoToggle"
                        >
                            {{ mutedVideo ? "ShowVideo" : "HideVideo" }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="endCall"
                        >
                            EndCall
                        </button>
                    </div> -->
                </section>
            </div>

            <!-- <div class="w-2/5 relative">
                <div class="relative w-full messages">
                    <div
                        class="bottom-0 w-full flex justify-center items-center"
                    >
                        <Messages
                            :authUser="authUser"
                            :userId="authUser?.username"
                        />
                    </div>
                </div>
            </div> -->
        </div>
    </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import AgoraRTC from "agora-rtc-sdk-ng";
import AC from "agora-chat";
import Messages from "./AgoraMessages.vue";
import Storage, { storageKeys } from "../../services/Storage";
export default {
    name: "AgoraChat",
    components: { Messages },
    props: {
        authUser: { type: Object },
        allUsers: { type: Array },
        agoraId: { type: String },
        name: { type: String },
    },
    setup(props) {
        const localPlayerContainer = ref();
        const store = useStore();
        const callPlaced = ref(false);
        const message = ref(null);
        const client = ref(null);
        const localStream = ref(null);
        const localAudioTrack = ref(null);
        const localVideoTrack = ref(null);
        const mutedAudio = ref(false);
        const mutedVideo = ref(false);
        const userOnlineChannel = ref(null);
        const onlineUsers = ref([]);
        const incomingCall = ref(false);
        const incomingCaller = ref(null);
        const agoraChannel = ref(null);

        const chatClient = ref(null);

        const handleSendMessage = async (msg) => {
            console.log("message.value ", message.value, msg);

            if (message.value.trim()) {
                try {
                    const option = {
                        chatType: "singleChat", // Sets the chat type as a one-to-one chat.
                        type: "txt", // Sets the message type.
                        to: `${props.name}-user_${props.authUser?.id}`, // Sets the recipient of the message with user ID.
                        msg: message, // Sets the message content.
                    };
                    let msg = AC.message.create(option);

                    await chatClient.value.send(msg);
                    message.value = "";
                } catch (error) {
                    console.log(error);
                }
            } else {
                //
            }
        };

        const initUserOnlineChannel = () => {
            userOnlineChannel.value = window.Echo.join("agora-online-channel");
            console.log("userOnlineChannel", userOnlineChannel.value);
        };

        const initUserOnlineListeners = () => {
            userOnlineChannel.value.here((users) => {
                onlineUsers.value = users;
            });
            userOnlineChannel.value.joining((user) => {
                // check user availability
                const joiningUserIndex = onlineUsers.value.findIndex(
                    (data) => data.id === user?.id
                );
                if (joiningUserIndex < 0) {
                    onlineUsers.value.push(user);
                }
            });
            userOnlineChannel.value.leaving((user) => {
                const leavingUserIndex = onlineUsers.value.findIndex(
                    (data) => data.id === user?.id
                );
                onlineUsers.value.splice(leavingUserIndex, 1);
            });
            // listen to incomming call
            userOnlineChannel.value.listen("MakeAgoraCall", ({ data }) => {
                if (
                    parseInt(data.userToCall) === parseInt(props.authUser?.id)
                ) {
                    const callerIndex = onlineUsers.value.findIndex(
                        (user) => user?.id === data.from
                    );
                    incomingCaller.value =
                        onlineUsers.value[callerIndex]["name"];
                    incomingCall.value = true;
                    // the channel that was sent over to the user being called is what
                    // the receiver will use to join the call when accepting the call.
                    agoraChannel.value = data.channelName;
                }
            });
        };

        const getUserOnlineStatus = (id) => {
            const onlineUserIndex = onlineUsers.value.findIndex(
                (data) => data.id === id
            );
            if (onlineUserIndex < 0) {
                return "Offline";
            }
            return "Online";
        };

        const placeCall = async (id, calleeName) => {
            try {
                // channelName = the caller's and the callee's id. you can use anything. tho.
                const channelName = props.name;
                console.log("channelName", channelName);

                generateToken(channelName, (tokenRes) => {
                    store.dispatch("auth/agoraCallUser", {
                        userToCall: 1,
                        username: 1,
                        channelName: channelName,
                        onSuccess: () => {
                            initializeAgora();

                            joinRoom(tokenRes, channelName);
                        },
                        onError: () => {},
                    });
                });
                // Broadcasts a call event to the callee and also gets back the token
            } catch (error) {
                console.log(error);
            }
        };

        const acceptCall = async () => {
            initializeAgora();
            const tokenRes = await generateToken(agoraChannel.value);
            joinRoom(tokenRes, this.agoraChannel);
            incomingCall = false;
            callPlaced = true;
        };
        const declineCall = () => {
            // You can send a request to the caller to
            // alert them of rejected call
            incomingCall.value = false;
        };
        const generateToken = (channelName, onSuccess) => {
            store.dispatch("auth/generateAgoraToken", {
                channelName,
                onSuccess: (resp) => {
                    console.log(resp);
                    if (resp) onSuccess(resp);
                },
                onError: () => {},
            });
        };

        /**
         * Agora Events and Listeners
         */
        const initializeAgora = () => {
            client.value = AgoraRTC.createClient({
                mode: "rtc",
                codec: "h264",
            });
            console.log(client.value);

            // client.value.init(
            //     props.agoraId,
            //     () => {
            //         console.log("AgoraRTC client initialized");
            //     },
            //     (err) => {
            //         console.log("AgoraRTC client init failed", err);
            //     }
            // );
        };

        const joinRoom = async (token, channel) => {
            console.log("joinRoom token ", token, channel);

            client.value.join(props.agoraId, channel, token, null);

            callPlaced.value = true;
            console.log("test ===> ");

            createLocalStream();
            initializedAgoraListeners();
        };

        const initializedAgoraListeners = () => {
            //   Register event listeners
            client.value.on("stream-published", function (evt) {
                console.log("Publish local stream successfully");
                console.log(evt);
            });
            //subscribe remote stream
            client.value.on("stream-added", ({ stream }) => {
                console.log("New stream added: " + stream.getId());
                client.value.subscribe(stream, function (err) {
                    console.log("Subscribe stream failed", err);
                });
            });
            client.value.on("stream-subscribed", (evt) => {
                // Attach remote stream to the remote-video div
                evt.stream.play("remote-video");
                client.value.publish(evt.stream);
            });
            client.value.on("stream-removed", ({ stream }) => {
                console.log(String(stream.getId()));
                stream.close();
            });
            client.value.on("peer-online", (evt) => {
                console.log("peer-online", evt.uid);
            });
            client.value.on("peer-leave", (evt) => {
                var uid = evt.uid;
                var reason = evt.reason;
                console.log("remote user left ", uid, "reason: ", reason);
            });
            client.value.on("stream-unpublished", (evt) => {
                console.log(evt);
            });
        };
        const createLocalStream = async () => {
            await AgoraRTC.createMicrophoneAudioTrack();
            localAudioTrack.value = await AgoraRTC.createMicrophoneAudioTrack();
            localVideoTrack.value = await AgoraRTC.createCameraVideoTrack();
            client.value.publish([
                localAudioTrack.value,
                localVideoTrack.value,
            ]);

            localVideoTrack.value.play(localPlayerContainer.value);
            console.log("publish success!");
        };

        const endCall = () => {
            localStream.value.close();
            client.value.leave(
                () => {
                    console.log("Leave channel successfully");
                    callPlaced.value = false;
                },
                (err) => {
                    console.log("Leave channel failed");
                }
            );
        };

        const handleAudioToggle = () => {
            if (mutedAudio.value) {
                localStream.value.unmuteAudio();
                mutedAudio.value = false;
            } else {
                localStream.value.muteAudio();
                mutedAudio.value = true;
            }
        };
        const handleVideoToggle = () => {
            if (mutedVideo.value) {
                localStream.value.unmuteVideo();
                mutedVideo.value = false;
            } else {
                localStream.value.muteVideo();
                mutedVideo.value = true;
            }
        };

        onMounted(() => {
            initUserOnlineChannel();
            initUserOnlineListeners();
        });

        return {
            callPlaced,
            message,
            localPlayerContainer,
            handleSendMessage,
            placeCall,
            handleVideoToggle,
            handleAudioToggle,
            endCall,
            acceptCall,
            declineCall,
            getUserOnlineStatus,
        };
    },
};
</script>

<style scoped>
main {
    margin-top: 50px;
}
#video-container {
    width: 100%;
    height: 500px;
    max-width: 90vw;
    max-height: 50vh;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
}
#local-video {
    width: 30%;
    height: 120px;
    border: 1px solid #fff;
    border-radius: 6px;
    z-index: 2;
    cursor: pointer;
}
#remote-video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.action-btns {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -50px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
#login-form {
    margin-top: 100px;
}
</style>
