<template>
    <!-- component -->
    <div class="bg-primary dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div class="relative w-2/3 bg-bottom bg-contain">
                <div class="mt-10 flex justify-center items-center top">
                    <a href="/" class="text-5xl font-bold text-white">
                        <img
                            src="/images/logo_white.svg"
                            class="w-32"
                            alt="AlloDoto Logo"
                        />
                    </a>
                </div>
                <div
                    class="w-full h-4/5 bg-no-repeat bg-center absolute bottom-0 right-0 left-0 overflow-hidden"
                >
                    <img src="/images/medical_5.svg" alt="" />
                </div>
            </div>

            <div
                class="flex items-center w-full max-w-md px-6 mx-auto lg:w-1/3 bg-white"
            >
                <div class="flex-1">
                    <div class="flex flex-col justify-center">
                        <p class="mt-3 text-secondary dark:text-primary">
                            Ré-initialiser votre mot de passe
                        </p>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="save">
                            <div class="mb-2">
                                <label
                                    for="email"
                                    class="block text-sm text-gray-600 dark:text-gray-200"
                                    >Type</label
                                >
                                <v-select
                                    v-model="form.type"
                                    :options="[
                                        { id: 'email', name: 'Par email' },
                                        {
                                            id: 'phone',
                                            name: 'Par SMS (Message)',
                                        },
                                    ]"
                                    :multiple="false"
                                    :value="form.type"
                                    placeholder="Sélectionnez un mode"
                                ></v-select>
                            </div>
                            <div class="mt-3" v-if="form.type.id === 'email'">
                                <label
                                    for="email"
                                    class="block text-sm text-gray-600 dark:text-gray-200"
                                    >Email</label
                                >
                                <div class="h-10">
                                    <input
                                        v-model="form.email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="remi@allodoto.com"
                                        class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </div>
                            </div>

                            <div class="mt-3" v-if="form.type.id === 'phone'">
                                <label
                                    for="email"
                                    class="block text-sm text-gray-600 dark:text-gray-200"
                                    >Téléphone</label
                                >
                                <div
                                    class="h-10 flex items-center shadow-none rounded-lg border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                >
                                    <Field
                                        v-slot="{ field }"
                                        v-model="form.phone"
                                        name="phone"
                                    >
                                        <phone-number-input
                                            type="text"
                                            v-bind="field"
                                            v-model="form.phone"
                                            :defaultCountry="countryCode"
                                            @phoneChanged="
                                                (phone1Updated) =>
                                                    (form.phone = phone1Updated)
                                            "
                                            placeholder="Numero de télephone *"
                                            class="doctor-info-phone h-10 w-full bg-white px-2 text-base font-medium text-[#6B7280] outline-none"
                                        />
                                    </Field>
                                </div>
                            </div>

                            <div class="mt-6">
                                <button
                                    type="submit"
                                    :class="`
                      w-full
                      px-4
                      py-2
                      tracking-wide
                      text-primary pointer-cursor
                      transition-colors
                      duration-200 bg-opacity-25 hover:bg-opacity-50
                      transform rounded-md ${
                          disabled
                              ? 'bg-primary'
                              : 'bg-primary hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                      } `"
                                    :disabled="disabled"
                                >
                                    Continuer
                                    <span>
                                        <i
                                            class="la la-arrow-right text-lg"
                                        ></i>
                                    </span>
                                </button>
                            </div>
                            <div
                                class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500"
                            >
                                <p>ou</p>
                                <a
                                    href="/login"
                                    class="px-2 py-4 tracking-wide text-slate-500 pointer-cursor transition-colors duration-200 transform rounded-md"
                                >
                                    Se Connecter
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, ref } from "vue";
import { Field } from "vee-validate";
export default {
    components: { Field },
    props: [],
    setup() {
        const form = ref({ type: { id: "phone", name: "Par SMS (Message)" } });
        const isSuccess = ref(true);

        const instance = getCurrentInstance();
        const store = instance.proxy.$store;

        const isSubmitting = ref(false);
        const disabled = computed(
            () =>
                (form.value?.type.id == "email" && !form.value?.email) ||
                (form.value?.type.id == "phone" && !form.value?.phone) ||
                isSubmitting.value
        );

        const save = () => {
            const data = { ...form.value };
            data.type = form.value.type?.id;
            data.phone = form.value.phone
                ?.replaceAll(" ", "")
                ?.replaceAll("+", "");
            isSubmitting.value = true;
            store.dispatch("auth/reset", {
                data,
                onSuccess: (token) => {
                    isSubmitting.value = false;
                    isSuccess.value = true;

                    if (typeof window != "undefined")
                        window.location.replace(`/reset/code?rToken=${token}`);
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        return { isSuccess, disabled, form, isSubmitting, save };
    },
    methods: {},
};
</script>
