<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Conditions d'utilisation</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Conditions d'utilisation"
        />
    </Head>
    <app-bar></app-bar>

    <div class="w-full flex justify-center pt-10 bg-color pb-20">
        <div class="w-3/5 xs:w-11/12 px-5 text-justify" id="app-terms">
            <div class="flex items-center justify-center mt-5 mb-2">
                <img src="/images/section_icon.svg" class="w-14" alt="" />
            </div>
            <div class="text-center my-10">
                <h3 class="text-4xl text-tertiary2 font-semibold text-center">
                    Foire aux Questions
                </h3>
                <span
                    class="text-md font-normal italic text-tertiary2 text-opacity-60"
                    >Dernière mise à jour le
                    <strong>15 juillet 2023</strong></span
                >
            </div>

            <div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure v-slot="{ open }">
                        <DisclosureButton
                            class="text-tertiary3 flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >La plateforme est-elle un service d’urgence ou
                                un cabinet médical ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Non, notre plateforme n’est pas un service d’urgence
                            ni un cabinet médical. Si vous faites face à une
                            urgence médicale, veuillez appeler les secours ou
                            vous rendre immédiatement dans le service d'urgence
                            le plus proche.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Quels types de professionnels sont disponibles
                                sur la plateforme ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Nous proposons des rendez-vous avec des médecins
                            généralistes, spécialistes, gynécologues, dentistes,
                            psychologues, kinésithérapeutes, et bien d'autres
                            professionnels de santé.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment créer un compte sur la plateforme ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Cliquez sur s'<a
                                href="/create-my-account"
                                target="_blank"
                                rel="noopener noreferrer"
                                >inscrire</a
                            >, remplissez vos informations personnelles et
                            validez votre compte via l’email de confirmation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je utiliser la plateforme sans créer de
                                compte ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, un compte est nécessaire pour accéder aux
                            fonctionnalités, comme la prise de rendez-vous et le
                            suivi des consultations.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                J’ai oublié mon mot de passe, que faire ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Cliquez sur
                            <a
                                href="/"
                                target="_blank"
                                class="text-opacity-90"
                                rel="noopener noreferrer"
                                >"Mot de passe oublié"</a
                            >
                            pour recevoir un lien de réinitialisation par email
                            et ou message.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Comment modifier mes informations personnelles
                                ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Rendez-vous dans la section "Mon Profil" pour
                            mettre à jour vos coordonnées ou préférences.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je supprimer mon compte ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez supprimer votre compte à tout
                            moment; contactez notre service client via la
                            section "Besoin d’Aide" pour demander la suppression
                            de votre compte.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment trouver un professionnel de santé ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Utilisez la barre de recherche pour entrer une
                            spécialité, un nom ou une localisation, puis filtrez
                            les résultats selon vos besoins.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment prendre un rendez-vous ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Choisissez un professionnel, sélectionnez une plage
                            horaire disponible, et confirmez le rendez-vous.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Puis-je prendre un rendez-vous pour quelqu’un
                                d’autre ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez réserver un rendez-vous pour un
                            proche en indiquant ses informations.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Est-ce que mon rendez-vous est confirmé
                                immédiatement ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Une fois réservé, vous recevrez une confirmation
                            par email ou notification. Certains professionnels
                            peuvent demander une validation avant la
                            confirmation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je réserver plusieurs rendez-vous à la fois
                                ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez prendre plusieurs rendez-vous pour
                            différents jours ou professionnels.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Que dois-je apporter à mon rendez-vous ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Pour votre rendez-vous, assurez-vous de vous munir
                            d’une <strong>pièce d’identité</strong> et, si
                            possible, de votre
                            <strong>dossier médical</strong> (s'il n'est pas
                            déjà sur <strong>Allô Doto</strong>), surtout dans
                            le cadre d’une consultation de suivi. Ces documents
                            permettront au praticien d’avoir une vue complète de
                            votre situation médicale.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Que faire en cas de problème avec le praticien
                                ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Si vous subissez un mauvais traitement, comme un
                            <strong>décalage important de votre créneau</strong
                            >, l’<strong>absence du praticien</strong>, ou un
                            <strong> geste déplacé</strong>, veuillez
                            <a
                                href="/contact"
                                target="_blank"
                                rel="noopener noreferrer"
                                >signaler</a
                            >
                            immédiatement l’incident via la plateforme. Cela
                            nous permettra de prendre des mesures appropriées
                            pour résoudre la situation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Quand dois-je arriver pour mon rendez-vous ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Il est recommandé d’arriver
                            <strong
                                >au moins 20 minutes avant l’heure
                                prévue</strong
                            >
                            afin de compléter les formalités administratives
                            nécessaires avant le début de votre consultation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment modifier un rendez-vous ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Accédez à la section
                            <a
                                href="/account/appointments"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><strong>"Mes rendez-vous"</strong></a
                            >
                            et sélectionnez <strong>"Modifier"</strong> pour
                            choisir une nouvelle date ou heure. Veuillez noter
                            que toute modification effectuée moins de 48 heures
                            avant l’heure prévue est considérée comme une
                            nouvelle prise de rendez-vous et peut être soumise
                            aux conditions de votre professionnel de santé.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je annuler un rendez-vous ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez annuler un rendez-vous en accédant
                            à la section
                            <a
                                href="/account/appointments"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><strong>"Mes rendez-vous"</strong></a
                            >
                            et en cliquant sur <strong>"Annuler"</strong>.
                            Cependant, veuillez noter que toute annulation
                            effectuée moins de 48 heures avant le rendez-vous
                            est soumise aux conditions spécifiques du
                            professionnel de santé, y compris d’éventuelles
                            pénalités ou frais d'annulation. Assurez-vous de
                            vérifier les politiques de chaque professionnel
                            avant de finaliser votre réservation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Quels sont les délais pour modifier ou annuler
                                un rendez-vous ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Cela dépend du professionnel, mais en général, vous
                            pouvez annuler jusqu'à 48 heures avant.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je reprogrammer un rendez-vous annulé ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez réserver un nouveau créneau
                            directement après l'annulation. Cependant, si vous
                            annulez un rendez-vous à moins de
                            <strong>48 heures</strong> à
                            <strong>cinq reprises</strong>, votre accès à la
                            plateforme sera suspendu pendant
                            <strong>15 jours</strong>. Cette mesure vise à
                            encourager une utilisation responsable et à garantir
                            la disponibilité des créneaux pour tous les
                            utilisateurs. Assurez-vous de bien vérifier vos
                            disponibilités avant de réserver.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Que faire si je manque un rendez-vous ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Si vous manquez un rendez-vous, nous vous
                            conseillons de contacter directement le
                            professionnel pour convenir d’un nouveau créneau.
                            Toutefois, si vous manquez
                            <strong class="text-tertiary2"
                                >cinq rendez-vous consécutifs</strong
                            >
                            sans annulation préalable, votre accès à la
                            plateforme sera suspendu pour une durée de
                            <strong>15 jours</strong>. Cette mesure est mise en
                            place pour garantir une meilleure organisation et
                            éviter les abus, afin que les créneaux restent
                            disponibles pour les autres utilisateurs.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Que faire si je suis en retard pour un
                                rendez-vous ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >En cas de retard, il est urgent de
                            <a
                                href="/contact"
                                target="_blank"
                                rel="noopener noreferrer"
                                >signaler</a
                            >
                            directement au praticien le temps de retard estimé,
                            via les coordonnées fournies dans la confirmation de
                            rendez-vous ou à travers la plateforme. Il est
                            important de noter qu'au-delà de
                            <strong>15 minutes de retard</strong>, votre
                            rendez-vous sera automatiquement annulé, et le
                            praticien se réserve le droit de ne plus vous
                            recevoir. Dans ce cas,
                            <strong
                                >la politique d'annulation de
                                rendez-vous</strong
                            >
                            s'appliquera, et les frais associés ne seront pas
                            remboursés. Veillez donc à planifier votre trajet
                            pour arriver à l'heure.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Que faire si un professionnel annule mon
                                rendez-vous ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >En cas d’annulation par un professionnel, vous
                            serez informé immédiatement. Les
                            <strong>frais de service restent valables</strong>,
                            vous permettant de choisir un autre créneau
                            disponible avec le même professionnel ou un autre
                            praticien de votre choix. Vous pouvez effectuer
                            cette reprogrammation directement depuis votre
                            profil.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je synchroniser mes rendez-vous avec mon
                                calendrier ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, après avoir réservé, vous pouvez exporter vos
                            rendez-vous vers Google Calendar, Apple Calendar ou
                            Outlook.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je recevoir des rappels pour mes
                                rendez-vous ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, des rappels automatiques sont envoyés par
                            email ou/et SMS.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je partager mes documents médicaux via la
                                plateforme ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Oui, certains professionnels permettent de joindre
                            des documents (analyses, ordonnances, etc.) lors de
                            la prise de rendez-vous ou avant la consultation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Puis-je laisser un avis sur un professionnel ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, après votre rendez-vous, vous recevrez une
                            invitation à laisser un avis sur le praticien. Cela
                            est
                            <strong
                                >essentiel pour améliorer la qualité de notre
                                base de données</strong
                            >
                            et pour attribuer une note fiable au professionnel.
                            Ces évaluations permettent également à la plateforme
                            de recommander des praticiens de confiance à
                            d’autres patients, contribuant ainsi à
                            l’amélioration continue de nos services. N’hésitez
                            pas à partager votre expérience pour aider la
                            communauté
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Je n’arrive pas à me connecter à mon compte. Que
                                faire ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Assurez-vous d’utiliser les bons identifiants.
                            Sinon, réinitialisez votre mot de passe.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >L’application ne fonctionne pas correctement.
                                Que faire ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Essayez de mettre à jour l’application ou
                            désinstallez-la et réinstallez-la.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Je ne trouve pas de professionnel spécifique.
                                Pourquoi ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Certains professionnels ne sont pas encore inscrits
                            sur notre plateforme.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment signaler un problème technique ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Contactez notre support via l’onglet "Besoin d’Aide"
                            ou écrivez à
                            <a href="mailto:sos@allodoto.com"
                                >sos@allodoto.com</a
                            >
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Dois-je payer pour utiliser la plateforme ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, l’utilisation de la plateforme est gratuite
                            pour les patients. Cependant, la prise de
                            rendez-vous se fait après
                            <strong
                                >confirmation du paiement des frais de
                                service</strong
                            >
                            associés à l’utilisation de la plateforme. Les frais
                            de consultation sont payés directement au praticien
                            lors de la consultation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Puis-je payer directement sur la plateforme ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, le paiement sur la plateforme concerne
                            uniquement les frais de service pour confirmer votre
                            rendez-vous. Ce paiement garantit la réservation de
                            votre créneau horaire. Les frais de consultation,
                            quant à eux, sont réglés directement sur place avec
                            le professionnel, qui mentionne sa grille tarifaire
                            dans son profil sur la plateforme.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment demander un remboursement ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Si vous souhaitez demander un remboursement, vous
                            devez d'abord
                            <a
                                href="/contact"
                                target="_blank"
                                rel="noopener noreferrer"
                                >signaler</a
                            >
                            le praticien via la plateforme. Une enquête sera
                            alors diligentée pour évaluer la situation et
                            déterminer les responsabilités dans le cadre du
                            conflit. Ce processus nous permettra de trouver une
                            solution appropriée.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je recevoir une facture après une
                                consultation ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, le praticien générera la facture une fois la
                            consultation terminée. Vous pourrez ensuite la
                            retrouver dans la section
                            <a
                                href="/account/appointments"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><strong>"Mes rendez-vous"</strong></a
                            >
                            après le paiement des frais de consultation.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Les paiements sont-ils sécurisés ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, tous les paiements en ligne passent par une
                            passerelle de paiement cryptée et sécurisée.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Que faire si mon paiement échoue ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >En cas d’échec de paiement, essayez à nouveau avec
                            une autre méthode de paiement. Si le problème
                            persiste, contactez le support client
                            <a href="mailto:sos@allodoto.com"
                                >sos@allodoto.com</a
                            >
                            en fournissant une description détaillée du problème
                            rencontré ainsi que des captures d’écran illustrant
                            l’erreur. Cela permettra à l’équipe de support
                            d’identifier rapidement la cause du problème et de
                            vous aider efficacement.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Mes informations personnelles sont-elles
                                protégées ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, nous utilisons des protocoles de sécurité
                            avancés pour protéger vos données.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Partagez-vous mes données avec des tiers ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, vos données ne sont partagées qu'avec le
                            professionnel que vous consultez.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je supprimer mes données personnelles ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, vous pouvez demander la suppression de vos
                            données en contactant notre support
                            <a href="mailto: dpo@habilis.bj"> dpo@habilis.bj</a>
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Mes informations médicales sont-elles
                                confidentielles ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, toutes les informations échangées avec un
                            professionnel sont strictement confidentielles.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Comment signaler un problème de confidentialité
                                ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                        >
                            Contactez-nous immédiatement via la section
                            <strong>"Besoin d’Aide"</strong> ou par e-mail à
                            <a href="mailto:dpo@habilis.bj">dpo@habilis.bj </a>
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Comment contacter le service client ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Utilisez l’onglet
                            <strong>"Besoin d’Aide"</strong> sur la plateforme
                            ou écrivez à
                            <a href="mailto:sos@allodoto.com"
                                >sos@allodoto.com</a
                            >
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Quels sont les horaires du support ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Le service est disponible 24h/24 et 7j/7
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je parler à un conseiller en direct ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, via la messagerie whatsApp et par appel
                            téléphonique
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Combien de temps faut-il pour obtenir une
                                réponse ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Les demandes sont traitées sous 48 heures.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Puis-je soumettre une suggestion ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Oui, toute suggestion visant à améliorer la qualité
                            de nos services est grandement appréciée. Vous
                            pouvez soumettre vos idées ou commentaires par mail
                            à
                            <a href="mailto:sos@allodoto.com"
                                >sos@allodoto.com</a
                            >
                            , et nous nous engageons à les prendre en compte
                            pour continuer à améliorer votre expérience sur la
                            plateforme.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >Puis-je obtenir une ordonnance via la
                                plateforme ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, il est impératif de consulter un professionnel
                            de santé pour qu’il puisse émettre une ordonnance.
                            Une fois la consultation terminée, l'ordonnance sera
                            disponible et pourra être téléchargée depuis votre
                            profil sur la plateforme.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg"
                                >La plateforme est-elle accessible pour les
                                urgences médicales ?
                            </span>
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, en cas d’urgence médicale, contactez
                            immédiatement les secours ou rendez-vous aux
                            urgences.
                        </DisclosurePanel>
                    </Disclosure>
                </div>

                <div
                    class="mx-auto w-full max-w-md rounded-2xl p-2 mb-5 bg-white"
                >
                    <Disclosure as="div" class="mt-2" v-slot="{ open }">
                        <DisclosureButton
                            class="flex text-tertiary3 w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-md font-normal font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                            <span class="text-lg">
                                Puis-je obtenir des conseils médicaux
                                directement via la plateforme ?</span
                            >
                            <ChevronUpIcon
                                :class="open ? 'rotate-180 transform' : ''"
                                class="h-5 w-5 text-purple-500"
                            />
                        </DisclosureButton>
                        <DisclosurePanel
                            class="px-4 pb-2 pt-4 text-md font-normal text-tertiary2 text-opacity-75"
                            >Non, pour tout conseil lié à votre état de santé,
                            il est impératif de consulter un professionnel de
                            santé qualifié. Cependant, la plateforme met à votre
                            disposition un
                            <a
                                :href="blogUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                ><strong>“Blog santé”</strong></a
                            >
                            où vous pouvez accéder à diverses
                            <strong>astuces et articles</strong> liés au
                            bien-être et à la santé. Vous avez également la
                            possibilité de poser des questions générales dans ce
                            cadre, mais elles ne remplacent pas une consultation
                            médicale.
                        </DisclosurePanel>
                    </Disclosure>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import Footer from "@/components/footer/index.vue";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/20/solid";
import { computed } from "vue";

export default {
    components: {
        Footer,
        Head,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronUpIcon,
    },
    setup() {
        const blogUrl = computed(() =>
            typeof window != "undefined"
                ? `${window.location.protocol}//blog.${window.location.host}`
                : ""
        );

        return { blogUrl };
    },
};
</script>
