<template>
    <div
        class="bg-white rounded-xl flex justify-between p-5 box-planning transition transition-all"
        style="border: 1px solid rgb(235, 235, 237)"
    >
        <div class="flex w-3/5 justify-between pratician-info">
            <div class="w-full pratician-data">
                <div class="flex items-center">
                    <div
                        v-if="
                            !planning.pratician &&
                            planning?.medical_center?.profile.img
                        "
                        class="w-14 h-14 rounded-full mb-3 bg-no-repeat bg-slate-400"
                        :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${planning?.medical_center?.profile.img});
                `"
                    ></div>
                    <div
                        v-else-if="
                            !planning?.pratician?.avatar.includes('-default.')
                        "
                        class="w-14 h-14 rounded-full mb-3 bg-no-repeat bg-slate-400"
                        :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${planning.pratician?.avatar});
                `"
                    ></div>

                    <div
                        v-else
                        class="w-14 h-14 text-xl font-bold font-ibm rounded-full mb-3 bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                    >
                        {{ initials }}
                    </div>
                    <div v-if="planning.pratician">
                        <h3
                            class="font-bold font-inter px-2 text-slate-600 text-md pt-3"
                        >
                            Dr. {{ planning.pratician?.full_name }}
                        </h3>

                        <p
                            class="text-slate-400 font-qs font-semibold text-sm px-2"
                        >
                            {{ specialties }}
                            <span v-if="otherSpecialties.length"> et </span>

                            <el-tooltip
                                v-if="otherSpecialties.length"
                                :content="otherSpecialties.toString()"
                                placement="right"
                            >
                                <a
                                    class="underline text-[#2b4660] lowercase"
                                    href="#"
                                    >{{ otherSpecialties.length }} autres
                                    spécialités</a
                                >
                            </el-tooltip>
                        </p>
                        <p class="text-sm text-slate-500 px-2 py-2">
                            {{ planning?.medical_center?.name }}
                        </p>
                    </div>
                    <div v-else>
                        <h3
                            class="font-bold font-qs px-2 text-slate-600 text-md"
                        >
                            {{ planning?.medical_center?.name }}
                        </h3>

                        <p
                            class="text-slate-400 font-qs font-semibold text-sm px-2"
                        >
                            {{ specialties }}
                            <span v-if="otherSpecialties.length"> et </span>

                            <el-tooltip
                                v-if="otherSpecialties.length"
                                :content="otherSpecialties.toString()"
                                placement="right"
                            >
                                <a
                                    class="underline text-[#2b4660] lowercase"
                                    href="#"
                                    >{{ otherSpecialties.length }} autres
                                    spécialités</a
                                >
                            </el-tooltip>
                        </p>
                    </div>
                </div>

                <div
                    class="my-5"
                    v-if="planning.medical_center?.profile?.phone_1"
                >
                    <div class="flex">
                        <div class="flex">
                            <img
                                src="/images/phone.svg"
                                class="w-5 h-5"
                                alt=""
                            />
                            <span class="mx-1 text-slate-600 text-sm">{{
                                formatPhone(
                                    planning.medical_center?.profile?.phone_1
                                )
                            }}</span>
                        </div>
                        <div
                            class="ml-3 flex"
                            v-if="planning.medical_center?.profile?.phone_2"
                        >
                            <img
                                src="/images/phone.svg"
                                class="w-5 h-5"
                                alt=""
                            />
                            <span class="mx-1 text-slate-600 text-sm">{{
                                formatPhone(
                                    planning.medical_center?.profile?.phone_2
                                )
                            }}</span>
                        </div>
                    </div>
                </div>

                <div class="pricing prices flex flex-col justify-center">
                    <div class="flex">
                        <div class="text-primary01">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-currency-exchange"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z"
                                />
                            </svg>
                        </div>
                        <h3
                            class="text-sm font-medium px-3 text-slate-600 font-inter"
                            v-if="planning.price"
                        >
                            Entre {{ priceMin }} et {{ priceMax }}
                            <i
                                v-tooltip.bottom="
                                    'Ce prix représente une foruchette mais peut differer en fonction du moment de la consultation'
                                "
                                class="la la-info-circle text-md text-[#574f7d]"
                            ></i>
                        </h3>
                        <h3
                            class="text-sm font-medium font-inter px-3 text-slate-600"
                            v-else
                        >
                            N/A
                        </h3>
                    </div>
                </div>

                <div class="my-4">
                    <div
                        class="flex text-primary01"
                        v-if="planning.address?.address"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.30129 4.40426C5.39736 5.24534 4.79199 6.56573 4.79199 8.5C4.79199 10.4025 5.66287 12.5297 6.81646 14.1978C7.38946 15.0264 8.01441 15.7153 8.60247 16.1899C9.21178 16.6816 9.69628 16.875 10.0003 16.875C10.3044 16.875 10.7889 16.6816 11.3982 16.1899C11.9862 15.7153 12.6112 15.0264 13.1842 14.1978C14.3378 12.5297 15.2087 10.4025 15.2087 8.5C15.2087 6.56573 14.6033 5.24534 13.6994 4.40426C12.7871 3.55545 11.4957 3.125 10.0003 3.125C8.50499 3.125 7.21352 3.55545 6.30129 4.40426ZM5.44979 3.48914C6.64881 2.37348 8.274 1.875 10.0003 1.875C11.7267 1.875 13.3518 2.37348 14.5509 3.48914C15.7582 4.61252 16.4587 6.29214 16.4587 8.5C16.4587 10.7396 15.4545 13.1125 14.2123 14.9088C13.5874 15.8124 12.8842 16.5969 12.1832 17.1626C11.5035 17.7112 10.7379 18.125 10.0003 18.125C9.2627 18.125 8.4972 17.7112 7.81745 17.1626C7.11645 16.5969 6.41327 15.8124 5.78836 14.9088C4.54612 13.1125 3.54199 10.7396 3.54199 8.5C3.54199 6.29214 4.24246 4.61252 5.44979 3.48914Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10 6.45825C8.96447 6.45825 8.125 7.29772 8.125 8.33325C8.125 9.36879 8.96447 10.2083 10 10.2083C11.0355 10.2083 11.875 9.36879 11.875 8.33325C11.875 7.29772 11.0355 6.45825 10 6.45825ZM6.875 8.33325C6.875 6.60736 8.27411 5.20825 10 5.20825C11.7259 5.20825 13.125 6.60736 13.125 8.33325C13.125 10.0591 11.7259 11.4583 10 11.4583C8.27411 11.4583 6.875 10.0591 6.875 8.33325Z"
                                fill="currentColor"
                            />
                        </svg>

                        <span class="mx-1 text-sm text-slate-500">{{
                            planning.address?.address
                        }}</span>
                    </div>
                </div>

                <div class="mt-3 action">
                    <button
                        :href="`/praticians/${planning?._id}`"
                        class="w-full mt-2 rounded-md bg-primary01 py-2 text-center px-3 text-white px-2"
                    >
                        Prendre rendez-vous
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="displayedSlots?.length > 0"
            class="pratician-slots w-2/5 py-2"
        >
            <div
                class="flex mb-2.5 w-full justify-between"
                v-for="(item, index) in displayedSlots"
                :key="index"
            >
                <div class="mx-2">
                    <h3
                        class="text-sm capitalize text-slate-600 font-bold font-qs"
                    >
                        {{ moment(item.date).format("ddd") }}
                    </h3>
                    <h4 class="text-xs text-slate-500 font-ibm capitalize">
                        {{ moment(item.date).format("DD/MM") }}
                    </h4>
                </div>
                <div class="grid grid-rows-7 grid-flow-col gap-4">
                    <button
                        v-for="idx in grid"
                        :key="idx"
                        class="w-16 rounded bg-primary01 text-primary01 px-2 bg-opacity-15 slot w-100"
                    >
                        {{ moment(item.slots[idx - 1]?.start).format("HH:mm") }}
                    </button>
                </div>
            </div>
            <div class="flex w-full justify-end">
                <div class="mx-2 w-1/12"></div>
                <div class="flex justify-center w-9/12">
                    <a
                        :href="`/praticians/${planning?.id}`"
                        class="w-full mt-2 rounded-lg py-1 text-center px-3 bg-primary2 border border-primary2 text-primary2 px-2 mx-1 bg-opacity-15"
                    >
                        Voir l'agenda complet
                    </a>
                </div>
            </div>
        </div>
        <div
            v-if="displayedSlots?.length == 0"
            class="w-2/5 bg-slate-100 flex justify-center items-center ml-3 rounded p-3 no-planning"
        >
            <div class="flex box">
                <i class="la la-calendar-times text-sm mx-2 text-slate-800"></i>
                <p
                    class="text-sm text-slate-500"
                    v-if="planning?.medical_center?.profile?.phone_1"
                >
                    Pas de rendez-vous réservable en ligne pour ce
                    praticien.Appelez le
                    <strong>
                        {{
                            formatPhone(
                                planning?.medical_center?.profile?.phone_1
                            )
                        }}</strong
                    >
                    pour prendre un rendez-vous.
                </p>
                <p class="text-sm text-slate-500" v-else>
                    Pas de rendez-vous réservable en ligne pour ce praticien.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { computed, defineComponent, onMounted, watch, ref } from "vue";
import { ElPopover, ElTooltip } from "element-plus";
import { useWindowSize } from "@vueuse/core";
import { AsYouType } from "libphonenumber-js";
import * as MapPin from "#/images/map-pin.svg";

export default defineComponent({
    components: { ElPopover, ElTooltip, MapPin },
    props: { planning: { type: Object } },
    setup(props) {
        const specialties = computed(() =>
            props.planning.specialties
                .filter((s, i) => i < 3)
                .reduce(
                    (acc, x, i) => (acc += `${i > 0 ? "," : ""} ${x.name}`),
                    ""
                )
                .toString()
        );
        const grid = ref(7);
        const { width, height } = useWindowSize();

        const initials = computed(() =>
            props.planning.pratician.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };
        const otherSpecialties = computed(() =>
            props.planning.specialties
                .filter((s, i) => i >= 3)
                .map((x) => x.name)
        );

        const xOf = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "XOF",
        });
        const slotsData = ref(null);
        const displayedSlots = ref(null);
        const priceMin = computed(() =>
            xOf.format(props.planning?.price?.min ?? 0)
        );
        const priceMax = computed(() =>
            xOf.format(props.planning?.price?.max ?? 0)
        );
        const buildSlots = () => {
            const groups = props.planning.slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            displayedSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
                .filter((x, i) => i <= 2)
                .map((s) => ({
                    date: s.date,
                    slots: s.slots
                        .sort(
                            (a, b) =>
                                moment(a.start).unix() - moment(b.start).unix()
                        )
                        .filter((y, index) => index <= 7),
                }));
        };

        const updateDimen = (w) => {
            let r = w;
            let g = 0;
            if (w < 1000) {
                g = (r * 9) / 1000;
            } else {
                r = w / 3;
                g = (r * 6) / 500;
            }

            grid.value = Math.round(g - 1);
        };
        watch(
            () => width.value,
            (val) => {
                updateDimen(val);
            }
        );
        onMounted(() => {
            buildSlots();
            updateDimen(width.value);
            moment.locale("fr");
        });
        return {
            specialties,
            otherSpecialties,
            priceMax,
            grid,
            formatPhone,
            initials,
            priceMin,
            displayedSlots,
            moment,
        };
    },
});
</script>
<style lang="scss">
.box-planning {
    user-select: none;
    transition: border 0.4s ease-out, box-shadow 0.4s ease-out;
    &:hover {
        box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.1);
    }

    .slot {
        transition: background-color 0.2s, color 0.2s;
    }
}
@media screen and (min-width: 1024px) {
    .action {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .box-planning {
        flex-direction: column;
    }
    .pratician-info {
        width: 100% !important;
        justify-content: space-between;

        .action {
            display: none;
        }
    }
    .pratician-slots,
    .no-planning {
        width: 100% !important;
        margin-top: 3em !important;
        margin-left: 0 !important;
        .box {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .pratician-info {
        width: 100% !important;
        .pratician-data {
            width: 100% !important;
        }
    }
    .pratician-slots,
    .no-planning {
        display: none !important;
    }
    .prices {
        display: none;
    }
}

@media screen and (max-width: 612px) {
    .pratician-info {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        .pratician-data {
            width: 100% !important;
        }

        .pricing {
            width: 100% !important;
            margin-top: 2em;
        }
    }
    .pratician-slots,
    .no-planning {
        display: none !important;
    }
    .prices {
        display: none;
    }
}
</style>
