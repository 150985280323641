<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Notre raison d'être</title>
        <meta
            head-key="description"
            name="description"
            :content="`La technologie au service de la santé des Africains.Gagnez en temps et en efficacité avec nous à vos côtés pour une meilleure santé`"
        />
    </Head>
    <app-bar></app-bar>

    <div
        class="w-full flex flex-col justify-center items-center mt-10"
        id="notice"
    >
        <div
            class="flex xs:flex-col lg:flex-row md:flex-row xl:flex-row sm:flex-col xs:flex-col justify-between items-center w-10/12 py-20"
        >
            <div class="w-1/2 md:w-1/2 xs:w-full sm:w-full">
                <h1
                    class="text-6xl text-balckp font-bold font-ibm leading-tight px-3"
                >
                    La technologie au service de la santé en
                    <span class="underline decoration-primary01">
                        <span class="text-primary2">Afrique</span></span
                    >
                </h1>
                <p class="py-5 text-slate-600 text-lg font-medium">
                    Nous mettons la technologie au cœur de l’accès aux soins de
                    santé, rendant ces derniers accessibles à tous, peu importe
                    la localisation géographique sur le continent. Notre
                    objectif est de garantir des soins de qualité tout en
                    optimisant l'expérience en cabinet. Nous réduisons les temps
                    d'attente et facilitons la communication entre
                    professionnels de santé et patients, pour un suivi plus
                    fluide et efficace.
                </p>
            </div>
            <div class="w-1/2 md:w-3/5 xs:w-full sm:w-full">
                <img src="/images/img_1.svg" class="object-cover" alt="" />
            </div>
        </div>
    </div>

    <div class="bg-color flex flex-col items-center">
        <div class="w-10/12 flex flex-col justify-center items-center py-8">
            <h1 class="text-xl font-bold text-left text-slate-600 text-center">
                Nos ambitions :
            </h1>
            <p class="text-slate-500 text-md font-inter">
                Améliorer le service en cabinet et contribuer à une meilleure
                santé en <strong>Afrique</strong>
            </p>
        </div>

        <div
            class="w-10/12 flex md:flex-row sm:flex-col xs:flex-col justify-between items-center bg-color"
        >
            <div class="w-2/4 md:w-2/5 xs:w-full sm:w-full">
                <img src="/images/img_2.svg" alt="" />
            </div>

            <div
                class="w-5/12 md:w-1/2 xs:w-full sm:w-full flex flex-col justify-start bg-white px-8 rounded"
            >
                <div
                    class="mt-5 bg-primary02 bg-opacity-15 w-8 h-8 rounded flex items-center justify-center"
                >
                    <img src="/images/check-circle.svg" class="w-6" alt="" />
                </div>
                <p class="py-5 text-slate-700 text-3xl font-ibm font-bold">
                    Allô Doto vous permet au travers de petites configurations :
                </p>

                <ul class="list-disc" style="list-style-type: disc">
                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Gagner du temps médical et profiter d’une nouvelle
                            expérience de travail
                        </p>
                    </div>
                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Augmenter vos revenus
                        </p>
                    </div>
                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Offrir de nouveaux services à vos patients
                        </p>
                    </div>

                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Mettre en place de nouveaux moyens de prise en
                            charge de vos patients (à distance, de manière
                            préventive, en coopération avec des confrères ou en
                            continu)
                        </p>
                    </div>

                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Prendre de meilleures décisions cliniques
                        </p>
                    </div>
                    <div class="flex pb-3">
                        <img src="/images/check-ok.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Dévélopper votre patientèle
                        </p>
                    </div>
                </ul>
            </div>
        </div>
    </div>
    <div class="w-full flex justify-center">
        <div
            class="w-10/12 xs:flex-col flex justify-between items-center py-20"
        >
            <div
                class="w-2/4 md:w-2/5 xs:w-full sm:w-full relative"
                style="background: url(/images/right-bg.png)"
            >
                <img src="/images/img_3.svg" class="z-50 w-[97%]" alt="" />

                <img
                    src="/images/bg_elements.svg"
                    class="absolute top-0 w-full z-20"
                    alt=""
                />

                <img
                    src="/images/text_box.svg"
                    class="absolute -bottom-[90px] left-0 w-[300px] z-20"
                    alt=""
                />
            </div>
            <div
                class="w-1/2 md:w-1/2 xs:flex-col xs:w-full sm:w-full flex justify-end relative"
            >
                <div class="w-10/12">
                    <div
                        class="mt-5 bg-primary02 bg-opacity-15 w-8 h-8 rounded flex items-center justify-center"
                    >
                        <img
                            src="/images/check-circle.svg"
                            class="w-6"
                            alt=""
                        />
                    </div>
                    <p class="py-5 text-slate-700 text-3xl font-ibm font-bold">
                        Faciliter l'accès aux soins pour tous
                    </p>

                    <p class="py-5 text-slate-700">
                        Nos solutions permettent à vos patients de :
                    </p>

                    <div class="flex pb-3">
                        <img src="/images/point_icon.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Accéder facilement aux meilleurs spécialistes
                        </p>
                    </div>

                    <div class="flex pb-3">
                        <img src="/images/point_icon.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Gérer leur santé plus facilement
                        </p>
                    </div>

                    <div class="flex pb-3">
                        <img src="/images/point_icon.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Recevoir des soins adaptés
                        </p>
                    </div>

                    <div class="flex pb-3">
                        <img src="/images/point_icon.svg" class="w-6" alt="" />
                        <p class="text-slate-700 py-1 pl-2 font-ibm">
                            Recevoir des soins continus
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-full flex justify-center my-20">
        <div class="w-10/12 flex xs:flex-col xs:items-center xs:w-full">
            <div class="w-1/2 xs:w-10/12">
                <p class="py-5 text-4xl font-ibm font-extrabold">
                    La bonne santé c'est bien mais dans un
                    <strong class="underline decoration-primary01"
                        ><span class="text-primary2">environnement</span>
                        sain</strong
                    >
                    c'est encore mieux.
                </p>

                <div class="mt-3">
                    <p class="font-inter text-slate-500 text-md">
                        Nous utilisons les bénéfices tirés des prises de
                        rendez-vous pour planter des arbres dans plusieurs
                        régions oú nos opérons et nous initierons désormais des
                        campagnes de sensibilisation et de dépistage à travers
                        notre continent: l'<strong class="text-primary01"
                            >Afrique</strong
                        >.
                    </p>
                </div>
            </div>
            <div class="w-1/2 xs:w-full xs:justify-center flex justify-end">
                <img
                    src="/images/planet.svg"
                    class="h-full w-8/12 xs:w-10/12"
                    alt=""
                />
            </div>
        </div>
    </div>

    <div class="w-full my-24">
        <div
            class="flex sm:flex-col md:flex-row xs:flex-col w-10/12 justify-between mb-20 mx-auto"
        >
            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3 flex flex-col items-center">
                    <img src="/images/img_5.svg" class="w-[50px]" alt="" />
                    <h1 class="py-3 font-bold text-xl font-ibm">
                        Co-construction
                    </h1>
                    <p class="text-sm font-inter text-slate-600 text-center">
                        Nous sommes à l'écoute de vos besoins et défis.
                        Ensemble, nous nous engageons à améliorer constamment la
                        plateforme, en vous offrant toujours plus de
                        fonctionnalités pour simplifier votre quotidien et
                        répondre efficacement à vos attentes.
                    </p>
                </div>
            </div>
            <div class="flex items-center justify-center">
                <img
                    src="/images/next.svg"
                    class="h-full w-8/12 xs:hidden sm:hidden md:hidden"
                    alt=""
                />
            </div>

            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3 flex flex-col items-center">
                    <img src="/images/img_6.svg" class="w-[50px]" alt="" />
                    <h1 class="py-3 font-bold text-xl font-ibm">
                        Accessibilité
                    </h1>
                    <p class="text-sm font-inter text-slate-600 text-center">
                        Nous nous engageons à vous offrir un accès facile et
                        continu à nos services à travers différents canaux, afin
                        de vous garantir une expérience optimale et de remplir
                        nos missions dans les meilleures conditions possibles.
                        Les praticiens et leurs équipes bénéficient d'une
                        plateforme intuitive, leur permettant de gérer leur
                        patientèle en toute autonomie et efficacité.
                    </p>
                </div>
            </div>
            <div class="flex items-center justify-center">
                <img
                    src="/images/next.svg"
                    class="h-full w-8/12 xs:hidden sm:hidden md:hidden"
                    alt=""
                />
            </div>
            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3 flex flex-col items-center">
                    <img src="/images/img_4.svg" class="w-[50px]" alt="" />
                    <h1 class="py-3 font-bold text-xl font-ibm">
                        Confidentialité
                    </h1>
                    <p class="text-sm font-inter text-slate-600 text-center">
                        Nous mettons en place un large éventail de mesures de
                        protection rigoureuses pour garantir la sécurité de vos
                        données personnelles. Toutes les informations que nous
                        collectons sont stockées et traitées en conformité avec
                        les réglementations en vigueur dans les pays africains
                        où nous opérons. Votre confidentialité est notre
                        priorité, et nous nous engageons à respecter les
                        standards les plus élevés de protection des données.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>

<script>
import { Head } from "@inertiajs/vue3";
import Home from "@/components/home/index.vue";
import Footer from "@/components/footer/index.vue";
export default {
    components: { Home, Footer, Head },
    setup() {},
};
</script>
