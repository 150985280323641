<template>
    <footer class="flex flex-col items-center font-ibm bg-black">
        <div class="flex w-10/12 xs:flex-col my-10 justify-between">
            <div>
                <h1 class="text-3xl text-white font-bold">Newsletter</h1>
                <p class="text-slate-400 text-lg py-4">
                    Souscrivez à notre newsletter pour recevoir nos infos à
                    chaud
                </p>
            </div>
            <div class="flex items-center justify-center">
                <input
                    type="text"
                    placeholder="Email"
                    class="px-3 py-5 rounded-lg outline-none bg-black h-4 border text-slate-400 border-slate-400"
                />
                <button
                    class="outline-none font-normal px-4 inline-block py-2 bg-primary3 text-white text-sm rounded-lg ml-3"
                >
                    Recevoir nos actus
                </button>
            </div>
        </div>
        <div
            class="py-20 flex xl:w-10/12 lg:w-10/12 xs:flex-col xs:w-10/12 main-c"
        >
            <div
                class="logo-box lg:w-2/5 xl:w-2/5 md:w-2/5 xs:w-full sm:w-full font-ibm relative"
            >
                <div class="flex mb-10">
                    <a href="/">
                        <img
                            src="/images/allo_logo.svg"
                            class="mr-3 w-36"
                            alt="Allô Doto Logo"
                    /></a>
                </div>

                <img
                    src="/images/health_icon.png"
                    class="absolute bottom-0 inset-x-px w-20"
                />
            </div>
            <div
                class="w-4/5 xs:w-full footer-items flex xs:flex-col xs:justify-start font-ibm justify-between"
            >
                <div class="flex flex-col w-1/3 xs:w-full">
                    <h3
                        class="font-ibm text-md text-white font-bold uppercase py-3"
                    >
                        Entreprise
                    </h3>
                    <a
                        href="/legal-notice"
                        class="py-3 text-slate-300 font-light"
                        >À Propos</a
                    >
                    <a
                        :href="blogUrl"
                        target="_blank"
                        class="py-3 text-slate-300 font-light"
                        >Blog</a
                    >

                    <a href="/faq" class="py-3 text-slate-300 font-light"
                        ><i class="la la-question-circle mr-1"></i> Aide</a
                    >
                </div>

                <div class="flex flex-col w-1/3">
                    <h3
                        class="font-ibm text-md text-white uppercase font-bold py-3"
                    >
                        Légal
                    </h3>
                    <a
                        href="/legal-notice"
                        class="py-3 text-slate-300 font-light"
                        >Mentions légales</a
                    >
                    <a href="/contact" class="py-3 text-slate-300 font-light"
                        >Réclamations</a
                    >
                    <a
                        href="/security-policy"
                        class="py-3 text-slate-300 font-light"
                        >Politique de confidentialité</a
                    >
                    <a href="/terms" class="py-3 text-slate-300 font-light"
                        >Conditions d'utilisation</a
                    >
                </div>

                <div class="flex flex-col w-1/3">
                    <h3
                        class="font-ibm text-md text-white uppercase font-bold py-3"
                    >
                        Nous contacter
                    </h3>
                    <a href="" class="py-2 text-slate-300 font-light"
                        >+229 67083775</a
                    >
                    <a href="/contact" class="py-3 text-slate-300 font-light"
                        >sos@allodoto.com</a
                    >
                    <a
                        href="/security-policy"
                        class="font-light py-3 text-primary2"
                        >Ouvert 24/7</a
                    >
                </div>

                <!-- <div class="lg:w-1/3 md:w-1/3 xs:w-full">
                    <div class="rounded-md p-4 bg-primary bg-opacity-25">
                        <p
                            class="text-primary flex items-center font-bold font-ibm"
                        >
                            <span class="text-primary mr-2">
                                <svg
                                    viewBox="0 0 18 18"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.2 1.4l6.5 12.9c.9 1.7-.4 3.7-2.3 3.7H2.6C.7 18-.6 16 .3 14.3L6.8 1.4A2.6 2.6 0 019 0a2.6 2.6 0 012.2 1.4zM10 8a1 1 0 00-2 0v3a1 1 0 102 0V8zm0 6a1 1 0 11-2 0 1 1 0 012 0z"
                                    ></path>
                                </svg>
                            </span>

                            Avertissement
                        </p>

                        <p class="mt-5 text-primary font-ibm">
                            <strong>Allô Doto </strong> n’est pas un service
                            d’urgence (sauf Exception
                            <a class="text-secondary" href="/terms#emergency"
                                >"Demande d'intervention ou alerte"</a
                            >) ou un cabinet médical, pour toute urgence,
                            appelez le 18 (numéro d’urgence de votre pays de
                            résidence)
                        </p>
                    </div>
                </div> -->
            </div>
        </div>

        <div
            class="bg-white flex items-center sm:px-0 py-5 w-full flex-col social justify-between mb-0"
        >
            <div class="w-10/12 flex justify-between">
                <div class="flex w-1/4">
                    <div
                        class="text-sm text-gray-400 font-normal w-10/12 py-3 font-ibm flex"
                    >
                        <span class="mr-2"> &copy; 2024</span>
                        <strong>
                            <a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            ></strong
                        >
                    </div>
                </div>
                <div class="flex">
                    <country-flag
                        v-tooltip.top="'Nos services sont disponibles au Bénin'"
                        country="bj"
                        rounded
                        shadow
                        class="mr-1"
                    ></country-flag>
                    <country-flag
                        v-tooltip.top="
                            'Nos services sont disponibles en Côte d\'Ivoire'
                        "
                        country="ci"
                        rounded
                        shadow
                        class=""
                    ></country-flag>
                </div>
                <div class="flex justify-start items-center">
                    <div class="mr-2">
                        <a
                            href="https://www.facebook.com/allodoto"
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i
                                class="lab la-facebook text-3xl text-[#4267B2]"
                            ></i
                        ></a>
                    </div>
                    <div class="mr-2">
                        <a
                            href="https://www.instagram.com/allodoto/"
                            target="_blank"
                            rel="noopener noreferrer"
                            ><i
                                class="lab la-instagram text-3xl text-[#E1306C]"
                            ></i
                        ></a>
                    </div>
                    <div class="mr-2">
                        <a
                            href="https://twitter.com/allo_doto"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blackp"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-twitter-x"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import { computed } from "vue";

export default {
    setup() {
        const blogUrl = computed(() =>
            typeof window != "undefined"
                ? `${window.location.protocol}//blog.${window.location.host}`
                : ""
        );

        return { blogUrl };
    },
};
</script>
