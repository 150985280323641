<template>
    <Dialog
        v-model:visible="displayModal"
        modal
        header="Connexion"
        @update:visible="$emit('cancel')"
        :closable="true"
        :style="{ width: '30vw' }"
    >
        <div class="">
            <p class="mb-5 text-md font-ibm">
                Veuiller vous connecter pour continuer
            </p>
            <p v-if="errorMsg" class="text-danger py-2 text-sm font-inter">
                La combinaison Email/Mot de passe est incorrecte
            </p>
            <div class="">
                <form @submit.prevent="save">
                    <div>
                        <label
                            for="email"
                            class="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                            >Email
                        </label>
                        <input
                            v-model="form.email"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="example@allodoto.com"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 outline-none placeholder-gray-400 bg-white border border-secondary rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>

                    <div class="mt-6">
                        <div class="flex justify-between mb-2">
                            <label
                                for="password"
                                class="text-sm text-gray-600 dark:text-gray-200"
                                >Mot de passe</label
                            >
                        </div>

                        <input
                            v-model="form.password"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Your Password"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-secondary outline-none rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>

                    <div class="mt-6">
                        <button
                            type="submit"
                            :class="`
                    w-full
                    px-4
                    py-2
                    tracking-wide
                    text-white pointer-cursor
                    transition-colors
                    duration-200
                    transform rounded-md ${
                        disabled
                            ? 'bg-primary'
                            : 'bg-primary hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                    } `"
                            :disabled="disabled"
                        >
                            Se Connecter
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </Dialog>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import Dialog from "primevue/dialog";
import { useStore } from "vuex";
export default defineComponent({
    components: { Dialog },
    props: {
        callbackUrl: { type: String, default: "" },
        show: { type: Boolean, default: true },
    },
    emits: ["onLoggedIn", "cancel"],
    setup(props, { emit }) {
        const store = useStore();
        const disabled = computed(
            () =>
                !form.value?.email ||
                !form.value?.password ||
                isSubmitting.value
        );

        const isSubmitting = ref(false);
        const displayModal = ref(props.show);

        const form = ref({ email: "", password: "" });
        const errorMsg = ref();
        const save = () => {
            isSubmitting.value = true;
            store.dispatch("auth/login", {
                login: form.value.email,
                password: form.value.password,
                onSuccess: () => {
                    isSubmitting.value = false;
                    // isSuccess.value = true;
                    displayModal.value = false;
                    // window.location.replace(props.callbackUrl ?? window.location.href);
                    emit("onLoggedIn");
                },
                onError: (error) => {
                    // isSuccess.value = false;
                    console.log(error?.data?.errors);
                    errorMsg.value = error?.data?.errors;
                    isSubmitting.value = false;
                },
            });
        };

        watch(
            () => props.show,
            (val) => {
                displayModal.value = val;
            }
        );

        return { disabled, errorMsg, form, displayModal, save };
    },
});
</script>
