<template>
    <div :class="`${alert && !isLoading ? 'flex' : 'hidden'}  flex-col`">
        <div class="flex items-center justify-between bg-white p-3 mb-5">
            <h5
                v-if="alert.identifier"
                class="text-sm text-black font-semibold uppercase"
            >
                {{ alert.identifier }}
                {{
                    alert.intervention?.code
                        ? `/ ${alert.intervention?.code}`
                        : ""
                }}
            </h5>
            <div class="flex">
                <div
                    class="border text-sm rounded-xl border-warning px-2 py-1 text-warning"
                    v-if="status?.value === 'pending'"
                >
                    En attente de prise en charge
                </div>
                <div
                    v-else
                    :class="`px-2 py-1 bg-opacity-25 bg-${
                        color[alert.status ?? status?.value]
                    } rounded-xl text-${
                        color[alert.status ?? status?.value]
                    } font-semibold flex items-center text-sm`"
                >
                    <div
                        :class="`w-2 h-2 rounded-full bg-${
                            color[alert.status ?? status?.value]
                        } mr-2`"
                    ></div>
                    {{ statusesText[alert.status ?? status?.value] }}
                </div>
            </div>
            <h5 class="font-semibold text-sm">
                {{ moment(alert.requested_at).format("DD/MM/yyyy HH:mm:ss") }}
            </h5>
        </div>
        <div class="w-full flex bg-white">
            <div class="w-full flex map-section">
                <div
                    class="w-9/12 rounded-sm p-2 relative border-none"
                    v-if="alert"
                >
                    <div
                        v-if="distanceTime"
                        class="absolute top-0 text-sm right-0 z-50 m-3 bg-white px-3 text-slate-600"
                    >
                        {{ distanceTime }}
                    </div>

                    <GMapMap
                        class="h-full"
                        ref="map"
                        :center="center"
                        :zoom="13"
                        :options="options"
                        map-type-id="terrain"
                        style="width: 100%; height: 400px; position: relative"
                    >
                        <GMapPolyline
                            v-show="displayPolyline"
                            :path="points"
                            :options="{
                                strokeColor: drawingColor[currentIntStatus],
                                strokeWeight: 3,
                            }"
                            :editable="false"
                            ref="polyline"
                        />

                        <DirectionsRenderer
                            travelMode="DRIVING"
                            :origin="startLocation"
                            :destination="endLocation"
                            :waypoints="path"
                            :remove="removeDirection"
                            :handleDirection="handleDirectionCreated"
                        />
                        <GMapMarker
                            :position="{
                                lat: alert.latitude,
                                lng: alert.longitude,
                            }"
                            :clickable="true"
                            :draggable="false"
                            :icon="{
                                url: '/images/home.png',
                                scaledSize: {
                                    width: 45,
                                    height: 45,
                                    f: 'px',
                                    b: 'px',
                                },
                                origin: { x: 0, y: 0 },
                                anchor: { x: 18, y: 40 },
                            }"
                        />

                        <GMapMarker
                            v-if="
                                hospitalAddress?.latitude &&
                                hospitalAddress?.longitude
                            "
                            :position="{
                                lat: hospitalAddress?.latitude,
                                lng: hospitalAddress?.longitude,
                            }"
                            :clickable="true"
                            :draggable="false"
                            :icon="{
                                url: '/images/hospital-marker.png',
                                scaledSize: {
                                    width: 45,
                                    height: 45,
                                    f: 'px',
                                    b: 'px',
                                },
                                origin: { x: 0, y: 0 },
                                anchor: { x: 18, y: 40 },
                            }"
                        />

                        <GMapMarker
                            v-if="carAddress?.latitude && carAddress?.longitude"
                            :position="{
                                lat: carAddress?.latitude,
                                lng: carAddress?.longitude,
                            }"
                            :clickable="true"
                            :draggable="false"
                            :icon="{
                                url: '/images/ambulance-a.svg',
                                scaledSize: {
                                    width: 45,
                                    height: 45,
                                    f: 'px',
                                    b: 'px',
                                },
                                origin: { x: 0, y: 0 },
                                anchor: { x: 18, y: 40 },
                            }"
                        />

                        <GMapMarker
                            v-for="hospital in hospitals"
                            :key="`hospital-${hospital.id}`"
                            v-show="getHospitalPosition(hospital)"
                            :position="getHospitalPosition(hospital)"
                            :clickable="true"
                            :draggable="false"
                            :icon="{
                                url:
                                    hospital?.id ==
                                    alert?.intervention?.hospital?.id
                                        ? '/images/hospital-marker.png'
                                        : '/images/hospital-marker1.png',
                                scaledSize: {
                                    width: 45,
                                    height: 45,
                                    f: 'px',
                                    b: 'px',
                                },

                                origin: { x: 0, y: 0 },
                                anchor: { x: 18, y: 40 },
                            }"
                            @click="hospital.windowOpen = true"
                        >
                        </GMapMarker>

                        <GMapMarker
                            v-for="car in cars"
                            :key="`car-${car.id}`"
                            :position="{
                                lat:
                                    car.last_known_position?.latitude ||
                                    car?.current_latitude,
                                lng:
                                    car.last_known_position?.longitude ||
                                    car.current_longitude,
                            }"
                            :clickable="true"
                            :draggable="currentCar?.id == car.id"
                            :icon="{
                                url:
                                    currentCar?.id == car.id
                                        ? '/images/car-marker.png'
                                        : '/images/car-marker1.png',
                                scaledSize: {
                                    width: currentCar?.id == car.id ? 25 : 45,
                                    height: 45,
                                    f: 'px',
                                    b: 'px',
                                },
                                origin: { x: 0, y: 0 },
                                anchor: { x: 18, y: 40 },
                            }"
                            @dragend="carDragEnd"
                        />
                    </GMapMap>
                </div>

                <div
                    class="w-3/12 flex justify-between bg-white rounded-sm p-3 flex-col"
                >
                    <div class="w-full flex">
                        <div>
                            <img
                                class="w-14 h-14 rounded-full border mx-auto object-cover overflow-hidden border-slate-400"
                                :src="alert.patient?.avatar ?? '/images/06.jpg'"
                                alt=""
                            />
                        </div>
                        <div class="flex flex-col justify-around ml-3">
                            <h5 class="text-sm text-slate-700 font-semibold">
                                {{ alert.patient?.full_name }}
                            </h5>
                            <p class="text-slate-500">
                                {{ alert.patient?.username }}
                            </p>
                            <h6 class="text-xs text-slate-400">
                                {{ alert.patient?.profile?.profession }}
                            </h6>
                            <h6 class="text-xs text-primary-">
                                <!-- {{
                  $root
                    .phonenumber(`+${alert.patient.phone_1}`)
                    .formatInternational()
                }} -->
                            </h6>
                        </div>
                    </div>
                    <div class="flex flex-col border-b border-slate-300 pb-5">
                        <div class="mt-2 flex justify-between">
                            <div>
                                <h3 class="text-sm">
                                    {{ alert.patient?.profile?.weight }} Kg
                                </h3>
                                <h6 class="text-xs text-slate-400">Poids</h6>
                            </div>
                            <div>
                                <h3 class="text-sm text-right">
                                    {{
                                        calculateAge(
                                            alert.patient?.profile?.birth_date
                                        )
                                    }}
                                </h3>
                                <h6 class="text-xs text-slate-400">Âge</h6>
                            </div>
                        </div>

                        <div class="mt-2 flex justify-between">
                            <div>
                                <h3 class="text-sm">
                                    {{
                                        `${
                                            alert.patient?.profile?.size / 100
                                        }`.replace(".", "m")
                                    }}
                                </h3>
                                <h6 class="text-xs text-slate-400">Taille</h6>
                            </div>
                            <div>
                                <h3 class="text-sm text-right">
                                    {{
                                        alert.patient?.profile?.blood_group?.toUpperCase() ||
                                        "N/A"
                                    }}
                                </h3>
                                <h6 class="text-xs text-slate-400">
                                    Groupe Sanguin
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex my-1">
                            <h3 class="text-sm text-slate-500 font-medium">
                                Distance:
                            </h3>
                            <strong
                                v-if="distanceEstimated"
                                class="px-1 text-sm text-slate-600 font-semibold"
                                >{{ distanceEstimated }}</strong
                            >
                            <span v-else class="px-1 text-sm">-</span>
                        </div>
                        <div class="flex my-1">
                            <h3 class="text-sm text-slate-500 font-medium">
                                Temps (Estimation):
                            </h3>
                            <strong
                                class="px-1 text-sm text-slate-600 font-semibold"
                                v-if="timeEstimated"
                                >{{ timeEstimated }}
                                {{
                                    alert.status !== "ended" &&
                                    arrivalTime !== "-"
                                        ? `(${moment(arrivalTime).format(
                                              "HH:mm"
                                          )})`.replace(":", "h ")
                                        : alert.started_at && alert.ended_at
                                        ? `(${moment(alert.started_at).format(
                                              "dd/MM/yyyy HH:mm:ss"
                                          )} - ${moment(alert.ended_at).format(
                                              "dd/MM/yyyy HH:mm:ss"
                                          )})`
                                        : ""
                                }}</strong
                            >
                            <span v-else class="px-1">-</span>
                        </div>
                    </div>
                    <div v-if="alert.status !== 'ended'" class="flex mt-3">
                        <button
                            @click="
                                alert?.intervention?.id
                                    ? editIntervention(alert?.intervention)
                                    : newIntervention()
                            "
                            class="rounded bg-primary2 border-0 text-primary2 w-full p-2 bg-opacity-25"
                        >
                            {{
                                alert?.intervention?.id
                                    ? "Mettre á jour l'intervention"
                                    : "Créer l'intervention"
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white p-3 mt-4 mb-5 rounded">
            <tabs
                :options="tabs"
                :selected="selectedTab"
                @tab-changed="tabChanged"
            >
                <template v-for="opt in tabs" :key="opt.key" #[opt.key]>
                    <component
                        :is="opt.component"
                        :intervention="currentIntervention"
                        :interventionCreated="interventionCreated"
                        :selected="selectedTab === opt.key"
                        @updateParent="handleParentUpdate"
                    ></component>
                </template>
            </tabs>
        </div>
    </div>
    <div
        v-if="isLoading"
        class="flex h-full w-full justify-center items-center"
    >
        <Loader class="w-20 h-20 text-slate-400" />
    </div>
</template>

<script>
import store from "@/store";
import HereMap from "@/components/maps/map";
import { gmapStyle } from "@/Utils/map.js";
import {
    computed,
    onBeforeMount,
    ref,
    onMounted,
    watch,
    onBeforeUnmount,
} from "vue";
import DirectionsRenderer from "@/components/maps/DirectionsRenderer";
import Select from "../../components/Select/index.vue";
import moment from "moment";
import Interventions from "./Interventions.vue";
import NewInterventions from "./newIntervention.vue";
import { Field } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import Loader from "@/components/Loader/index.vue";
import { getDistance } from "@/Utils/function";
import { ambulanceASvg } from "@/Utils/data";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import SlidingMarker from "marker-animate-unobtrusive";
// const SlidingMarker = require("marker-animate-unobtrusive");

export default {
    name: "dashboard_alert",
    components: {
        HereMap,
        TabPanel,
        TabView,
        Select,
        Field,
        Loader,
        DirectionsRenderer,
    },

    setup(props, { emit }) {
        const selectedTab = ref("interventions");
        const interventionCreated = ref(false);
        const displayPolyline = ref(false);
        const isLoading = ref(false);
        const removeDirection = ref(false);
        const endLocation = ref(null);
        const startLocation = ref(null);
        const center = ref({});
        const options = ref({ styles: gmapStyle, disableDefaultUI: true });
        const currentAlert = computed(() => store.getters["medical/alert"]);
        const alert = computed(() => store.getters["medical/alert"]);
        const router = useRouter();
        const hospitals = computed(() => store.getters["medical/hospitals"]);
        const interventions = ref([]);
        const mObject = ref(null);
        const allCars = computed(() => store.getters["config/cars"]);
        const cars = computed(() =>
            [...allCars.value].filter(
                (x) => x.id !== alert.value?.intervention?.ambulance?.id
            )
        );

        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const currentCar = computed(
            () => currentAlert.value?.intervention?.ambulance
        );

        const suggestedRoute = ref(null);
        const $route = useRoute();
        const map = ref(null);
        const distanceEstimated = ref(null);
        const timeEstimated = ref(null);
        const arrivalTime = ref(null);
        const distanceTime = ref(null);
        const route = ref(null);
        const points = ref([]);
        const flightPath = computed(() => ({
            path: points.value,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        }));
        const currentIntervention = ref(null);
        const currentIntStatus = ref();
        const carAddress = computed(() =>
            (alert.value?.intervention?.addresses ?? []).find(
                (x) => parseInt(x.name) === 1 || x.name == "from"
            )
        );
        const hospitalAddress = computed(() =>
            (alert.value?.intervention?.addresses ?? []).find(
                (x) => parseInt(x.name) === 2 || x.name == "to"
            )
        );
        const status = computed(() => {
            const [first] = (currentAlert.value.statuses || []).sort((a, b) =>
                moment(a).isAfter(moment(b))
            );
            return first || { value: "waiting" };
        });
        const carMarker = ref(null);
        const color = computed(() => ({
            pending: "pending",
            ended: "success",
            intervention_started: "warning",
            arrived: "primary",
            picked_up: "primary",
            supported: "black",
            moved: "primary",
        }));

        const drawingColor = computed(() => ({
            pending: "#206ba4",
            undefined: "#fb7756",
            intervention_started: "#4fb270",
            ended: "#d34932",
        }));

        const intervention = ref({
            ambulance: null,
            hospital: null,
            description: "",
            isUnique: true,
        });

        const statusesText = computed(() => ({
            pending: "Intervention en cours",
            waiting: "En attente dintervention",
            ended: "Intervention terminée",
            intervention_started: "Intervention en cours (démarré)",
        }));

        const tabs = computed(() => {
            const elms = [
                {
                    name: "Interventions",
                    icon: "",
                    key: "interventions",
                    component: Interventions,
                },
            ];

            if (alert.value.status !== "ended")
                elms.push({
                    name: "Nouvelle intervention",
                    icon: "",
                    key: "newIntervention",
                    component: NewInterventions,
                });

            return elms;
        });
        const path = ref([]);

        const onDrag = (evt) => {
            emit("onMapUpdate", map.value, {
                lat: evt.latLng.lat(),
                lng: evt.latLng.lng(),
            });
        };

        const tabChanged = (tab) => {
            selectedTab.value = tab;
        };

        const getHospitalPosition = (hospital) => {
            if (!hospital) return null;
            else {
                const [address] = (hospital?.addresses || []).sort((a, b) =>
                    moment(a.created_at).isSameOrAfter(b.created_at)
                );
                if (address)
                    return { lat: address.latitude, lng: address.longitude };
                else null;
            }
        };

        const createIntervention = () => {
            const data = {
                alert_id: currentAlert.value?.id,
                ambulance_id: intervention.value?.ambulance?.id,
                hospital_id: intervention.value?.hospital?.id,
                description: intervention.value?.description,
                isUnique: intervention.value?.isUnique || true,
                route: route.value || intervention.value.route,
            };
            store.dispatch("medical/postIntervention", {
                data,
                onSuccess: () => {
                    getAlert();
                    intervention.value = {
                        ambulance: null,
                        hospital: null,
                        description: "",
                        isUnique: true,
                    };
                    interventionCreated.value = true;
                    tabChanged("interventions");
                    carMarker.value = null;
                    points.value = [];
                    mountCurrentRoute();
                },
                onError: () => {},
            });
        };

        const updateIntervention = (formData) => {
            const data = {
                alert_id: currentAlert.value?.id,
                ambulance_id: formData?.ambulance?.id,
                hospital_id: formData?.hospital?.id,
                description: formData?.description,
                isUnique: formData?.isUnique || true,
                route: route.value || intervention.value.route,
            };
            store.dispatch("medical/putIntervention", {
                interventionId: formData.intervention_id,
                data,
                onSuccess: () => {
                    intervention.value = {
                        ambulance: null,
                        hospital: null,
                        description: "",
                        isUnique: true,
                    };
                    getInterventions();

                    carMarker.value?.setMap(null);
                    carMarker.value = null;
                    removeDirection.value = true;
                    loadAlert();
                    buildCarMarker();

                    path.value = [];
                    endLocation.value = null;
                    startLocation.value = null;

                    interventionCreated.value = true;
                    tabChanged("interventions");
                },
                onError: () => {},
            });
        };

        const handleDirectionCreated = (route) => {
            if (!route) return;
            const {
                legs: [{ distance, duration, steps, via_waypoint }],
            } = route;
            const list = [];
            steps.map((x, index) => {
                const {
                    distance: d,
                    duration: dur,
                    start_location,
                    polyline,
                    end_location,
                } = x;
                const wp = via_waypoint.map((w) => w.step_index);

                list.push({
                    order: index + 1,
                    isRequired: wp.includes(index),
                    distance: d.value,
                    duration: dur.value,
                    polyline: polyline.points,
                    start: start_location,
                    end: end_location,
                });
            });
            distanceEstimated.value = distance.text;
            timeEstimated.value = duration.text;
            arrivalTime.value = moment().add(
                parseInt(duration.text),
                "minutes"
            );
            distanceTime.value = `${distance.text} / ${duration.text}`;
            route.value = {
                polyline: route.overview_polyline,
                distance: distance.value,
                duration: duration.value,
                steps: list,
            };

            intervention.value.route = {
                polyline: route.overview_polyline,
                distance: distance.value,
                duration: duration.value,
                steps: list,
            };
        };

        const cancel = () => {
            handleReset();
        };

        const handleParentUpdate = (eventName, data) => {
            removeDirection.value = false;
            if (eventName === "ambulanceChanged") {
                ambulanceChanged(data);
            }

            if (eventName === "description") {
                intervention.value.description = data;
            }

            if (eventName === "isUnique") {
                intervention.value.isUnique = data;
            }

            if (eventName === "hospitalChanged") {
                hospitalChanged(data);
            }

            if (eventName === "createIntervention") {
                createIntervention();
            }

            if (eventName === "editIntervention") {
                updateIntervention(data);
            }

            if (eventName === "interventions") {
                tabChanged("interventions");
            }
        };

        const newIntervention = () => {
            selectedTab.value = "newIntervention";
        };

        const editIntervention = (data) => {
            selectedTab.value = "newIntervention";
            setTimeout(() => {
                currentIntervention.value = { ...data };
            }, 2000);
        };

        const hospitalChanged = (newVal) => {
            const h = hospitals.value.find((x) => x.id === newVal?.id);

            const hospAddress = (intervention.value?.addresses || []).find(
                (x) => parseInt(x.name) == 1
            );
            intervention.value.hospital = newVal;
            path.value[0] = {
                location: {
                    lat: currentAlert.value.latitude,
                    lng: currentAlert.value.longitude,
                },
                stopover: false,
            };
            if (h) {
                const [address] = h.addresses;

                endLocation.value = {
                    lat: hospAddress?.latitude ?? address?.latitude,
                    lng: hospAddress?.longitude ?? address?.longitude,
                };
            }
        };
        const ambulanceChanged = (newVal) => {
            const c = (cars.value || []).find((x) => x.id === newVal?.id);
            intervention.value.ambulance = newVal;

            if (c?.last_known_position || c?.current_latitude)
                startLocation.value = {
                    lat: c.last_known_position?.latitude || c.current_latitude,
                    lng:
                        c.last_known_position?.longitude || c.current_longitude,
                };
        };

        const calculateAge = (dob, dt) => {
            dt = dt || new Date();
            var diff = dt.getTime() - new Date(dob).getTime();
            return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
        };

        const mountCurrentRoute = () => {
            points.value = [];
            displayPolyline.value = true;
            const [lastIntervention] = interventions.value
                //   .filter((x) => x.status !== "ended")
                .sort((a, b) =>
                    moment(a.created_at).isSameOrAfter(b.created_at)
                );

            if (lastIntervention) {
                suggestedRoute.value = (lastIntervention.routes || []).find(
                    (x) => x.status === "suggested"
                );

                distanceEstimated.value = suggestedRoute.value?.distance
                    ? `${suggestedRoute.value?.distance / 1000} km`
                    : "-";
                timeEstimated.value = suggestedRoute.value?.duration
                    ? `${Math.round(suggestedRoute.value?.duration / 60)} mins`
                    : "-";

                arrivalTime.value =
                    timeEstimated.value != "-"
                        ? moment().add(parseInt(timeEstimated.value), "minutes")
                        : "-";
                distanceTime.value = `${distanceEstimated.value} km / ${timeEstimated.value} mins`;
                currentIntStatus.value = lastIntervention.status;
                const [firstPoint] = lastIntervention.routes.filter(
                    (x) => x.departure
                );
                let mainDistance = 0;

                if (firstPoint) {
                    const [lat0, lng0] = firstPoint.departure?.split(",");
                    mainDistance = getDistance(
                        { lat: parseFloat(lat0), lng: parseFloat(lng0) },
                        {}
                    );
                }
                points.value = (lastIntervention.routes || [])
                    .sort((a, b) => a.number < b.number)
                    .reduce((acc, x) => {
                        if (x.departure) {
                            const [lat, lng] = x.departure.split(",");
                            acc.push({
                                lat: parseFloat(lat),
                                lng: parseFloat(lng),
                            });
                        }

                        if (x.arrival) {
                            const [lat1, lng1] = x.arrival.split(",");
                            acc.push({
                                lat: parseFloat(lat1),
                                lng: parseFloat(lng1),
                            });
                        }

                        return acc;
                    }, []);

                if (lastIntervention.ambulance.current_latitude) {
                    center.value = {
                        lat: lastIntervention.ambulance.current_latitude,
                        lng: lastIntervention.ambulance.current_longitude,
                    };
                }
            }
        };

        const getInterventions = () => {
            store.dispatch("medical/getAlertInterventions", {
                portfolioId: portfolio.value?.id,
                id: $route.params.id,
                onSuccess: (data) => {
                    interventions.value = [...data];
                },
                onError: (err) => {},
            });
        };

        const loadAlert = () => {
            store.dispatch("medical/getAlert", {
                id: $route.params.id,
                portfolioId: portfolio.value?.id,
                onSuccess: () => {
                    path.value = [];
                    center.value = {
                        lat: alert.value.latitude,
                        lng: alert.value.longitude,
                    };
                    mountCurrentRoute();
                    isLoading.value = false;
                },
            });
        };

        onBeforeMount(() => {
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value.id },
            });
            store.dispatch("config/getAmbulances", {});
        });

        watch(
            () => interventions.value,
            (val) => {
                mountCurrentRoute();
            }
        );

        const carMoved = (lat, lng) => {
            store.dispatch("medical/interventionCarMoved", {
                portfolioId: portfolio.value.id,
                interventionId: currentAlert.value?.intervention?.id,
                alertId: currentAlert.value?.id,
                carId: currentCar.value?.id,
                data: {
                    latitude: lat,
                    longitude: lng,
                },
                onSuccess: () => {},
                onError: () => {},
            });
        };

        const listen = () => {
            if (!currentAlert.value?.identifier) return;
            window.Echo.channel(
                `alerts.${currentAlert.value?.identifier
                    ?.toString()
                    .toLowerCase()}`
            ).listen("NewIntervention", (data) => {
                console.log("NewIntervention", data);
            });

            window.Echo.channel(
                `alerts.${currentAlert.value?.identifier
                    ?.toString()
                    .toLowerCase()}.updated`
            ).listen("AlertUpdatedEvent", (data) => {
                console.log("AlertUpdatedEvent", data);
                updateAlert();
            });

            listenToCars();
        };

        const getAlert = () => {
            store.dispatch("medical/getAlert", {
                id: $route.params.id,
                portfolioId: portfolio.value?.id,
                onSuccess: () => {
                    console.log(center.value, "center.value");

                    center.value = {
                        lat: alert.value.latitude,
                        lng: alert.value.longitude,
                    };
                    listenToCars();
                    // isLoading.value = true;
                },
            });
        };

        const updateAlert = () => {
            store.dispatch("medical/getAlert", {
                id: $route.params.id,
                portfolioId: portfolio.value?.id,
                onSuccess: () => {
                    // isLoading.value = true;
                },
            });
        };

        const listenToCars = () => {
            if (!currentAlert.value?.intervention) return;
            window.Echo.channel(
                `cars.${currentAlert.value?.intervention?.ambulance?.code
                    ?.toString()
                    .toLowerCase()}.${currentAlert.value?.intervention?.code
                    ?.toString()
                    .toLowerCase()}.position`
            ).listen(".PositionChanged", (data) => {
                console.log("PositionChanged", data, data.position.latitude);

                var icon = carMarker.value?.getIcon();
                const id = currentCar.value?.code?.toLowerCase();
                var newPosition = new google.maps.LatLng(
                    data.position.latitude,
                    data.position.longitude
                );
                var lastPosition = new google.maps.LatLng(
                    carMarker.value.getPosition().lat(),
                    carMarker.value.getPosition().lng()
                );

                var heading = google.maps.geometry.spherical.computeHeading(
                    lastPosition,
                    newPosition
                );
                var image = carMarker.value.getIcon();
                image.rotation = heading;
                if (heading !== 0)
                    $('img[src="/images/ambulance-a.svg#car1"]').css({
                        width: "60px !important",
                        transform: "rotate(" + heading + "deg)",
                        // "transform-origin":
                        //     (heading > 70 && heading <= 100) ||
                        //     (heading > -70 && heading <= -100)
                        //         ? "60px 50px"
                        //         : "50px 60px",
                    });

                // carMarker.value?.setIcon(image);
                carMarker.value.setPosition(newPosition);
                const coordinate = new google.maps.LatLng(
                    data.position.latitude,
                    data.position.longitude
                ); //Store these lat lng values somewhere. These should be constant.
                center.value = {
                    lat: data.position.latitude,
                    lng: data.position.longitude,
                };

                const bound = new google.maps.LatLngBounds([
                    new google.maps.LatLng(
                        data.position.latitude,
                        data.position.longitude
                    ),
                    new google.maps.LatLng(
                        alert.value.latitude,
                        alert.value.longitude
                    ),
                    new google.maps.LatLng(
                        hospitalAddress.value.latitude,
                        hospitalAddress.value.longitude
                    ),
                ]);
                // mObject.value?.panToBounds(bound);
            });
        };

        const carDragEnd = (data) => {
            carMoved(data.latLng.lat(), data.latLng.lng());
        };

        const interventionCarDragEnd = (data) => {
            carMoved(data.latLng.lat(), data.latLng.lng());
        };

        onBeforeUnmount(() => {
            window.Echo.leaveChannel(
                `alerts.${currentAlert.value?.identifier
                    ?.toString()
                    .toLowerCase()}`,
                () => {
                    console.log("Cahnnel leaved");
                }
            );
            window.Echo.leaveChannel(
                `alerts.${currentAlert.value?.identifier
                    ?.toString()
                    .toLowerCase()}.updated`,
                () => {
                    console.log("Chanel leaved");
                }
            );
        });

        const animateMarkerTo = (marker, newPosition) => {
            var options = {
                duration: 1000,
                easing: function (x, t, b, c, d) {
                    // jquery animation: swing (easeOutQuad)
                    return -c * (t /= d) * (t - 2) + b;
                },
            };

            window.requestAnimationFrame =
                window.requestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.msRequestAnimationFrame;
            window.cancelAnimationFrame =
                window.cancelAnimationFrame || window.mozCancelAnimationFrame;

            // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
            marker.AT_startPosition_lat = marker.getPosition().lat();
            marker.AT_startPosition_lng = marker.getPosition().lng();
            var newPosition_lat = newPosition.lat();
            var newPosition_lng = newPosition.lng();

            // crossing the 180° meridian and going the long way around the earth?
            if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
                if (newPosition_lng > marker.AT_startPosition_lng) {
                    newPosition_lng -= 360;
                } else {
                    newPosition_lng += 360;
                }
            }

            var animateStep = function (marker, startTime) {
                var ellapsedTime = new Date().getTime() - startTime;
                var durationRatio = ellapsedTime / options.duration; // 0 - 1
                var easingDurationRatio = options.easing(
                    durationRatio,
                    ellapsedTime,
                    0,
                    1,
                    options.duration
                );

                if (durationRatio < 1) {
                    marker.setPosition({
                        lat:
                            marker.AT_startPosition_lat +
                            (newPosition_lat - marker.AT_startPosition_lat) *
                                easingDurationRatio,
                        lng:
                            marker.AT_startPosition_lng +
                            (newPosition_lng - marker.AT_startPosition_lng) *
                                easingDurationRatio,
                    });

                    // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
                    if (window.requestAnimationFrame) {
                        marker.AT_animationHandler =
                            window.requestAnimationFrame(function () {
                                animateStep(marker, startTime);
                            });
                    } else {
                        marker.AT_animationHandler = setTimeout(function () {
                            animateStep(marker, startTime);
                        }, 17);
                    }
                } else {
                    marker.setPosition(newPosition);
                }
            };

            // stop possibly running animation
            if (window.cancelAnimationFrame) {
                window.cancelAnimationFrame(marker.AT_animationHandler);
            } else {
                clearTimeout(marker.AT_animationHandler);
            }

            animateStep(marker, new Date().getTime());
        };

        const buildCarMarker = () => {
            carMarker.value?.setMap(null);
            const id = currentCar.value?.code?.toLowerCase();
            const [lastPosition] = (
                currentAlert.value?.intervention?.positions || []
            ).sort(
                (a, b) =>
                    moment(b.updated_at).unix() - moment(a.updated_at).unix()
            );

            // SlidingMarker.initializeGlobally();

            console.log(carAddress.value, "carAddress.value?.latitude");
            carMarker.value = new SlidingMarker({
                draggable: alert.value.status !== "ended",
                position: new google.maps.LatLng(
                    lastPosition?.latitude ?? carAddress.value?.latitude,
                    lastPosition?.longitude ?? carAddress.value?.longitude
                ),
                optimized: false,
                icon: {
                    url: `/images/ambulance-a.svg#car1`,
                    scaledSize: {
                        width: 60,
                        height: 60,
                        f: "px",
                        b: "px",
                    },
                    // size: new google.maps.Size(50, 50),
                    rotation: 0,
                    // anchor: new google.maps.Point(0, -30),
                    origin: { x: 0, y: 0 },
                    anchor: { x: 20, y: 20 },
                },
                title: "Ambulance",
            });

            google.maps.event.addListener(
                carMarker.value,
                "dragend",
                function (evt) {
                    console.log("marker", evt);

                    interventionCarDragEnd(evt);
                }
            );
            carMarker.value?.setMap(mObject.value);

            const bound = [];

            if (alert.value?.latitude) {
                bound.push(
                    new google.maps.LatLng(
                        alert.value?.latitude,
                        alert.value?.longitude
                    )
                );
            }
            if (hospitalAddress.value?.latitude) {
                bound.push(
                    new google.maps.LatLng(
                        hospitalAddress.value?.latitude,
                        hospitalAddress.value?.longitude
                    )
                );
            }
            mObject.value?.panTo(
                new google.maps.LatLngBounds(...bound).getCenter()
            );
        };

        watch(
            () => alert.value,
            (val) => {
                buildCarMarker();
            }
        );

        watch(
            () => portfolio.value,
            (val) => {
                router.push({ name: "app.alerts" });
            }
        );

        onMounted(() => {
            isLoading.value = true;
            loadAlert();
            getInterventions();
            setInterval(() => {
                color.value = color.value === "danger" ? "white" : "danger";
                listen();
            }, 1000);

            map.value?.$mapPromise.then((mapObject) => {
                mObject.value = mapObject;

                buildCarMarker();
            });
        });

        return {
            options,
            map,
            currentAlert,
            carMarker,
            color,
            endLocation,
            startLocation,
            hospitals,
            cars,
            isLoading,
            currentCar,
            currentIntervention,
            interventions,
            path,
            removeDirection,
            distanceTime,
            distanceEstimated,
            timeEstimated,
            arrivalTime,
            tabs,
            center,
            status,
            interventionCreated,
            alert,
            hospitalAddress,
            intervention,
            selectedTab,
            statusesText,
            points,
            carAddress,
            flightPath,
            currentIntStatus,
            displayPolyline,
            drawingColor,
            currentIntStatus,
            onDrag,
            handleParentUpdate,
            getHospitalPosition,
            moment,
            editIntervention,
            cancel,
            handleDirectionCreated,
            createIntervention,
            newIntervention,
            tabChanged,
            calculateAge,
            carDragEnd,
        };
    },
};
</script>

<style scoped lang="scss">
#box {
    .map-section {
        height: 400px;
    }
}

#map {
    width: 60vw;
    min-width: 360px;
    text-align: center;
    margin: 5% auto;
    background-color: #ccc;
}
</style>
